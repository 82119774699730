import  React,{ useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormField, useValidation } from '@jpmuitk/form-field';
import { Form } from '@jpmuitk/forms';
import { Input } from '@jpmuitk/input';
import { OrderedButton, ButtonBar } from '@jpmuitk/button-bar';
import { NewReturningUserData, errorMessageStyle, base_url } from '../../global.constants';
import { Banner } from '@jpmuitk/banner';
import { emailValidationRules } from '../../ValidationRules';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { saveReturnUser } from './redux/action/newReturningUserAction';
import Loader from '../../../components/loader/Loader';
import '../../global.scss'


const NewReturningUser = () => {
    const history = useHistory();
    const [showBanner, setShowBanner] = useState(false);
    const [userType, setUserType] = useState('');
    const [email, setEmail] = useState('');
    const [bannerTitle, setBannerTitle] = useState('');
    const [data, setData] = useState({});
    const dispatch = useDispatch();
    const ReturnData = useSelector(state => state.ReturnUserReducer.ReturnUser);
    const isLoading = useSelector(state => state.ReturnUserReducer.isLoading);
    const isUserLoading = useSelector(state => state.AuthCheckReducer.isLoading);
    const [emailExceptions, validateEmail] = useValidation(emailValidationRules);
    const newuser = useSelector(state => state.ReturnUserReducer.newUserErrorCode);
    const existing = useSelector(state => state.ReturnUserReducer.success);
    

    useEffect(() =>{
        if(ReturnData)
        {
            setData(ReturnData)
        }
    
    },[ReturnData])


    useEffect(() => {
        if (existing) {
        window.location = base_url+'/dashboard'
        }
    }, [existing]);

    useEffect(() => {
        if (newuser) 
        {
        history.push('/registration')
        }
    }, [newuser]);
     

    useEffect(() => {
    }, [userType])
    const handleClose = () => {
        setShowBanner(false);
        setBannerTitle('');
    };
    const handleChange = (e) => {
        const { value } = e.target;
        validateEmail(value);
        setEmail(value);

        setData({
            ...data, emailAddress: value
        })
    }
    const handleUserTypeChange = (userType, path) => {
        if(path)
        history.push(path)
        setUserType(userType)
    }

 const handleChanges = () =>{
    dispatch(saveReturnUser(data))
 }
    return (
        <>  
            {isLoading || isUserLoading? <Loader /> : null}
           <div className={`authenticationContainer`} id="main">
            {showBanner && <Banner state="success" onClose={handleClose}>{bannerTitle}</Banner>}
            <h1>{NewReturningUserData.header}</h1>
            <p>{NewReturningUserData.info}</p>
            <ButtonBar className='marginTop30v justifyCenter'>
                <OrderedButton className={'regularButton'} onClick={() => handleUserTypeChange(NewReturningUserData.newUser, '/registration')}>{NewReturningUserData.newUser}</OrderedButton>
                <OrderedButton className={`ctaButton`} variant="cta" onClick={() => handleUserTypeChange(NewReturningUserData.returningUser)}>{NewReturningUserData.returningUser}</OrderedButton>
            </ButtonBar>
            {(userType === NewReturningUserData.returningUser) &&
            <div className='marginTop30'>
                <Form fullWidthFields className={`formClass`}>
                    <Grid container spacing={2} >
                        <Grid item xs={12}>
                            <FormField label="E-mail address"
                                HelperTextProps={errorMessageStyle}
                                helperText={emailExceptions[0]?.message}
                                validationState={emailExceptions[0]?.type}>
                                <Input name='E-mail address' fullWidth autoComplete='off'
                                    value={email}
                                    onChange={handleChange}
                                    onBlur={handleChange}
                                />
                            </FormField>
                        </Grid>
                    </Grid>
                </Form>
                <ButtonBar className='marginTop30 justifyCenter'>
                    <OrderedButton className={'regularButton'} onClick={() => handleUserTypeChange('')}>{NewReturningUserData.cancel}</OrderedButton>
                    <OrderedButton className={`ctaButton`} variant="cta" onClick={handleChanges}>{NewReturningUserData.validate}</OrderedButton>
                </ButtonBar>
            </div>
            }
        </div>
        </>
    )
}
export default NewReturningUser;