import axios from "axios";
import {
  base_url,
  base_url_user,
  base_url_loan,
} from "../../../../global.constants";

const baseURL = base_url_loan + "/api/v1/loansvc/ext";
const baseURL_user = base_url_user + "/api/v1/usersvc/ext";

export const fetchPurpose_failure = (error) => {
  return {
    type: "FETCH_PURPOSE_FAILURE",
    payload: error,
  };
};

export const updatePurposeData = (payload) => ({
  type: "UPDATE_PURPOSE_DETAILS_LIST",
  payload,
});

export const updateDeleteData = (payload) => ({
  type: "UPDATE_DELETE_DETAILS_LIST",
  payload,
});

export const setPurposeData = (payload) => ({
  type: "SET_PURPOSE_DETAILS_LIST",
  payload,
});

export const postFailureData = (payload) => ({
  type: "FETCH_PURPOSE_FAILURE",
  payload,
});

export const getPurposeData = (loanId, userId) => {
  const payload = { userId: userId, loanId: loanId };
  return async (dispatch) => {
    dispatch({ type: "REQUEST_GET_PURPOSE_DATA" });
    // dispatch(fetchAccountant_request(AccountantpsaveLoanPurposeayload))
    const Url = baseURL + `/getLoanPurpose.do`;
    axios
      .post(Url, payload)
      .then((response) => {
        if ((response && response.status == 200) || response.status == 202) {
          dispatch(setPurposeData(response.data?.purposeVOs));
        } else {
          dispatch(fetchPurpose_failure(response.status));
        }
      })
      .catch((error) => {
        dispatch(fetchPurpose_failure(error.response.status));
      });
  };
};

export const setEntityData = (payload) => ({
  type: "SET_ENTITY_DETAILS_LIST",
  payload,
});

export const setStoreData = (payload) => ({
  type: "SET_STORE_DETAILS_LIST",
  payload,
});

export const getStoreData = (loanId, userId) => {
  const payload = { loanId: loanId, userId: userId };
  return async (dispatch) => {
    dispatch({ type: "REQUEST_GET_PURPOSE_DATA" });
    const Url = baseURL_user + `/getStoreInfoSummary.do`;
    axios
      .post(Url, payload)
      .then((response) => {
        if ((response && response.status == 200) || response.status == 202) {
          dispatch(setStoreData(response.data));
        } else {
          dispatch(fetchPurpose_failure(response.status));
        }
      })
      .catch((error) => {
        dispatch(fetchPurpose_failure(error.response.status));
      });
  };
};
export const getEntityData = (loanId, userId) => {
  const payload = { loanId: loanId };
  return async (dispatch) => {
    dispatch({ type: "REQUEST_GET_PURPOSE_DATA" });
    const Url = baseURL + `/getBorrowerEntity.do`;
    axios
      .post(Url, payload)
      .then((response) => {
        if ((response && response.status == 200) || response.status == 202) {
          dispatch(setEntityData(response.data));
        } else {
          dispatch(fetchPurpose_failure(response.status));
        }
      })
      .catch((error) => {
        dispatch(fetchPurpose_failure(error.response.status));
      });
  };
};

export const postDeleteData = (url, payload) => {
  url = baseURL + url;
  return (dispatch) => {
    axios
      .post(url, payload)
      .then((response) => {})
      .catch((error) => {
        console.error("Something went wrong!", error);
      });
  };
};

export const postData = (url, data, userId, loanId) => async (dispatch) => {
  url = baseURL + url;
  const payload = { loanId: loanId, userId: userId, purposeVOs: data };
  try {
    dispatch({ type: "REQUEST_GET_PURPOSE_DATA" });
    await axios.post(url, payload).then((response) => {
      if (response && response.status === 200) {
        dispatch(updatePurposeData());
      } else {
        dispatch(postFailureData(response?.status));
      }
    });
  } catch (e) {
    dispatch(postFailureData(e.response?.status));
    return e;
  }
};
