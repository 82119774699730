import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FormField, useValidation } from "@jpmuitk/form-field";
import Grid from "@material-ui/core/Grid";
import { Input } from "@jpmuitk/input";
import { DatePicker } from "@jpmuitk/date-picker";
import { Banner } from "@jpmuitk/banner";
import { AriaAnnouncerProvider } from "@jpmuitk/aria-announcer";
import { Button } from "@jpmuitk/button";
import { RadioButtonGroup } from "@jpmuitk/radio-button";
import { Dropdown } from "@jpmuitk/dropdown";
import moment from "moment";
import {
  errorMessageStyle,
  yearsStartValue,
  yearsEndValue,
  Tooltip_info,
  RestarauntSummary,
} from "../../global.constants";
import dynamicForm from "../../../components/form/DynamicForm";
import StockHolderDetails from "./StockholderDetails";
import "react-dates/lib/css/_datepicker.css";
import "../../global.scss";
import "./RestDetails.scss";
import { BIZSTRUCTURE, selectState, selectEntity } from "./common.js";
import ErrorBoundary from "../../../components/form/ErrorBoundary";
import {
  zipcodeValidationRules,
  restaurantAddressValidationRules,
} from "../../ValidationRules";

const RestDetails = ({
  createInitialList,
  addNewItem,
  removeAnItem,
  header,
  remove,
  defaultValue,
  ind,
  group,
  updateRest,
  deleteStakeHolder,
  updateStakeHolder,
  btnDisable,
}) => {
  const mergedErrorMessages = (exceptions) =>
    exceptions.map(({ type, message }) => `${message}`).join("");
  const [zipcodeExceptions, validateZipcode] = useValidation(
    zipcodeValidationRules
  );
  const [addressExceptions, validateAddress] = useValidation(
    restaurantAddressValidationRules
  );
  const countrystateData = useSelector(
    (state) => state.CountrystatesReducer.Countrystates
  );
  const loanId = useSelector((state) => state.DashboardReducer?.currentLoan);
  const loggedUserId = useSelector(
    (state) => state.AuthCheckReducer?.loggedInUserId
  );
  const [restaurantDetails, setRestaurantDetails] = useState(defaultValue);

  let addressFields;
  let borrowerEntityFields;
  let expiryDate;
  let purchaseDate;
  const yearsDate = [...new Array(yearsStartValue)].map((ele, id) => {
    let yr = new Date().getFullYear() - yearsEndValue + id;
    return {
      value: yr,
      text: String(yr),
      id: yr,
    };
  });
  const ownedOrLease = [
    {
      label: "Leased",
      value: "LEASED",
    },
    {
      label: "Land Owned",
      value: "OWNED",
    },
  ];

  useEffect(() => {
    if (restaurantDetails) {
      updateRest(ind, { ...restaurantDetails });
    }
  }, [restaurantDetails]);

  // useEffect(() => {
  //   setRestaurantDetails(defaultValue);
  // }, [defaultValue]);

  const handleAddStackholder = () => {
    let stockholdersList = addNewItem(restaurantDetails.stockHolders);
    restaurantDetails.stockHolders = [...stockholdersList];
    setRestaurantDetails({ ...restaurantDetails });
  };

  const handleStackHolders = (e) => {
    if (/^\d{0,2}$/g.test(e?.target?.value) && e?.target?.value < 100) {
      let stockholdersVal = createInitialList(e?.target?.value);
      if (restaurantDetails.borrowerEntity === null) {
        restaurantDetails.borrowerEntity = {};
      }
      restaurantDetails.stockHolders = stockholdersVal;
      setRestaurantDetails({ ...restaurantDetails });
    }
  };

  const handleUpdateStackeHolder = (ind) => {
    let storeArr = [];
    if (!Boolean(storeArr.length)) storeArr = restaurantDetails?.stockHolders;
    let stockholdersList = storeArr?.map(
      ({
        borrowerName,
        ownershipPercent,
        stockHolderId,
        stockHolderName,
        stockHolderTitle,
        ...rest
      }) => ({
        ownershipPercent: parseInt(ownershipPercent),
        stockHolderId,
        stockHolderName,
        stockHolderTitle,
        borrowerName: restaurantDetails?.borrowerEntity?.borrowerName,
      })
    );
    const payload = {
      borrowerEntity: {
        userId: loggedUserId,
        loanId: loanId?.activeLoanId,
        borrowerId: restaurantDetails?.borrowerEntity?.borrowerId,
        borrowerName: restaurantDetails?.borrowerEntity?.borrowerName,
        businessStructId: selectEntity(
          restaurantDetails?.borrowerEntity?.businessStructId
        ).businessStructId,
        stateOfIncorporation: selectState(
          restaurantDetails?.borrowerEntity?.stateOfIncorporation,
          countrystateData
        ).stateId,
      },
      stockHolders: stockholdersList,
    };
    updateStakeHolder(payload, ind, group, false);
    // updateRest(ind, { ...restaurantDetails });
  };

  const handleRemoveStackholder = (singleStock, idx) => {
    const {
      stockHolderId,
      stockHolderName,
      stockHolderTitle,
      stockholderType,
      ownershipPercent,
      createdDate,
      updatedDate,
    } = singleStock;

    let deleteSingleStockholderData = {
      userId: loggedUserId,
      loginId: null,
      loanId: restaurantDetails.loanId,
      actionName: null,
      successCode: null,
      errorCode: null,
      url: null,
      errKeyList: null,
      stockHolderId: stockHolderId || null,
      storeId: restaurantDetails.storeId,
      borrowerId: restaurantDetails?.borrowerEntity?.borrowerId || null,
      borrowerName: restaurantDetails?.borrowerEntity?.borrowerName || null,
      stockHolderName: stockHolderName || null,
      stockHolderTitle: stockHolderTitle || null,
      ownershipPercent: ownershipPercent || null,
      createdDate: createdDate || null,
      createdBy: loggedUserId,
      updatedDate: updatedDate || null,
      updatedBy: loggedUserId,
      stockholderType: stockholderType || null,
      deleted: false,
    };
    let stockholderVal = removeAnItem(idx, restaurantDetails.stockHolders);
    restaurantDetails.stockHolders = stockholderVal;
    setRestaurantDetails({ ...restaurantDetails });
    deleteStakeHolder(deleteSingleStockholderData, idx);
  };

  const updateStockholder = (e, ind) => {
    const { name, value } = e.target;
    if (
      (/^\d{0,2}$/g.test(value) && value < 100) ||
      name !== "ownershipPercent"
    ) {
      restaurantDetails.stockHolders[ind][name] = value;
      setRestaurantDetails({ ...restaurantDetails });
    } else if (value < 101 && name === "ownershipPercent") {
      restaurantDetails.stockHolders[ind][name] = value;
      setRestaurantDetails({ ...restaurantDetails });
    }
  };

  const handleChange = (val, key, inx) => {
    localStorage.setItem("fix", 0);
    addressFields = restaurantDetails?.addressVO;
    borrowerEntityFields = restaurantDetails?.borrowerEntity;
    expiryDate = restaurantDetails?.licenseExpirationDate;
    purchaseDate = restaurantDetails?.purchaseDate;
    if (addressFields && key && key == "addressVO.address") {
      addressFields.address = val;
    } else if (addressFields && key && key == "addressVO.city") {
      addressFields.city = val;
    } else if (addressFields && key && key == "addressVO.stateId") {
      addressFields.stateId = val;
    } else if (addressFields && key && key == "addressVO.zipCode") {
      addressFields.zipCode = val;
    } else if (key == "licenseExpirationDate") {
      expiryDate = moment(val).format("MM/DD/YYYY");
    } else if (key == "purchaseDate") {
      purchaseDate = moment(val).format("MM/DD/YYYY");
    }
    if (borrowerEntityFields && key && key == "borrowerEntity.borrowerName") {
      borrowerEntityFields.borrowerName = val;
    } else if (
      borrowerEntityFields &&
      key &&
      key == "borrowerEntity.businessStructId"
    ) {
      borrowerEntityFields.businessStructId = val;
    } else if (
      borrowerEntityFields &&
      key &&
      key == "borrowerEntity.stateOfIncorporation"
    ) {
      borrowerEntityFields.stateOfIncorporation = val;
    }
    if (key) {
      let data = dotObj(key);
      let id = key.split(".")[0];
      if (key.split(".").length > 1) {
        setRestaurantDetails({
          ...restaurantDetails,
          [id]: { ...data.ref, [data.id]: val },
        });
      } else {
        data.ref[data.id] = val;
        setRestaurantDetails({ ...restaurantDetails, [data.id]: val });
      }
      if (key == "addressVO.address") {
        validateAddress(val);
      }
      if (key == "addressVO.zipCode") {
        zipCodeValidation(val);
      }
    }
    updateRest(ind, { ...restaurantDetails });
  };

  const dotObj = (key, val) => {
    let temp = restaurantDetails;
    let id = "";
    key.split(".").map((ele) => {
      if (temp[ele] instanceof Object) {
        temp = temp[ele];
      } else {
        id = ele;
      }
    });
    return { ref: temp, id: id };
  };

  const Validator = (e) => {
    if (/^\d{0,5}$/g.test(e?.target?.value)) {
      restaurantDetails.nationalStoreNumber = e?.target?.value;
      setRestaurantDetails({ ...restaurantDetails });
      handleChange(e.target.value, "nationalStoreNumber");
    }
  };

  const zipValidator = (e) => {
    validateZipcode([]);
    const zipcode = e?.target?.value.replace("-", "");
    if (/^[0-9]*$/.test(zipcode)) {
      if (restaurantDetails.addressVO == null) {
        restaurantDetails.addressVO = {};
      }
      if (zipcode.length <= 5) {
        setRestaurantDetails({
          ...restaurantDetails,
          addressVO: { ...restaurantDetails.addressVO, zipCode: zipcode },
        });
      } else if (zipcode.length < 10) {
        setRestaurantDetails({
          ...restaurantDetails,
          addressVO: {
            ...restaurantDetails.addressVO,
            zipCode: String(zipcode).replace(/(\d{5})/, "$1-"),
          },
        });
      }
    }
  };
  const zipCodeValidation = (value) => {
    if (value.length != 10 && value.length != 5) {
      validateZipcode(value);
    } else {
      validateZipcode([]);
    }
  };

  let stocks = restaurantDetails?.stockHolders?.filter(
    (ele, indx) => ele !== undefined
  );

  return (
    <div className={`nestedFormContainer`}>
      <div id={`header${ind}`} className={`restuarantHeaderContainer`}>
        <h2 className={`headingClass`}>{`${header} #${ind + 1}`}</h2>
        <Button onClick={() => remove(ind, group)}>
          delete this restaurant
        </Button>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormField label="National Restaurant Number">
            <Input
              name="nationalStoreNumber"
              fullWidth
              autoComplete="off"
              value={restaurantDetails?.nationalStoreNumber}
              onChange={Validator}
            />
          </FormField>
        </Grid>
        <Grid item xs={12}>
          <FormField
            label={
              <>
                {RestarauntSummary.restAddLabel}
                <span
                  className="addRequired"
                  role="img"
                  aria-label={RestarauntSummary.restAddLabel}
                >
                  {" "}
                </span>
              </>
            }
            classes={{ helperText: "errorHelperText" }}
            helperText={mergedErrorMessages(addressExceptions)}
            validationState={addressExceptions[0]?.type}
            hasStatusIndicator={true}
          >
            <span
              tabIndex="0"
              className="sr-only"
              role="tooltip"
              aria-label={Tooltip_info}
            ></span>

            <Input
              name="address"
              className="textFont"
              multiline
              fullWidth
              autoComplete="off"
              value={restaurantDetails?.addressVO?.address}
              onChange={(e) =>
                handleChange(e.target.value, "addressVO.address")
              }
              role="tooltip"
              aria-label={Tooltip_info}
            />
          </FormField>
        </Grid>
        <Grid item xs={12}>
          <FormField label="City">
            <Input
              name="city"
              fullWidth
              autoComplete="off"
              value={restaurantDetails?.addressVO?.city}
              onChange={(e) => handleChange(e.target.value, "addressVO.city")}
            />
          </FormField>
        </Grid>
        <Grid item xs={12}>
          <FormField label="State">
            <Dropdown
              name="state"
              className={"dropDownField"}
              itemToString={(item) => (item ? item.stateName : "")}
              selectedItem={selectState(
                restaurantDetails?.addressVO?.stateId,
                countrystateData
              )}
              source={countrystateData}
              onSelect={(e, item) =>
                handleChange(item.stateId, "addressVO.stateId")
              }
            />
          </FormField>
        </Grid>
        <Grid item xs={12}>
          <FormField
            label="Zip"
            HelperTextProps={errorMessageStyle}
            helperText={zipcodeExceptions[0]?.message}
            validationState={zipcodeExceptions[0]?.type}
          >
            <Input
              name="zip"
              fullWidth
              autoComplete="off"
              value={restaurantDetails?.addressVO?.zipCode}
              onChange={zipValidator}
              onBlur={(e) => handleChange(e.target.value, "addressVO.zipCode")}
            />
          </FormField>
        </Grid>
        <Grid item xs={12}>
          <FormField label="Expiration Date of License Agreement (mm/dd/yyyy)">
            <DatePicker
              defaultDate={moment("01/01/2020", "DD/MM/YYYY")}
              dateFormat={"MM/DD/YYYY"}
              date={moment(
                restaurantDetails?.licenseExpirationDate,
                "MM/DD/YYYY"
              )}
              YearDropdownProps={{ source: yearsDate, listWidth: "80px" }}
              onInputValueChange={(date) =>
                handleChange(date, "licenseExpirationDate")
              }
              PopperProps={{ placement: "bottom-end" }}
            />
          </FormField>
        </Grid>
        <Grid item xs={12}>
          <div className="padding8">
            <RadioButtonGroup
              aria-label="owned or leased"
              legend="Is the land owned or leased?"
              name="ownedOrleased"
              role="radio"
              radios={ownedOrLease}
              className={"singleRowRadioBtn"}
              value={restaurantDetails?.landOwnedOrLeased}
              onChange={(e) =>
                handleChange(e.target.value, "landOwnedOrLeased")
              }
            />
          </div>
        </Grid>
        {restaurantDetails?.landOwnedOrLeased == "LEASED" ? (
          <Grid item xs={12}>
            <FormField label="Please provide lease options">
              <span
                tabIndex="0"
                className="sr-only"
                role="tooltip"
                aria-label={Tooltip_info}
              ></span>

              <Input
                name="notes"
                fullWidth
                autoComplete="off"
                multiline={true}
                className={"leaseOptions"}
                value={restaurantDetails?.leaseOptions}
                onChange={(e) => handleChange(e.target.value, "leaseOptions")}
              />
            </FormField>
          </Grid>
        ) : (
          ""
        )}
        <Grid item xs={12}>
          <FormField label="Date of Purchase (mm/dd/yyyy)">
            <DatePicker
              defaultDate={moment("01/01/2020", "DD/MM/YYYY")}
              dateFormat={"MM/DD/YYYY"}
              date={moment(restaurantDetails?.purchaseDate, "MM/DD/YYYY")}
              onInputValueChange={(date) => handleChange(date, "purchaseDate")}
              YearDropdownProps={{ source: yearsDate, listWidth: "80px" }}
              PopperProps={{ placement: "bottom-end" }}
            />
          </FormField>
        </Grid>
        <Grid item xs={12}>
          <FormField
            label="What Company or Individual is the Franchisee Agreement assigned to for this restaurant?"
            classes={{ helperText: "errorHelperText" }}
          >
            <span
              tabIndex="0"
              className="sr-only"
              role="tooltip"
              aria-label={Tooltip_info}
            ></span>
            <Input
              name="mgmtCompanyName"
              fullWidth
              autoComplete="off"
              multiline={true}
              className={"boldText"}
              value={restaurantDetails?.borrowerEntity?.borrowerName}
              onChange={(e) =>
                handleChange(e.target.value, "borrowerEntity.borrowerName", ind)
              }
            />
          </FormField>
        </Grid>
        <Grid item xs={12}>
          <FormField label="How is this entity structured?">
            <Dropdown
              itemToString={(item) => (item ? item.businessStructName : "")}
              selectedItem={selectEntity(
                restaurantDetails?.borrowerEntity?.businessStructId
              )}
              source={BIZSTRUCTURE}
              onSelect={(e, item) =>
                handleChange(
                  item.businessStructId,
                  "borrowerEntity.businessStructId"
                )
              }
              className={"dropDownField"}
            />
          </FormField>
        </Grid>
        {restaurantDetails?.borrowerEntity?.businessStructId > "1" &&
        restaurantDetails?.borrowerEntity?.businessStructId < "7" ? (
          <>
            <Grid item xs={12}>
              <FormField label="State of Incorporation">
                <Dropdown
                  itemToString={(item) => (item ? item.stateName : "")}
                  selectedItem={selectState(
                    restaurantDetails?.borrowerEntity?.stateOfIncorporation,
                    countrystateData
                  )}
                  source={countrystateData}
                  onSelect={(e, item) =>
                    handleChange(
                      item.stateId,
                      "borrowerEntity.stateOfIncorporation"
                    )
                  }
                  className={"dropDownField"}
                />
              </FormField>
            </Grid>
            <Grid item xs={12}>
              <FormField label="How many shareholders (please list all)">
                <Input
                  name="noOfShareholders"
                  fullWidth
                  autoComplete="off"
                  className={"boldText"}
                  value={stocks?.length == 0 ? "" : stocks?.length}
                  onChange={handleStackHolders}
                />
              </FormField>
            </Grid>
          </>
        ) : restaurantDetails?.borrowerEntity?.businessStructId == "7" ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormField label={"Please specify"}>
              <Input
                name="otherDetails"
                fullWidth
                autoComplete="off"
                onChange={(e) =>
                  handleChange(e?.target?.value, "mgmtEntityotherDetails")
                }
                value={restaurantDetails?.mgmtEntityotherDetails}
              />
            </FormField>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
      {restaurantDetails?.borrowerEntity?.businessStructId &&
      restaurantDetails?.borrowerEntity?.businessStructId > "1"
        ? restaurantDetails?.stockHolders?.map((ele, idx) => {
            return (
              ele && (
                <StockHolderDetails
                  key={idx}
                  ind={idx}
                  defaultValue={ele}
                  updateState={updateStockholder}
                  remove={() => handleRemoveStackholder(ele, idx)}
                />
              )
            );
          })
        : ""}
      <div
        className={`footerBtnContainer`}
        style={
          restaurantDetails?.borrowerEntity?.businessStructId &&
          restaurantDetails?.borrowerEntity?.businessStructId > "1" &&
          stocks?.length
            ? { display: "flex", justifyContent: "space-between" }
            : { display: "flex", justifyContent: "end" }
        }
      >
        {restaurantDetails?.borrowerEntity?.businessStructId &&
        restaurantDetails?.borrowerEntity?.businessStructId > "1" &&
        stocks?.length ? (
          <Button
            variant="cta"
            onClick={handleAddStackholder}
            classes={{ root: "shareholderClass" }}
          >
            add another shareholder
          </Button>
        ) : (
          ""
        )}
        {btnDisable ? (
          <Button
            variant="cta"
            classes={{ root: "updateButtonClass" }}
            className="updateButtonStockClass"
            disabled
          >
            {" "}
            Update This Restaurant
          </Button>
        ) : (
          <>
            <Button
              variant="cta"
              onClick={() => handleUpdateStackeHolder(ind)}
              classes={{ root: "updateButtonClass" }}
              className="updateButtonStockClass"
            >
              {" "}
              Update This Restaurant
            </Button>
          </>
        )}
      </div>
      <AriaAnnouncerProvider>
        <Banner state="info" className="updateNoteStockClass">
          Please Note: Shareholder's data along with Owner, Entity and State
          will only save upon clicking on Update This Restaurant button.
        </Banner>
      </AriaAnnouncerProvider>
    </div>
  );
};

const initStackholder = {
  stockHolderId: "",
  stockHolderName: "",
  stockHolderTitle: "",
  ownershipPercent: "",
};
const dynamicFormProps = {
  initObj: { ...initStackholder },
  idKey: "stockHolderId",
};

export default dynamicForm(
  React.memo(ErrorBoundary(RestDetails)),
  dynamicFormProps
);
