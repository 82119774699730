import React, { useState, useEffect } from "react";
import { FormField } from "@jpmuitk/form-field";
import Grid from "@material-ui/core/Grid";
import { Form } from "@jpmuitk/forms";
import { Input } from "@jpmuitk/input";
import { Button } from "@jpmuitk/button";
import { RadioButtonGroup } from "@jpmuitk/radio-button";
import { DatePicker } from "@jpmuitk/date-picker";
import moment from "moment";
import dynamicForm from "../../../components/form/DynamicForm";
import "./MiscellaneousDetails.scss";
import Owners from "./Owners";
import { OrderedButton, ButtonBar } from "@jpmuitk/button-bar";
import { useSelector, useDispatch } from "react-redux";
import {
  miscellaneousDetails,
  FOURHUNDREDFOUR,
  MisDetailsConstants,
  isNumber,
  Tooltip_info,
  yearsStartValue,
  yearsEndValue,
  CONSTANT_NO,
  CONSTANT_YES,
} from "../../global.constants";
import { useHistory } from "react-router-dom";
import {
  getMiscellaneousDetailsData,
  postMiscellaneousDetailsData,
  deleteMiscellaneousDetailsData,
} from "./redux/actions/MiscellaneousDetailsAction";
import Loader from "../../../components/loader/Loader";
import Error from "../../../components/error/Error";
import {
  getNextNavigation,
  getPreviousNavigation,
} from "../../../components/navigationPath/navigationPath";
import "../../global.scss";
import HiddenValidator from "../../../containers/HiddenValidator";
import { formHas_error } from "../../dynamic/returnToDashboard/redux/action/dashboardAction";

const Miscellaneous = (props) => {
  const { createInitialList, addNewItem } = props;
  const yesOrNo = MisDetailsConstants.yesOrNo;
  let startDate = MisDetailsConstants.emptyString;
  let endDate = MisDetailsConstants.emptyString;
  const [year, setYear] = useState("");
  const [approvedOwner, setApprovedOwner] = useState({
    isApproved: "",
  });
  const [obligations, setObligations] = useState("");
  const [lawsuit, setLawsuit] = useState("");
  const [bankruptcy, setBankruptcy] = useState("");
  const [dispute, setDispute] = useState("");
  const [yesComments, setYesComments] = useState("");
  const [awards, setAwards] = useState("");
  const [committees, setCommittees] = useState("");
  const [political, setPolitical] = useState({
    anyPoliticalPosition: "",
  });
  const [bank, setBank] = useState("");
  const [currentPosition, setCurrentPosition] = useState("");
  const [position, setPosition] = useState("");
  const [politicalStart, setStartDate] = useState("");
  const [politicalEnd, setPoliticalEnd] = useState("");
  const [error, setError] = useState(error);
  const [deletespan, setDeletespan] = useState(false);
  const [addspan, setAddspan] = useState({
    isAdded: false,
    newlyAddedIndex: null,
  });
  const [newUpdate, setNewUpdate] = useState({});
  const dispatch = useDispatch();
  const misDetails = useSelector(
    (state) => state?.MiscellaneousDetailsReducer?.miscellaneousData
  );
  const isLoading = useSelector(
    (state) => state.MiscellaneousDetailsReducer.isLoading
  );
  const errors = useSelector(
    (state) => state.MiscellaneousDetailsReducer.error
  );
  const history = useHistory();
  const validMis = newUpdate?.otherOwnerDetails?.filter(
    (ele, i) => ele !== undefined
  ).length;
  const loanId = useSelector((state) => state.DashboardReducer?.currentLoan);
  const userId = useSelector((state) => state.AuthCheckReducer?.loggedInUserId);

  const updateStore = () => {
    if (newUpdate) {
      const state = { ...newUpdate };
      if (newUpdate.otherApprovedOwner === CONSTANT_NO) {
        state.otherOwnerDetails = [];
      }
      if (loanId?.activeLoanId) {
        dispatch(
          postMiscellaneousDetailsData(state, loanId?.activeLoanId, userId)
        );
      }
    }
  };
  useEffect(() => {
    if (errors) return setError(errors);
    setError("");
  }, [errors]);

  useEffect(() => {
    if (loanId?.activeLoanId) {
      dispatch(getMiscellaneousDetailsData(loanId?.activeLoanId, userId));
    }
  }, []);
  const handlePost = (url, sameComp = true) => {
    updateStore();
    if (!sameComp) {
      dispatch(formHas_error(false));
      return false;
    }
    history.push(url);
  };
  const handleAddNewOwner = () => {
    let owner = addNewItem(newUpdate.otherOwnerDetails);
    setAddspan({
      ...addspan,
      isAdded: true,
      newlyAddedIndex: newUpdate.otherOwnerDetails.length,
    });
    setNewUpdate({
      ...newUpdate,
      otherOwnerDetails: owner,
    });
  };
  const handleRemoveOwner = (id) => {
    setDeletespan(true);
    setDeletespan(id);
    const owrDtlId = newUpdate?.otherOwnerDetails[id]?.owrDtlId;
    if (owrDtlId && loanId?.activeLoanId) {
      dispatch(
        deleteMiscellaneousDetailsData(owrDtlId, loanId?.activeLoanId, userId)
      );
    }
    let owner = [];
    newUpdate?.otherOwnerDetails.forEach((element, index) => {
      if (index != id) {
        owner.push(element);
      }
    });

    setNewUpdate((state) => {
      return {
        ...state,
        otherOwnerDetails: owner,
      };
    });
  };

  const Validator = (e) => {
    const { value } = e.target;
    setYear(value);
    setNewUpdate({ ...newUpdate, approvedYear: value ? value : null });
  };
  const handleApprovedOwner = (e, selected) => {
    if (selected === CONSTANT_NO) {
      setApprovedOwner({
        ...approvedOwner,
        isApproved: CONSTANT_NO,
      });
      setNewUpdate({ ...newUpdate, otherApprovedOwner: CONSTANT_NO });
    }
    if (selected === CONSTANT_YES) {
      setApprovedOwner({
        ...approvedOwner,
        isApproved: CONSTANT_YES,
      });
      setNewUpdate({ ...newUpdate, otherApprovedOwner: CONSTANT_YES });
    }
  };
  const handleObligations = (e, selected) => {
    setObligations(e.target.value);
    setNewUpdate({ ...newUpdate, anyObligations: e.target.value });
  };
  const handleLawsuit = (e, selected) => {
    setLawsuit(e.target.value);
    setNewUpdate({ ...newUpdate, anyClaims: e.target.value });
  };
  const handleBankruptcy = (e, selected) => {
    setBankruptcy(e.target.value);
    setNewUpdate({ ...newUpdate, bankruptcy: e.target.value });
  };
  const handleDispute = (e, selected) => {
    setDispute(e.target.value);
    setNewUpdate({ ...newUpdate, taxDispute: e.target.value });
  };
  const handleYesComments = (e) => {
    const { value } = e.target;
    setYesComments(value);
    setNewUpdate({
      ...newUpdate,
      commentVO: { ...newUpdate.commentVO, comments: value ? value : null },
    });
  };
  const handleAwards = (e) => {
    const { value } = e.target;
    setAwards(value);
    setNewUpdate({ ...newUpdate, recognitionAward: value ? value : null });
  };
  const handleCommittees = (e) => {
    const { value } = e.target;
    setCommittees(value);
    setNewUpdate({ ...newUpdate, teamCommitteeDetails: value ? value : null });
  };
  const handlePolitical = (e, selected) => {
    if (selected === CONSTANT_YES) {
      setPolitical({
        ...political,
        anyPoliticalPosition: CONSTANT_YES,
      });
      setNewUpdate({
        ...newUpdate,
        politicalPositionHeld: CONSTANT_YES,
        termStartDate: newUpdate.termStartDate,
        termEndDate: newUpdate.termEndDate,
        politicalPosition: newUpdate.politicalPosition,
        positionCurrentlyHeld: newUpdate.positionCurrentlyHeld,
      });
      setStartDate(newUpdate.termStartDate);
      setPoliticalEnd(newUpdate.termEndDate);
      setCurrentPosition(newUpdate.politicalPosition);
      setPosition(newUpdate.positionCurrentlyHeld);
    }
    if (selected === CONSTANT_NO) {
      setPolitical({
        ...political,
        anyPoliticalPosition: CONSTANT_NO,
      });
      setNewUpdate({
        ...newUpdate,
        politicalPositionHeld: CONSTANT_NO,
        termStartDate: null,
        termEndDate: null,
        politicalPosition: null,
        positionCurrentlyHeld: null,
      });
      setStartDate(null);
      setPoliticalEnd(null);
      setCurrentPosition(null);
      setPosition(null);
    }
  };
  const handleBank = (e) => {
    const { value } = e.target;
    setBank(value);
    setNewUpdate({ ...newUpdate, mainOperatingBank: value ? value : null });
  };
  const handleCurrentPosition = (e, selected) => {
    setCurrentPosition(selected);
    if (selected === CONSTANT_NO) {
      setPoliticalEnd(moment().format("MM/DD/YYYY"));
      setNewUpdate({
        ...newUpdate,
        termEndDate: moment().format("MM/DD/YYYY"),
        positionCurrentlyHeld: false,
      });
    } else {
      setPoliticalEnd("");
      setNewUpdate({
        ...newUpdate,
        termEndDate: null,
        positionCurrentlyHeld: true,
      });
    }
  };
  const handlePosition = (e) => {
    const { value } = e.target;
    setPosition(value);
    setNewUpdate({ ...newUpdate, politicalPosition: value ? value : null });
  };

  const handleOwnerOperator = (e) => {
    let value = e.target.value;
    if (value <= 99) {
      setNewUpdate({
        ...newUpdate,
        otherOwnerDetails: createInitialList(value ? value : null),
      });
    }
  };
  const handleChange = (sameComp = true) => {
    handlePost(getNextNavigation(), sameComp);
    window.scrollTo(0, 0);
  };
  const handlePreviousChange = () => {
    handlePost(getPreviousNavigation());
    window.scrollTo(0, 0);
  };

  const handleStartDate = (fieldname, value) => {
    if (fieldname === "startdate") {
      setStartDate(value);
      setNewUpdate({ ...newUpdate, termStartDate: value });
    }
  };
  const handleEndDate = (fieldname, value) => {
    if (fieldname === "enddate") {
      setPoliticalEnd(value);
      setNewUpdate({ ...newUpdate, termEndDate: value });
    }
  };
  const defaultCheck = (valueCheck) => {
    return valueCheck ? valueCheck : "";
  };
  const years = [...new Array(yearsStartValue)].map((ele, id) => {
    let yr = new Date().getFullYear() - yearsEndValue + id;
    return {
      value: yr,
      text: String(yr),
      id: yr,
    };
  });
  useEffect(() => {
    if (misDetails) {
      setNewUpdate(misDetails);
      if (misDetails.approvedYear) {
        setYear(misDetails.approvedYear);
      }
      if (misDetails.anyObligations) {
        setObligations(misDetails.anyObligations);
      }
      if (misDetails.anyClaims) {
        setLawsuit(misDetails.anyClaims);
      }
      if (misDetails.bankruptcy) {
        setBankruptcy(misDetails.bankruptcy);
      }
      if (misDetails.taxDispute) {
        setDispute(misDetails.taxDispute);
      }
      if (misDetails.otherApprovedOwner) {
        setApprovedOwner({
          ...approvedOwner,
          isApproved: misDetails.otherApprovedOwner,
        });
      }
      if (misDetails.politicalPositionHeld === CONSTANT_YES) {
        setPolitical({
          ...political,
          anyPoliticalPosition: CONSTANT_YES,
        });
      }
      if (misDetails.politicalPositionHeld === CONSTANT_NO) {
        setPolitical({
          ...political,
          anyPoliticalPosition: CONSTANT_NO,
        });
      }
      if (misDetails.termStartDate) {
        startDate = misDetails.termStartDate;
        startDate = moment(startDate).format("MM/DD/YYYY");
        setStartDate(startDate);
      }
      if (misDetails.termEndDate) {
        endDate = misDetails.termEndDate;
        endDate = moment(endDate).format("MM/DD/YYYY");
        setPoliticalEnd(endDate);
      }
      if (
        misDetails.positionCurrentlyHeld !== undefined &&
        misDetails.positionCurrentlyHeld !== null
      ) {
        misDetails.positionCurrentlyHeld
          ? setCurrentPosition(CONSTANT_YES)
          : setCurrentPosition(CONSTANT_NO);
      }

      if (misDetails.commentId && misDetails?.commentVO) {
        setYesComments(misDetails.commentVO.comments);
      }
      if (misDetails.recognitionAward) {
        setAwards(misDetails.recognitionAward);
      }
      if (misDetails.teamCommitteeDetails) {
        setCommittees(misDetails.teamCommitteeDetails);
      }
      if (misDetails.politicalPosition) {
        setPosition(misDetails.politicalPosition);
      }
      if (misDetails.mainOperatingBank) {
        setBank(misDetails.mainOperatingBank);
      }
    }
    setDeletespan(false);
    setAddspan({});
  }, [misDetails]);
  const GetMisMethod = () => {
    return (
      <>
        {approvedOwner.isApproved === CONSTANT_YES ? (
          <Grid item xs={12}>
            <FormField label={miscellaneousDetails.totalOwner}>
              <Input
                inputProps={{ maxLength: 2 }}
                name="debtListValue"
                fullWidth
                autoComplete="off"
                value={defaultCheck(validMis)}
                onChange={handleOwnerOperator}
                onKeyPress={(e) => isNumber(e, e.target.name)}
              />
            </FormField>
          </Grid>
        ) : null}
      </>
    );
  };
  const AddMethod = () => {
    return (
      <>
        {approvedOwner.isApproved === CONSTANT_YES && validMis > 0 ? (
          <Grid item xs={12}>
            <div className={`addButtonClass`}>
              <Button onClick={handleAddNewOwner} variant="cta">
                {miscellaneousDetails.addButton}
                {addspan.isAdded ? (
                  <span
                    class="sr-only"
                    aria-live="assertive"
                    role="alert"
                    aria-label={`Owner/Operators ${addspan.newlyAddedIndex} Added`}
                  ></span>
                ) : (
                  ""
                )}
              </Button>
            </div>
          </Grid>
        ) : (
          ""
        )}
      </>
    );
  };
  return (
    <>
      {isLoading ? <Loader /> : null}
      {error === FOURHUNDREDFOUR ? <Error /> : false}
      {!error ? (
        <div className={`dynamicContainer`} id="main" role="main">
          <h1 className={`headingClass`}>{miscellaneousDetails.header}</h1>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Form fullWidthFields className={`formClass`}>
                <Grid item xs={12}>
                  <FormField label={miscellaneousDetails.approverYear}>
                    <Input
                      name="year"
                      fullWidth
                      autoComplete="off"
                      onChange={Validator}
                      value={defaultCheck(year)}
                      inputProps={{ maxLength: 4 }}
                      onKeyPress={(e) => isNumber(e, e.target.name)}
                    />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <div className="padding8">
                    <RadioButtonGroup
                      aria-label="Controlled Example"
                      role="radio"
                      legend={miscellaneousDetails.anyOtherApprover}
                      name="mgntCompany"
                      className={"singleRowRadioBtn"}
                      radios={yesOrNo}
                      value={approvedOwner.isApproved}
                      onChange={handleApprovedOwner}
                    />
                  </div>
                </Grid>
                <GetMisMethod />
                {approvedOwner.isApproved === CONSTANT_YES &&
                newUpdate?.otherOwnerDetails?.length > 0
                  ? newUpdate?.otherOwnerDetails?.map(
                      (ele, index) =>
                        ele !== undefined && (
                          <Owners
                            id={ele?.owrDtlId}
                            setNewUpdate={setNewUpdate}
                            data={ele}
                            itemDeleted={deletespan}
                            remove={handleRemoveOwner}
                            ind={index}
                          />
                        )
                    )
                  : ""}
                <AddMethod />
                <Grid item xs={12}>
                  <div className="padding8">
                    <RadioButtonGroup
                      aria-label="Controlled Example"
                      role="radio"
                      legend={miscellaneousDetails.obligations}
                      name="mgntCompany"
                      className={"singleRowRadioBtn"}
                      radios={yesOrNo}
                      value={obligations}
                      onChange={handleObligations}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="padding8">
                    <RadioButtonGroup
                      aria-label="Controlled Example"
                      role="radio"
                      legend={miscellaneousDetails.lawsuit}
                      name="mgntCompany"
                      className={"singleRowRadioBtn"}
                      radios={yesOrNo}
                      value={lawsuit}
                      onChange={handleLawsuit}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="padding8">
                    <RadioButtonGroup
                      aria-label="Controlled Example"
                      role="radio"
                      legend={miscellaneousDetails.affiliates}
                      name="mgntCompany"
                      className={"singleRowRadioBtn"}
                      radios={yesOrNo}
                      value={bankruptcy}
                      onChange={handleBankruptcy}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="padding8">
                    <RadioButtonGroup
                      aria-label="Controlled Example"
                      role="radio"
                      legend={miscellaneousDetails.affiliatesTaxes}
                      name="mgntCompany"
                      className={"singleRowRadioBtn"}
                      radios={yesOrNo}
                      value={dispute}
                      onChange={handleDispute}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <FormField
                    label={miscellaneousDetails.plsExplain}
                    helperText={miscellaneousDetails.placeholder}
                    helperTextPlacement={"tooltip"}
                    hasStatusIndicator
                  >
                    <Input
                      name="notes"
                      fullWidth
                      autoComplete="off"
                      multiline={true}
                      className={"leaseOptions"}
                      value={defaultCheck(yesComments)}
                      onChange={handleYesComments}
                      role="tooltip"
                      aria-label={Tooltip_info}
                    />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField
                    label={miscellaneousDetails.recognition}
                    helperText={miscellaneousDetails.placeholder}
                    helperTextPlacement={"tooltip"}
                    hasStatusIndicator
                  >
                    <Input
                      name="notes"
                      fullWidth
                      autoComplete="off"
                      multiline={true}
                      className={"leaseOptions"}
                      value={defaultCheck(awards)}
                      onChange={handleAwards}
                      role="tooltip"
                      aria-label={Tooltip_info}
                    />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField
                    label={miscellaneousDetails.committees}
                    helperText={miscellaneousDetails.placeholder}
                    helperTextPlacement={"tooltip"}
                    hasStatusIndicator
                  >
                    <Input
                      name="notes"
                      fullWidth
                      autoComplete="off"
                      multiline={true}
                      className={"leaseOptions"}
                      value={defaultCheck(committees)}
                      onChange={handleCommittees}
                      role="tooltip"
                      aria-label={Tooltip_info}
                    />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <div className="padding8">
                    <RadioButtonGroup
                      aria-label="Controlled Example"
                      role="radio"
                      legend={miscellaneousDetails.politicalPositions}
                      name="mgntCompany"
                      className={"singleRowRadioBtn"}
                      radios={yesOrNo}
                      value={political.anyPoliticalPosition}
                      onChange={handlePolitical}
                    />
                  </div>
                </Grid>

                {political.anyPoliticalPosition === CONSTANT_YES ? (
                  <div className={`nestedFormContainerClass`}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <FormField label={miscellaneousDetails.politicalStart}>
                          <DatePicker
                            dateFormat={"MM/DD/YYYY"}
                            date={moment(politicalStart, "MM/DD/YYYY")}
                            onInputValueChange={(value) =>
                              handleStartDate("startdate", value)
                            }
                            PopperProps={{ placement: "bottom-end" }}
                            YearDropdownProps={{
                              source: years,
                              listWidth: "80px",
                            }}
                            StartInputProps={{ className: "datePickerBox" }}
                            CalendarProps={{ defaultInputValue: "01/01/1939" }}
                          />
                        </FormField>
                      </Grid>
                      {currentPosition != CONSTANT_NO ? (
                        <Grid item xs={12}>
                          <FormField label={miscellaneousDetails.politicalEnd}>
                            <DatePicker
                              dateFormat={"MM/DD/YYYY"}
                              date={moment(politicalEnd, "MM/DD/YYYY")}
                              onInputValueChange={(value) =>
                                handleEndDate("enddate", value)
                              }
                              PopperProps={{ placement: "bottom-end" }}
                              YearDropdownProps={{
                                source: years,
                                listWidth: "80px",
                              }}
                              StartInputProps={{ className: "datePickerBox" }}
                              CalendarProps={{
                                defaultInputValue: "01/01/1939",
                              }}
                            />
                          </FormField>
                        </Grid>
                      ) : (
                        ""
                      )}
                      {currentPosition == CONSTANT_NO ? (
                        <Grid item xs={12}>
                          <FormField label={miscellaneousDetails.politicalEnd}>
                            <DatePicker
                              dateFormat={"MM/DD/YYYY"}
                              date={moment(politicalEnd, "MM/DD/YYYY")}
                              onInputValueChange={(value) =>
                                handleEndDate("enddate", value)
                              }
                              PopperProps={{ placement: "bottom-end" }}
                              YearDropdownProps={{
                                source: years,
                                listWidth: "80px",
                              }}
                              StartInputProps={{ className: "datePickerBox" }}
                              CalendarProps={{
                                defaultInputValue: "01/01/1939",
                              }}
                            />
                          </FormField>
                        </Grid>
                      ) : (
                        ""
                      )}
                      <Grid item xs={12}>
                        <div className="padding8">
                          <RadioButtonGroup
                            aria-label="Controlled Example"
                            role="radio"
                            legend={miscellaneousDetails.currentPosition}
                            name="mgntCompany"
                            className={"singleRowRadioBtn"}
                            radios={yesOrNo}
                            value={currentPosition}
                            onChange={handleCurrentPosition}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <FormField
                          label={miscellaneousDetails.positionDetails}
                          helperText={miscellaneousDetails.placeholder}
                          helperTextPlacement={"tooltip"}
                          hasStatusIndicator
                        >
                          <Input
                            name="notes"
                            fullWidth
                            autoComplete="off"
                            multiline={true}
                            className={"leaseOptions"}
                            value={defaultCheck(position)}
                            onChange={handlePosition}
                            role="tooltip"
                            aria-label={Tooltip_info}
                          />
                        </FormField>
                      </Grid>
                    </Grid>
                  </div>
                ) : null}
                <Grid item xs={12}>
                  <FormField label={miscellaneousDetails.bankDetails}>
                    <Input
                      name="notes"
                      fullWidth
                      autoComplete="off"
                      value={defaultCheck(bank)}
                      onChange={handleBank}
                    />
                  </FormField>
                </Grid>
              </Form>
              <p className="noteClass">{miscellaneousDetails.note}</p>
              <ButtonBar className="buttonClass">
                <OrderedButton
                  className={"nextSectionClass"}
                  variant="cta"
                  onClick={handleChange}
                >
                  {miscellaneousDetails.next}
                </OrderedButton>
                <OrderedButton onClick={handlePreviousChange}>
                  {miscellaneousDetails.previous}
                </OrderedButton>
                <HiddenValidator
                  validateTrigger={() => handleChange(false)}
                  labels={"next section"}
                  ordBtn={true}
                />
              </ButtonBar>
            </Grid>
          </Grid>
        </div>
      ) : null}
    </>
  );
};

const initObj = {
  owrDtlId: "",
  owrName: MisDetailsConstants.emptyString,
  approvedYear: MisDetailsConstants.emptyString,
  relationship: MisDetailsConstants.emptyString,
};

const dynamicFormProps = {
  initObj: { ...initObj },
  idKey: "owrDtlId",
};

export default dynamicForm(Miscellaneous, dynamicFormProps);
