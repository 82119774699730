import React,{ useEffect, useState } from 'react';
import { FormField } from '@jpmuitk/form-field';
import Grid from '@material-ui/core/Grid';
import { Button } from '@jpmuitk/button';
import { Dropdown } from '@jpmuitk/dropdown';
import { child, LOANS, POLConstants, PurposeOfLoanText, structure2, structure3, structure4, structureData } from '../../global.constants'
import Loan from './Loan';
import Coinvestment from './CoInvestment';

const LoanDetails = (props) => {

    const { id, remove, data, entityDetails, setLoanDetails, loanDetails, storeRestaurentData, itemDeleted} = props;
    const [loanData, setLoanData] = useState({});
    
    const [entityDetail, setEntityDetail] = useState(entityDetails);
    const [structure, setStructure] = useState(structureData);
    const [storeRestaurent, setStoreRestaurent] = useState([]);
    const [loan, setLoan] = useState({
        "loanId": '',
        "loanName": "Select"
    });

 

    useEffect(() => {
        const sel = LOANS.filter(ele => {
            if (ele.loanId == data.purposeTypeId) {
                return ele;
            }
        });
        setLoan(...sel);
        setLoanData(data);
    }, [data])

    const handleDefaultLoan = (item, idLoan) => {
        let loanSetData = {};
        item.forEach((element) => {
            if (element.loanId == idLoan) {
                loanSetData = element;
            }
        });
        return loanSetData;
    };
    useEffect(() => {
        let borrowDetails = [];
        borrowDetails.push({
            borrowerId: null,
            borrowerName: POLConstants.SELECT
        })
        if (entityDetails) {
            entityDetails.forEach(element => {
                const borrowData = {
                    borrowerId: element.borrowerId,
                    borrowerName: element.borrowerName
                }
                borrowDetails.push(borrowData);
            });
            setEntityDetail(borrowDetails);
        }
    }, [entityDetails])
    useEffect(() => {
        let storeData = [];
        if (storeRestaurentData && storeRestaurentData.storeInfoVOList) {
            storeRestaurentData.storeInfoVOList.forEach(element => {
                const details = {
                    nationalStoreNumber : element.nationalStoreNumber,
                    isRequired: ''
                }
                storeData.push(details);
            });
            setStoreRestaurent(storeData);
        }
    }, [storeRestaurentData])

    const onUpdateDetected = (value) => {
        loanDetails[id] = value;
        setLoanDetails([...loanDetails])
    }
    const updateHandler = (e) => {
        const { name, value } = e;
        if(loanData) {
        loanData[name] = value;
        }
        setLoanData({ ...loanData });
        onUpdateDetected(loanData);
    }

    const updateChildHandler = (e) => {
        const { name, value } = e;
        loanData.childPurposeVO[name] = value;
        setLoanData({ ...loanData });
        onUpdateDetected(loanData);
    }

    const defaultValue = (childPurpose) => {
        return childPurpose ? childPurpose : {}
    }

    const updateLoanId = (item, name) => {
        setLoan(item)
        loanData[name] = item.loanId;
        if(item.loanId == POLConstants.numOne || item.loanId == POLConstants.numFive || item.loanId == POLConstants.numSix) {
            loanData.childPurposeVO = child;
        } else {
            loanData.childPurposeVO = null;
        }
        setLoanData({ ...loanData });
        onUpdateDetected(loanData);

      
        if(item.loanId == POLConstants.numNine) {
            setStructure(structure2);
        } else if(item.loanId == POLConstants.numEleven) {
            setStructure(structure3);
        } else if(item.loanId == POLConstants.numTwlve) {
            setStructure(structure4);
        }
    }



    return (
        <div className={`nestedThirdFormContainer`}>
            <div className={`headerContainer`}>
                <h2 className={`headingClass`}>
                    {`${PurposeOfLoanText.subHeader} ${id + POLConstants.numOne}`}
                </h2>
                <Button onClick={() => remove(id, loanData)}>
                    {PurposeOfLoanText.btnDelLoan}
                    {itemDeleted && id===0 ? <span class="sr-only" aria-live="assertive" role="alert" aria-label= {`Request ${itemDeleted+1} deleted`} ></span> : "" }
                </Button>
            </div>
            <Grid container spacing={2} >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormField label={PurposeOfLoanText.loanPurpose}>
                        <Dropdown
                            name='purposeTypeId'
                            className={'dropDownField'}
                            itemToString={item => (item ? item.loanName : POLConstants.emptyString)}
                            selectedItem = {handleDefaultLoan(LOANS, loanData?.purposeTypeId)}
                            source={LOANS}
                            onSelect={(_, item) => updateLoanId(item, 'purposeTypeId')}
                        />
                    </FormField>
                </Grid>

                {loan.loanId > POLConstants.numZero ? <Loan
                    loan={loan}
                    Data={loanData}
                    entityDetails={entityDetail}
                    storeRestaurent={storeRestaurent}
                    update={updateHandler}
                    structure= {structure}
                /> : POLConstants.emptyString}
                {(loan.loanId == POLConstants.numOne || loan.loanId == POLConstants.numFive || loan.loanId == POLConstants.numSix) ? <Coinvestment
                    loan={loan}
                    entityDetails={entityDetail}
                    storeRestaurent = {storeRestaurent}
                    Data={defaultValue(loanData.childPurposeVO)}
                    update={updateChildHandler}
                    structure= {structure}
                /> : POLConstants.emptyString}

            </Grid>
        </div>
    );
}

export default LoanDetails;


