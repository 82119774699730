import {
    FETCH_LeftNavigation_REQUEST ,
    FETCH_LeftNavigation_SUCCESS ,
    FETCH_LeftNavigation_FAILURE ,

  } from '../../../global.constants';


const initialState = {
  LeftNavigationDataobject: [],
}

const LeftNavigationReducer = (state = initialState, {
  type,
  payload
}={}) => {
  switch (type) {
    case FETCH_LeftNavigation_FAILURE:
    case FETCH_LeftNavigation_REQUEST:
      return Object.assign(state, {
       
      });

    case FETCH_LeftNavigation_SUCCESS:
      return Object.assign(state, {
        LeftNavigationDataobject: payload
      });

    default:
      return state;
  }
};
export default LeftNavigationReducer ;
