import { FAILURE_POST_DATA, RECEIVE_POST_DATA, FAILURE_GET_DATA, 
    RECEIVE_REGION_DATA, FAILURE_REGION_DATA, RECEIVE_PROFILE_DATA, base_url_auth,FFW_EXT_EMAIL_ALREADY_EXISTS,RECEIVE_ERR_DATA,FETCH_LOAD_REQUEST } from "../../../../global.constants"
import axios from 'axios'
import {fetchUserName } from "../../../../../auth/redux/action/AuthCheckAction"; 
export const fetchProfileData = (payload,setIsExistingUser,setShowBanner,proceedLogout) => async dispatch => {
    try {
        dispatch({ type: FETCH_LOAD_REQUEST});
        await axios.put(base_url_auth+`/api/v1/authsvc/ext/checkAndChangeExtFranchiseeProfile.do`, payload)
            .then(response => {
                
                if (response && response.status === 200) {
                    dispatch(receivePostData());
                    dispatch(fetchUserName(response.data?.firstName+ ' ' + response.data?.lastName))
                
                    if(response.data.successCode === null || "") 
                    {
                        dispatch(err(response.data.errorCode))
                        setIsExistingUser(true) 
                        setShowBanner(false)

                    }
                    else{ 
                     if(response.data.isEmailChanged === true)
                     {
                        setIsExistingUser(false) 
                        proceedLogout()
                     }
                     else{
                        setIsExistingUser(false) 
                        setShowBanner(true)
                      
                    }
                }
                }
                else {
                    dispatch(failurePostData())
                }
            });
    }
    catch (e) {
        return e;
    }
};

export const getUserData = (userId) => async dispatch => {
    const payload = {
        "userId" : userId
    }
    
    try {
        dispatch({ type: FETCH_LOAD_REQUEST});
      await axios.post(base_url_auth+`/api/v1/authsvc/ext/getExternalFranchiseeProfile.do`, payload)
            .then(response => {
                if (response && response.status === 200) {
                    dispatch(receiveData(response.data));
                }
                else {
                    dispatch(failureData())
                }
            });
    }
    catch (e) {
        return e;
    }
};

export const receivePostData = (payload) => ({
    type: RECEIVE_POST_DATA,
    payload
})

export const err = (payload) => ({
    type: RECEIVE_ERR_DATA,
    payload
})
export const failurePostData = (payload) => ({
    type: FAILURE_POST_DATA,
    payload
})

export const receiveData = (payload) => {
    return {
        type: RECEIVE_PROFILE_DATA,
        payload
    }

}

export const failureData = () => {
    return {
        type: FAILURE_GET_DATA,
    }
}

export const receiveRegionData = (payload) => {
    return {
        type: RECEIVE_REGION_DATA,
        payload
    }
}

export const failureRegionData = () => {
    return {
        type: FAILURE_REGION_DATA,
    }
}
