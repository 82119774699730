const initialRestState = {
  restaurentInfo: {},
  dataStoreNext: false,
  error: "",
  isLoading: false,
};

const RestaurantReducer = (
  state = initialRestState,
  { type, payload } = {}
) => {
  switch (type) {
    case "FETCH_RESTAURANTINFO_SUCCESS":
      return Object.assign(state, {
        restaurentInfo: payload,
        isLoading: false,
      });
    case "FETCH_RESTAURANTINFO_REQUEST":
      return Object.assign(state, {
        isLoading: true,
      });
    case "FETCH_RESTAURANTINFO_FAILURE":
      return Object.assign(state, {
        error: payload,
        isLoading: false,
      });
    case "UPDATE_REST_DETAILS":
    case "SET_REST_DETAILS":
      return Object.assign(state, {
        restaurentInfo: payload,
        isLoading: false,
      });

    case "DATA_STORED":
      return Object.assign(state, {
        dataStoreNext: payload,
        isLoading: false,
      });
    default:
      return state;
  }
};

export default RestaurantReducer;
