import React, { useEffect, useState } from "react";
import axios from "axios";
import { FormField } from "@jpmuitk/form-field";
import Grid from "@material-ui/core/Grid";
import { Form } from "@jpmuitk/forms";
import { Input } from "@jpmuitk/input";
import { Button } from "@jpmuitk/button";
import { RadioButtonGroup } from "@jpmuitk/radio-button";
import { Dropdown } from "@jpmuitk/dropdown";
import { Tabs, Tab } from "@jpmuitk/tabs";
import { Dialog, DialogTitle, DialogActions } from "@jpmuitk/dialog";
import { useSelector, useDispatch } from "react-redux";
import StockHolderDetails from "./StockholderDetails";
import dynamicForm from "../../../components/form/DynamicForm";
import { base_url_user, RestarauntSummary } from "../../global.constants";
import "./RestDetails.scss";
import "../../global.scss";
import OwnRestaurant from "./OwnRestaurant";
import CurrentRestaurant from "./CurrentRestaurant";
import {
  getAPI,
  postAPI,
  deleteAPI,
} from "../../../components/form/serviceProvider";
import {
  dataStoreNext,
  getEntityData,
  getRestaurantsData,
  setData,
} from "./redux/action";
import {
  STOCKBIZSTRUCTURE,
  initObj,
  radioMgmtCmp,
  selectStockEntity,
  selectState,
} from "./common.js";
import { useHistory } from "react-router-dom";
import ErrorBoundary from "../../../components/form/ErrorBoundary";
import { getCountrystatesData } from "../../commonactions/redux/actions/action";
import { AriaAnnouncerProvider } from "@jpmuitk/aria-announcer";
import { State as ToastState, Toast } from "@jpmuitk/toast";
import {
  getNextNavigation,
  getPreviousNavigation,
} from "../../../components/navigationPath/navigationPath";

import Loader from "../../../components/loader/Loader";
import HiddenValidator from "../../../containers/HiddenValidator";
import { formHas_error } from "../../dynamic/returnToDashboard/redux/action/dashboardAction";

const RestaurantInfoSummary = (props) => {
  const dispatch = useDispatch();
  const location = useHistory();
  const restDetailsData = useSelector(
    (state) => state.RestaurantReducer.restaurentInfo
  );
  const countrystateData = useSelector(
    (state) => state.CountrystatesReducer.Countrystates
  );
  var addressRequired = false;
  const loanId = useSelector((state) => state.DashboardReducer?.currentLoan);
  const loggedUserId = useSelector(
    (state) => state.AuthCheckReducer?.loggedInUserId
  );
  const [activeTab, setActiveTab] = useState(0);
  const [idlr, setIdlr] = useState(true);
  const [disableUpdate, setDisableUpdate] = useState(false);
  const [addressCheck, setAddressCheck] = useState(false);
  const [restDetails, setRestDetails] = useState(restDetailsData);

  useEffect(() => {
    setIdlr(true);
    let url = `/ext/getStoreInfoSummary.do`;
    if (loanId?.activeLoanId) {
      dispatch(getAPI(url, loanId?.activeLoanId, loggedUserId, setData));
      if (!countrystateData.length) {
        dispatch(getCountrystatesData());
      }
    }
    setTimeout(() => {
      setIdlr(false);
    }, 1000);
  }, []);
  useEffect(() => {
    if (sessionStorage.getItem("store_fix") != 1) {
      setRestDetails({ ...restDetailsData });
      sessionStorage.setItem("store_fix", 0);
    }

    if (restDetailsData?.storeInfoVOList?.length > 0) {
      setTimeout(() => {
        setIdlr(false);
      }, 1000);
    }
  }, [restDetailsData]);


  const handleAddressToastClose = () => {
    setAddressCheck(false);
  };

  const handleNextClick = (sameComp = true) => {
    addressRequired = false;
    dispatch(dataStoreNext(false));
    // OLD STORE CHECK
    if (restDetails?.oldStoresList) {
      restDetails?.oldStoresList?.forEach((store) => {
        if (store?.addressVO === null || store?.addressVO?.address === "") {
          setAddressCheck(true);
          addressRequired = true;
          sameComp = false;
          dispatch(formHas_error(true));
        }
      });
    }
    // NEW STORE CHECK
    if (restDetails?.newStoresList) {
      restDetails?.newStoresList?.forEach((store) => {
        if (store?.addressVO === null || store?.addressVO?.address === "") {
          setAddressCheck(true);
          addressRequired = true;
          sameComp = false;
          dispatch(formHas_error(true));
        }
      });
    }
    if (!addressRequired) {
      updateStore();
      if (!sameComp) {
        dispatch(formHas_error(false));
        return false;
      }
      location.push(getNextNavigation());
      window.scrollTo(0, 0);
    }
  };
  const handlePreviousClick = () => {
    addressRequired = false;
    dispatch(setData({ ...restDetails }));
    dispatch(dataStoreNext(false));
    if (restDetails?.oldStoresList) {
      restDetails?.oldStoresList?.forEach((store) => {
        if (store?.addressVO === null || store?.addressVO?.address === "") {
          addressRequired = true;
          dispatch(dataStoreNext(true));
        }
      });
    }
    if (restDetails?.newStoresList) {
      restDetails?.newStoresList?.forEach((store) => {
        if (store?.addressVO === null || store?.addressVO?.address === "") {
          addressRequired = true;
          dispatch(dataStoreNext(true));
        }
      });
    }
    if (!addressRequired) {
      location.push(getPreviousNavigation());
      window.scrollTo(0, 0);
    }
  };

  const updateSingleStore = (store) => {
    // TODO: POINT TO SINGLE SAVE STORE API ONCE CREATED
    let url = "/ext/saveStoreInfoDetails.do/";
    let payload = {
      loanId: loanId?.activeLoanId,
      userId: loggedUserId,
      ...store,
    };
    postAPI(url, JSON.parse(JSON.stringify(payload)));
  };

  const updateStore = () => {
    let url = "/ext/saveStoreInfoDetails.do/";
    const updatedOldStoreList = restDetails?.oldStoresList?.map(
      (store, ind) => {
        if (!store?.updatedDate) {
          return {
            ...store,
            storeId: null,
            mgmtCompany: restDetails.mgmtCompany,
            mgmtCompanyEntityId: restDetails.mgmtCompanyEntityId,
            mgmtCompanyName: restDetails.mgmtCompanyName,
            stateIncorpration: restDetails.stateIncorpration,
            mgmtStateOfIncorporation: restDetails.mgmtStateOfIncorporation,
            businessStructId: store?.businessStructId,
            stateOfIncorporation: store?.stateOfIncorporation,
            stockHolders: store?.stockHolders?.filter(
              (e) => e.stockHolderId !== ""
            ),
            borrowerEntity: store?.borrowerEntity,
            borrowerId: store?.borrowerId,
            borrowerName: store?.borrowerName,
            freshStore: "N",
          };
        } else {
          return {
            ...store,
            mgmtCompany: restDetails.mgmtCompany,
            mgmtCompanyEntityId: restDetails.mgmtCompanyEntityId,
            mgmtCompanyName: restDetails.mgmtCompanyName,
            stateIncorpration: restDetails.stateIncorpration,
            mgmtStateOfIncorporation: restDetails.mgmtStateOfIncorporation,
            businessStructId: store?.businessStructId,
            stateOfIncorporation: store?.stateOfIncorporation,
            stockHolders: store?.stockHolders?.filter(
              (e) => e.stockHolderId !== ""
            ),
            borrowerEntity: store?.borrowerEntity,
            borrowerId: store?.borrowerId,
            borrowerName: store?.borrowerName,
            freshStore: "N",
          };
        }
      }
    );
    const updatedNewStoreList = restDetails.newStoresList?.map((store, ind) => {
      if (!store?.updatedDate) {
        return {
          ...store,
          storeId: null,
          mgmtCompany: restDetails.mgmtCompany,
          mgmtCompanyEntityId: restDetails.mgmtCompanyEntityId,
          mgmtCompanyName: restDetails.mgmtCompanyName,
          stateIncorpration: restDetails.stateIncorpration,
          mgmtStateOfIncorporation: restDetails.mgmtStateOfIncorporation,
          businessStructId: store?.businessStructId,
          stateOfIncorporation: store?.stateOfIncorporation,
          stockHolders: store?.stockHolders?.filter(
            (e) => e.stockHolderId !== ""
          ),
          borrowerEntity: store?.borrowerEntity,
          borrowerId: store?.borrowerId,
          borrowerName: store?.borrowerName,
          freshStore: "N",
        };
      } else {
        return {
          ...store,
          mgmtCompany: restDetails.mgmtCompany,
          mgmtCompanyEntityId: restDetails.mgmtCompanyEntityId,
          mgmtCompanyName: restDetails.mgmtCompanyName,
          stateIncorpration: restDetails.stateIncorpration,
          mgmtStateOfIncorporation: restDetails.mgmtStateOfIncorporation,
          businessStructId: store?.businessStructId,
          stateOfIncorporation: store?.stateOfIncorporation,
          stockHolders: store?.stockHolders?.filter(
            (e) => e.stockHolderId !== ""
          ),
          borrowerEntity: store?.borrowerEntity,
          borrowerId: store?.borrowerId,
          borrowerName: store?.borrowerName,
          freshStore: "N",
        };
      }
    });

    let newStoreList = !!updatedNewStoreList ? [...updatedNewStoreList] : [];
    let oldStoreList = !!updatedOldStoreList ? [...updatedOldStoreList] : [];
    let data = [...newStoreList, ...oldStoreList]?.filter(
      (ele) => ele != undefined
    );
    restDetails.userId = loggedUserId;
    delete restDetails.storeInfoVOList;
    let payload = {
      ...restDetails,
      storeInfoVOList: data.map((x) => {
        return {
          ...x,
        };
      }),
      loanId: loanId?.activeLoanId,
      oldStoresList: [...oldStoreList],
      newStoresList: [...newStoreList],
    };
    dispatch(setData({ ...payload }));
    postAPI(url, JSON.parse(JSON.stringify(payload)));
  };

  const handleAddNewItem = (key) => {
    sessionStorage.setItem("store_fix", 0);
    initObj["storeId"] = restDetails[key]?.length + 1;
    initObj["newStore"] = key == "newStoresList" ? "Y" : "N";
    initObj["freshStore"] = "Y";
    if (restDetails.mgmtCompany === "Y") {
      initObj["mgmtCompany"] = restDetails.mgmtCompany;
      initObj["mgmtCompanyEntityId"] = restDetails.mgmtCompanyEntityId;
      initObj["mgmtCompanyName"] = restDetails.mgmtCompanyName;
      initObj["stateIncorpration"] = restDetails.stateIncorpration;
      initObj["mgmtStateOfIncorporation"] =
        restDetails.mgmtStateOfIncorporation;
    }
    setRestDetails({
      ...restDetails,
      [key]: [...restDetails[key], { ...initObj }],
    });
  };

  const handleDeleteAnItem = (ind, key) => {
    sessionStorage.setItem("store_fix", 0);
    const tempList = [...restDetails[key]];
    tempList[ind] = undefined;
    let delRes = props.removeAnItem(ind, restDetails[key]);
    setRestDetails({ ...restDetails, [key]: [...delRes] });
    dispatch(setData({ ...restDetails, [key]: [...delRes] }));
  };

  const handleStockHolders = (e) => {
    if (e?.target?.value < 100) {
      let stockholders = props.createInitialList(e?.target?.value);
      dispatch(setData({ ...restDetails, stockHolders: stockholders }));
      setRestDetails({ ...restDetails, stockHolders: stockholders });
    }
  };

  const handleAddStockholder = () => {
    let stockholders = props.addNewItem(restDetails.stockHolders);
    dispatch(setData({ ...restDetails, stockHolders: stockholders }));
    setRestDetails({ ...restDetails, stockHolders: stockholders });
  };

  const handleRemoveStockholder = (id) => {
    let stockholders = props.removeAnItem(id, restDetails?.stockHolders);
    dispatch(setData({ ...restDetails, stockHolders: stockholders }));
    setRestDetails({ ...restDetails, stockHolders: stockholders });
  };

  const updateStockholder = (e, ind) => {
    const { name, value } = e.target;
    if (
      (/^\d{0,2}$/g.test(value) && value < 100) ||
      name !== "ownershipPercent"
    ) {
      if (restDetails.stockHolders?.length && restDetails.stockHolders[ind]) {
        restDetails.stockHolders[ind][name] = value;
      }
    } else if (value < 101 && name === "ownershipPercent") {
      if (restDetails.stockHolders?.length && restDetails.stockHolders[ind]) {
        restDetails.stockHolders[ind][name] = value;
      }
    }

    dispatch(setData({ ...restDetails }));
    setRestDetails({ ...restDetails });
  };

  const handleChange = (val, key) => {
    sessionStorage.setItem("store_fix", 0);
    setRestDetails({
      ...restDetails,
      [key]: val,
    });
    dispatch(setData({ ...restDetails, [key]: val }));
  };

  const updateStakeHolder = async (data, indx, grp, blurCall) => {
    sessionStorage.setItem("store_fix", 0);
    if (!blurCall) setDisableUpdate(true);
    const url = `/ext/updateBorrowerEntityAndSHInfo.do`;
    const reqPayload = {
      ...data,
      userId: loggedUserId,
      loanId: data?.borrowerEntity?.loanId,
    };
    const response = await postAPI(url, reqPayload);

    if (blurCall) {
      if (grp === "oldStoresList") {
        restDetails.oldStoresList[indx].borrowerEntityOld =
          restDetails.oldStoresList[indx].borrowerEntity &&
          restDetails.oldStoresList[indx].borrowerEntity?.borrowerName &&
          restDetails.oldStoresList[indx].freshStore !== "Y"
            ? {
                ...restDetails.oldStoresList[indx].borrowerEntity,
                stockHolders:
                  restDetails.oldStoresList[indx].stockHolders || [],
              }
            : null;
        restDetails.oldStoresList[indx].yesUpdate = false;
      } else {
        restDetails.newStoresList[indx].borrowerEntityOld =
          restDetails.newStoresList[indx].borrowerEntity &&
          restDetails.newStoresList[indx].borrowerEntity?.borrowerName &&
          restDetails.newStoresList[indx].freshStore !== "Y"
            ? {
                ...restDetails.newStoresList[indx].borrowerEntity,
                stockHolders:
                  restDetails.newStoresList[indx].stockHolders || [],
              }
            : null;
        restDetails.newStoresList[indx].yesUpdate = false;
      }
    } else {
      if (grp === "oldStoresList") {
        restDetails.oldStoresList[indx].borrowerId =
          response?.borrowerEntity?.borrowerId;
        restDetails.oldStoresList[indx].borrowerName =
          response?.borrowerEntity?.borrowerName;
        restDetails.oldStoresList[indx].borrowerEntity = {
          ...response?.borrowerEntity,
        };
        restDetails.oldStoresList[indx].stockHolders =
          response.stockHolders && response.stockHolders.length > 0
            ? [...response.stockHolders]
            : [];
        restDetails.oldStoresList[indx].borrowerEntityOld = null;
        restDetails.oldStoresList[indx].yesUpdate = true;
      } else {
        restDetails.newStoresList[indx].borrowerId =
          response?.borrowerEntity?.borrowerId;
        restDetails.newStoresList[indx].borrowerName =
          response?.borrowerEntity?.borrowerName;
        restDetails.newStoresList[indx].borrowerEntity = {
          ...response?.borrowerEntity,
        };
        restDetails.newStoresList[indx].stockHolders =
          response.stockHolders && response.stockHolders.length > 0
            ? [...response.stockHolders]
            : [];
        restDetails.newStoresList[indx].borrowerEntityOld = null;
        restDetails.newStoresList[indx].yesUpdate = true;
      }
    }

    const payload = {
      ...restDetails,
    };

    setRestDetails(payload);
    dispatch(setData(payload));
    if (!blurCall)
      setTimeout(() => {
        setDisableUpdate(false);
      }, 1000);
  };

  const updateRestaurant = (id, val) => {
    if (val.newStore == "N" && restDetails?.oldStoresList) {
      restDetails.oldStoresList[id] = JSON.parse(JSON.stringify(val));
    } else if (restDetails?.newStoresList) {
      restDetails.newStoresList[id] = JSON.parse(JSON.stringify(val));
    }
    setRestDetails({
      ...restDetails,
    });
    dispatch(setData({ ...restDetails }));
  };

  const deleteStakeHolder = async (data) => {
    const url = `/ext/deleteSHInfo.do`;
    const response = await deleteAPI(url, data, data.loanId, loggedUserId);
    dispatch(setData({ ...restDetails }));
  };

  let { newStoresList, oldStoresList, stockHolders } = restDetails;
  const upcomingRestNos = newStoresList?.filter(
    (ele) => ele != undefined
  ).length;
  const currentRestNos = oldStoresList?.filter(
    (ele) => ele != undefined
  ).length;
  const stockHolder = stockHolders?.filter((ele) => ele != undefined);

  const renderContent = () => {
    if (activeTab === 1) {
      return (
        <CurrentRestaurant
          handleDeleteAnItem={handleDeleteAnItem}
          updateRestaurant={updateRestaurant}
          handleAddNewItem={handleAddNewItem}
          updateStakeHolder={updateStakeHolder}
          deleteStakeHolder={deleteStakeHolder}
          btnDisable={disableUpdate}
          data={newStoresList}
        />
      );
    }
    return (
      <OwnRestaurant
        handleDeleteAnItem={handleDeleteAnItem}
        updateRestaurant={updateRestaurant}
        updateStakeHolder={updateStakeHolder}
        deleteStakeHolder={deleteStakeHolder}
        handleAddNewItem={handleAddNewItem}
        btnDisable={disableUpdate}
        data={oldStoresList}
      />
    );
  };

  return (
    <>
      {idlr ? <Loader /> : null}
      <div className={`dynamicContainer restContainer`} id="main" role="main">
        <h1 className={`headingClass`}>{RestarauntSummary.header}</h1>
        <Form fullWidthFields className={`formClass`}>
          <Grid container spacing={2}>
            <Tabs
              onChange={(event, value, index) => {
                setActiveTab(index);
              }}
              renderContent={renderContent}
              renderTab={({ getTabProps }) => {
                const propsVals = getTabProps();
                return <Tab {...propsVals} label={propsVals.label} />;
              }}
              tabs={["Owned Restaurants", "Purchasing or Building Restaurants"]}
              overflowMenu={false}
              value={activeTab}
              className="marginLeft8"
            />
            {(currentRestNos && currentRestNos != 0) ||
            (upcomingRestNos && upcomingRestNos != 0) ? (
              <>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div className="padding8">
                    <RadioButtonGroup
                      aria-label="Controlled Example"
                      legend={RestarauntSummary.mgntCompany}
                      name="mgntCompany"
                      role="radio"
                      className={"singleRowRadioBtn"}
                      onChange={(e) =>
                        handleChange(e?.target?.value, "mgmtCompany")
                      }
                      radios={radioMgmtCmp}
                      value={restDetails?.mgmtCompany}
                    />
                  </div>
                </Grid>
                {restDetails?.mgmtCompany === "Y" ? (
                  <>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <FormField label={RestarauntSummary.compnyLegalName}>
                        <Input
                          name="legalName"
                          fullWidth
                          autoComplete="off"
                          value={restDetails?.mgmtCompanyName}
                          onChange={(e) =>
                            handleChange(e?.target?.value, "mgmtCompanyName")
                          }
                        />
                      </FormField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <FormField label={RestarauntSummary.entityStructured}>
                        <AriaAnnouncerProvider>
                          <Dropdown
                            className={"dropDownField"}
                            itemToString={(item) =>
                              item ? item.businessStructName : ""
                            }
                            selectedItem={selectStockEntity(
                              restDetails?.mgmtCompanyEntityId
                            )}
                            source={STOCKBIZSTRUCTURE}
                            onSelect={(e, item) =>
                              handleChange(
                                item.businessStructId,
                                "mgmtCompanyEntityId"
                              )
                            }
                          />
                        </AriaAnnouncerProvider>
                      </FormField>
                    </Grid>
                    {restDetails?.mgmtCompanyEntityId > "0" &&
                    restDetails?.mgmtCompanyEntityId < "5" ? (
                      <>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <FormField
                            label={RestarauntSummary.stateIncorpration}
                          >
                            <Dropdown
                              className={"dropDownField"}
                              itemToString={(item) =>
                                item ? item.stateName : ""
                              }
                              selectedItem={selectState(
                                restDetails?.mgmtStateOfIncorporation,
                                countrystateData
                              )}
                              source={countrystateData}
                              onSelect={(e, item) =>
                                handleChange(
                                  item.stateId,
                                  "mgmtStateOfIncorporation"
                                )
                              }
                            />
                          </FormField>
                        </Grid>
                      </>
                    ) : restDetails?.mgmtCompanyEntityId == "5" ? (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormField label={"Please specify"}>
                          <Input
                            name="otherDetails"
                            fullWidth
                            autoComplete="off"
                            onChange={(e) =>
                              handleChange(
                                e?.target?.value,
                                "mgmtEntityotherDetails"
                              )
                            }
                            value={restDetails?.mgmtEntityotherDetails}
                          />
                        </FormField>
                      </Grid>
                    ) : (
                      ""
                    )}

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <FormField label={RestarauntSummary.noOfStockholders}>
                        <Input
                          name="legalName"
                          fullWidth
                          autoComplete="off"
                          value={
                            stockHolder?.length == 0 ? "" : stockHolder?.length
                          }
                          onChange={handleStockHolders}
                        />
                      </FormField>
                    </Grid>
                    {restDetails?.stockHolders?.map(
                      (ele, ind) =>
                        ele && (
                          <StockHolderDetails
                            key={ind}
                            ind={ind}
                            defaultValue={ele}
                            updateState={updateStockholder}
                            remove={handleRemoveStockholder}
                            aria-live="polite"
                          />
                        )
                    )}
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </Grid>
          {stockHolder?.length ? (
            <div className="parentShContainer">
              <Button variant="cta" onClick={handleAddStockholder}>
                add another shareholder
              </Button>
            </div>
          ) : (
            ""
          )}
        </Form>
        {addressCheck ? (
          <Toast
            onClose={handleAddressToastClose}
            state={ToastState.ERROR}
            title="Address"
            className="addressToast"
          >
            Restaurant Address is mandatory
          </Toast>
        ) : (
          ""
        )}
        <p className={"noteSectionClass"}>{RestarauntSummary.note}</p>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className={`restuarantFooterContainer`}>
            <Button onClick={handlePreviousClick}>previous section</Button>
            <Button variant="cta" onClick={handleNextClick}>
              next section
            </Button>
            <HiddenValidator
              validateTrigger={() => handleNextClick(false)}
              labels={"next section"}
            />
          </div>
        </Grid>
      </div>
    </>
  );
};

const initStockholder = {
  stockHolderId: "",
  stockHolderName: "",
  stockHolderTitle: "",
  ownershipPercent: "",
};
const dynamicFormProps = {
  initObj: { ...initStockholder },
  idKey: "stockHolderId",
};

export default dynamicForm(
  ErrorBoundary(RestaurantInfoSummary),
  dynamicFormProps
);
