import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { FormField, useValidation } from '@jpmuitk/form-field';
import { Form } from '@jpmuitk/forms';
import { Input } from '@jpmuitk/input';
import { OrderedButton, ButtonBar } from '@jpmuitk/button-bar';
import { Dropdown } from '@jpmuitk/dropdown';
import { RegistrationData, errorMessageStyle, ERRORCODE } from '../../global.constants';
import { firstNameValidationRules, emailValidationRules, lastNameValidationRules, regionValidationRules } from '../../ValidationRules';
import { useHistory } from "react-router-dom";
import '../../global.scss'
import Loader from "../../../components/loader/Loader";
import Error from '../../../components/error/Error';
import { getRegionData } from '../../commonactions/redux/actions/action';
import { saveRegistartaionInfoData } from './redux/action/registrationAction';


const Registration = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [reEmailAddress, setReEmailAddress] = useState('');
    const [region, setRegion] = useState('');
    const [firstNameExceptions, validateFirstName] = useValidation([firstNameValidationRules[0]]);
    const [lastNameExceptions, validateLastName] = useValidation([lastNameValidationRules[0]]);
    const [emailExceptions, validateEmail] = useValidation(emailValidationRules);
    const [reEmailExceptions, setReEmailValidation] = useState({});
    const [regionExceptions, validateRegion] = useValidation(regionValidationRules);
    const isLoading = useSelector(state => state.NewRegistrationReducer.isLoading);
    const errors = useSelector(state => state.NewRegistrationReducer.error);
    const regionsData = useSelector(state => state?.CountrystatesReducer?.Regionname);
    const newusererror = useSelector(state => state.ReturnUserReducer.newUserErrorCode);
    const errormsg  = useSelector(state => state.NewRegistrationReducer.errorMessage);
    const newemail = useSelector(state => state.ReturnUserReducer.newmail);
    const regionNames = regionsData.map(region => region.regionName);
    regionNames?.sort()?.unshift(RegistrationData.SELECT)
    const [isExistingUser,setIsExistingUser] = useState(false);


    useEffect(() => {
        setRegion('Select');
    }, [])


    useEffect(() => {
        if (newusererror) {
            setEmailAddress(newemail.emailAddress)
        }
    }, [newusererror]);


    useEffect(() => {
        dispatch(getRegionData());
    }, [])
    const handleChange = (name, value, fieldname) => {
        if (name === 'firstName') {
            setFirstName(value);
        }
        else if (name === 'lastName') {
            setLastName(value);
        }
        else if (name === 'emailAddress') {
            setEmailAddress(value);
        }
        else if (name === 'reEmailAddress') {
            setReEmailAddress(value);
        }
        else if (fieldname === 'region') {
            setRegion(value);
        }
    }

    const onBlurEvent = (name, event) => {
        if (name === 'firstName') {
            validateFirstName(event.target.value);
        }
        else if (name === 'lastName') {
            validateLastName(event.target.value);
        }
        else if (name === 'emailAddress') {
            validateEmail(event.target.value);
        }
        else if (name === 'reEmailAddress') {
            validateReEmail(event.target.value, emailAddress);
        }
        else if (name === 'region') {
            validateRegion(region)
        }
    }

    const handleRegister = () => {
        validateFirstName(firstName);
        validateLastName(lastName);
        validateEmail(emailAddress);
        validateRegion(region);
        validateReEmail(reEmailAddress, emailAddress);
        if (firstName.length !== 0 && lastName.length !== 0 &&
            emailAddress.length !== 0 && reEmailAddress.length !== 0 && region !== 'Select') {
            const regionid = regionsData.find(({ regionName }) => regionName == region);
            var payload =
            {
                "loginId": emailAddress,
                "firstName": firstName,
                "lastName": lastName,
                "regions": regionid.regionId
            }
            dispatch(saveRegistartaionInfoData(payload,setIsExistingUser,navigateToSuccess));  
        }
    }
    const navigateToSuccess = () => {
        history.push('/registrationSuccess')
    }
    const validateReEmail = (reEmailAddress, emailAddress) => {
        if (reEmailAddress !== emailAddress) {
            setReEmailValidation({
                type: 'warning',
                message: 'E-mail Address does not match'
            })
        }
        else {
            setReEmailValidation({})
        }
    }

    const handleCancel = (e) => {
        window.location.href="/newReturningUser"
    
    }
    return (
        <>
            {isLoading ? <Loader /> : null}
            {errors == ERRORCODE ? <Error /> :
                <div className={`authenticationContainer`} id="main">
              <p className={'existingUserClass'}>{ isExistingUser ? RegistrationData.existingUser : null }</p>
              <p className={'errormsg'}>{!!errormsg ? errormsg : null}</p>
                    <h1>{RegistrationData.header}</h1>
                    <p className={`marginTop30 marginBottom45`}>
                        {newusererror === true ? RegistrationData.errorInfo : RegistrationData.info}
                    </p>
                    <Form fullWidthFields className={`formClass`}>
                        <Grid container spacing={2} >
                            <Grid item xs={12}>
                                <FormField
                                    label="First Name"
                                    HelperTextProps={errorMessageStyle}
                                    helperText={firstNameExceptions[0]?.message}
                                    validationState={firstNameExceptions[0]?.type}>
                                    <Input name='firstName' fullWidth
                                        value={firstName}
                                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                                        onBlur={(e) => onBlurEvent('firstName', e)}
                                    />
                                </FormField>
                            </Grid>
                            <Grid item xs={12}>
                                <FormField
                                    label="Last Name"
                                    HelperTextProps={errorMessageStyle}
                                    helperText={lastNameExceptions[0]?.message}
                                    validationState={lastNameExceptions[0]?.type}>
                                    <Input name='lastName' fullWidth
                                        value={lastName}
                                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                                        onBlur={(e) => onBlurEvent('lastName', e)}
                                    />
                                </FormField>
                            </Grid>
                            <Grid item xs={12}>
                                <FormField
                                    label="E-mail Address"
                                    HelperTextProps={errorMessageStyle}
                                    helperText={emailExceptions[0]?.message}
                                    validationState={emailExceptions[0]?.type}>
                                    <Input name='emailAddress' fullWidth
                                        value={emailAddress}
                                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                                        onBlur={(e) => onBlurEvent('emailAddress', e)}
                                    />
                                </FormField>
                            </Grid>
                            <Grid item xs={12}>
                                <FormField
                                    label="Re-key E-mail Address"
                                    HelperTextProps={errorMessageStyle}
                                    helperText={reEmailExceptions?.message}
                                    validationState={reEmailExceptions?.type}>
                                    <Input name='reEmailAddress' fullWidth autoComplete='off'
                                        value={reEmailAddress}
                                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                                        onBlur={(e) => onBlurEvent('reEmailAddress', e)}
                                    />
                                </FormField>
                            </Grid>
                            <Grid item xs={12}>
                                <FormField
                                    label="McDonald's Region"
                                    HelperTextProps={errorMessageStyle}
                                    helperText={regionExceptions[0]?.message}
                                    validationState={regionExceptions[0]?.type}>
                                    <Dropdown
                                        id='region'
                                        name='region'
                                        source={regionNames}
                                        selectedItem={region || ''}
                                        className={'dropDownField'}
                                        onBlur={(e) => onBlurEvent('region', e)}
                                        onChange={(e, selectedItem) => { handleChange(e, selectedItem, 'region') }}
                                    />
                                </FormField>
                            </Grid>
                        </Grid>
                    </Form>
                    <ButtonBar className='marginTop30 marginBottom45'>
                        <OrderedButton className={`commonStylingButton`} onClick={handleCancel}>{RegistrationData.cancel}</OrderedButton>
                        <OrderedButton variant="cta" onClick={handleRegister}>{RegistrationData.register}</OrderedButton>
                    </ButtonBar>
                </div>
            }
        </>
    )
}
export default Registration;