import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { FormField } from "@jpmuitk/form-field";
import { Input } from "@jpmuitk/input";
import { OrderedButton, ButtonBar } from "@jpmuitk/button-bar";
import {
  adminUpdaterates,
  ERRORCODE,
  FOURHUNDREDFOUR,
  FOURZEROONE,
} from "../../global.constants";
import "./UpdateCurrentRates.scss";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  getUpdateCurrentData,
  saveUpdateCurrentData,
} from "./redux/action/updateCurrentAction";
import Loader from "../../../components/loader/Loader";
import Error from "../../../components/error/Error";
import { Banner } from "@jpmuitk/banner";
import { AriaAnnouncerProvider } from "@jpmuitk/aria-announcer";
// Userstory FFANEW-172
const editorConfiguration = {
  fontFamily: {
    options: [
      "default",
      "Ubuntu, Arial, sans-serif",
      "Ubuntu Mono, Courier New, Courier, monospace",
    ],
  },
  fontSize: {
    options: ["tiny", "default", "big"],
  },
  supportAllValues: true,
  //plugins: [ Font ],
  toolbar: {
    extraPlugins: "font",
    items: [
      "heading",
      "alignment",
      "fontFamily",
      "fontColor",
      "fontSize",
      "fontBackgroundColor",
      "|",
      "bold",
      "italic",
      "strikethrough",
      "underline",
      "subscript",
      "superscript",
      "|",
      "outdent",
      "indent",
      "|",
      "bulletedList",
      "numberedList",
      "todoList",
      "|",
      "code",
      "codeBlock",
      "|",
    ],
    shouldNotGroupWhenFull: false,
  },
};
const UpdateCurrentRates = () => {
  const dispatch = useDispatch();
  const updateData = useSelector(
    (state) => state?.updateCurrentReducer?.updateCurrentData
  );
  const isAdminLoggedIn = useSelector(
    (state) => state.AuthCheckReducer?.AdminloggedInUser
  );
  const isLoading = useSelector(
    (state) => state?.updateCurrentReducer?.isLoading
  );
  const isAdminLoading = useSelector(
    (state) => state.AuthCheckReducer.isLoading
  );
  const errors = useSelector((state) => state?.updateCurrentReducer?.error);
  const [updatecurrentdata, setUpdatecurrentdata] = useState([]);
  const [open, setOpen] = useState("");
  const [updatemsg, setUpdatemsg] = useState("");
  const [newsflash, setNewsflash] = useState("");

  useEffect(() => {
    if (updateData) {
      setUpdatecurrentdata(updateData);
    }
  }, [updateData]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(getUpdateCurrentData());
    }, 1000);
  }, []);

  //Handle Event
  const handleChange = (name, value) => {
    if (/^-?\d*[.,]?\d{0,2}$/.test(value)) {
      if (value <= 100) {
        if (/^0/.test(value)) {
          setUpdatecurrentdata({ ...updatecurrentdata, [name]: "" });
        } else {
          setUpdatecurrentdata({ ...updatecurrentdata, [name]: value });
        }
      }
    }
  };
  //save action
  const onSaveAction = () => {
    setUpdatecurrentdata({
      busFixedRate3: updatecurrentdata.busFixedRate3,
      busFixedRate5: updatecurrentdata.busFixedRate5,
      busFixedRate7: updatecurrentdata.busFixedRate7,
      busFixedRate10: updatecurrentdata.busFixedRate10,
      busFloatingRate: updatecurrentdata.busFloatingRate,
      createdBy: updatecurrentdata.createdBy,
      createdDate: updatecurrentdata.createdDate,
      newsFlash: newsflash,
      rateId: updatecurrentdata.rateId,
      updatedBy: updatecurrentdata.updatedBy,
      updatedDate: updatecurrentdata.updatedDate,
    });
    dispatch(saveUpdateCurrentData(updatecurrentdata, isAdminLoggedIn));
    setUpdatemsg(adminUpdaterates.saveContent);
    setOpen(true);
    window.scrollTo(0, 0);
  };

  //cancel action
  const onCancelAction = () => {
    setUpdatecurrentdata(updateData);
    setUpdatemsg(adminUpdaterates.cancelContent);
    setOpen(false);
  };

  return (
    <>
      {isLoading || isAdminLoading ? <Loader /> : null}
      {errors == FOURZEROONE ? (
        <Error />
      ) : (
        <div className={`adminContainer`} id="main" role="main">
          <div className={`alertmsg`} aria-live="assertive" role="alert">
            {" "}
            {open !== "" && (
              <AriaAnnouncerProvider>
                <Banner
                  state={open ? "success" : "error"}
                  className={open ? "successAlert" : "failureAlert"}
                >
                  {updatemsg}
                </Banner>
              </AriaAnnouncerProvider>
            )}
          </div>

          <h1 className="business-header updateheadingClass">
            {adminUpdaterates.messageHeader}
          </h1>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <span aria-hidden="true">
                <CKEditor
                  editor={ClassicEditor}
                  config={editorConfiguration}
                  data={updatecurrentdata?.newsFlash}
                  onReady={(editor) => {
                    editor &&
                      editor.setData(updatecurrentdata?.newsFlash || "");
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    updatecurrentdata.newsFlash = data;
                    setNewsflash(data);
                  }}
                ></CKEditor>
              </span>
            </Grid>
            <ButtonBar className="centerPosition">
              <OrderedButton
                className={`commonStylingButton`}
                onClick={onCancelAction}
              >
                {adminUpdaterates.cancel}
              </OrderedButton>
              <OrderedButton variant="cta" onClick={onSaveAction}>
                {adminUpdaterates.save}
              </OrderedButton>
            </ButtonBar>
          </Grid>
        </div>
      )}
    </>
  );
};
export default UpdateCurrentRates;