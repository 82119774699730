import axios from 'axios'
import {
    FETCH_REGIONINFO_REQUEST,SAVE_REGIONINFO_RECEIVE,FETCH_REGIONINFO_SUCCESS,
    SAVE_REGIONINFO_SUCCESS,SAVE_REGIONINFO_FAILURE,FETCH_REGIONINFO_FAILURE,REGION_REQUEST,base_url_user } from '../../../../global.constants'

export const fetchRegionInfo_request = (data)=>{
    return {
        type: FETCH_REGIONINFO_REQUEST,
        payload : data
    }
 }
 export const fetchRegionInfo_success = ()=>{
     return {
         type: FETCH_REGIONINFO_SUCCESS,
     }
 }
 export const fetchRegionInfo_failure = (error)=>{
     return {
         type: FETCH_REGIONINFO_FAILURE,
         payload : error 
     }
 }
 
 export const saveRegionInfo_receive = (data)=>{
     return {
         type: SAVE_REGIONINFO_RECEIVE,
         payload : data
       
     }
  }
 export const saveRegionInfo_failure = (error)=>{
     return {
         type: SAVE_REGIONINFO_FAILURE,
         payload : error 
     }
 }
 
 export const saveRegionInfo_success = ()=>{
     return {
         type: SAVE_REGIONINFO_SUCCESS,
     }
 }
 
 export const   getRegionInfoData =  (loanId,userId) => async dispatch => {
 const payload={ "loanId": loanId, "userId": userId}
 
try {
    dispatch({type : REGION_REQUEST});
       await axios.post(
        base_url_user+`/api/v1/usersvc/ext/getRegionInfo.do`, payload)
           .then(response => {
               if (response && response.status === 200) {
                  dispatch(fetchRegionInfo_request(response.data));
               }
               else {
                    dispatch(fetchRegionInfo_failure(response.status))
               }
           });
   }
   catch (e) {
        dispatch(fetchRegionInfo_failure(e.response?.status))
       return e;
   }
   };

 export const saveRegionInfoData = (payload,loanId,userId) => async dispatch =>  {
     const reqPayload = {...payload, "loanId": loanId, "userId": userId}
     
try {
    dispatch({ type:  REGION_REQUEST});
    await axios.put(
    base_url_user +`/api/v1/usersvc/ext/saveRegionInfo.do`, reqPayload)
        .then(response => {
            if (response && response.status === 200) {
                dispatch(saveRegionInfo_receive())
            }
            else {
                dispatch(saveRegionInfo_failure(response.status))
            }
        });
 }
catch (e) {
    dispatch(saveRegionInfo_failure(e.response?.status))
    return e;
}
};



  

 


 