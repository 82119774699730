import React from "react"
import "./loanProductSummary.scss";
import Grid from '@material-ui/core/Grid';
import { LOANPRODUCTSUMMARY_HEADER1, LOANPRODUCT_SUBHEADER, Multiple_Advance, WORKING_CAPITAL, MUTIPLE, LOANPRODUCT_FOOTER } from "../../static.constants"
import '../../global.scss'
// UserStory FFANEW-3
export default function LoanProductSummary() {
    return (
        <div className='container' id="main">
                <h1>{LOANPRODUCTSUMMARY_HEADER1}</h1>
                <p>{LOANPRODUCT_SUBHEADER}</p>
                  
                        <table className='staticTable'>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th className="text-center bold">{Multiple_Advance.header}</th>
                                    <th className="text-center bold">{WORKING_CAPITAL.header}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{"Purposes"}</td>
                                    <td><ul>{MUTIPLE.map((items, i) => { return <li key={i}>{items}</li> })}</ul> </td>
                                    <td>
                                        <ul>
                                            <li>{WORKING_CAPITAL.businessExpenses}</li>
                                            <li>{WORKING_CAPITAL.season}</li>
                                            <li>{WORKING_CAPITAL.equipment}</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td >{'Maturities'}</td>
                                    <td>{Multiple_Advance.finance}</td>
                                    <td>{WORKING_CAPITAL.months}</td>
                                </tr>
                                <tr>
                                    <td ><span className="blockSpan">Diligence Fees</span><span className="blockSpan">(non-refundable)</span></td>
                                    <td>{Multiple_Advance.amount}</td>
                                    <td>{WORKING_CAPITAL.accompanied}</td>
                                </tr>
                                <tr>
                                    <td>{'Payments'}</td>
                                    <td><ul><li>{Multiple_Advance.payment}
                                        <ol className="loan-summary-payments">
                                            <li>{Multiple_Advance.level}</li>
                                            <li>{Multiple_Advance.principal}</li>
                                            <li>{Multiple_Advance.season}</li>
                                        </ol>
                                        </li>
                                        </ul>
                                    </td>
                                    <td>{WORKING_CAPITAL.interest}</td>
                                </tr>
                                <tr>
                                    <td>{'Collateral'}</td>
                                    <td>
                                        <ul><li>{WORKING_CAPITAL.finance}</li>
                                        <li>{WORKING_CAPITAL.secure}</li>
                                        </ul>
                                    </td>
                                    <td>
                                        <ul>
                                            <li>{WORKING_CAPITAL.finance}</li>
                                            <li>{WORKING_CAPITAL.secure}</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{'Other terms'}</td>
                                    <td></td>
                                    <td><ul><li>{WORKING_CAPITAL.collateral}</li></ul></td>
                                </tr>
                            </tbody>
                        </table>
        </div>
    )
}