import {
    POST_REGISTRATION_DATA,
    RECEIVE_REGISTRATION_INFORMATION_REQUEST,
    FAILURE_REGISTRATION_POST_REQUEST,
    FFW_EXT_USER_ALREADY_EXISTS,
    base_url,
    base_url_auth,ERRROR_DATA, ERROR_MESSAGE
  } from "../../../../global.constants"
  import axios from 'axios';



  export const saveRegistartaionInfoData = (responsedata, setIsExistingUser,navigateToSuccess) =>  {
    return async (dispatch) => {
      dispatch({ type: RECEIVE_REGISTRATION_INFORMATION_REQUEST });
       axios.post( base_url_auth +`/api/v1/register`,responsedata)
        .then(response => {
          if (response && response.status === 200) {
            dispatch(submitRegistartaionData(response?.data));
            {response.data.errorCode === FFW_EXT_USER_ALREADY_EXISTS ? setIsExistingUser(true): navigateToSuccess()}
          }
          else{
            dispatch(failureRegistartaionPostData(response?.status))
          }
        })
        .catch((e) => {
          if( e?.response?.data?.errorCode){
            dispatch(errorkeylist(e?.response?.data?.errorCode))
        }
        else{
          dispatch(errorkeylist(ERROR_MESSAGE))
          dispatch(failureRegistartaionPostData(e?.response?.status))
        }
        });
    }
  };

  export const failureRegistartaionPostData = (payload) => ({
    type: FAILURE_REGISTRATION_POST_REQUEST,
    payload
  })

  export const submitRegistartaionData = (data) => {
    return {
      type: POST_REGISTRATION_DATA,
      payload: data
    }
  }

  export const errorkeylist = (data) => {
    return {
      type: ERRROR_DATA,
      payload: data
    }
  }



