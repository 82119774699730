import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { FormField, useValidation } from "@jpmuitk/form-field";
import { Banner } from "@jpmuitk/banner";
import { Form } from "@jpmuitk/forms";
import { Input } from "@jpmuitk/input";
import { OrderedButton, ButtonBar } from "@jpmuitk/button-bar";
import { Dropdown } from "@jpmuitk/dropdown";
import { useHistory } from "react-router-dom";
import {
  firstNameValidationRules,
  emailValidationRules,
  lastNameValidationRules,
  regionValidationRules,
} from "../../ValidationRules";
import { fetchProfileData, getUserData } from "./redux/action/MyProfileAction";
import {
  MyProfileConstants,
  RegionInformationConstants,
} from "../../global.constants";
import "./MyProfile.scss";
import "../../global.scss";
import { successBanner } from "../submitRequest/redux/actions/submitRequestAction";
import { AriaAnnouncerProvider } from "@jpmuitk/aria-announcer";
import { getRegionData } from "../../commonactions/redux/actions/action";
import Loader from "../../../components/loader/Loader";

const MyProfile = () => {
  const [firstNameExceptions, validateFirstName] = useValidation(
    firstNameValidationRules
  );
  const [lastNameExceptions, validateLastName] = useValidation(
    lastNameValidationRules
  );
  const [emailExceptions, validateEmail] = useValidation(emailValidationRules);
  const [regionExceptions, validateRegion] = useValidation(
    regionValidationRules
  );
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [oldEmail, setOldEmail] = useState("");
  const [janusUserName, setJanusUserName] = useState("");
  const [showBanner, setShowBanner] = useState(false);
  const [showlogBanner, setShowlogBanner] = useState(false);
  const [banner, setBanner] = useState({
    state: "",
    bannerTitle: "",
  });
  const [logoutbanner, setlogoutBanner] = useState({
    state: "",
    bannerTitle: "",
  });

  const isLoading = useSelector((state) => state.ProfileReducer.isLoading);
  const [validate, setValidate] = useState(false);
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const userdata = useSelector((state) => state.ProfileReducer.userdata);
  // const regions = useSelector(state => state.ProfileReducer.userdata?.regionList);
  const errorcode = useSelector((state) => state.ProfileReducer.errorCode);
  const userId = useSelector((state) => state.AuthCheckReducer.loggedInUserId);
  const regn = useSelector((state) => state.CountrystatesReducer.Regionname);
  const [region, setRegion] = useState([]);
  const [isExistingUser, setIsExistingUser] = useState(false);
  const newcountrystatesarray = region?.map((x) => x.regionName);
  newcountrystatesarray.unshift(RegionInformationConstants.select);
  const history = useHistory();
  const [lder, setLder] = useState(false);
  useEffect(() => {
    if (userdata) {
      setFirstName(userdata?.firstName);
      setLastName(userdata?.lastName);
      setEmail(userdata?.emailAddress);
      setOldEmail(userdata?.emailAddress);
      setJanusUserName(userdata?.janusUserName);
      setData(userdata);
    }
  }, [userdata]);

  useEffect(() => {
    if (regn) {
      setRegion(regn);
    }
  }, [regn]);

  useEffect(() => {
    dispatch(successBanner(false));
    dispatch(getUserData(userId));
    if (regn) {
      dispatch(getRegionData());
    }
  }, []);
  const proceedLogout = () => {
    setShowlogBanner(true);
    setLder(true);
    setTimeout(() => {
      history.push(`/fdao/signOut`);
      window.location.reload();
    }, 2000);
  };
  const handleFirstNameChange = (e) => {
    validateFirstName([]);
    const { value } = e.target;
    setFirstName(value);
  };

  const handleRegionChange = (e, selectedItem) => {
    validateRegion([]);
    const selectedRegion = userdata?.regionList.find(
      (x) => x.regionName === selectedItem
    );
    setData({
      ...data,
      selectedRegion: {
        ...data.selectedRegion,
        regionName: selectedItem,
        regionId: selectedRegion?.regionId,
      },
    });
  };

  const handleChange = () => {
    validateFirstName(firstName);
    validateLastName(lastName);
    validateEmail(email);
    validateRegion(data?.selectedRegion?.regionName);
    setValidate(true);
  };

  useEffect(() => {
    if (validate) {
      validateform();
    }
  }, [validate]);

  const validateform = () => {
    if (
      firstNameExceptions.length === 0 &&
      lastNameExceptions.length === 0 &&
      emailExceptions.length === 0 &&
      regionExceptions.length === 0
    ) {
      const payload = {
        firstName: firstName,
        lastName: lastName,
        emailAddress: email,
        userId: userId,
        oldEmailAddress: oldEmail,
        janusUserName: userdata?.janusUserName || "",
        regions: data.selectedRegion.regionId || 0,
        reg_name: data.selectedRegion.regionName || "Select",
      };

      dispatch(
        fetchProfileData(
          payload,
          setIsExistingUser,
          setShowBanner,
          proceedLogout
        )
      );
      // setShowBanner(true);
      setBanner({
        ...banner,
        state: MyProfileConstants.sucess,
        bannerTitle: MyProfileConstants.successBannerMessage,
      });
      setlogoutBanner({
        ...logoutbanner,
        state: MyProfileConstants.sucess,
        bannerTitle: MyProfileConstants.loggedout,
      });
    }
    setValidate(false);
  };

  const handleLastNameChange = (e) => {
    validateLastName([]);
    const { value } = e.target;
    setLastName(value);
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  const handleCancel = () => {
    dispatch(getUserData(userId));
    setShowBanner(true);
    setBanner({
      ...banner,
      state: MyProfileConstants.error,
      bannerTitle: MyProfileConstants.cancelBannerMessage,
    });
    validateEmail(email);
    validateFirstName([]);
    validateLastName([]);
    validateRegion([]);
  };

  const handleClose = () => {
    setShowBanner(false);
    setShowlogBanner(false);
    setBanner({});
    setlogoutBanner({});
  };

  const mergedErrorMessages = (exceptions) =>
    exceptions.map(({ type, message }) => `${message}`).join("");
  return (
    <>
      {isLoading || lder ? <Loader /> : null}
      <div className={`dynamicContainer`} id="main" role="main">
        <p className={"existingEmailClass"}>
          {isExistingUser ? errorcode : null}
        </p>
        {showBanner && (
          <Banner state={banner.state} onClose={handleClose}>
            {banner.bannerTitle}
          </Banner>
        )}
        {showlogBanner && (
          <Banner state={logoutbanner.state} onClose={handleClose}>
            {logoutbanner.bannerTitle}
          </Banner>
        )}
        <Form fullWidthFields className={`formClass`} onChange={handleClose}>
          <h1 className={`headingClass`}>{MyProfileConstants.myprofile}</h1>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormField
                label="First Name"
                classes={{ helperText: "errorHelperText" }}
                helperText={mergedErrorMessages(firstNameExceptions)}
                validationState={firstNameExceptions[0]?.type || ""}
              >
                <Input
                  name="firstname"
                  fullWidth
                  autoComplete="off"
                  value={firstName}
                  onChange={handleFirstNameChange}
                  onBlur={(e) => validateFirstName(e.target.value)}
                />
              </FormField>
            </Grid>
            <Grid item xs={12}>
              <FormField
                label="Last Name"
                classes={{ helperText: "errorHelperText" }}
                helperText={mergedErrorMessages(lastNameExceptions)}
                validationState={lastNameExceptions[0]?.type || ""}
              >
                <Input
                  name="lastname"
                  value={lastName}
                  autoComplete="off"
                  onChange={handleLastNameChange}
                  onBlur={(e) => validateLastName(e.target.value)}
                />
              </FormField>
            </Grid>
            <Grid item xs={12}>
              <FormField
                label="Email Address"
                classes={{ helperText: "errorHelperText" }}
                helperText={mergedErrorMessages(emailExceptions)}
                validationState={emailExceptions[0]?.type || ""}
              >
                <Input
                  name="emailAddress"
                  value={email}
                  autoComplete="off"
                  onChange={handleEmailChange}
                  onBlur={(e) => validateEmail(e.target.value)}
                />
              </FormField>
            </Grid>
            <Grid item xs={12}>
              <FormField
                label="Region"
                classes={{ helperText: "errorHelperText" }}
                helperText={mergedErrorMessages(regionExceptions)}
                validationState={regionExceptions[0]?.type || null}
              >
                <AriaAnnouncerProvider>
                  <Dropdown
                    onChange={handleRegionChange}
                    id="region"
                    source={newcountrystatesarray}
                    className={"dropDownField"}
                    selectedItem={
                      data?.selectedRegion?.regionName
                        ? data?.selectedRegion?.regionName
                        : "Select"
                    }
                    onBlur={() =>
                      validateRegion(
                        data?.selectedRegion?.regionName
                          ? data?.selectedRegion?.regionName
                          : "Select"
                      )
                    }
                  />
                </AriaAnnouncerProvider>
              </FormField>
            </Grid>
          </Grid>
        </Form>
        <p className={`alertClass`}>{MyProfileConstants.alertMessage}</p>
        <ButtonBar sortOrder="asc">
          <OrderedButton
            className={`commonStylingButton`}
            variant="cta"
            onClick={handleChange}
          >
            {MyProfileConstants.change}
          </OrderedButton>
          <OrderedButton onClick={handleCancel}>
            {MyProfileConstants.cancel}
          </OrderedButton>
        </ButtonBar>
      </div>
    </>
  );
};

export default MyProfile;
