import { Button } from "@jpmuitk/button";
import RestDetails from "./RestaurantDetails";
import ErrorBoundary from "../../../components/form/ErrorBoundary";
import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";

const CurrentRestaurant = ({
  handleDeleteAnItem,
  updateRestaurant,
  handleAddNewItem,
  data,
  updateStakeHolder,
  deleteStakeHolder,
  btnDisable,
}) => {
  const upcomingRestNos = data?.filter((ele) => ele != undefined)?.length;
  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <div className="newStoresTopContainer">
          {upcomingRestNos === 0 ? (
            <h3>No purchased restaurants</h3>
          ) : (
            <h3>
              Number of restaurants you have purchased or building at this time:{" "}
              <span className="numberOfRest">{upcomingRestNos}</span>
            </h3>
          )}
          {upcomingRestNos > 0 && (
            <div className={`addNewStoreContainer`}>
              <Button
                variant="cta"
                name="newStoresList"
                onClick={() => handleAddNewItem("newStoresList")}
                aria-live="polite"
              >
                Add Another Restaurant
              </Button>
            </div>
          )}
        </div>
      </Grid>
      {data?.map((ele, ind) => {
        return (
          <RestDetails
            key={ele.storeId}
            defaultValue={ele}
            ind={ind}
            remove={handleDeleteAnItem}
            group="newStoresList"
            header={"Restaurant Being Added to Organization"}
            updateRest={updateRestaurant}
            updateStakeHolder={updateStakeHolder}
            deleteStakeHolder={deleteStakeHolder}
            btnDisable={btnDisable}
          />
        );
      })}
      {upcomingRestNos === 0 ? (
        <div className={`footerBtnPurchaseContainer`}>
          <Button
            variant="cta"
            name="newStoresList"
            onClick={() => handleAddNewItem("newStoresList")}
            aria-live="polite"
          >
            add a Restaurant
          </Button>
        </div>
      ) : (
        <div className={`footerBtnPurchaseContainer`}>
          <Button
            variant="cta"
            name="newStoresList"
            onClick={() => handleAddNewItem("newStoresList")}
            aria-live="polite"
          >
            Add Another Restaurant
          </Button>
        </div>
      )}
    </>
  );
};

export default ErrorBoundary(CurrentRestaurant);
