

const initialData = {
    Count: 0,
    loanDetails: [],
    initialBorrowData: [],
    storeRestaurents: {},
    isLoading: false,
    error: ''
}
const loanReducer = (state = initialData, action = {}) => {
    const { type, payload } = action;

    let newState = { ...state };

    switch (type) {

        case "SET_PURPOSE_DETAILS_LIST":

            newState.loanDetails = payload;
            newState.isLoading = false;
            return newState;

        case "UPDATE_PURPOSE_DETAILS_LIST":
            newState.loanDetails = [...payload];
            newState.isLoading = false;
            return newState;

        case "SET_ENTITY_DETAILS_LIST":
            newState.initialBorrowData = [...payload];
            newState.isLoading = false;
            return newState;

        case "SET_STORE_DETAILS_LIST":

            newState.storeRestaurents = { ...payload };
            newState.isLoading = false;
            return newState;

        case "FETCH_PURPOSE_FAILURE":
            newState.error = payload;
            newState.isLoading = false;
            return newState

        case "REQUEST_GET_PURPOSE_DATA":
            newState.isLoading = true;
            return newState


        default:
            return newState;
    }
}

export default loanReducer;