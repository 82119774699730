import {combineReducers} from 'redux';
import IndicativeRatesReducer from './containers/dynamic/rates/redux/reducer/reducer'
import ProfileReducer from './containers/dynamic/myProfile/redux/reducer/MyProfileReducer';
import DashboardReducer from './containers/dynamic/returnToDashboard/redux/reducer/dashboardReducer';
import debtReducer from './containers/dynamic/debtSummary/redux/reducer';
import BussinessAddressInfoReducer from './containers/dynamic/businessAddressInfo/redux/reducer/businessReducer';
import ReferralInformationReducer from './containers/dynamic/referralInformation/redux/reducer/referralreducer'
import RegionInformationReducer from './containers/dynamic/regionInformation/redux/reducer/regionreducer'
import CommentsReducer from './containers/dynamic/additionalComments/redux/reducer/AdditionalCommentReducer';
import ContactInfoReducer from './containers/dynamic/contactInfo/redux/reducer/contactReducer';
import PhysicalAddressInfoReducer from './containers/dynamic/physicalMailingAddress/redux/reducer/physicalMailingReducer';
import RestaurantReducer from './containers/dynamic/restaurantInfoSummary/redux/RestaurantReducer';
import AccountantInformationReducer from './containers/dynamic/accountantInformation/redux/reducer/AccountantInformationReducer';
import CountrystatesReducer from './containers/commonactions/redux/reducer/reducer';
import LeftNavigationReducer from './containers/commonactions/redux/reducer/LeftNavReducer';
import adminLeftnavReducer from './containers/commonactions/redux/reducer/adminLeftnavReducer';
import updateCurrentReducer from './containers/admin/UpdateCurrentRates/redux/reducer/updateCurrentReducer';
import ThirdPartyWiringReducer from './containers/dynamic/thirdPartyWiringInstructions/redux/reducer/ThirdPartyWiringReducer';
import MiscellaneousDetailsReducer from './containers/dynamic/miscellaneousDetails/redux/reducer/MiscellaneousDetailsReducer'
import searchFranchiseReducer from './containers/admin/searchFranchise/redux/reducer/searchFranchiseReducer';
import AdminDashboardReducer from './containers/admin/adminDasboard/redux/reducer/AdminDashboardReducer';
import FranchiseProfileReducer from './containers/admin/FranchiseProfile/redux/reducer/FranchiseProfileReducer';
import loanReducer from './containers/dynamic/purposeOfLoan/redux/reducer/loanReducer';
import HomePageReducer from './containers/static/home/redux/reducer/homeReducer';
import BankerCommentsReducer from './containers/dynamic/bankersComments/redux/reducer/BankerCommentReducer';
import SubmitRequestFinanceFranchiesReducer from './containers/dynamic/submitRequest/redux/reducer/submitRequestReducer';
import PreviewReducer from './containers/commonactions/redux/reducer/PreviewReducer';
import { SearchReducer, OnLineReducer } from './containers/admin/onlineRequest/redux/reducer/SearchFiltersReducer';
import ReturnUserReducer from './containers/authentication/newReturningUser/redux/reducer/newReturningUserReducer';
import NewRegistrationReducer from './containers/authentication/registration/redux/reducer/registrationReducer';
import { AuthCheckReducer, AuthCodeReducer} from './auth/redux/reducer/AuthCheckReducer';
const reducers= combineReducers({
    IndicativeRatesReducer,
    ProfileReducer,
    DashboardReducer,
    debtReducer,
    BussinessAddressInfoReducer,
    ReferralInformationReducer,
    RegionInformationReducer,
    CommentsReducer,
    ContactInfoReducer,
    PhysicalAddressInfoReducer,
    RestaurantReducer,
    AccountantInformationReducer,
    CountrystatesReducer,
    LeftNavigationReducer,
    adminLeftnavReducer,
    updateCurrentReducer,
    ThirdPartyWiringReducer,
    MiscellaneousDetailsReducer,
    searchFranchiseReducer,
    AdminDashboardReducer,
    FranchiseProfileReducer,
    loanReducer,
    HomePageReducer,
    BankerCommentsReducer,
    SubmitRequestFinanceFranchiesReducer,
    PreviewReducer,
    SearchReducer,
    OnLineReducer,
    ReturnUserReducer,
    NewRegistrationReducer,
    AuthCheckReducer,
    AuthCodeReducer
});

export default reducers;
