import {
  FETCH_BUSSINESSADDRESSINFO_REQUEST,
  FETCH_BUSSINESSADDRESSINFO_SUCCESS,
  FETCH_BUSSINESSADDRESSINFO_FAILURE,
  SAVE_BUSINESSADDRESDATA_REQUEST,
  SAVE_BUSINESSADDRESDATA_SUCCESS,
  SAVE_BUSINESSADDRESDATA_FAILURE,
  FETCH_COPYHOMEADDRESS_REQUEST,
  FETCH_COPYHOMEADDRESS_SUCCESS,
  FETCH_COPYHOMEADDRESS_FAILURE
} from '../../../../global.constants';


const initialState = {
  BusinessAddressInfoData: [],
  CopymailingaddressData : [],
  error: '',
  isLoading : false
}

const BussinessAddressInfoReducer = (state = initialState, { type, payload }= {}) => {
  switch (type) {
  

    case FETCH_BUSSINESSADDRESSINFO_SUCCESS:
      return Object.assign(state, {
        BusinessAddressInfoData: payload,
        isLoading: false
      });
      case FETCH_COPYHOMEADDRESS_REQUEST:
      case SAVE_BUSINESSADDRESDATA_REQUEST:
      case FETCH_BUSSINESSADDRESSINFO_REQUEST:
        return Object.assign(state, {
          isLoading: true,
        });

    case FETCH_BUSSINESSADDRESSINFO_FAILURE:
      return Object.assign(state, {
        error : payload,
        isLoading: false
      });
      case SAVE_BUSINESSADDRESDATA_FAILURE:
      case SAVE_BUSINESSADDRESDATA_SUCCESS:
      case FETCH_COPYHOMEADDRESS_FAILURE:
        return Object.assign(state, {
          isLoading: false
        });
    case FETCH_COPYHOMEADDRESS_SUCCESS:
        return Object.assign(state, {
          CopymailingaddressData : payload,
          isLoading: false
        });
  
   
   
  
   

    default:
      return state;
  }
};
export default BussinessAddressInfoReducer;