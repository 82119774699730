import {
  FETCH_STATUS_REQUEST,
  FETCH_STATUS_FAILURE,
  FETCH_BANKERS_REQUEST,
  FETCH_BANKERS_FAILURE,
  FETCH_ADMIN_DASHBOARD_REQUEST,
  FETCH_ADMIN_DASHBOARD_FAILURE,
  FETCH_FRANCHISE_DATA_FAILURE,
  FETCH_FRANCHISE_DATA_REQUEST,
  REQUEST_BANKERS_REQUEST
} from '../../../../global.constants';

const initialState = {
  AdminDasboard: [],
  //error: 'Cannot find Loan details for loanId',
  bankers: [],
  status: [],
  isLoading:false,
  error:'',
  errorMsg: ''
}

const AdminDashboardReducer = (state = initialState, {
  type,
  payload
} = {}) => {
  switch (type) {
    case REQUEST_BANKERS_REQUEST:
      return Object.assign({}, state, {
       isLoading:true
      });
    case FETCH_BANKERS_FAILURE:
      return Object.assign(state, {
        bankers: [],
        isLoading: false,
        error: payload.status,
        errorMsg: payload.status === 500 ? payload.response.data.message : ''
      });
    case FETCH_STATUS_REQUEST:
      return Object.assign(state, {
        stage: "request",
        status: payload,
        isLoading:false
      });
    case FETCH_STATUS_FAILURE:
      return Object.assign(state, {
        isLoading: false,
        error: payload.status,
        errorMsg: payload.status === 500 ? payload.response.data.message : ''
      });
    case FETCH_ADMIN_DASHBOARD_REQUEST:
      return Object.assign(state, {
        stage: "request",
        AdminDasboard: payload,
        isLoading:false
      });
    default:
      return state;
  }
};
export default AdminDashboardReducer;
