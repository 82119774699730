import { numberValidator } from "../DebtDetails";

const initialDebtSummary = {
    debtDetails: [],
    isLoading: false,
    error: ''
};

const debtReducer = (state = initialDebtSummary, action={} ) => {
    const { type, payload } = action;
    let newState = { ...state };
    switch (type) {
        case "SET_DEBT_REQUEST":
            newState.isLoading = true;
            return newState;
        case "SET_DEBT_DETAILS_LIST":
            let maxDec = {
                "originalAmount": { 'dec': 2, 'length': 10 },
                "outstandingAmount": { 'dec': 2, 'length': 10 },
                "interestRate": { 'dec': 3, 'length': 11 },
                "monthlyPayment": { 'dec': 2, 'length': 10 },
            }
            payload.map((ele, ind) => {
                for (let item in ele) {
                    if (numberValidator[item]) {
                        let { length, dec } = maxDec[item]
                        let len = (String(ele[item]).length + dec) % length;
                        if (len > dec || len === 0) {
                            ele[item] = Number(ele[item]).toFixed(dec);
                        } else if (len < dec) {
                            ele[item] = Number(ele[item]).toFixed(len);
                        }

                    }
                }
            });

            newState.debtDetails = payload;
            newState.isLoading = false;
            return newState;
        case "SET_DEBT_FAILURE":
            newState.isLoading = false;
            newState.error = payload;
            return newState;
        case "UPDATE_DEBT_DETAILS_LIST":
            newState.debtDetails = [...payload];
            return newState;
        default:
            return newState;
    }
}

export default debtReducer;