import React from "react";
import { Link } from "@jpmuitk/link";
import { getFileAPI } from "./commonactions/redux/actions/action";
export const ANNUALCONTENT =
  "Please review your loan documents carefully and note the due dates for submitted financial statements and other information required by Chase.";
export const ENDCONTENT =
  "Please direct all financials to Rita Havel, Credit Doc Team Lead. To facilitate processing of the statements, please use removable binder or paper clips rather than stapling or permanently binding the statements.";
export const ANNUALFINANCIALHEADING = "Annual Financial Statement Submission";
export const DESCRIPTION =
  "Rates are generally updated each business day by 10:00 a.m. Central Time and are subject to change without notice. To receive an update on an existing loan rate, please call our toll-free number at 800.543.4622, option 2.";
export const DATA = `NEP Loans**:`;
export const RATESHEADING = "Indicative Rates as of ";
export const PERCENT = "%";
export const BUSINESSLOAN = "Business Loans:";
export const THREEYEARFIXED = "3 year fixed: ";
export const TWOFIVE = "2.51%";
export const FIVEYEARFIXED = "5 year fixed: ";
export const THREEFOURTHREE = "3.43%";
export const THREENINEFIVE = "3.95%";
export const FIRSTNOTE = "* Loans available for approved purposes only";
export const SECNOTE =
  '** For restaurants built between 1998-2004 with a "New Restaurant Land-Based Option Rider"';
export const PERCENTAGE = "3.01%";
export const PREFIXED = "5.53%";
export const FLOATVALUE = "Floating: ";
export const THREETWOTWO = "3.22%";
export const SEVENYEARFIXED = "7 year fixed: ";
export const THREEFIVESIX = "3.56%";
export const TENYEARFIXED = "10 year fixed*: ";
export const DATAINFO = `(existing, re-financed or assumed building loans)`;
export const MIDDLECONTENT =
  'All financial statements should be in final form (please do not send drafts, as final form statements are needed to satisfy this reporting requirement) and emailed to with the subject line "Year End Financials". Alternatively, you may mail or fax statements to:';
export const ADDRESS = [
  "JPMorgan Chase Bank, N.A.",
  "Attn: Rita Havel, Credit Doc Team Lead",
  "712 Main St., 9th Floor Central, TX2-N050",
  "Houston, TX 77002",
];
export const FAXDATA = ["866.342.9609"];
export const AT = " at ";
export const NAME = "REGULAR or OVERNIGHT MAIL";
export const FAX = "Fax";
export const ALLFINANCIAL =
  " All financial statements should be in final form (please do not send drafts, as final form statements are needed to satisfy this reporting requirement) and emailed to ";
export const URL = "mcdonalds.finance@chase.com";
export const CONTENT =
  ' with the subject line "Year End Financials". Alternatively, you may mail or fax statements to:';
export const ALLYEARFINANCE = "Send all yearly financials to:";

//Contact Us CONTENTS
export const CONTACTADDRESS = [
  "Toll-Free Number",
  " 800.543.4622",
  "  Fax Number",
  "866.342.9609",
  "Hours of Operation",
  " 8:00 a.m. - 4:45 p.m. CT",
];
export const CONTACTMAILADDRESS = [
  "Mailing Address",
  "Chase Franchise Finance",
  " 712 Main St.",
  "9th Floor Central TX2-N050",
  " Houston, Texas 77002",
];
export const CONTACT_US = {
  header: `Contact Us`,
  subheader: `Our goal is to make our financing information highly accessible so you always have acess to the most current information available. You can access our information
    through our printed literature, through this website via email, and through our toll-free telephone line where you can easily be connected with one of our team
    members.`,
  query: `If you have any questions about your loan request or the information required for processing, please feel free to contact us. When you have completed the request
              for financing and the personal financial statement, please send them along with the other required items to:`,
  subheader1: `Chase Franchise Finance Bankers`,

  subheader2: `Program Managers`,
  subheader3: `Client Service Professionals`,
  finance: `This program was designed to provide financing for New and Existing Domestic McDonald's Franchisees. Please call us for more information on international
              financing opportunities. Please check back with us periodically for updates.`,
  info: `Chase is unable to provide information on obtaining a McDonald's franchise. For that type of information, please visit the `,
  site: `McDonald's web site`,

  mcdonald: "http://www.mcdonalds.com/us/en/home.html",
  bankers: [
    {
      name: "LaTisha Land-Joiner",
      mobile: "800.543.4622, Option 16",
      mail: "latisha.k.land-joiner@chase.com",
      url: "mailto:latisha.k.land-joiner@chase.com",
      imgSrc: "LaTishaWeb.jpg",
    },
    {
      name: " Dona Wingard",
      mobile: "800.543.4622, Option 11",
      mail: "dona.wingard@chase.com",
      url: "mailto:dona.wingard@chase.com",
      imgSrc: "DonaWeb.jpg",
    },

    {
      name: "Charles Kosmas",
      mobile: "800.543.4622, Option 9",
      mail: "charles.kosmas@chase.com",
      url: "mailto:charles.kosmas@chase.com",
      imgSrc: "CharlieWeb.jpg",
    },

    {
      name: "Lisa C. Lim",
      mobile: "800.543.4622, Option 6",
      mail: "lisa.c.lim@chase.com",
      url: "mailto:lisa.c.lim@chase.com",
      imgSrc: "LisaLim.jpg",
    },

    {
      name: "Benn D. Copeland",
      mobile: "800.543.4622, Option 0",
      mail: "benn.d.copeland@chase.com",
      url: "mailto:benn.d.copeland@chase.com",
      imgSrc: "BennWeb.jpg",
    },

    {
      name: " Angela J. Newbould",
      mobile: "800.543.4622, Option 0",
      mail: "angela.j.newbould@chase.com",
      url: "mailto:angela.j.newbould@chase.com",
      imgSrc: "AngelaWeb.jpg",
    },
  ],
  projectManagers: [
    {
      name: " Troy Ahrens",
      mobile: "800.543.4622, Option 12",
      mail: "troy.ahrens@chase.com",
      url: "mailto:troy.ahrens@chase.com",
      imgSrc: "TroyWeb.jpg",
    },
  ],

  clientServiceProfessionals: [
    {
      name: "Etherine Elliot",
      mobile: "800.543.4622, option 0",
      mail: "etherine.elliott@chase.com",
      url: "mailto:etherine.elliott@chase.com",
      imgSrc: "elliott_etherine.jpg",
    },
    {
      name: "Marisol Ramirez",
      mobile: "800.543.4622, option 0",
      mail: "Marisol.ramirez@chase.com",
      url: "mailto:Marisol.ramirez@chase.com",
      imgSrc: "MarisolWeb.jpg",
    },

    {
      name: "Tracy Knighton",
      designation: "Client Service Assistant Manager",
      mobile: "800.543.4622, option 0",
      mail: "Tracy.a.knighton@chase.com",
      url: "mailto:Tracy.a.knighton@chase.com",
      imgSrc: "Tracy.jpg",
    },
    {
      name: "Terri Bellow",
      designation: "Client Service Manager",
      mobile: "800.543.4622, option 0",
      mail: "Terri.l.bellow@chase.com",
      url: "mailto:Terri.l.bellow@chase.com",
      imgSrc: "TerriWeb.jpg",
    },
  ],
};
export const DownloadPrintableStatement = {
  downloadStatement: "Franchise Loan Request",
  documentRequired: "Document Required:",
  review:
    "Review the Required Information for your loan type (Term Loan or Line of Credit) to determine which forms are required. Your Chase Franchise Finance banker will notify you if additional information is needed.",
  theInformation: "The ",
  printableStatement: "Franchisee Loan Request",
  printablelUrl:
    "https://www.chasemcdfinance.com/ffw-public/documents/LoanRequestForm.pdf",
  isavaliableStatement:
    " is available for download in Adobe Acrobat (.pdf) format.",
  providedContent: "You can also enter your request online through our ",
  onlineloanrequestUrl: "/newReturningUser",
  requestStatement: "Online Loan Request",
};
export const ErrorConstants = {
  back: "We’ll be back shortly",
  update: "For real time updates follow @ChaseSupport on Twitter.",
  available: "Our Advisors are available from 7 AM to 9 PM EST.",
  access:
    "Telephone bankers may be able to access some account information while our site is unavailable.",
  reach: "You can reach them at one of the numbers below:",
  person: "Person Checking, Savings & CD",
  threeFive: "1-800-935-9935",
  businessBanking: "Business Banking",
  threeEight: "1-800-242-7338",
  creditLoans: "Home Equity Line of Credit Loans",
  fiveSix: "1-800-836-5656",
  retirement: "Retirement self-directed Accounts",
  sixOne: "1-800-776-6061",
  privateClient: "Chase Private Client",
  twoSix: "1-888-994-5626",
  privateClientBanker: "(or contact your Private Client Banker)",
  creditCards: "Credit Cards",
  callThe: "Call the number on the back of your credit card",
  online: "Chase Commercial Online",
  serviceOfficer:
    "Call you Client Service Professional or Client Service Officer",
  mortgages: "Mortgages",
  threeSix: "1-800-848-9136",
  autoLoansAndLeases: "Auto Loans & Leases",
  sevenFive: "1-800-336-6675",
  studentLoans: "Student Loans",
  zerFour: "1-800-487-4404",
  jpmsecurities: "J.P.Morgan Securities",
  nineEight: "1-888-807-6898",
  investment: "Investment/Requirements",
  fourNine: "1-800-392-5749",
};
export const Faqs = {
  header: "Frequently Asked Questions",
  faqData: {
    faq1: {
      summary: (
        <p className="bold">
          What types of financing does Chase Franchise Finance provide?
        </p>
      ),
      content: (
        <p className="faqContent">
          Chase Franchise Finance generally offers financing to McDonald's
          Owner/Operators for the acquisition of a restaurant (new or existing),
          the acquisition of furniture, fixtures or equipment, exercising a BFL,
          acquiring a McOpCo, building a PlayPlace, refinancing or restructuring
          existing debt, reinvestment and various other special purposes
          relating to your McDonald's restaurants.
        </p>
      ),
    },
    faq2: {
      summary: (
        <p className="bold">Where may I obtain a copy of the loan request?</p>
      ),
      content: (
        <p className="faqContent">
          You can complete your loan request through an{" "}
          <Link target="_blank" href="/newReturningUser">
            online form
          </Link>
          . If you have previously submitted an online request, your saved
          information can be used for subsequent requests. You can also download
          a <Link href="/franchiseloanrequests/downloadPrintable"> form</Link>{" "}
          from this website or call Chase Franchise Finance and ask to have one
          sent directly to you (either by fax, email or mail).
        </p>
      ),
    },
    faq3: {
      summary: (
        <p className="bold">
          What maturities are offered by Chase Franchise Finance?
        </p>
      ),
      content: (
        <p className="faqContent">
          Chase offers flexible financing terms, call your Chase Franchise
          Finance banker for your specific needs.
        </p>
      ),
    },
    faq4: {
      summary: (
        <p className="bold">
          Are diligence fees refundable if my loan does not close with Chase?
        </p>
      ),
      content: (
        <ul>
          <li>
            No, the fees are used to cover the costs associated with performing
            loan diligence, such as credit inquiries, corporate and lien
            searches, etc., regardless of funding decision.
          </li>
          <li>
            All diligence fees should accompany the initial loan request, or you
            may contact your Chase Franchise Finance banker for alternate
            payment options.
          </li>
        </ul>
      ),
    },
    faq5: {
      summary: (
        <p className="bold">
          What interest rate options are available through Chase Franchise
          Finance?
        </p>
      ),
      content: (
        <p className="faqContent">
          Chase currently offers both floating and fixed rate options. To see
          available rates, click <Link href="/rates/indicativeRates">here</Link>
          .
        </p>
      ),
    },
    faq6: {
      summary: (
        <p className="bold">
          Are there breakage costs associated with early repayment of loans?
        </p>
      ),
      content: (
        <p className="faqContent">
          Whether a breakage cost applies to your loan depends on a variety of
          factors (e.g., market conditions, rate type). Please call your Chase
          Franchise Finance banker to discuss options and potential costs.
        </p>
      ),
    },
    faq7: {
      summary: (
        <p className="bold">
          What steps should I take to refinance an existing fixed rate loan in
          order to take advantage of lower rates?
        </p>
      ),
      content: (
        <div className="faqContent">
          <p>
            First, contact your Chase Franchise Finance banker to discuss
            options and potential costs. Second, consult your accountant and
            determine whether or not the new rate, considering any applicable
            breakage fee, would financially benefit you.
          </p>
          <p>
            If you decide to refinance, submit a loan request to Chase along
            with the information listed for the type of loan. Click{" "}
            <Link href="/requiredInformation/requiredInformationLoans">
              here
            </Link>{" "}
            for required information. Your Chase banker will notify you if
            additional information is needed.
          </p>
        </div>
      ),
    },
    faq8: {
      summary: <p className="bold">How may I obtain a breakage fee quote?</p>,
      content: (
        <p className="faqContent">
          Your Chase Franchise Finance banker can quote a breakage fee on any
          business day.
        </p>
      ),
    },
    faq9: {
      summary: (
        <p className="bold">Does Chase check personal credit bureaus?</p>
      ),
      content: (
        <p className="faqContent">
          Yes, Chase may check your personal credit rating as part of its
          underwriting process.
        </p>
      ),
    },
    faq10: {
      summary: (
        <p className="bold">
          What information will be disclosed to McDonald’s Corporation?
        </p>
      ),
      content: (
        <p>
          Chase and McDonald’s may obtain from and disclose to each other
          financial and other information about McDonald’s Owner/Operators with
          whom Chase has or had a lending relationship, including but not
          limited to, financial condition and the existence of any loan or
          franchise default.
        </p>
      ),
    },
    faq11: {
      summary: (
        <p className="bold">How often are financial statements required?</p>
      ),
      content: (
        <p className="faqContent">
          Generally, annual financial statements are required within 120 days of
          your fiscal year end; however, the due dates and other requirements
          applicable to your loan may differ. Please review your loan documents
          carefully for applicable terms and contact your Chase Franchise
          Finance banker with any questions.
        </p>
      ),
    },
    faq12: {
      summary: (
        <p className="bold">
          Is it possible to cross-collateralize my restaurants and use equity in
          one store to purchase another?
        </p>
      ),
      content: (
        <p className="faqContent">
          Loans can be secured by assets owned by a different entity under
          common ownership.
        </p>
      ),
    },
    faq13: {
      summary: <p className="bold">How do I draw on my line of credit?</p>,
      content: (
        <ul>
          <li>
            First time to draw,{" "}
            <a onClick={(e) => getFileAPI(e, "workingcapitalfirstdraw", "pdf")}>
              click here
            </a>{" "}
            for request form.
          </li>
          <li>
            For all subsequent draws:{" "}
            <ul>
              <li>Dial 800.543.4622</li>
              <li>
                Select option 0 and speak to a Client Service Representative
              </li>
              <li>
                Requests received before 2:00 p.m., Central Time, will be
                disbursed the same business day. Requests received after 2:00
                p.m. will be processed the following business day.
              </li>
              <li>
                If all conditions to draw under your loan documents are
                satisfied, funds are generally disbursed within 48 hours of your
                request.
              </li>
            </ul>
          </li>
        </ul>
      ),
    },
    faq14: {
      summary: (
        <p className="bold">
          Can I convert my floating rate loan to a fixed rate?
        </p>
      ),
      content: (
        <p className="faqContent">
          Call your Chase Franchise Finance banker to discuss availability and
          current fixed rate options.
        </p>
      ),
    },
  },
};
export const PersonalFinancialStatement = {
  financialStatement: "Personal Financial Statement",
  documentRequired: "Document Required:",
  review: "Review the ",
  information: "Required Information",
  content:
    " for your loan type to determine which forms are required in addition to the Personal Financial Statement. Your Chase Franchise Finance banker will notify you if additional information is needed.",
  fillInformation: "Please fill out the ",
  highlighted: "HIGHLIGHTED",
  section: " sections of the ",
  personalStatement: "Personal Financial Statement.",
  providedContent: "If you are providing us a",
  format:
    " dated within a year in your own format (Excel, Quicken, CPA prepared, etc.), please fill out ONLY the ",
  pages: " sections on  ",
  pageNo: "pages 1-4.",
  requiredUrl: "/requiredInformation/requiredInformationLoans",
  financialUrl: "../../../assets/documents/PFS.pdf",
};
export const Form = {
  forms: "Forms",
  paymentFormUrl:
    "https://www.chasemcdfinance.com/ffw-public/documents/FFWAdditionaPaymentForm.pdf",
  addressFormUrl:
    "https://www.chasemcdfinance.com/ffw-public/documents/AddressChangeForm.pdf",
  autoDebitFormUrl:
    "https://www.chasemcdfinance.com/ffw-public/documents/AutodebitForm.pdf",
  drawRequestFormUrl:
    "https://www.chasemcdfinance.com/ffw-public/documents/AdvanceLoanRequestForm.pdf",
  irsFormUrl: "https://www.irs.gov/pub/irs-pdf/fw9.pdf",
  paymentForm: "Additional Payment Form",
  addressForm: "Address Change Form",
  autoDebitForm: "Auto-Debit Form",
  drawRequestForm: "Draw Request Form - All Loan types",
  irsForm: "IRS Form W-9",
};
//HOME CONTENTS

export const HOMEHEADER1 = `Chase Franchise Finance`;
export const CONTENTH1 = `Building on a Golden Past to Deliver a Promising Future`;
export const HOMEHEADER2 = `We Understand Your Business`;
export const H2CONTENT1 = `As a provider of financial services to the McDonald's System for more than 50 years, Chase Franchise Finance thoroughly understands your business. This focus allows us to closely follow changes that may impact your financing needs. With this understanding, Chase can be responsive as you grow your business.`;
export const H2CONTENT2 = ` The members of the Chase Franchise Finance team are banking professionals whose primary responsibility is to service the financing needs of the McDonald's System. `;
export const FIND = `Find out more`;
export const LINKCONTENT = `Click to begin our quick and easy Interactive Loan Request.`;
export const BUTTON = `Interactive loan request`;
export const CONTENT6 = `COVID-19 UPDATE Please be sure to scan and return loan documents via email to prevent any delays.  Please contact your banker with any questions regarding assistance during these times.`;
export const CONTENT6p = `If you are locked out or would like any assistance we are here to help you. Please call your banker directly or client service professional at `;
export const PHONENUMBER = `1-800-543-4622 `;
export const CONTENT6p1 = `option 0.`;
//homefind CONTENTS

export const HEADER1 = `Chase Franchise Finance Understands Your Business`;
export const CONTENT1 = `As a financing source specifically designed for and dedicated to Owner/Operators of McDonald's franchises, Chase Franchise Finance thoroughly understands your business. This focus allows us to closely follow changes that may impact your financing needs. With this understanding, Chase can be responsive as you grow your business.`;
export const CONTENT2 = `The members of the Chase Franchise Finance team are banking professionals whose primary responsibility is to service the financing needs of the McDonald's System. Each team member has many years of banking experience and most have spent a considerable part of their banking careers working with Franchise Owner/Operators.`;
export const HEADER2 = `Our Commitment to the Franchise Business`;
export const CONTENT3 = `Chase is committed to similar goals of quality, service, creativity, and value that have made McDonald's the successful brand that it is today.`;
export const QUALITYDATA = `A professional staff that thoroughly understands your business.`;
export const SERVICEDATA = `Commitment to providing a quick and accurate assessment of your loan request - and dedication to providing you timely funding to fuel your growth and develop your business.`;
export const CREATIVITYDATA = ` Exemplary products and financial solutions that were specifically developed to accommodate the financing needs of McDonald's Owner/Operators.`;
export const VALUEDATA = `Competitive rates to improve your bottom line.`;
export const CONTENT4 = `These attributes have made Chase a premier lender to McDonald's Owner/Operators. Chase has a long standing relationship with the McDonald's Franchise System. One of Chase's predecessor organizations, American National Bank, was one of the first banks to finance McDonald's and its founder, Ray Kroc. Chase is committed to providing flexible financial solutions to McDonald's Owner/Operators.`;
export const InterestRateOption = {
  hh: `Interest Rate Options`,
  th1: `Floating Rate`,
  th2: `Fixed Rate`,
  col01: `Loan Types`,
  Multiple: `Multiple Advance`,
  Term: `Term (single advance)`,
  Working: `Working Capital Line of Credit`,
  Term2: `Term (single advance)`,
  Term3: ` Multiple Advance (if converted after
    advancing period)`,
  Payments: `Payments`,
  Multiple2: `Multiple Advance and Term Loans:`,
  Level1: `Level payments of principal and interest based on a preset
  amortization rate.`,
  also: `  Also available: Level Principal Payments and Seasonal
  Payments`,
  Working1: ` Working Capital: Interest due monthly, with principal due at
  maturity.`,
  Leve2: ` Level payments of principal and interest based on market rate
  at closing.`,
  also1: ` Also available: Level Principal Payments and Seasonal Payments`,
  prepayment: `Prepayment Premium/Funding Loss Indemnification`,
  Multiple3: `Multiple Advance and Term Loans:`,
  you: ` You may incur additional costs for repaying early or on a
  date other than your scheduled payment date. Please review
  your loan documents for applicable funding loss
  indemnification language and contact your Chase Franchise
  Finance banker to discuss options and potential costs.`,
  Working2: `Working Capital Line of Credit: Not applicable`,
  you2: `You may incur additional costs for repaying early or on a date
  other than your scheduled payment date. Please review your loan
  documents for applicable prepayment premium language and contact
  your Chase Franchise Finance banker to discuss options and
  potential costs.`,
  conversion: `Conversion to Fixed Rate (Election of Negotiated Rate)`,
  Multiple4: `Multiple Advance and Term Loans:`,
  please: `Please review your loan documents for applicable election of
  negotiated rate language and call your Chase Franchise
  Finance banker to discuss current fixed rate options.`,
  Working3: `Working Capital Line of Credit: Not applicable`,
  you3: `You may incur additional costs for repaying early
  or on a date other than your scheduled payment date. Please review
  your loan documents for applicable prepayment premium language and
  contact your Chase Franchise Finance banker to discuss options and
  potential costs.`,
  headerpara1: ` The following is a summary of rate options offered by Chase Franchise  Finance. For current rates, please contact a`,
  headerpara2: ` directly or call your Chase Franchise Finance banker at our toll-free number 800.543.4622 for a rate    quote. The following does not purport to summarize all terms, conditionsand other provisions that may be contained in any loan documentation.`,
};
//loan product Summary CONTENTS
// Rates
export const CONTACT_US_BANKER = {
  heading_1: "please contact a",
  heading_link: "Chase Banker",
  heading_2: "directly for rates.",
  target_link: "/contactUs",
};
export const LOANPRODUCTSUMMARY_HEADER1 = `Loan Products Summary`;
export const LOANPRODUCT_SUBHEADER = `The following does not purport to summarize all terms, conditions and other provisions that may be contained in any loan documentation. All loans and lines of
credit are subject to credit approval and satisfaction of due diligence requirements.`;
export const LOANPRODUCT_FOOTER = `McDonald's, the Golden Arches Logo and the McDonald's Building Designs are trademarks of McDonald's Corporation and its affiliates, used with permission. McDonald's does not endorse
or control this program, but permits Chase to make this offer available. McDonald's is not responsible or liable for any products or services offered by Chase.`;
export const MUTIPLE = [
  "Purchase New or Existing Restaurant(s)",
  "Exercise BFL(s)/Relocate/Rebuild",
  "Refinance/Restructure Existing Debt",
  "Reinvestment/Remodel",
  "PlayPlaces",
  "Purchase Equipment",
  "Purchase McOpCo",
  "Coinvestment",
  "Other Special Business Purposes",
];

export const Multiple_Advance = {
  header: "Multiple Advance & Term Loans",
  finance: `Up to seven years and up to 10 years on special financing needs.`,
  amount: "$250",
  payment: " Flexible Payment Options:",
  level: "Level Payments",
  principal: "Level Principal Payments",
  season: "Seasonal Payments",
};

export const WORKING_CAPITAL = {
  header: "Working Capital Line of Credit",
  businessExpenses: "Unexpected Business Expenses",
  season: "Seasonal Cash Flow",
  equipment: "Small Equipment Purchases",
  months: "Up to twelve months.",
  accompanied: "$100 if accompanied by a term loan request, otherwise $250.",
  interest: " Interest due monthly, with principal due at maturity.",
  finance: " First lien position on all assets financed.",
  secure:
    "Cross-collateralization: loans can be secured by assets owned by a different entity under common ownership.",
  collateral: "Amounts: Up to $10,000 for each store taken as collateral.",
};
export const OnlineloanRequest = {
  LegalRelease: "Legal Release For ",
  LoanRequestUrl: "/newReturningUser",
  OnlineLoanRequest: "Online Loan Requests",
  content: `During the review of submitted requests, Chase may obtain a credit report on the principals (stockholders, proprietors, and partners) or the business entity included on the loan request. As permitted by law, Chase may share account information and information contained in a submitted request and in any credit report on the principals with other Chase affiliates for the purpose of considering eligibility for their products or services including loans and lines of credit. Further, Chase and McDonald's may share with each other any information they may have with respect to the requester's operation(s) as a Franchisee of McDonald's as such information may relate to the services provided to the requester by Chase.`,
  subContent: `By submitting a request to Chase, the requester certifies that the information contained in the online request, attachments, and addendums are true, complete and correct to the best of the requester's knowledge. The requester further certifies that the proceeds of the loan being requested will be used for McDonald's business-related purposes and not for personal, family, tax, or household purposes.`,
};
export const RequiredInformationData = {
  header: "Required Information for Loan and Line of Credit Requests",
  info: "You will be notified if additional information is needed.",
  purchaseNewRestaurant: "Purchase New Restaurant",
  purchaseExistingRestaurant:
    "Purchase Existing Restaurant from Another Operator",
  exercise: "Exercise BFL, Relocate or Rebuild Restaurant",
  purchaseMc: "Purchase McOpCo",
  purcRefinance: "Refinance / Restructure Existing Debt",
  Refinance: "Refinance Existing Building Loan",
  assumeExisting: "Assume Existing IMCO Building Loan from Another Operator",
  reInvestment:
    "Reinvestment, Remodel, PlayPlace, Equipment Purchases and Special Programs",
  lineOfCredit: "Line of Credit",
  signedAndCompleted: "Signed and Completed Application ",
  cross: "X",
  diligence: "Diligence Fee ($250)",
  standAloneRequest:
    "(For a stand-alone request; $100 if request accompanies a term loan request)",
  current: "Current ",
  personalFinancialStatement: "Personal Financial Statement",
  lastFiscalYearEnd:
    "Last 3 fiscal year-end consolidated and individual Profit and Loss Statements and Balance Sheets of all restaurants owned",
  mostCurrentConsolidated:
    "Most current consolidated and individual year-to-date Profit and Loss Statements and Balance Sheets of all restaurants owned",
  consolidatedIndividualYear:
    "Consolidated and individual year-to-date Profit and Loss Statements and Balance Sheets of all restaurants owned for comparable prior year period",
  existingProfitLoss:
    " Existing Profit and Loss Statements for Past trailing Twelve Month period",
  profitLossStatements:
    "Profit and Loss Statements and Balance Sheets for Management Company, if applicable for all comparable fiscal year and interim periods of historical financials provided on restaurants",
  costBreakdown: "Cost breakdown of expenses being financed ",
  oneYearProjectedProfit:
    "One year of Projected Profit and Loss Statements on New Restaurant(s)",
  copyDriversLicense: `Copy of Driver's License `,
  organizationDocuments: `Organization Documents: If a Corporation - Recorded Articles of Incorporation `,
  organizationDocumentsLiability: `Organization Documents: If a Limited Liability Company - Recorded Articles of Organization, Updated Member Certificate, LLC Operating Agreement`,
  organizationDocumentsJointVenture: `Organization Documents: If a Joint Venture, General or Limited Partnership - Executed Partnership Documents `,
  newExistingClients: `W-9s For New Clients or If Not On File For Existing Clients`,
};
