import React from "react"
import "../homeFindMore/homeFind";
import { Accordion, ExpansionPanel } from '@jpmuitk/accordion';
import { Link } from '@jpmuitk/link';
import { Faqs } from './../../static.constants';
import '../../global.scss';
import { ColumnLayout } from '@jpmuitk/column-layout';

export default function faqs(){
    return(
        <div className={'home container'}  id="main">
            <h1 className="pageHeader">{Faqs.header}</h1>
            <ColumnLayout container>
                <ColumnLayout item xs={12} className='paddingRight96'>
                <Accordion
                    bordered
                    defaultExpanded={[Object.keys(Faqs.faqData)[0]]}
                    panels={Object.keys(Faqs.faqData)}
                    renderPanel={({ value, getPanelProps }) => {
                        const faq = Faqs.faqData[value];
                        return (
                            <ExpansionPanel
                                content={faq.content}
                                summary={faq.summary}
                                {...getPanelProps()}
                            />
                        );
                    }}
                />
                </ColumnLayout>
            </ColumnLayout>
        </div>
    )
}
