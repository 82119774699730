import React, { Suspense, lazy, useEffect } from "react";
import { Switch, Route, Routes, Link } from "react-router-dom";
import Rates from "../containers/dynamic/rates/Rates";
import AnnualFinancial from "../containers/static/annualFinancial/AnnualFinancial";
import Forms from "../containers/static/forms/Forms";
import FinancialStatement from "../containers/static/financialStatement/FinancialStatement";
import LoanRequest from "../containers/static/loanRequest/LoanRequest";
import Home from "../containers/static/home/home";
import HomeFindMore from "../containers/static/homeFindMore/homeFind";
import DownloadPrintable from "../containers/static/downloadprintable/DownloadPrintable";
import InterestRateOptions from "../containers/static/interestRateOption/InterestRateOptions";
import FAQ from "../containers/static/faqs/faqs";
import LoanProductSummary from "../containers/static/loanProductSummary/loanProductSummary";
import ContactUs from "../containers/static/contactUs/ContactUs";
import RequiredInformation from "../containers/static/requiredInformation/RequiredInformation";
import ReturnToDashboard from "../containers/dynamic/returnToDashboard/ReturnToDashboard";
import MyProfile from "../containers/dynamic/myProfile/MyProfile";
import DebtSummary from "../containers/dynamic/debtSummary/DebtSummary";
import RestaurantInfoSummary from "../containers/dynamic/restaurantInfoSummary/RestaurantInfoSummary";
import BusinessAddressInfo from "../containers/dynamic/businessAddressInfo/BusinessAddressInfo";
import ReferralInformation from "../containers/dynamic/referralInformation/ReferralInformation";
import RegionInformation from "../containers/dynamic/regionInformation/RegionInformation";
import AdditionalComments from "../containers/dynamic/additionalComments/AdditionalComments";
import Registration from "../containers/authentication/registration/Registration";
import NewReturningUser from "../containers/authentication/newReturningUser/NewReturningUser";
import RegistrationSuccess from "../containers/authentication/registrationSuccess/RegistrationSuccess";
import ContactInfo from "../containers/dynamic/contactInfo/ContactInfo";
import PhysicalMailingAddress from "../containers/dynamic/physicalMailingAddress/PhysicalMailingAddress";
import Miscellaneous from "../containers/dynamic/miscellaneousDetails/MiscellaneousDetails";
import AccountantInformation from "../containers/dynamic/accountantInformation/AccountantInformation";
import ThirdPartyWiring from "../containers/dynamic/thirdPartyWiringInstructions/ThirdPartyWiring";
import SubmitRequest from "../containers/dynamic/submitRequest/SubmitRequest";
import PurposeOfLoan from "../containers/dynamic/purposeOfLoan/PurposeOfLoan";
import LandingPage from "../containers/admin/LandingPage/LandingPage";
import UpdateCurrentRates from "../containers/admin/UpdateCurrentRates/UpdateCurrentRates";
import OnlineRequest from "../containers/admin/onlineRequest/OnlineRequest";
import AdminDashboard from "../containers/admin/adminDasboard/AdminDashboard";
import FranchiseProfile from "../containers/admin/FranchiseProfile/FranchiseProfile";
import BankersComments from "../containers/dynamic/bankersComments/BankersComments";
import useDocumentTitle from "../containers/useDocumentTitle";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { dynamic_Menu } from "../containers/global.constants";
import SearchFranchise from "../containers/admin/searchFranchise/searchFranchise";
import axios from "axios";
import SignOut from "../containers/static/signOut/SignOut";
//axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('authCode');

const RedirectToNewReturningUser = () => {
  /*  window.open('/newReturningUser', '_blank') */
  return null;
};

const RenderComponent = ({ title, children }) => {
  useDocumentTitle(title + ` : Franchise Digital Account Opening`);
  return <>{children}</>;
};

const AppRoutes = () => {
  const loanId = useSelector((state) => state.DashboardReducer?.currentLoan);
  const refreshId = loanId?.activeLoanId;
  const { pathname } = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (window.location.hostname.includes("admin") && pathname === "/") {
      history.push("/home");
    }
  }, []);
  return (
    <Switch>
      <Route exact path="/">
        <RenderComponent title="Home">
          <Home />
        </RenderComponent>
      </Route>
      <Route path="/fdao/Signout">
        <RenderComponent title="Home">
          <SignOut />
        </RenderComponent>
      </Route>
      <Route path="/home">
        <RenderComponent title="Home">
          <Home />
        </RenderComponent>
      </Route>
      <Route exact path="/termsconditions">
        <RenderComponent title={"Terms & Conditions : Loan Product Summary"}>
          <LoanProductSummary />
        </RenderComponent>
      </Route>
      <Route path="/termsconditions/loanProductsSummary">
        <RenderComponent title={"Terms & Conditions : Loan Product Summary"}>
          <LoanProductSummary />
        </RenderComponent>
      </Route>
      <Route path="/termsconditions/annualFinancialStatementSubmission">
        <RenderComponent
          title={"Terms & Conditions : Annual Financial Statement"}
        >
          <AnnualFinancial />
        </RenderComponent>
      </Route>
      <Route path="/termsconditions/onlineLoanRequests">
        <RenderComponent title={"Terms & Conditions : Online Loan Requests"}>
          <LoanRequest />
        </RenderComponent>
      </Route>
      <Route exact path="/rates">
        <RenderComponent title="Rates : Indicative Rates">
          <InterestRateOptions />
        </RenderComponent>
      </Route>
      {/* comment out rates routes and change rates main route to different rates component */}
      {/* <Route path="/rates/indicativeRates">
      <RenderComponent title="Rates : Indicative Rates "><Rates /></RenderComponent>
      </Route> */}
      {/* <Route path="/rates/interestRateOptions">
      <RenderComponent title="Rates : Interest Rate Options"><InterestRateOptions /></RenderComponent>
      </Route> */}
      <Route exact path="/requiredInformation">
        <RenderComponent
          title={"Required Information : Loans & Line of Credit Requests"}
        >
          <RequiredInformation />
        </RenderComponent>
      </Route>
      <Route path="/requiredInformation/requiredInformationLoans">
        <RenderComponent
          title={"Required Information : Loans & Line of Credit Requests"}
        >
          <RequiredInformation />
        </RenderComponent>
      </Route>
      <Route path="/requiredInformation/personalFinancialStatements">
        <RenderComponent title="Required Information : Financial Statements">
          <FinancialStatement />
        </RenderComponent>
      </Route>
      <Route path="/requiredInformation/forms">
        <RenderComponent title="Required Information : Forms">
          <Forms />
        </RenderComponent>
      </Route>
      <Route exact path="/franchiseloanrequests">
        <RenderComponent title="Franchisee Loan Request : Download Printable">
          <DownloadPrintable />
        </RenderComponent>
      </Route>
      <Route path="/franchiseloanrequests/downloadPrintable">
        <DownloadPrintable />
      </Route>
      <Route path="/franchiseloanrequests/onlineInterative">
        <RedirectToNewReturningUser />
      </Route>
      <Route path="/faqs">
        <RenderComponent title="Faqs">
          <FAQ />
        </RenderComponent>
      </Route>
      <Route path="/homeFind">
        <RenderComponent title="Find more">
          <HomeFindMore />
        </RenderComponent>
      </Route>
      <Route path="/contactUs">
        <RenderComponent title="Contact Us">
          <ContactUs />
        </RenderComponent>
      </Route>
      <Route path="/dashboard">
        <RenderComponent title="Return To Dashboard">
          <ReturnToDashboard />
        </RenderComponent>
      </Route>
      <Route path="/myProfile">
        <RenderComponent title="My Profile">
          <MyProfile />
        </RenderComponent>
      </Route>
      <Route path="/contactInformation">
        <RenderComponent title="Contact Information">
          <ContactInfo />
        </RenderComponent>
      </Route>
      <Route path="/currentDebtSummaryDetails">
        <RenderComponent title="Current Debt Summary">
          <DebtSummary />
        </RenderComponent>
      </Route>
      <Route path="/restaurantInformationSummaryDetails">
        <RenderComponent title="Restaurant Information Summary">
          <RestaurantInfoSummary />
        </RenderComponent>
      </Route>
      <Route path="/businessAddressInformation">
        <RenderComponent title="Business Address Information">
          <BusinessAddressInfo />
        </RenderComponent>
      </Route>
      <Route path="/referralInformation">
        <RenderComponent title="Referral Information">
          <ReferralInformation />
        </RenderComponent>
      </Route>
      <Route path="/regionInformation">
        <RenderComponent title="Region Information">
          <RegionInformation />
        </RenderComponent>
      </Route>
      <Route path="/accountantInformation">
        <RenderComponent title="Accountant Information">
          <AccountantInformation />
        </RenderComponent>
      </Route>

      <Route path="/thirdPartyWiringInstructions">
        <RenderComponent title="Third Party Wiring">
          <ThirdPartyWiring />
        </RenderComponent>
      </Route>

      <Route path="/additionalComments">
        <RenderComponent title="Additional Comments">
          <AdditionalComments />
        </RenderComponent>
      </Route>
      <Route path="/newReturningUser">
        <RenderComponent title="New Returning User">
          <NewReturningUser />
        </RenderComponent>
      </Route>
      <Route path="/registration">
        <RenderComponent title="Registration">
          <Registration />
        </RenderComponent>
      </Route>
      <Route path="/registrationSuccess">
        <RenderComponent title="Registration Success">
          <RegistrationSuccess />
        </RenderComponent>
      </Route>
      <Route path="/physicalMailingAddress">
        <RenderComponent title="Physical Mailing Address">
          <PhysicalMailingAddress />
        </RenderComponent>
      </Route>
      <Route path="/submitRequesttoChaseFranchiseFinance">
        <RenderComponent title="Submit Request">
          <SubmitRequest />
        </RenderComponent>
      </Route>
      <Route path="/miscellaneousDataRequired">
        <RenderComponent title="Miscellaneous Data Required">
          <Miscellaneous />
        </RenderComponent>
      </Route>
      <Route path="/purposeofLoanRequest">
        <RenderComponent title="Purpose of loan request">
          <PurposeOfLoan />
        </RenderComponent>
      </Route>
      <Route path="/adminHome">
        <RenderComponent title="Landing Page">
          <LandingPage />
        </RenderComponent>
      </Route>
      <Route path="/searchFranchise">
        <RenderComponent title="Search For Franchise">
          <SearchFranchise />
        </RenderComponent>
      </Route>
      <Route path="/updateCurrentRates&Message">
        <RenderComponent title={"Update Current rates & message"}>
          <UpdateCurrentRates />
        </RenderComponent>
      </Route>
      <Route path="/adminDashboard">
        <RenderComponent title={"Dashboard - Admin"}>
          <AdminDashboard />
        </RenderComponent>
      </Route>
      <Route path="/on-LineRequests">
        <RenderComponent title={"on-Line Requests"}>
          <OnlineRequest />
        </RenderComponent>
      </Route>
      <Route path="/franchiseeProfile">
        <RenderComponent title={"Franchisee Profile"}>
          <FranchiseProfile />
        </RenderComponent>
      </Route>
      <Route path="/bankersComments">
        <RenderComponent title={"Bankers Comments"}>
          <BankersComments />
        </RenderComponent>
      </Route>
    </Switch>
  );
};

export default AppRoutes;
