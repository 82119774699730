import axios from "axios";
import {
  base_url,
  POST_RECEIVE_BANKER_DATA,
  POST_FALIURE_BANKER_DATA,
  RECEIVE_BANKER_COMMENT_DATA,
  FAILURE_BANKER_COMMENT_DATA,
  SPINNER_ENDPOINT_BANKER,
  base_url_admin,
} from "../../../../global.constants";

export const getBankerCommentData = (loanId) => async (dispatch) => {
  const payload = { loanId: loanId };
  dispatch({ type: SPINNER_ENDPOINT_BANKER });
  try {
    await axios
      .post(
        base_url_admin + `/api/v1/adminsvc/admin/getBankerComments.do`,
        payload
      )
      .then((response) => {
        if (response && response.status === 200) {
          dispatch(receiveBankerCommentData(response.data));
        } else {
          dispatch(failureBankerCommentData(response.status));
        }
      });
  } catch (e) {
    dispatch(failureBankerCommentData(e.response.status));
    return e;
  }
};

export const postBankerComment =
  (payload, loanId, userId) => async (dispatch) => {
    const reqPayload = { ...payload, userId: userId, loanId: loanId };
    dispatch({ type: SPINNER_ENDPOINT_BANKER });
    try {
      await axios
        .post(
          base_url_admin + `/api/v1/adminsvc/admin/addUpdateBankerComment.do`,
          reqPayload
        )
        .then((response) => {
          if (response && response.status === 200) {
            dispatch(postReceiveBankerData());
          } else {
            dispatch(postFailurBankerData(response.status));
          }
        });
    } catch (e) {
      dispatch(postFailurBankerData(e.response.status));
      return e;
    }
  };

export const postReceiveBankerData = (payload) => ({
  type: POST_RECEIVE_BANKER_DATA,
  payload,
});

export const postFailurBankerData = (payload) => ({
  type: POST_FALIURE_BANKER_DATA,
  payload,
});

export const receiveBankerCommentData = (payload) => ({
  type: RECEIVE_BANKER_COMMENT_DATA,
  payload,
});

export const failureBankerCommentData = (payload) => ({
  type: FAILURE_BANKER_COMMENT_DATA,
  payload,
});
