import React from "react";
import "./ContactUs.scss";
import Grid from "@material-ui/core/Grid";
import { Link } from "@jpmuitk/link";
import {
  CONTACT_US,
  CONTACTADDRESS,
  CONTACTMAILADDRESS,
} from "../../static.constants";

// UserStory FFANEW-13
export default function ContactUs() {
  return (
    <div className={"container"} id="main">
      <h1>{CONTACT_US.header}</h1>
      <p>{CONTACT_US.subheader}</p>
      <p>{CONTACT_US.query}</p>
      <Grid container>
        <Grid item xs={6} sm={4} md={4} className="padding0">
          <div className="contactmailaddress">
            {" "}
            <div>
              {CONTACTMAILADDRESS.map((item, i) => {
                return (
                  <address className={`addressClass`} key={i}>
                    {item}
                  </address>
                );
              })}
            </div>
          </div>
        </Grid>
        <Grid item xs={6} sm={4} md={4} className="padding0">
          <div className="contactaddress">
            {CONTACTADDRESS.map((item, i) => {
              return (
                <address className={`addressClass`} key={i}>
                  {item}
                </address>
              );
            })}
          </div>
        </Grid>
      </Grid>
      <h2>{CONTACT_US.subheader1}</h2>
      <Grid container spacing={2}>
        {CONTACT_US.bankers.map((banker, i) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              className="padding0"
              key={banker.name}
            >
              <div className="contact-financial">
                <img
                  className="usercontact"
                  src={require(`../../../assets/images/Contactusimages/${banker.imgSrc}`)}
                  alt={`${banker?.name}`}
                />
                <div>
                  <span className="bold">{banker.name}</span>
                  <br />
                  <div className="email">
                    <span className="jpmuitk-icon-message jss165  usermessage"></span>
                    <Link
                      href={banker.url}
                      role="button"
                      className="contact-mail"
                    >
                      {banker.mail}{" "}
                    </Link>
                  </div>
                  <div className="phone">
                    <span className="jpmuitk-icon-call jss165  usercall"></span>
                    <span>{banker.mobile}</span>
                  </div>
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>
      <h2 className="paddingTop50">{CONTACT_US.subheader2}</h2>
      <Grid container>
        {CONTACT_US.projectManagers.map((manager, i) => {
          return (
            <Grid item xs={12} sm={6} className="padding0" key={manager.name}>
              <div className="contact-financial">
                <img
                  className="usercontact"
                  src={require(`../../../assets/images/Contactusimages/${manager.imgSrc}`)}
                  alt={`${manager?.name}`}
                />
                <div>
                  <span className=" bold">{manager.name}</span>
                  <br />
                  <div className="email">
                    <span className="jpmuitk-icon-message jss165  usermessage"></span>
                    <Link
                      href={manager.url}
                      role="button"
                      className="contact-mail"
                    >
                      {manager.mail}{" "}
                    </Link>
                  </div>
                  <div className="phone">
                    <span className="jpmuitk-icon-call jss165  usercall"></span>
                    <span>{manager.mobile}</span>
                  </div>
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>
      <h2 className="paddingTop50">{CONTACT_US.subheader3}</h2>
      <Grid container>
        {CONTACT_US.clientServiceProfessionals.map((professional, i) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              className="padding0"
              key={professional.name}
            >
              <div className="contact-financial">
                <img
                  className="usercontact"
                  src={require(`../../../assets/images/Contactusimages/${professional.imgSrc}`)}
                  alt={`${professional?.name}`}
                />
                <div className="info verticalAlignInherit">
                  <span className=" bold">{professional.name}</span>
                  <br />
                  {professional.designation && (
                    <>
                      <span className=" bold">{professional.designation}</span>
                      <br />
                    </>
                  )}
                  <div className="email">
                    <span className="jpmuitk-icon-message jss165  usermessage"></span>
                    <Link
                      href={professional.url}
                      role="button"
                      className="contact-mail"
                    >
                      {professional.mail}{" "}
                    </Link>
                  </div>
                  <div className="phone">
                    <span className="jpmuitk-icon-call jss165  usercall"></span>
                    <span>{professional.mobile}</span>
                  </div>
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>
      <p className="marginTop70">{CONTACT_US.finance}</p>
      <p>
        {CONTACT_US.info}
        <Link href={CONTACT_US.mcdonald} target="_blank">
          {CONTACT_US.site}
        </Link>
      </p>
    </div>
  );
}
