import React, { useState, useEffect } from "react";
import "./home.scss";
import "../homeFindMore/homeFind";
import mcdonald from "../../../assets/images/mcdonald.jpeg";
import { Banner } from "@jpmuitk/banner";
import { Link } from "@jpmuitk/link";
import { Button } from "@jpmuitk/button";
import {
  HOMEHEADER1,
  CONTENTH1,
  HOMEHEADER2,
  H2CONTENT1,
  H2CONTENT2,
  FIND,
  LINKCONTENT,
  BUTTON,
} from "../../static.constants";
import { getHomePageBannerData } from "./redux/actions/homeAction";
import { useSelector, useDispatch } from "react-redux";
import { AriaAnnouncerProvider } from "@jpmuitk/aria-announcer";

const Home = () => {
  const dispatch = useDispatch();
  const homeBannerData = useSelector(
    (state) => state?.HomePageReducer?.homePageBanner
  );
  const [finalElement, setFinalelement] = useState([]);

  const handleClick = () => {
    window.open("/newReturningUser", "_blank");
  };
  useEffect(() => {
    dispatch(getHomePageBannerData());
  }, []);

  useEffect(() => {
    if (homeBannerData?.newsFlash !== undefined) {
      var flashData = homeBannerData?.newsFlash;
      var splithtmlTags = [];

      splithtmlTags = flashData.match(
        /<\s*(\w+\b)(?:(?!<\s*\/\s*\1\b)[\s\S])*<\s*\/\s*\1\s*>|\S+/g
      );

      var htmlArray = [];
      for (var i = 0; i < splithtmlTags.length; i++) {
        htmlArray.push(splithtmlTags[i]);
      }
      setFinalelement(htmlArray);
    }
  }, [homeBannerData]);

  return (
    <div className="home container" id="main">
      <div>
        <h1 className="homeHeader1">{HOMEHEADER1}</h1>
        <p className="contenth1">{CONTENTH1}</p>
      </div>
      <div className="bgc">
        <div className="content-1">
          <img src={mcdonald} alt="mcdonald" />
        </div>

        <div className="content-2">
          <h2 className="homeHeader2">{HOMEHEADER2}</h2>
          <p className="contenth2">
            {H2CONTENT1}
            <p className="contenth3">{H2CONTENT2}</p>
          </p>
          <p>
            <Link className="find" href="/homeFind">
              {FIND}
            </Link>
          </p>
          <p className="linkContent">{LINKCONTENT}</p>

          {/* <button type="button" className="button">{BUTTON}</button> */}
          <Button variant="cta" onClick={handleClick}>
            {BUTTON}
          </Button>
        </div>
      </div>

      <div className="home-content">
        <AriaAnnouncerProvider>
          <div className="homebanner">
            <Banner
              // eslint-disable-next-line no-script-url
              state="info"
            >
              {finalElement &&
                finalElement.map((item) => {
                  return (
                    <div
                      dangerouslySetInnerHTML={{ __html: item }}
                      key={item}
                    />
                  );
                })}
            </Banner>
          </div>
        </AriaAnnouncerProvider>
      </div>
    </div>
  );
};
export default React.memo(Home);
