import React from "react";
import { Spinner } from '@jpmuitk/spinner';
import './Loader.scss';
// Userstory FFANEW-115
const Loader = props => {

  return (
      <>
        <div className="loaderBox">
            <p>{'Loading... Please wait'}</p>
            <Spinner className='spinner'/>
        </div>
      <div className="overlay"></div>
      </>
  );
};

export default Loader;