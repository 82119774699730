import {
  RECEIVE_SEARCH_DATA,
  FAILURE_SEARCH_DATA,
  RECEIVE_EDIT_DATA,
  FAILURE_DISABLE_DATA,
  REQUEST_SEARCH_DATA
} from '../../../../global.constants';

const initialState = {
  franchiseSearchVOList: [],
  isLoading: false,
  error: '',
  errorMsg: '',
  emailError: ''
}

const FranchiseProfileReducer = (state = initialState, {
  type,
  payload
} = {}) => {
  switch (type) {
    case REQUEST_SEARCH_DATA:
      return Object.assign({}, state, {
        isLoading: true
      });
    case RECEIVE_SEARCH_DATA:
      return Object.assign({}, state, {
        franchiseSearchVOList: payload ? payload : [],
        isLoading: false
      });
    case FAILURE_SEARCH_DATA:
      return Object.assign({}, state, {
        isLoading: false,
        error: payload,
       // errorMsg: payload.status === 500 ? payload.response.data.message : ''
      });
    case RECEIVE_EDIT_DATA:
      return Object.assign({}, state, {
        isLoading: false,
        emailError: payload.data[0] === 'Y' ? '' : payload.data.slice(3)
      });
    case FAILURE_DISABLE_DATA:
      return Object.assign({}, state, {
        isLoading: false,
        error: payload.status,
        errorMsg: payload.status === 500 ? payload.response.data.message : ''
      });
    default:
      return state;
  }
};
export default FranchiseProfileReducer;
