import React from 'react';
import Loader from '../../../components/loader/Loader';



const SignOut = () => {

return(
<Loader/>
)}

export default SignOut;
