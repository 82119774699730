import React, { useEffect, useState } from "react"
import './ReferralInformation.scss'
import { ReferralInformationConstants } from '../../global.constants'
import { FormField, useValidation } from '@jpmuitk/form-field';
import { Form } from '@jpmuitk/forms';
import { Input } from '@jpmuitk/input';
import { OrderedButton, ButtonBar } from '@jpmuitk/button-bar';
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { fetchreferralInformationData } from './redux/action/referralaction'

const ReferralInformation = () => {
    const commonData = {
        referalCode: '',
        sourceCode: ''
    }
    const [data, setData] = useState(commonData)
    const infoData = useSelector(state => state.ReferralInformationReducer.referralData)
    const history = useHistory()
    const dispatch = useDispatch();

    const handleChange = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })
    }

    const handleNextClick = () => {
        dispatch(fetchreferralInformationData(data))
        history.push('myProfile')
    }
    const handlePreviousClick = () => {
        dispatch(fetchreferralInformationData(data))
        history.push('myProfile')
    }
    useEffect(() => {
        if (infoData?.referalCode && infoData?.sourceCode) {
            setData(infoData)
        }
    }, [infoData])
    return (
        <div className='dynamicContainer'>
            <Form className={`formClass`}>
                <h1 className={`headingClass`}>{ReferralInformationConstants.referral}</h1>
                <p>{ReferralInformationConstants.referalcontent}</p>
                <FormField label="Name of Referral">
                    <Input onChange={handleChange} name='referalCode' value={data.referalCode} />
                </FormField>
            
            <h1 className={`headingClass marginTop30`}>{ReferralInformationConstants.sourcecode}</h1>
            <p>{ReferralInformationConstants.sourcecontent}</p>
            <FormField label="Source Code">
                <Input onChange={handleChange} name='sourceCode' value={data.sourceCode} inputProps={{ maxLength: 20 }} />
            </FormField>
            </Form>
            <p className='noteClass'>{ReferralInformationConstants.note}</p>
            <ButtonBar className='referralButton'>
                <OrderedButton variant="cta"
                    onClick={handleNextClick}>{ReferralInformationConstants.nextSection}</OrderedButton>
                <OrderedButton onClick={handlePreviousClick}>{ReferralInformationConstants.previousSection}</OrderedButton>
            </ButtonBar>
        </div>
    )
}
export default ReferralInformation;