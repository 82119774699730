import axios from "axios";
import {
  RECEIVE_SEARCH_POST_DATA,
  FAILURE_SEARCH_POST_DATA,
  REQUEST_SEARCH_POST_DATA,
  base_url,
  base_url_admin,
} from "../../../../global.constants";

export const postFranchiseData = (payload, pageNo) => async (dispatch) => {
  dispatch({ type: REQUEST_SEARCH_POST_DATA });
  const pageNoCheck = pageNo === undefined ? (pageNo = 0) : pageNo;
  try {
    await axios
      .post(
        base_url_admin +
          `/api/v1/adminsvc/admin/getFranchiseePwdDashboard.do/${pageNoCheck}/10`,
        payload
      )
      .then((response) => {
        if (response && (response.status === 200 || response.status === 204)) {
          dispatch(receivePostData(response.data));
        } else {
          dispatch(failurePostData(response));
        }
      });
  } catch (e) {
    dispatch(failurePostData(e.response));
    return e;
  }
};

export const receivePostData = (payload) => ({
  type: RECEIVE_SEARCH_POST_DATA,
  payload,
});

export const failurePostData = (payload) => ({
  type: FAILURE_SEARCH_POST_DATA,
  payload,
});
