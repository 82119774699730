import React from "react";
import { ToolbarButton } from "@jpmuitk/toolbar";
import { useHistory } from "react-router-dom";
import { Icon } from "@jpmuitk/icon";

const Logout = (key) => {
  const history = useHistory();
  const handleLogOut = () => {
    history.push(`/fdao/signOut`);
    window.location.reload();
  };
  return (
    <ToolbarButton
      className="secondaryNavButton"
      onItemClick={handleLogOut}
      key={key}
    >
      <Icon name="export" accessibleText="logout"></Icon> Log Out
    </ToolbarButton>
  );
};

export default Logout;
