import axios from "axios";
import {
  REQUEST_LOGGEDIN_USER,
  FETCH_LOGGEDIN_USER,
  FETCH_LOGGEDINUSR_FAILURE,
  FETCH_LOGGEDIN_USER_ID,
  FETCH_LOGGEDINUSER_ID_FAILURE,
  base_url_user,
  base_url_admin,
  base_url_auth,
  FETCH_AUTH_CODE,
  FETCH_AUTHCODE_FAILURE,
  REQUEST_AUTH_CODE,
  FETCH_LOGGEDIN_ADMIN_ID,
  FETCH_LOGGEDIN_USER_NAME,
} from "../../../containers/global.constants";

export const fetchUser_success = (data) => {
  return {
    type: FETCH_LOGGEDIN_USER,
    payload: data,
  };
};

export const fetchUser_failure = (error) => {
  return {
    type: FETCH_LOGGEDINUSR_FAILURE,
    payload: error,
  };
};
export const fetchUserId_success = (data) => {
  return {
    type: FETCH_LOGGEDIN_USER_ID,
    payload: data,
  };
};
export const fetchUserName = (data) => {
  return {
    type: FETCH_LOGGEDIN_USER_NAME,
    payload: data,
  };
};

export const fetchAdminId_success = (data) => {
  return {
    type: FETCH_LOGGEDIN_ADMIN_ID,
    payload: data,
  };
};

export const fetchUserId_failure = (error) => {
  return {
    type: FETCH_LOGGEDINUSER_ID_FAILURE,
    payload: error,
  };
};

export const getUserDetails = (payload, isAdminScreen) => async (dispatch) => {
  dispatch({ type: REQUEST_LOGGEDIN_USER });
  try {
    await axios.get(base_url_auth + `/api/user`, payload).then((response) => {
      if (response && response.status === 200) {
        let email = response.data?.email;
        let sid = response.data?.sid;

        if (isAdminScreen && email && response.data?.roles?.length) {
          dispatch(adminUserId(sid, email));
        } else if (!!email) {
          dispatch(getUserId(email.toLowerCase()));
        }
        dispatch(fetchUser_success(response.data));
      } else {
        dispatch(fetchUser_failure(response.status));
      }
    });
  } catch (e) {
    dispatch(fetchUser_failure(e.response));
    return e;
  }
};

export const getUserId = (userName) => async (dispatch) => {
  const payload = {
    loginId: userName,
  };
  dispatch({ type: REQUEST_LOGGEDIN_USER });
  try {
    await axios
      .post(base_url_auth + `/api/v1/usersvc/getUserId.do`, payload)
      .then((response) => {
        if (response && response.status === 200) {
          dispatch(fetchUserId_success(response.data?.userId));
        } else {
          dispatch(fetchUserId_failure(response.status));
        }
      });
  } catch (e) {
    dispatch(fetchUser_failure(e.response));
    return e;
  }
};

export const adminUserId = (sid, email) => async (dispatch) => {
  let postData = { loginId: sid, emailAddress: email };

  dispatch({ type: REQUEST_LOGGEDIN_USER });
  try {
    await axios
      .post(base_url_auth + `/api/v1/adminsvc/admin/saveNewUser.do`, postData)
      .then((response) => {
        if (response && response.status === 200) {
          dispatch(fetchAdminId_success(response.data?.userId));
        } else {
          dispatch(fetchUserId_failure(response.status));
        }
      });
  } catch (e) {
    dispatch(fetchUser_failure(e.response));
    return e;
  }
};

export const fetchAuthCode_success = (data) => {
  return {
    type: FETCH_AUTH_CODE,
    payload: data,
  };
};
export const fetchAuthCode_failure = (error) => {
  return {
    type: FETCH_AUTHCODE_FAILURE,
    payload: error,
  };
};

export const getAuthCode = (payload, isAdminScreen) => async (dispatch) => {
  dispatch({ type: REQUEST_AUTH_CODE });

  try {
    await axios.get(base_url_auth + `/api/awsx509token`).then((response) => {
      if (response && response.status === 200) {
        localStorage.setItem(
          "authCode",
          response.data.accessToken !== "" ? response.data.accessToken : ""
        );
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + response.data?.accessToken;
        axios.defaults.withCredentials = true;
        axios.defaults.headers.common["Content-Type"] = "application/json";
        dispatch(getUserDetails({}, isAdminScreen));
        dispatch(fetchAuthCode_success(response.data.accessToken));
      } else {
        dispatch(fetchAuthCode_failure(response.status));
      }
    });
  } catch (e) {
    dispatch(fetchAuthCode_failure(e.response));
    return e;
  }
};
