import {  RECEIVE_PROFILE_DATA, RECEIVE_REGION_DATA,JNAME,RECEIVE_ERR_DATA, FETCH_LOAD_REQUEST,RECEIVE_POST_DATA} from '../../../../global.constants';

const initialState = {
    regions: [],
    firstname: "",
    lastname: "",
    email: "",
    userid: null,
    regionid: null,
    regionname: "",
    errorCode:'',
    userdata: {},
    isLoading: false
}

const ProfileReducer = (state = initialState, { type, payload } = {}) => {
    switch (type) {

        case FETCH_LOAD_REQUEST:
            return Object.assign({}, state, {
              isLoading: true
            });
        case RECEIVE_PROFILE_DATA:
            const postState = {
                ...state,
                firstname: payload.firstName,
                lastname: payload.lastName,
                email: payload.emailAddress,
               // userid: payload.userId,
              regionname: payload.selectedRegion,
              regions: payload.regionList
            }
        
           return Object.assign(state, { 
                userdata: payload,
                isLoading: false
            });

        case  RECEIVE_POST_DATA:
            return Object.assign(state, { 
                isLoading: false
            });

          case RECEIVE_ERR_DATA:
            return Object.assign(state, { 
                errorCode : payload,
                isLoading: false
            });

        default:
            return state
    }
}

export default ProfileReducer;
