import {
  GET_BANKERS_LIST,
  GET_STATUS_LIST,
  SUBMIT_SEARCH_PARAMS,
  EDIT_BANKERS_COMMENTS,
  SUBMIT_STATUS_CHANGE,
  GET_LOAN_COMMENTS,
  CLEAR_LOAN_COMMENT,
  FETCH_BANKERS_FAILURE,
  FETCH_STATUS_FAILURE,
  FETCH_RESULTS_FAILURE,
  FETCH_COMMENTS_FAILURE,
  EDIT_BANKERS_COMMENTS_RECEIVED,
} from "../../../../global.constants";

const initialState = {
  bankerList: [],
  statusList: [],
  searchedData: {},
  bankerComment: {},
  statusChange: {},
  getComment: "",
  isLoading: true,
  error: "",
  errorMsg: "",
};

export const SearchReducer = (state = initialState, action = {}) => {
  const { payload } = action;
  switch (action.type) {
    case GET_BANKERS_LIST:
      return {
        ...state,
        bankerList: action.payload,
        isLoading: false,
      };
    case GET_STATUS_LIST:
      return {
        ...state,
        statusList: action.payload,
        isLoading: false,
      };
    case FETCH_STATUS_FAILURE:
    case FETCH_RESULTS_FAILURE:
    case FETCH_BANKERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        errorMsg: payload.status === 500 ? payload.response.data.message : "",
      };
    case SUBMIT_SEARCH_PARAMS:
      return {
        ...state,
        searchedData: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const OnLineReducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case EDIT_BANKERS_COMMENTS_RECEIVED:
      return {
        ...state,
        bankerComment: payload,
        isLoading: false,
      };
    case EDIT_BANKERS_COMMENTS:
      return {
        ...state,
        bankerComment: payload,
        isLoading: false,
      };
    case SUBMIT_STATUS_CHANGE:
      return {
        ...state,
        statusChange: payload,
        isLoading: false,
      };
    case GET_LOAN_COMMENTS:
      return {
        ...state,
        getComment: payload,
        isLoading: false,
      };
    case CLEAR_LOAN_COMMENT:
      return {
        ...state,
        getComment: null,
        isLoading: false,
      };
    case FETCH_RESULTS_FAILURE:
    case FETCH_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        errorMsg: payload.status === 500 ? payload.response.data.message : "",
      };
    default:
      return state;
  }
};
