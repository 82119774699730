import React  from 'react';
import { Link } from '@jpmuitk/link';
import {Form} from './../../static.constants';
import { getFileAPI } from '../../commonactions/redux/actions/action';

const Forms = () => {
    
    return (
        <div className='container' id="main">
            <h1>{Form.forms}</h1>
            <div>
                <ul>
                    <li><Link href={''}onClick={(e)=>getFileAPI(e,'ffwadditionalpayment','pdf')}  className={`formLinkStyle`} target="_blank" aria-label={`${Form.paymentForm} External link opens as PDF`}>{Form.paymentForm}</Link> </li>
                    <li><Link href={''} onClick={(e)=>getFileAPI(e,'addresschange','pdf')} className={`formLinkStyle`} target="_blank" aria-label={`${Form.addressForm} External link opens as PDF`}>{Form.addressForm}</Link></li>
                    <li><Link href={''} onClick={(e)=>getFileAPI(e,'autodebit','pdf')} className={`formLinkStyle`} target="_blank" aria-label={`${Form.autoDebitForm} External link opens as PDF`}>{Form.autoDebitForm}</Link></li>
                    <li><Link  href={''} onClick={(e)=>getFileAPI(e,'advanceloanrequest','pdf')} className={`formLinkStyle`} target="_blank" aria-label={`${Form.drawRequestForm} External link opens as PDF`}>{Form.drawRequestForm}</Link></li>
                    <li><Link  href={Form.irsFormUrl} className={`formLinkStyle`} target="_blank" aria-label={`${Form.irsForm} External link opens as PDF`}>{Form.irsForm}</Link></li>
                 </ul>   
            </div>
        </div>
    )
}
export default Forms;