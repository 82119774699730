import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { FormField, useValidation } from '@jpmuitk/form-field';
import Grid from '@material-ui/core/Grid';
import { Form } from '@jpmuitk/forms';
import { Input } from '@jpmuitk/input';
import { DatePicker } from '@jpmuitk/date-picker';
import { Button } from '@jpmuitk/button';
import ErrorBoundary from '../../../components/form/ErrorBoundary';
import { DebtSummaryLabel, yearsEndValue, yearsStartValue, Tooltip_info } from '../../global.constants';
import 'react-dates/lib/css/_datepicker.css';
import '../../global.scss';
import './DebtDetails.scss';

export const numberValidator = {
    "originalAmount": true,
    "outstandingAmount": true,
    "interestRate": true,
    "monthlyPayment": true,
}

const DebtDetails = (props) => {

    const { removeDebt, defaultValue, updateRoot, ind } = props;
    const [value, setValue] = useState(JSON.parse(defaultValue));
    const id = defaultValue.debtId;
    const years = [...new Array(yearsStartValue)].map((ele, id) => {
        let yr = new Date().getFullYear() - yearsEndValue + id;
        return {
            value: yr,
            text: String(yr),
            id: yr
        }
    })

    useEffect(() => {
        setValue(JSON.parse(defaultValue));
    }, [props.defaultValue]);

    useEffect(() => {
        updateRoot({ ...value }, ind);
    }, [value]);

    const numberRangeValidator = (val, maxDigits, decimal) => {
        let decimalPoint = new RegExp(`^\\d*\\.?[\\d]{0,${decimal}}$`, 'g');
        let numb = val.replace(/,/g, '');
        if (numb.replace(/\./g, '').length <= maxDigits && decimalPoint.test(numb)) {
            return true;
        }
        return false;
    }

    const formatUSD = (val) => {
        return String(val).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }

    const handleKeyPress = (evt) => {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
       key = String.fromCharCode(key);
       if(value.interestRate + key > 100) {
        evt.preventDefault();
           return false
       }
       return true
    }

    const updateState = (e, key, maxDigits = 10, decimal = 2) => {
        if (key.includes("Date")) {
            value[key] = e;
            setValue({ ...value });
        } else if (numberValidator[key]) {
            let val = e?.target?.value.replace(/,/g, '');
            value[key] = numberRangeValidator(val, maxDigits, decimal) ? val == '' ? ''
                : val[val.length - 1] == '.' ? val :
                    val[val.length - 1] == '0' ? val
                        : Number(val)
                : value[key];
            setValue({ ...value });
        } else if (key === 'comments') {
            value.commentVO.comments = e?.target?.value;
            setValue({ ...value });
        } else {
            value[key] = e?.target?.value;
            setValue({ ...value });
        }
    }

    const updatedefaultValue = (key, val, max = 10, dec = 2) => {
        if (!value[key]) {
            value[key] = val;
            setValue({ ...value });
        } else {
            let num = String(value[key]).replace(/[,.]/g, '');
            let len = (num.length + dec) % max;
            if (len > dec || len === 0) {
                value[key] = Number(value[key]).toFixed(dec);
            } else if (len < dec) {
                value[key] = Number(value[key]).toFixed(len);
            }
            setValue({ ...value });
        }
    }

    return (
        <div className={`nestedThirdFormContainer`}>
            <div className={`headerContainer`}>
                <h1 className={`headingClass`}>
                    {`${DebtSummaryLabel.debtHeading} ${ind + 1}`}
                </h1>
                <Button onClick={() => removeDebt(ind)}>{DebtSummaryLabel.btnDelDebt}</Button>
            </div>

            <Form fullWidthFields id={`debt-form-${id}`} className={`formClass`}>
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        <FormField label={DebtSummaryLabel.lender}>
                            <Input
                                name={`lender`}
                                fullWidth
                                autoComplete='off'
                                onChange={(e) => updateState(e, 'lender')}
                                value={value.lender}
                            />
                        </FormField>
                    </Grid>
                    <Grid item xs={12}>
                        <FormField label={DebtSummaryLabel.originalDate}>
                            <DatePicker
                                dateFormat={'MM/DD/YYYY'}
                                date={moment(value.originalNoteDate, 'MM/DD/YYYY')}
                                onDateChange={(date) => {
                                    date && updateState(date.format('MM/DD/YYYY'), "originalNoteDate")
                                }}
                                PopperProps={{ placement: 'bottom-end' }}                                
                                YearDropdownProps={{ 'source': years, listWidth: '80px' }}
                                StartInputProps={{ className: 'datePickerBox' }}
                                CalendarProps={{ "defaultInputValue": "01/01/1939" }}
                            />
                        </FormField>
                    </Grid>
                    <Grid item xs={12}>
                        <FormField label={DebtSummaryLabel.maturityDate}>
                            <DatePicker
                                dateFormat={'MM/DD/YYYY'}
                                CalendarProps={{ "defaultInputValue": "01/01/1939" }}
                                date={moment(value.maturityDate, 'MM/DD/YYYY')}
                                onDateChange={(date) => date && updateState(date.format('MM/DD/YYYY'), "maturityDate")}
                                YearDropdownProps={{ 'source': years, listWidth: '80px' }}
                                StartInputProps={{ className: 'datePickerBox' }}
                                PopperProps={{ placement: 'bottom-end' }}
                            />
                        </FormField>

                    </Grid>
                    <Grid item xs={12}>
                        <FormField label={DebtSummaryLabel.originalAmt}>
                            <Input
                                name='originalAmount'
                                fullWidth
                                autoComplete='off'
                                onBlur={(e) => updatedefaultValue('originalAmount', '0.00')}
                                onChange={(e) => updateState(e, 'originalAmount', 10, 2)}
                                value={formatUSD( value.originalAmount === '0.00' ? ''  : value.originalAmount )}
                            />
                        </FormField>
                    </Grid>
                    <Grid item xs={12}>
                        <FormField label="Current Amount Outstanding ($)">
                            <Input
                                name='outstandingAmount'
                                fullWidth
                                autoComplete='off'
                                onChange={(e) => updateState(e, 'outstandingAmount', 10, 2)}
                                onBlur={(e) => updatedefaultValue('outstandingAmount', '0.00')}
                                value={formatUSD(value.outstandingAmount === '0.00'  ? '': value.outstandingAmount)}
                            />
                        </FormField>
                    </Grid>
                    <Grid item xs={12}>
                        <FormField label={DebtSummaryLabel.interest}>
                            <Input
                                name='interestRate'
                                fullWidth autoComplete='off'
                                onChange={(e) => updateState(e, 'interestRate', 5, 3)}
                                onBlur={(e) => updatedefaultValue('interestRate', '0.000', 11, 3)}
                                value={formatUSD(value.interestRate === '0.000' ? '' : value.interestRate)}
                                onKeyPress={handleKeyPress}
                            />
                        </FormField>
                    </Grid>
                    <Grid item xs={12}>
                        <FormField label={DebtSummaryLabel.monthlyPayment}>
                            <Input
                                name='monthlyPayment'
                                fullWidth
                                autoComplete='off'
                                onChange={(e) => updateState(e, 'monthlyPayment', 10, 2)}
                                onBlur={(e) => updatedefaultValue('monthlyPayment', '0.00')}
                                value={formatUSD(value.monthlyPayment === '0.00' ? '' : value.monthlyPayment  )}
                            />
                        </FormField>
                    </Grid>
                    <Grid item xs={12}>
                        <FormField
                            label={DebtSummaryLabel.comments}
                            helperText={DebtSummaryLabel.placeholderText}
                            helperTextPlacement={'tooltip'}
                            hasStatusIndicator
                        >
                            <span tabIndex='0' className='sr-only' role='tooltip' aria-label={Tooltip_info}></span>
                            <Input
                                name='comments'
                                className="comments"
                                fullWidth
                                autoComplete='off'
                                multiline={true}
                                onChange={(e) => updateState(e, "comments")}
                                value={value?.commentVO?.comments}
                                role='tooltip' aria-label={Tooltip_info}
                            />
                        </FormField>
                    </Grid>
                </Grid>
            </Form>
        </div>
    );
}

export default React.memo(ErrorBoundary(DebtDetails));