export const radioMgmtCmp = [
  {
    label: "Yes",
    value: "Y",
  },
  {
    label: "No",
    value: "N",
  },
];

export const BIZSTRUCTURE = [
  {
    businessStructId: "0",
    businessStructName: "Select",
  },
  {
    businessStructId: "1",
    businessStructName: "Sole Proprietorship",
  },
  {
    businessStructId: "2",
    businessStructName: "Limited Liability Company",
  },
  {
    businessStructId: "3",
    businessStructName: "General Partnership",
  },
  {
    businessStructId: "4",
    businessStructName: "Corporation",
  },
  {
    businessStructId: "5",
    businessStructName: "Limited Partnership",
  },
  {
    businessStructId: "6",
    businessStructName: "Joint Partnership",
  },
  {
    businessStructId: "7",
    businessStructName: "Other",
  },
];
export const STOCKBIZSTRUCTURE = [
  {
    businessStructId: "0",
    businessStructName: "Select",
  },
  {
    businessStructId: "1",
    businessStructName: "Limited Liability Company",
  },
  {
    businessStructId: "2",
    businessStructName: "General Partnership",
  },
  {
    businessStructId: "3",
    businessStructName: "Corporation",
  },
  {
    businessStructId: "4",
    businessStructName: "Limited Partnership",
  },
  {
    businessStructId: "5",
    businessStructName: "Other",
  },
];
export const STATES = [
  {
    stateId: 0,
    stateName: "Select",
  },
  {
    stateId: 1,
    stateName: "Illinois",
  },
  {
    stateId: 2,
    stateName: "Minnesota",
  },
  {
    stateId: 3,
    stateName: "Montana",
  },
];

export const initObj = {
  storeId: "",
  nationalStoreNumber: "",
  addressId: "",
  addressVO: {
    address: "",
    city: "",
    stateId: "",
    zipCode: "",
  },
  licenseExpirationDate: "",
  newStore: "",
  landOwnedOrLeased: "",
  leaseOptions: "",
  purchaseDate: "",
  businessStructId: "",
  otherBizStruEntity: null,
  borrowerEntity: {
    borrowerName: "",
    borrowerId: "",
    stockHolders: [],
  },
  mgmtCompany: "N",
  mgmtCompanyName: "",
  mgmtCompanyEntityId: "",
  mgmtStateOfIncorporation: "",
};

export const selectEntity = (entity) => {
  let selected = BIZSTRUCTURE.filter((ele) => entity == ele?.businessStructId);
  return selected.length ? selected[0] : BIZSTRUCTURE[0];
};
export const selectStockEntity = (entity) => {
  let selected = STOCKBIZSTRUCTURE.filter(
    (ele) => entity == ele?.businessStructId
  );
  return selected.length ? selected[0] : STOCKBIZSTRUCTURE[0];
};

export const selectState = (stateId, countrystateData) => {
  let states = countrystateData || STATES;
  let selected = states.filter((ele) => stateId == ele?.stateId);
  return selected.length ? selected[0] : states[0];
};
