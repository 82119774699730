import React, { useEffect, useState } from "react";
import { DataGrid } from "@jpmuitk/data-grid";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "@jpmuitk/forms";
import { Input } from "@jpmuitk/input";
import { OrderedButton, ButtonBar } from "@jpmuitk/button-bar";
import Grid from "@material-ui/core/Grid";
import { FormField } from "@jpmuitk/form-field";
import CustomPagination from "../../../components/pagination/pagination";
import { Link } from "@jpmuitk/link";
import "../../global.scss";
import "ag-grid-community/dist/styles/ag-grid.css";
import "./searchFranchise.scss";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { postFranchiseData } from "./redux/action/searchFranchiseAction";
import {
  searchFranchise,
  FOURHUNDREDFOUR,
  FIVEHUNDRED,
} from "../../global.constants";
import { useHistory } from "react-router-dom";
import Loader from "../../../components/loader/Loader";
import Error from "../../../components/error/Error";
import {
  currentLoanId,
  returnTodashboardflag,
} from "../../dynamic/returnToDashboard/redux/action/dashboardAction";
import {
  fetchUserId_success,
  fetchUserName,
} from "../../../auth/redux/action/AuthCheckAction";

const SearchFranchise = () => {
  const [Message, setMessage] = useState(false);
  const franchiseData = useSelector(
    (state) => state.searchFranchiseReducer.franchiseSearchVOList
  );
  const isLoading = useSelector(
    (state) => state.searchFranchiseReducer.isLoading
  );
  const isAdminLoading = useSelector(
    (state) => state.AuthCheckReducer.isLoading
  );
  const errors = useSelector((state) => state.searchFranchiseReducer.error);
  const errorMsg = useSelector(
    (state) => state.searchFranchiseReducer.errorMsg
  );
  const menuId = useSelector((state) => state.DashboardReducer.menuId);
  const [gridApi, setGridApi] = useState(null);
  const dispatch = useDispatch();
  const [apiData, setapiData] = useState([]);
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [userid, setUserId] = useState("");
  const [clearGrid, setClearGrid] = useState(true);
  const history = useHistory();
  const [error, setError] = useState(error);
  const PATH = "/dashboard";

  useEffect(() => {
    if (franchiseData) {
      if (!Boolean(franchiseData.franchiseProfile)) return setapiData([]);
      setapiData(franchiseData.franchiseProfile);
    }
  }, [franchiseData]);

  useEffect(() => {
    return () => setClearGrid(true);
  }, []);

  useEffect(() => {
    if (errors) return setError(errors);
    setError("");
  }, [errors]);

  const onGridReady = ({ api }) => {
    api.sizeColumnsToFit();
    setGridApi(api);
  };

  const handlePageChange = (e, page) => {
    console.log(page);
    const payload = {
      fullName: name,
      loginId: userid,
      operatorId: id,
    };
    dispatch(postFranchiseData(payload, page - 1));
    window.scrollTo(0, 0);
  };

  const handleClear = () => {
    setMessage(true);
    setName("");
    setUserId("");
    setId("");
    setClearGrid(true);
  };

  const handleSearch = () => {
    if (userid || name || id) {
      const payload = {
        fullName: name,
        loginId: userid,
        operatorId: id,
      };
      dispatch(postFranchiseData(payload, 0));
      setClearGrid(false);
      setError("");
      return;
    }
    setError(searchFranchise.errorMessage);
  };
  const handleEdit = (userId, fullName) => {
    dispatch(fetchUserId_success(userId));
    dispatch(fetchUserName(fullName));
    dispatch(returnTodashboardflag({ adminFlag: true, menuId: menuId }));
    history.push("/dashboard");
  };
  const franchiseNameCellRenderer = (params) => {
    return (
      <div>
        <Link
          className={`cursor`}
          onClick={() => handleEdit(params.data?.userId, params.data?.fullName)}
        >
          {params.data.fullName}
        </Link>
      </div>
    );
  };

  const dataGridExampleColumns = [
    {
      headerName: "Franchisee Name",
      field: "firstName",
      suppressMenu: true,
      cellRenderer: "franchiseNameCellRenderer",
      sortable: false,
    },
    {
      headerName: "Operator ID",
      field: "operatorId",
      suppressMenu: true,
      sortable: false,
    },
    {
      headerName: "City",
      field: "city",
      suppressMenu: true,
      sortable: false,
    },
    {
      headerName: "State",
      field: "state",
      suppressMenu: true,
      sortable: false,
    },
    {
      headerName: "E-mail Address",
      field: "emailAddress",
      suppressMenu: true,
      sortable: false,
    },
  ];

  return (
    <>
      {isLoading || isAdminLoading ? <Loader /> : null}
      {error === FOURHUNDREDFOUR ? <Error /> : null}
      {error === FIVEHUNDRED ? errorMsg : null}
      {!error ? (
        <div className="adminContainer" id="main" role="main">
          <div>
            <div id="myGrid" className="ag-theme-alpine searchFranchise">
              <Form fullWidthFields className={`formClass`}>
                <h1 className={`headingClass`}>
                  {searchFranchise.searchCriteria}
                </h1>
                <p className={`franchiseNote`}>{searchFranchise.noteText}</p>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormField label="Franchisee Name">
                      <Input
                        autoComplete="off"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                      ></Input>
                    </FormField>
                  </Grid>
                  <Grid item xs={12}>
                    <FormField label="Operator ID">
                      <Input
                        autoComplete="off"
                        onChange={(e) => setId(e.target.value)}
                        value={id}
                      ></Input>
                    </FormField>
                  </Grid>
                  <Grid item xs={12}>
                    <FormField label="User ID">
                      <Input
                        autoComplete="off"
                        onChange={(e) => setUserId(e.target.value)}
                        value={userid}
                      ></Input>
                    </FormField>
                  </Grid>
                </Grid>
              </Form>
              <ButtonBar className={`searchButtonsClass`}>
                <OrderedButton variant="cta" onClick={handleSearch}>
                  {searchFranchise.search}
                </OrderedButton>

                <OrderedButton
                  className={`commonStylingButton`}
                  onClick={handleClear}
                >
                  {searchFranchise.clearCriteria}
                  {Message ? (
                    <span
                      class="sr-only"
                      aria-live="assertive"
                      role="alert"
                      aria-label={`Cleared`}
                    ></span>
                  ) : (
                    ""
                  )}
                </OrderedButton>
              </ButtonBar>
              {!clearGrid && (
                <DataGrid
                  classes={{ root: "dataGridAdminDashboard" }}
                  domLayout={"autoHeight"}
                  className="return-to-dashboard-data-grid"
                  columnDefs={dataGridExampleColumns}
                  rowData={apiData}
                  onGridReady={onGridReady}
                  frameworkComponents={{
                    franchiseNameCellRenderer: franchiseNameCellRenderer,
                  }}
                />
              )}
              <div className={`paginationSearchClass`}>
                {!clearGrid && (
                  <CustomPagination
                    count={franchiseData.totalPages}
                    handlePageChange={(e, page) => handlePageChange(e, page)}
                    page={franchiseData.currentPage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SearchFranchise;
