import { Button } from "@jpmuitk/button";
import RestDetails from "./RestaurantDetails";
import ErrorBoundary from "../../../components/form/ErrorBoundary";
import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";

const OwnRestaurant = ({
  handleDeleteAnItem,
  updateRestaurant,
  handleAddNewItem,
  btnDisable,
  data,
  updateStakeHolder,
  deleteStakeHolder,
}) => {
  const currentRestNos = data?.filter((ele) => ele != undefined)?.length;

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <div className="oldStoreTopContainer">
          {currentRestNos === 0 ? (
            <h3>No currently owned Restaurants</h3>
          ) : (
            <h3>
              Number of restaurants currently owned:{" "}
              <span className="numberOfRest">{currentRestNos}</span>
            </h3>
          )}
          {currentRestNos > 0 && (
            <div className={`addOldStoreContainer`}>
              <Button
                variant="cta"
                name="oldStoresList"
                onClick={() => handleAddNewItem("oldStoresList")}
                aria-live="polite"
              >
                Add Another Restaurant
              </Button>
            </div>
          )}
        </div>
      </Grid>
      {data?.map((ele, ind) => {
        return (
          <RestDetails
            key={ele.storeId}
            ind={ind}
            defaultValue={ele}
            remove={handleDeleteAnItem}
            updateStakeHolder={updateStakeHolder}
            deleteStakeHolder={deleteStakeHolder}
            header={"Current Restaurant"}
            group="oldStoresList"
            updateRest={updateRestaurant}
            btnDisable={btnDisable}
          />
        );
      })}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {currentRestNos === 0 ? (
          <div className={`footerBtnContainer`}>
            <Button
              variant="cta"
              name="oldStoresList"
              onClick={() => handleAddNewItem("oldStoresList")}
              aria-live="polite"
            >
              add A Restaurant
            </Button>
          </div>
        ) : (
          <div className={`footerBtnContainer`}>
            <Button
              variant="cta"
              name="oldStoresList"
              onClick={() => handleAddNewItem("oldStoresList")}
              aria-live="polite"
            >
              Add another Restaurant
            </Button>
          </div>
        )}
      </Grid>
    </>
  );
};

export default ErrorBoundary(OwnRestaurant);
