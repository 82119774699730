import React from "react";
import { Link } from "@jpmuitk/link";
import "../../global.scss";
import { DownloadPrintableStatement } from "./../../static.constants";
import { getFileAPI } from "../../commonactions/redux/actions/action";

const DownloadPrintable = () => {
  return (
    <div className={`container`} id="main">
      <h1>{DownloadPrintableStatement.downloadStatement}</h1>
      <div>
        <p className={`italic`}>
          {DownloadPrintableStatement.documentRequired}
        </p>
        <div>
          {DownloadPrintableStatement.review}
          <p>
            {DownloadPrintableStatement.theInformation}
            <Link
              href=""
              onClick={(e) => getFileAPI(e, "loanrequest", "pdf")}
              target="_blank"
              aria-label={`${DownloadPrintableStatement.printableStatement} External link opens as PDF`}
            >
              {DownloadPrintableStatement.printableStatement}
            </Link>
            {DownloadPrintableStatement.isavaliableStatement}
          </p>
          {DownloadPrintableStatement.providedContent}{" "}
          <Link
            href={DownloadPrintableStatement.onlineloanrequestUrl}
            aria-label={`${DownloadPrintableStatement.requestStatement} External link`}
            target="_blank"
          >
            {DownloadPrintableStatement.requestStatement}
          </Link>
        </div>
      </div>
    </div>
  );
};
export default DownloadPrintable;
