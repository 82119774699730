import {FETCH_COUNTRYSTATES_SUCCESS,FETCH_REGION_SUCCESS, FETCH_REGION_FAILURE, COUNTRY_ACTION_SUCCESS
} from '../../../global.constants';

const initialState = {
  Countrystates: [],
  Regionname: [],
  Countrynames: [],
}

const CountrystatesReducer = (state = initialState, {type,payload}={}) => {
  switch (type) {
    
    case FETCH_COUNTRYSTATES_SUCCESS:
      // Select option added
      payload.unshift({stateId: '', stateCode: "", stateName: 'Select'})
      return Object.assign(state, {
        Countrystates: payload
      });
    case COUNTRY_ACTION_SUCCESS:
      return Object.assign(state, {
        Countrynames: payload
      });
    case FETCH_REGION_SUCCESS:
      return Object.assign(state, {
        Regionname: payload
      });

    case FETCH_REGION_FAILURE:
      return Object.assign(state, {
      });

    default:
      return state;
  }
};
export default CountrystatesReducer;