import React, { useEffect, useState } from "react";
import AppRoutes from "../../routes/app-routes";
import { Navigation, NavigationComponent } from "@jpmuitk/navigation";
import { useHistory, useLocation } from "react-router-dom";
import Footer from "../../components/footer/footer";
import { useDensity } from "@jpmuitk/theme";
import DialogBox from "../../components/dialogBox/DialogBox";
import { withStyles } from "@jpmuitk/theme";
import "../global.scss";
import { AppHeaderNext } from "@jpmuitk/app-header";
import { useSelector, useDispatch } from "react-redux";
import Logout from "../dynamic/logout/logout";
import {
  dynamic_Menu,
  adminleftnavdata,
  UNAUTHORIZED_URL,
  logout_url,
} from "./../global.constants";
import { LicenseManager } from "ag-grid-enterprise";
import { formHas_error } from "../../containers/dynamic/returnToDashboard/redux/action/dashboardAction";
import {
  getUserDetails,
  getAuthCode,
} from "../../auth/redux/action/AuthCheckAction";
import ContactUs from "../dynamic/contactUs/contactUs";
LicenseManager.setLicenseKey(
  "CompanyName=SHI International Corp._on_behalf_of_JPMorgan Chase,LicensedApplication=Franchise Digital Account Opening,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=0,AssetReference=AG-026668,ExpiryDate=24_March_2023_[v2]_MTY3OTYxNjAwMDAwMA==6964aa83367474ce571550230e07b5ae"
);

const staticMenu = {
  type: NavigationComponent.APP_HEADER,
  source: [
    {
      id: "home",
      name: "Home",
      type: NavigationComponent.HORIZONTAL_NAVIGATION,
    },
    {
      id: "termsconditions",
      name: "Terms & Conditions",
      type: NavigationComponent.HORIZONTAL_NAVIGATION,
      source: [
        { id: "loanProductsSummary", name: "Loan Products Summary" },
        {
          id: "annualFinancialStatementSubmission",
          name: "Annual Financial Statement Submission",
        },
        { id: "onlineLoanRequests", name: "Online Loan Requests" },
      ],
    },
    {
      id: "rates",
      name: "Rates",
    },
    {
      id: "requiredInformation",
      name: "Required Information",
      type: NavigationComponent.HORIZONTAL_NAVIGATION,
      source: [
        {
          id: "requiredInformationLoans",
          name: "Required Information for Loan and Line of Credit Requests",
        },
        {
          id: "personalFinancialStatements",
          name: "Personal Financial Statement",
        },
        { id: "forms", name: "Forms" },
      ],
    },
    {
      id: "franchiseloanrequests",
      name: "Franchisee Loan Request",
      type: NavigationComponent.HORIZONTAL_NAVIGATION,
      source: [
        { id: "downloadPrintable", name: "Download Printable" },
        { id: "onlineInterative", name: "Online Interactive" },
      ],
    },
    {
      id: "faqs",
      name: "FAQs",
    },
  ],
};

const AppContainer = () => {
  const leftnavflag = useSelector(
    (state) => state.DashboardReducer.currentLoan
  );
  const formHasError = useSelector((state) => state.DashboardReducer.formError);
  const authCode = useSelector((state) => state.AuthCodeReducer.authCode);
  const density = useDensity();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const [dataofleftnav, setDataleftnav] = useState(dynamic_Menu);
  const returnTodashboardflag = useSelector(
    (state) => state.DashboardReducer.returnTodashboardflag
  );
  const menuId = useSelector((state) => state.DashboardReducer.menuId);
  const isUserLoggedIn = useSelector(
    (state) => state.AuthCheckReducer?.loggedInUser
  );
  const isUserNotLoggedIn = useSelector(
    (state) => state.AuthCheckReducer?.error
  );
  const authCodeError = useSelector(
    (state) => state.AuthCodeReducer?.authCodeError
  );
  const [openModal, setopenModal] = useState(false);
  const loanId = useSelector((state) => state.DashboardReducer?.currentLoan);
  const refreshId = loanId?.activeLoanId;
  const [st, setSt] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const navFunc = React.useRef();
  const closeDialog = (value) => {
    setopenModal(value);
  };

  var sessionDialogbox = openModal ? (
    <DialogBox
      id="sessionDailog"
      open={openModal}
      closeDialog={closeDialog}
    ></DialogBox>
  ) : null;
  // clearSessionTime={clearSessionTime}
  const opendialogbox = () => {
    if (
      !(
        staticMenu?.source
          .map((nav) => nav.id)
          ?.includes(pathname.split("/")[1]) || pathname.split("/")[1] === ""
      )
    )
      setopenModal(true);
  };

  const myTimeout = setTimeout(opendialogbox, 3600000);

  useEffect(() => {
    if ((isAdminScreen || isDynamicScreen) && !authCode) {
      dispatch(getAuthCode({ env: "local" }, isAdminScreen));
    }
  }, [pathname]);

  useEffect(() => {
    setSt(formHasError);
  }, [formHasError]);

  useEffect(() => {
    if (!st) {
      push(localStorage.getItem("paths"));
      dispatch(formHas_error(true));
    }
  }, [st]);

  ////FFANEW-120 userstory start

  useEffect(() => {
    let leftnavIdandNameArray = [];
    if (returnTodashboardflag) {
      const isBankercomments = dataofleftnav.some((elements) => {
        if (elements.id === "bankersComments") {
          return true;
        }
      });
      if (!isBankercomments) {
        dataofleftnav.splice(13, 0, {
          id: "bankersComments",
          name: "Bankers Comments",
        });
      }
    }
    if (leftnavflag?.isLeftnavEnabled && !!leftnavflag.activeLoanId) {
      leftnavIdandNameArray = dataofleftnav.map((user) => {
        delete user.className;
        return user;
      });
      setDataleftnav(leftnavIdandNameArray);
    } else {
      leftnavIdandNameArray = dataofleftnav.map((user, index) => {
        if (index > 1) user.className = "mandatoryleftnav";
        return user;
      });
      setDataleftnav(leftnavIdandNameArray);
    }
  }, [leftnavflag, returnTodashboardflag]);
  ////FFANEW-120 userstory end

  useEffect(() => {
    if (
      !refreshId &&
      dynamic_Menu?.map((nav) => nav.id)?.includes(pathname.split("/")[1]) &&
      pathname.split("/")[1] !== dynamic_Menu[0].id &&
      dynamic_Menu?.map((nav) => nav.id)?.includes(pathname.split("/")[1]) &&
      pathname.split("/")[1] !== dynamic_Menu[1].id &&
      !(isUserLoggedIn && isUserLoggedIn?.roles && isUserLoggedIn?.roles.length)
    ) {
      history.push("/dashboard");
    }
    if (
      !refreshId &&
      dynamic_Menu?.map((nav) => nav.id)?.includes(pathname.split("/")[1]) &&
      window.location.hostname.includes("admin") &&
      isUserLoggedIn &&
      isUserLoggedIn?.roles &&
      isUserLoggedIn?.roles.length
    ) {
      history.push("/adminHome");
    }
  }, [isUserLoggedIn]);

  useEffect(() => {
    if (isUserNotLoggedIn !== "") {
      console.log("No User Logged In...");
    }
  }, [isUserLoggedIn, isUserNotLoggedIn]);

  const dynamicMenu = {
    type: NavigationComponent.VERTICAL_NAVIGATION,
    source: JSON.parse(JSON.stringify(dataofleftnav)),
  };
  const noMenu = {
    type: NavigationComponent.APP_HEADER,
    source: [],
  };

  const handleContactUs = () => {
    history.push("/contactUs");
  };

  const styles = {
    root: {
      height: "auto",
      minHeight: "100%",
    },
  };
  const actionsWidth = {
    touch: 250,
    low: 250,
    medium: 235,
    high: 205,
  };
  const actionProps = {
    iconSize: 12,
    maxWidth: actionsWidth[density],
  };

  const actionSourceContactUs = [
    {
      itemId: "contact Us",
      itemRenderer: ({ key }) => {
        return <ContactUs key={key} />;
      },
    },
  ];
  const actionSourceLogout = [
    {
      itemId: "logout",
      itemRenderer: ({ key }) => {
        return <Logout key={key} />;
      },
    },
  ];

  useEffect(() => {
    if (isUserNotLoggedIn !== "") {
      console.log("No User Logged In...");
    }
  }, [isUserLoggedIn, isUserNotLoggedIn, authCodeError, authCode]);

  const handleOnChange = (path) => {
    localStorage.setItem("paths", path);
    if (path.includes("onlineInterative")) {
      window.open("/newReturningUser", "_blank");
    } else {
      if (document.getElementById("hiddenValidatr")) {
        document.getElementById("hiddenValidatr").click();
      } else {
        push(path);
      }
    }
  };
  const isAuthenticationScreen = [
    "newReturningUser",
    "registration",
    "registrationSuccess",
  ].includes(pathname.split("/")[1]);
  const isDynamicScreen = dataofleftnav
    .map((nav) => nav.id)
    .includes(pathname.split("/")[1]);

  const isAdminScreen = ["adminHome", "searchFranchise"]
    .concat(adminleftnavdata.map((nav) => nav.id))
    .includes(pathname.split("/")[1]);

  //Admin Menu
  const adminMenu = {
    type: NavigationComponent.VERTICAL_NAVIGATION,
    source: [],
  };
  const source = []; // adminleftnavdata

  let noRoles = true;
  isUserLoggedIn &&
    isUserLoggedIn?.roles &&
    isUserLoggedIn?.roles.forEach((role) => {
      if (role === "Manager") {
        source.push(adminleftnavdata.find((a) => a.id === "adminDashboard"));
        adminMenu.source = source;
        noRoles = false;
      }
      if (role === "Banker") {
        source.push(adminleftnavdata.find((a) => a.id === "on-LineRequests"));
        source.push(adminleftnavdata.find((a) => a.id === "franchiseeProfile"));
        adminMenu.source = source;
        noRoles = false;
      }
      if (role === "Admin") {
        source.push(
          adminleftnavdata.find((a) => a.id === "updateCurrentRates&Message")
        );
        adminMenu.source = source;
        noRoles = false;
      }
    });

  if (
    isUserLoggedIn &&
    !Array.isArray(isUserLoggedIn) &&
    isUserLoggedIn.roles &&
    noRoles &&
    isAdminScreen
  ) {
    isUserLoggedIn.profiles &&
      isUserLoggedIn.profiles.forEach((profile) => {
        if (profile === "awsprod") {
          window.location = UNAUTHORIZED_URL.ProdEnv;
        } else {
          window.location = UNAUTHORIZED_URL.UatEnv;
        }
      });
  }
  // When no roles navigate to unauthorized page

  const currentPath = pathname.split("/")[1];
  if (
    isAdminScreen &&
    isUserLoggedIn?.roles &&
    adminMenu.source.length > 0 &&
    !adminMenu.source.map((nav) => nav.id).includes(currentPath) &&
    currentPath !== "adminHome" &&
    currentPath !== "searchFranchise"
  ) {
    isUserLoggedIn.profiles &&
      isUserLoggedIn.profiles.forEach((profile) => {
        if (profile === "awsprod") {
          window.location = UNAUTHORIZED_URL.ProdEnv;
        } else {
          window.location = UNAUTHORIZED_URL.UatEnv;
        }
      });
  }

  const actionScreen = isAdminScreen
    ? adminMenu
    : isDynamicScreen
    ? dynamicMenu
    : isAuthenticationScreen
    ? noMenu
    : staticMenu;
  if (returnTodashboardflag) {
    actionScreen.source.unshift({
      id: menuId || "adminHome",
      name: "Return to Administrator Dashboard",
    });
    actionScreen.source = actionScreen.source.filter(
      (ele) => ele.name !== "My Profile"
    );
  }
  if (isAdminScreen) {
    actionScreen.source = actionScreen.source.filter(
      (ele) => ele.name !== "Return to Administrator Dashboard"
    );
    actionScreen.source = actionScreen.source.sort((a, b) => a.order - b.order);
  }

  const StyledNavigation = withStyles(styles)(Navigation);

  const navigateThrough = (params) => {
    localStorage.setItem("paths", params);
    document.getElementById("hiddenValidatr").click();
  };

  return (
    <>
      {isDynamicScreen || isAdminScreen ? (
        <AppHeaderNext
          appTitle="Franchise Digital Account Opening"
          logoProps={{ variant: "chase" }}
          actionSource={!isAdminScreen && actionSourceLogout}
        />
      ) : (
        ""
      )}
      {(isDynamicScreen || isAdminScreen) &&
      window.location.href.indexOf("/adminHome") < 0 ? (
        <div className="baseContainer">
          <StyledNavigation
            className={
              window.location.href.indexOf("/adminHome") < 0 && isAdminScreen
                ? "adjPosition"
                : "adjDynamic"
            }
            source={actionScreen}
            actionprops={actionProps}
            actionsource={actionSourceContactUs}
            onChange={handleOnChange}
            activePath={pathname.split("/").filter((i) => i !== "")}
            AppHeaderProps={{
              appTitle: "Franchise Digital Account Opening",
              actionSource: !isAuthenticationScreen
                ? actionSourceContactUs
                : "",
              actionProps: actionProps,
              logoProps: { variant: "chase" },
            }}
            HorizontalNavigationProps={{ variant: "fullWidth" }}
          />
          <AppRoutes />
        </div>
      ) : (
        <StyledNavigation
          source={actionScreen}
          actionprops={actionProps}
          actionsource={actionSourceContactUs}
          onChange={handleOnChange}
          activePath={pathname.split("/").filter((i) => i !== "")}
          AppHeaderProps={{
            appTitle: "Franchise Digital Account Opening",
            actionSource: !isAuthenticationScreen ? actionSourceContactUs : "",
            actionProps: actionProps,
            logoProps: { variant: "chase" },
          }}
          HorizontalNavigationProps={{ variant: "fullWidth" }}
        >
          <AppRoutes />
        </StyledNavigation>
      )}
      <Footer
        role="contentinfo"
        isDynamicScreen={
          isDynamicScreen || isAdminScreen || isAuthenticationScreen
        }
      />
      {sessionDialogbox}
    </>
  );
};

export default React.memo(AppContainer);
