import React from 'react';

const ErrorBoundary = (WrapperComp) => {
    return class extends React.Component {
        constructor(props) {
            super(props);
            this.state = { hasError: false };
        }

        static getDerivedStateFromError(error) {
            return { hasError: true };
        }

        componentDidCatch(error, errorInfo) {
            console.log(error, errorInfo);
        }

        render() {
            if (this.state.hasError) {
                return (<div>
                    <h1>Something went wrong.</h1>
                </div>);
            }

            return <WrapperComp
                {...this.props}
            />
        }
    }
}

export default ErrorBoundary;