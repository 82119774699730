
import axios from 'axios';
import { FETCH_ACCOUNTANT_REQUEST, FETCH_ACCOUNTANT_SUCCESS,ACCOUNTANT_REQUEST,
    FETCH_ACCOUNTANT_FAILURE ,SAVE_ACCOUNTANT_FAILURE,SAVE_ACCOUNTANT_SUCCESS,SAVE_ACCOUNTANT_RECEIVE,
     base_url , base_url_user} from '../../../../global.constants';


export const fetchAccountant_request = (payload)=>{
   return {
       type: FETCH_ACCOUNTANT_REQUEST,
       payload
   }
}
export const fetchAccountant_success = ()=>{
    return {
        type: FETCH_ACCOUNTANT_SUCCESS,
    }
}
export const fetchAccountant_failure = (payload)=>{
    return {
        type: FETCH_ACCOUNTANT_FAILURE,
        payload 
    }
}

export const saveAccountant_receive = (payload)=>{
    return {
        type: SAVE_ACCOUNTANT_RECEIVE,
        payload 
    }
 }
export const saveAccountant_failure = (payload)=>{
    return {
        type: SAVE_ACCOUNTANT_FAILURE,
        payload 
    }
}

export const saveAccountant_success = ()=>{
    return {
        type: SAVE_ACCOUNTANT_SUCCESS,
    }
}

export const   getAccountantData = (loanId, userId) => async dispatch => {
const payload = {"loanId" : loanId,"userId": userId}
   

try {
    dispatch({type : ACCOUNTANT_REQUEST});
     await axios.post(
        base_url_user+`/api/v1/usersvc/ext/getAccountantInformation.do`, payload)
        .then(response => {
            if (response && response.status === 200) {
               dispatch(fetchAccountant_request(response.data));
            }
            else {
                 dispatch(fetchAccountant_failure(response.status))
            }
        });
}
catch (e) {
     dispatch(fetchAccountant_failure(e.response.status))
    return e;
}
};


export const saveAccountantData = (payload, loanId,userId) => async dispatch => { 

try {
    dispatch({ type: ACCOUNTANT_REQUEST});
      await axios.put(
        base_url_user+`/api/v1/usersvc/ext/saveAccountantInformation.do`, payload)
        .then(response => {
            if (response && response.status === 200) {
                dispatch(saveAccountant_receive())
            }
            else {
                dispatch(saveAccountant_failure(response.status))
            }
        });
 }
catch (e) {
    dispatch(saveAccountant_failure(e.response.status))
    return e;
}
};



