import React, { useEffect, useState } from "react"
import { SubmitRequestConstants ,ERRORCODE } from '../../global.constants'
import { OrderedButton, ButtonBar } from '@jpmuitk/button-bar';
import { Button } from '@jpmuitk/button';
import { Dialog,DialogTitle,DialogActions} from '@jpmuitk/dialog';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import Error from '../../../components/error/Error';
import Loader from '../../../components/loader/Loader';
import {submitRequestFinanceFranchies} from './redux/actions/submitRequestAction';
import {getPreviewPdf } from '../../commonactions/redux/actions/PreviewAction';
import HiddenValidator from '../../../containers/HiddenValidator';
import { formHas_error } from '../../dynamic/returnToDashboard/redux/action/dashboardAction';
import '../../global.scss';
const SubmitRequest = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const loanId = useSelector(state => state.DashboardReducer?.currentLoan);
    const isLoading = useSelector(state => state.SubmitRequestFinanceFranchiesReducer?.isLoading);
    const error = useSelector(state => state.SubmitRequestFinanceFranchiesReducer.error);
    const returnTodashboardflag = useSelector(state => state.DashboardReducer.returnTodashboardflag);
    const userId = useSelector(state => state.AuthCheckReducer?.loggedInUserId);
    const [showAlert, setShowAlert] = useState(false);
    const handleSubmit = (sameComp=true) => {
        setShowAlert(true);
        if(!sameComp) {
            dispatch(formHas_error(false));
            return false;
        }
        }
    const handleSubmitConfirmation = () => {
        if (loanId?.activeLoanId) {
            dispatch(submitRequestFinanceFranchies(loanId?.activeLoanId,userId))
            history.push('/dashboard')
            window.scrollTo(0, 0);
        }
       
    }
    const previousSection = () =>
    {
    if(returnTodashboardflag === true )
    {
        history.push('./bankersComments')
        window.scrollTo(0, 0);
    }
    else{
        history.push('./additionalComments')
        window.scrollTo(0, 0);
    }
}
    const handlePreview = ()=>{
        if (loanId?.activeLoanId) {
        dispatch(getPreviewPdf(loanId?.activeLoanId, userId))
    }}

    return (
        <>
        {isLoading ? <Loader /> : null}
        {error == ERRORCODE ? <Error/> :
        <div className='dynamicContainer' id="main" role="main">
            {showAlert && 
                <Dialog open={showAlert} className="dialogContainer">
                    <DialogTitle className="dialogcontentFont">{SubmitRequestConstants.submitAlert}</DialogTitle>
                    <DialogActions>
                        <Button className={`commonStylingButton`} onClick={handleSubmitConfirmation} variant="cta">{SubmitRequestConstants.OK} </Button>
                        <Button onClick={() => setShowAlert(false)}> {SubmitRequestConstants.Cancel} </Button>
                    </DialogActions>
                </Dialog>}
            <h1>{SubmitRequestConstants.header}</h1>
            <p>{SubmitRequestConstants.submitTerms1}</p>
            <p>{SubmitRequestConstants.submitTerms2}</p>
            <h2>{SubmitRequestConstants.section326}</h2>
            <h2>{SubmitRequestConstants.importantInfo}</h2>
            <p>{SubmitRequestConstants.complyInfo}</p>
            <h2>{SubmitRequestConstants.notice}</h2>
            <p>{SubmitRequestConstants.noticeInfo1}</p>
            <p>{SubmitRequestConstants.noticeInfo2}</p>
            <div className="buttonContainer1 marginTop30 ">
                <ButtonBar className="floatLeft" alignLeft>
                    <OrderedButton onClick={previousSection}>{SubmitRequestConstants.previousSection}</OrderedButton>
                </ButtonBar>
                <ButtonBar className="floatRight">
                    <OrderedButton className={`commonStylingButton`} variant="cta" onClick={handlePreview}>{SubmitRequestConstants.preview}</OrderedButton>
                    {!returnTodashboardflag ? <OrderedButton variant="cta" onClick={handleSubmit}>{SubmitRequestConstants.submitRequest}</OrderedButton> : null}
                    <HiddenValidator validateTrigger={() => handleSubmit(false)} labels={'next section'} />
                </ButtonBar>
            </div>
        </div>
      
            }
            </>
    )
}
export default SubmitRequest;
