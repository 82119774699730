import axios from "axios";
import {
  FETCH_PERVIEW_SUCCESS,
  FETCH_PERVIEW_FAILURE,
  FETCH_PERVIEW_REQUEST,
  base_url,
  base_url_user,
} from "../../../global.constants";
export const fetchPreview_request = () => {
  return {
    type: FETCH_PERVIEW_REQUEST,
  };
};

export const fetchPreview_success = (data) => {
  return {
    type: FETCH_PERVIEW_SUCCESS,
    payload: data,
  };
};
export const fetchPreview_filaure = (error) => {
  return {
    type: FETCH_PERVIEW_FAILURE,
    payload: error,
  };
};

export const getPreviewPdf = (loanId, userId) => {
  return async (dispatch) => {
    dispatch(fetchPreview_request());
    const Url = base_url_user + `/api/v1/usersvc/ext/getPreview.do`;

    axios
      .post(Url, { loanId: loanId, userId: userId }, { responseType: "blob" })
      .then((response) => {
        if (response && response?.status === 200) {
          const file = new Blob([response.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          dispatch(fetchPreview_success());
        } else {
          dispatch(fetchPreview_filaure(response.status));
        }
      })
      .catch((e) => {
        dispatch(fetchPreview_filaure(e?.response?.status));
      });
  };
};
