import {
  RECEIVE_CONTACT_GET_DATA,
  SUBMIT_CONTACT_DATA,
  RECEIVE_CONTACT_INFORMATION_REQUEST,
  FAILURE_CONTACT_GET_REQUEST,
  FAILURE_CONTACT_POST_REQUEST,
  base_url,
 base_url_user
} from "../../../../global.constants"
import axios from 'axios';

export const getContactInfoData = (loanId, userId) => async dispatch => {
  const payload = {"loanId" : loanId,"userId": userId}
  try {
    dispatch({ type: RECEIVE_CONTACT_INFORMATION_REQUEST });
    await axios.post(base_url_user+`/api/v1/usersvc/ext/getContactInfo.do`, payload)
      .then(response => {
        if (response && response?.status === 200) {
          dispatch(receiveContactData(response?.data));
        }
        else {
          dispatch(failureGetcontactData(response.status))
        }
      });
  }
  catch (e) {
    dispatch(failureGetcontactData(e.response.status))
    return e;
  }
};

export const saveContactInfoData = (responsedata, loanId,userId) =>  {
  return async(dispatch) => {
    dispatch({ type: RECEIVE_CONTACT_INFORMATION_REQUEST });
     axios.put(base_url_user + `/api/v1/usersvc/ext/saveContactInfo.do`, responsedata)
      .then(response => {
        if (response.status === 200) {
          dispatch(submitContactData(response?.data));
        }
        else {
          dispatch(failureContactPostData(response.status))
        }
      })
      .catch((e) => {
        dispatch(failureContactPostData(e.response.status))
      });
  }
};

export const failureGetcontactData = (payload) => ({
  type: FAILURE_CONTACT_GET_REQUEST,
  payload
})

export const failureContactPostData = (payload) => ({
  type: FAILURE_CONTACT_POST_REQUEST,
  payload
})
export const receiveContactData = (data) => {
  return {
    type: RECEIVE_CONTACT_GET_DATA,
    payload: data
  }
}


export const submitContactData = (data) => {
  return {
    type: SUBMIT_CONTACT_DATA,
    payload: data
  }
}


