import { RECEIVE_GET_MISCELLANEOUS_DATA, FAILURE_GET_MISCELLANEOUS_DATA, RECEIVE_MISCELLANEOUS_POST_DATA, FAILURE_MISCELLANEOUS_POST_DATA, FAILURE_TO_DELETE,REQUEST_GET_MISCELLANEOUS_DATA } from '../../../../global.constants'

const initialState = {
    miscellaneousData: {},
    isLoading: false,
    error: ''
}

const MiscellaneousDetailsReducer = (state = initialState, { type, payload } = {}) => {
                                                       
    switch (type) {
        case RECEIVE_GET_MISCELLANEOUS_DATA:
            const miscellaneousState = {
                ...state,
                miscellaneousData: payload,
                isLoading: false,
                error: ''
            }
            return miscellaneousState
        case FAILURE_GET_MISCELLANEOUS_DATA:
            const errorState = {
                ...state,
                isLoading: false,
                error: payload
            }
            return errorState
        case RECEIVE_MISCELLANEOUS_POST_DATA:
            const newState = {
                ...state,
                miscellaneousData: payload,
                isLoading: false
            }
            return newState
        case FAILURE_MISCELLANEOUS_POST_DATA:
            const errorPostState = {
                ...state,
                isLoading: false,
                error: payload
            }
            return errorPostState
            case REQUEST_GET_MISCELLANEOUS_DATA:
                const loadState = {
                    ...state,
                    isLoading: true
                }
                return loadState
        case FAILURE_TO_DELETE:
            return {
                stage: "failure"
            };
        default:
            return state
    }
}

export default MiscellaneousDetailsReducer;