import { REQUEST_REGION_POST_DATA, RECEIVE_REGION_POST_DATA, FAILURE_REGION_POST_DATA, REQUEST_REGIONINFO_DATA, RECEIVE_REGIONINFO_DATA, FAILURE_REGIONINFO_DATA,FETCH_REGION_REQUEST,FETCH_REGION_SUCCESS , FETCH_REGION_FAILURE,FETCH_REGIONINFO_REQUEST,SAVE_REGIONINFO_RECEIVE,FETCH_REGIONINFO_SUCCESS,SAVE_REGIONINFO_SUCCESS,SAVE_REGIONINFO_FAILURE,FETCH_REGIONINFO_FAILURE,REGION_REQUEST } from '../../../../global.constants'

const initialstate = {
  Regions:[],
  isLoading: false,
  error: ''
}

const RegionInformationReducer = (state = initialstate, { type, payload }={}) => {
  switch (type) {
    
    case FETCH_REGIONINFO_REQUEST:
      return Object.assign(state, { 
        Regions: payload,
        isLoading: false
      });

      case SAVE_REGIONINFO_RECEIVE:
      return Object.assign(state, { 
        Regions: payload,
        isLoading: false
      });


      case REGION_REQUEST:
        const loadState = {
            ...state,
            isLoading: true
        }
        return loadState
    case SAVE_REGIONINFO_SUCCESS:
    case FETCH_REGIONINFO_SUCCESS:
          return {
          stage : "success",
        
       };
  
  case SAVE_REGIONINFO_FAILURE:
  case FETCH_REGIONINFO_FAILURE:
    return Object.assign(state, { 
      isLoading: false,
      error: payload
  });


    default:
      return state
  }
}

export default RegionInformationReducer;