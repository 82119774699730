import axios from 'axios';
import {
  FETCH_RETURNTODASHBOARD_REQUEST,
  FETCH_RETURNTODASHBOARD_SUCCESS,
  FETCH_RETURNTODASHBOARD_FAILURE,
  CANCELLAONREQUEST_REQUEST,
  CANCELLAONREQUEST_SUCCESS,
  CANCELLAONREQUEST_FAILURE,
  CREATENEWREQUEST_FAILURE,
  RETURNTOADMINDASH,
  CURRENT_LOANID,
  CANCEL_LOANID,
  base_url_loan,
  FORM_HAS_ERROR
} from '../../../../global.constants';

export const formHas_error = (data) => ({type: FORM_HAS_ERROR, payload: data});

export const fetchReturntoDashboard_request = (data) => {
  return {
    type: FETCH_RETURNTODASHBOARD_REQUEST,
    payload: data
  }
}
export const fetchReturntoDashboard_success = (data) => {
  return {
    type: FETCH_RETURNTODASHBOARD_SUCCESS,
    payload: data
  }
}
export const fetchReturntoDashboard_failure = (error) => {
  return {
    type: FETCH_RETURNTODASHBOARD_FAILURE,
    payload: error
  }
}

export const currentLoanId = (currentloan) => {
  return {
    type: CURRENT_LOANID,
    payload: currentloan

  }
}

export const cancelLoanId = (cancelLoanId) => {
  return {
    type: CANCEL_LOANID,
    payload: cancelLoanId

  }
}

export const createNewRequest_failure = (data)=>{
  return {
    type: CREATENEWREQUEST_FAILURE,
    payload: data
  }
}

export const cancelLoanRequest_request = ()=>{
  return {
    type: CANCELLAONREQUEST_REQUEST,
  }
}

export const cancelLoanRequest_success = (data)=>{
  return {
    type: CANCELLAONREQUEST_SUCCESS,
    payload: data
  }
}

export const cancelLoanRequest_failure = ()=>{
  return {
    type: CANCELLAONREQUEST_FAILURE,
  }
}

export const returnTodashboardflag = (data) => {
  return {
    type: RETURNTOADMINDASH,
    payload: data
  }
}

export const fetchReturntoDashboard = (userId,page, isPagination, filter = 'All') => {
  const payload = {"userId": userId}
  const pageNumber = isPagination ? `&pageNo=${page}` : '';
  const filterNum = filter ? `?filter=${filter}` : '';
  localStorage.setItem('pageNo', parseInt(page) || 1)
  return (dispatch) => {
    dispatch(fetchReturntoDashboard_request())
     const Url =base_url_loan+`/api/v1/loansvc/ext/getReturnDashboard.do${filterNum}${pageNumber}`
    axios.post(Url, payload)
      .then(response => {
        if (response && response.status === 200) {
          const returndashboardpayload = response?.data
          dispatch(fetchReturntoDashboard_success(returndashboardpayload))
        }
        else {
          dispatch(fetchReturntoDashboard_failure(response.status))
        }

      })
      .catch((e) => {
        dispatch(fetchReturntoDashboard_failure(e.response?.status))
      });

  }
}

export const createNewRequest = (leftNavigation, userId) => {
  const payload = {"userId": userId}
  return (dispatch) => {
     const Url = base_url_loan+"/api/v1/loansvc/ext/createNewOnlineRequest.do";
    axios.post(Url, payload)
      .then(response => {
        if (response && response.status === 200) {
          const createnewrequestpayload = response?.data
          const currentloan = {"isLeftnavEnabled":leftNavigation, "activeLoanId": createnewrequestpayload?.loanId }
          dispatch(currentLoanId(currentloan))
        }
        else {
          dispatch(createNewRequest_failure(response.status))
        }

      })
      .catch((e) => {
        dispatch(createNewRequest_failure(e.response?.status))
      });

  }
}


export const cancelLoanRequest = (loanId, userId) => {
  const payload = {"loanId": loanId, "userId": userId}
  return (dispatch) => {
    dispatch(cancelLoanRequest_request())
    const Url =base_url_loan+`/api/v1/loansvc/ext/cancelLoanRequest.do`;
    axios.post(Url, payload)
      .then(response => {
        if (response && response.status === 200) {
          const cancelrequest = response?.data
          dispatch(cancelLoanRequest_success(localStorage.getItem("pageNo")))
        }
        else {
          dispatch(cancelLoanRequest_failure(response.status))
        }

      })
      .catch((e) => {
        dispatch(cancelLoanRequest_failure(e.response.status))
      });

  }
}
