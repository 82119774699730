import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Input } from "@jpmuitk/input";
import { Dropdown } from "@jpmuitk/dropdown";
import { FormattedInput } from "@jpmuitk/formatted-input";
import { OrderedButton, ButtonBar } from "@jpmuitk/button-bar";
import { DatePicker } from "@jpmuitk/date-picker";
import { ComboBox } from "@jpmuitk/combo-box";
import { AriaAnnouncerProvider } from "@jpmuitk/aria-announcer";
import { FormField, useValidation } from "@jpmuitk/form-field";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import { useHistory } from "react-router-dom";
import {
  ContactInfoConstants,
  ERRORCODE,
  Tooltip_info,
  Info_security,
  Info_act,
} from "../../global.constants";
import "react-dates/lib/css/_datepicker.css";
import "./ContactInfo.scss";
import {
  operatoridValidationRules,
  addressValidationRules,
  zipcodeValidationRules,
  ptelephoneValidationRules,
  mobilenumberValidationRules,
  socialsecurityValidationRules,
  reentersocialsecurityValidationRules,
  dateofbirthValidationRules,
  redateofbirthValidationRules,
} from "../../ValidationRules";
import DialogBox from "../../../components/dialogBox/DialogBox";
import {
  encrypt,
  decrypt,
} from "../../../components/encrydecryption/encrydecryption";
import Loader from "../../../components/loader/Loader";
import Error from "../../../components/error/Error";
import {
  getContactInfoData,
  saveContactInfoData,
} from "./redux/action/contactAction";
import {
  getCountrystatesData,
  getCountryNamesData,
} from "../../commonactions/redux/actions/action";
import { getNextNavigation } from "../../../components/navigationPath/navigationPath";
import HiddenValidator from "../../../containers/HiddenValidator";
import { formHas_error } from "../../dynamic/returnToDashboard/redux/action/dashboardAction";
const ContactDetail = () => {
  const parseDigits = (string) =>
    (string.match(ContactInfoConstants.digitsRegex) || []).join("");
  const mergedErrorMessages = (exceptions) =>
    exceptions.map(({ type, message }) => `${message}`).join("");
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const loanId = useSelector((state) => state.DashboardReducer?.currentLoan);
  const userId = useSelector((state) => state.AuthCheckReducer?.loggedInUserId);
  const contactInfodata = useSelector(
    (state) => state?.ContactInfoReducer?.contactinfoData
  );
  const isLoading = useSelector((state) => state.ContactInfoReducer.isLoading);
  const errors = useSelector((state) => state.ContactInfoReducer.error);
  const stateData = useSelector(
    (state) => state.CountrystatesReducer.Countrystates
  );
  const stateNames = stateData?.map((x) => x?.stateName);
  const [stateval, setStateval] = useState("");
  const initialStateVal =
    contactInfodata?.addressVO?.stateId !== null
      ? stateData?.find(
          (x) => x?.stateId === contactInfodata?.addressVO?.stateId
        )
      : stateNames[0];

  const countryData = useSelector(
    (state) => state.CountrystatesReducer.Countrynames
  );
  const countryNames = countryData?.map((x) => x?.countryName);
  const [countryval, setCountryval] = useState([]);
  const filteredCountry = countryData
    ?.filter((o1) =>
      contactInfodata?.citizenship
        ?.split(",")
        ?.some((o2) => o1?.countryId === parseInt(o2))
    )
    ?.map((x) => x?.countryName);

  const [operatorID, setOperatorID] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [phoneno, setPhoneno] = useState("");
  const [mobileno, setMobileno] = useState("");
  const [operatorIdExceptions, validateOperatorId] = useValidation(
    operatoridValidationRules
  );
  const [addressExceptions, validateAddress] = useValidation(
    addressValidationRules
  );
  const [zipcodeExceptions, validateZipcode] = useValidation(
    zipcodeValidationRules
  );
  const [ptelephoneExceptions, validatePtelephone] = useValidation(
    ptelephoneValidationRules
  );
  const [mobilenumberExceptions, validateMobilenumber] = useValidation(
    mobilenumberValidationRules
  );
  const [commonboolean, setCommonboolean] = useState(false);

  const current_year = new Date().getFullYear() - 83;
  const years = [...new Array(84)].map((ele, id) => {
    let yr = current_year + id;
    return {
      value: yr,
      text: String(yr),
      id: yr,
    };
  });

  useEffect(() => {
    if (loanId?.activeLoanId) {
      dispatch(getContactInfoData(loanId?.activeLoanId, userId));
      dispatch(getCountrystatesData());
      dispatch(getCountryNamesData());
    }
  }, [loanId]);

  useEffect(() => {
    if (contactInfodata) {
      if (!commonboolean) {
        setData(contactInfodata);
        setOperatorID(
          contactInfodata?.operatorId === null ||
            contactInfodata?.operatorId === undefined ||
            contactInfodata.length == 0
            ? ""
            : contactInfodata?.operatorId
        );
        setAddress(
          contactInfodata?.addressVO === null ||
            contactInfodata?.addressVO === undefined ||
            contactInfodata.length == 0
            ? ""
            : contactInfodata?.addressVO?.address
        );
        setCity(
          contactInfodata?.addressVO === null ||
            contactInfodata?.addressVO === undefined ||
            contactInfodata.length == 0
            ? ""
            : contactInfodata?.addressVO?.city
        );
        setZipcode(
          contactInfodata?.addressVO === null ||
            contactInfodata?.addressVO === undefined ||
            contactInfodata.length == 0
            ? ""
            : contactInfodata?.addressVO?.zipCode
        );
        setPhoneno(
          contactInfodata?.priResTelNo === null ||
            contactInfodata?.priResTelNo === undefined ||
            contactInfodata.length == 0
            ? ""
            : contactInfodata?.priResTelNo
        );
        setMobileno(
          contactInfodata?.mobileNo === null ||
            contactInfodata?.mobileNo === undefined ||
            contactInfodata.length == 0
            ? ""
            : contactInfodata?.mobileNo
        );
        setCountryval(
          contactInfodata?.citizenship === null ||
            contactInfodata?.citizenship === undefined ||
            contactInfodata.length == 0
            ? ""
            : filteredCountry
        );
        setStateval(
          contactInfodata?.addressVO === null ||
            contactInfodata?.addressVO === undefined ||
            contactInfodata.length == 0 ||
            contactInfodata?.addressVO?.stateId === null
            ? stateNames[0]
            : initialStateVal?.stateName
        );
      }
    }
  }, [contactInfodata]);

  const updateStore = () => {
    if (data && loanId?.activeLoanId !== undefined) {
      let copyData = data;
      copyData.loanId = loanId.activeLoanId;
      setData(copyData);
      dispatch(saveContactInfoData(data, loanId?.activeLoanId, userId));
    }
  };

  const formatZipCodeWithAppend = (string) => {
    const res = parseDigits(string)
      .split("")
      .reduce((r, v, index) => (index === 5 ? `${r}-${v}` : `${r}${v}`), "")
      .substr(0, 10);
    if (string.endsWith("-")) {
      if (res.length === 5) {
        return `${res}-`;
      }
    }
    return res;
  };
  const formatMobileValueWithAppend = (string) => {
    const res = parseDigits(string)
      .split("")
      .reduce(
        (r, v, index) =>
          index === 3 || index === 6 ? `${r}-${v}` : `${r}${v}`,
        ""
      )
      .substr(0, 12);
    if (string.endsWith("-")) {
      if (res.length === 3) {
        return `${res}-`;
      }

      if (res.length === 7) {
        return `${res}-`;
      }
    }
    return res;
  };

  //Handle Event
  const handleChange = (name, value, fieldname) => {
    setCommonboolean(true);
    if (name === "operatorId") {
      const re = /^[0-9\b]+$/;
      if (value === "" || re.test(value)) {
        //The first letter is not a zero
        if (/^0/.test(value)) {
          setData({ ...data, [name]: "" });
        } else {
          validateOperatorId([]);
          setData({ ...data, operatorId: value });
          setOperatorID(value);
        }
      }
    } else if (name === "address") {
      validateAddress([]);
      setData({
        ...data,
        addressVO: {
          ...data?.addressVO,
          [name]: value,
        },
      });
      setAddress(value);
    } else if (name === "city") {
      setData({
        ...data,
        addressVO: {
          ...data.addressVO,
          [name]: value,
        },
      });
      setCity(value);
    } else if (fieldname === "stateId") {
      //Based on stateId getting stateName
      const stateid = stateData.find(({ stateName }) => stateName == value);
      setStateval(value);
      setData({
        ...data,
        addressVO: {
          ...data?.addressVO,
          stateId: value != "Select" ? stateid?.stateId : null,
        },
      });
    } else if (fieldname === "citizenship") {
      setCountryval(value);
      var countryNametoId = countryData
        ?.filter((o1) => value?.some((o2) => o1.countryName === o2))
        ?.map((x) => x?.countryId)
        .toString();
      setData({ ...data, citizenship: countryNametoId });
    }
    if (name == "zipCode") {
      validateZipcode([]);
      setData({ ...data, addressVO: { ...data?.addressVO, zipCode: value } });
      setZipcode(value);
    } else if (name == "phoneNo") {
      validatePtelephone([]);
      setData({ ...data, priResTelNo: value });
      setPhoneno(value);
    } else if (name == "mobileNumber") {
      validateMobilenumber([]);
      setData({ ...data, mobileNo: value });
      setMobileno(value);
    }
  };

  const handleNextClick = (sameComp = true) => {
    if (city.length !== 0 || zipcode.length !== 0 || stateval !== "Select") {
      if (address.length === 0) {
        validateAddress("");
      } else {
        validateAddress(ContactInfoConstants.addressNotEmpty);
        finalData(sameComp);
      }
    } else if (
      city.length === 0 &&
      zipcode.length === 0 &&
      stateval === "Select"
    ) {
      validateAddress(ContactInfoConstants.addressNotEmpty);
      finalData(sameComp);
    }
  };

  const finalData = (sameComp) => {
    updateStore();
    if (sameComp) {
      history.push(getNextNavigation());
      window.scrollTo(0, 0);
    } else {
      history.push(window.location.href);
      dispatch(formHas_error(false));
      return false;
    }
  };
  const closeDialog = (value) => {
    setOpen(value);
  };
  const onBlurEvent = (name, event) => {
    if (name === "operatorId") {
      validateOperatorId(event.target.value);
    } else if (name === "zipCode") {
      if (event.target.value.length != 10 && event.target.value.length != 5) {
        validateZipcode(event.target.value);
      } else {
        validateZipcode([]);
      }
    } else if (name === "phoneNo") {
      validatePtelephone(event.target.value);
    } else if (name === "mobileNumber") {
      validateMobilenumber(event.target.value);
    }
  };
  const dialogBoxOpen = open ? (
    <DialogBox id="contact" open={open} closeDialog={closeDialog}></DialogBox>
  ) : (
    ""
  );

  return (
    <>
      {isLoading ? <Loader /> : null}
      {errors == ERRORCODE ? (
        <Error />
      ) : (
        <div className="dynamicContainer" id="main" role="main">
          <div className="contact-form contactformClass">
            <h1 className="contactheadingClass">
              {ContactInfoConstants.heading}
            </h1>
            <Grid item xs={12}>
              <div className="spaceText">
                <FormField label={ContactInfoConstants.fullname}>
                  <Input
                    readOnly
                    value={data?.franchiseName}
                    onClick={(e) => setOpen(true)}
                    className="textFont"
                  />
                </FormField>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="spaceText">
                <FormField
                  label={ContactInfoConstants.operator}
                  classes={{ helperText: "errorHelperText" }}
                  helperText={mergedErrorMessages(operatorIdExceptions)}
                  validationState={operatorIdExceptions[0]?.type}
                >
                  <Input
                    inputProps={{ maxLength: 10 }}
                    className="textFont"
                    name="operatorId"
                    value={operatorID || ""}
                    onBlur={(e) => onBlurEvent("operatorId", e)}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                  />
                </FormField>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="spaceText">
                <FormField
                  label={ContactInfoConstants.primary}
                  classes={{ helperText: "errorHelperText" }}
                  helperText={
                    mergedErrorMessages(addressExceptions) ||
                    ContactInfoConstants.placeholderText
                  }
                  validationState={addressExceptions[0]?.type}
                  helperTextPlacement={
                    !mergedErrorMessages(addressExceptions) && "tooltip"
                  }
                  hasStatusIndicator={true}
                >
                  <Input
                    className="textFont"
                    name="address"
                    value={address || ""}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                    multiline
                    role="tooltip"
                    aria-label={Tooltip_info}
                  />
                </FormField>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="spaceText">
                <FormField label={ContactInfoConstants.city}>
                  <Input
                    type="text"
                    className="textFont"
                    name="city"
                    value={city || ""}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                  />
                </FormField>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="spaceText">
                <FormField label={ContactInfoConstants.state}>
                  <Dropdown
                    name="stateId"
                    id="statedropDownField"
                    onChange={(e, selectedItem) => {
                      handleChange(e, selectedItem, "stateId");
                    }}
                    source={stateNames}
                    selectedItem={stateval || ""}
                  />
                </FormField>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="spaceText">
                <FormField
                  label={ContactInfoConstants.zip}
                  classes={{ helperText: "errorHelperText" }}
                  helperText={mergedErrorMessages(zipcodeExceptions)}
                  validationState={zipcodeExceptions[0]?.type}
                >
                  <FormattedInput
                    name="zipCode"
                    className="textFont"
                    value={zipcode || ""}
                    onBlur={(e) => onBlurEvent("zipCode", e)}
                    onChange={(value) => {
                      handleChange("zipCode", value);
                    }}
                    rifmOptions={{
                      accept: ContactInfoConstants.mobileRegex,
                      mask: (value) => 10 >= value.length,
                      format: formatZipCodeWithAppend,
                      append: (string) =>
                        string.length === 5 ? `${string}` : string,
                    }}
                  />
                </FormField>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="spaceText">
                <FormField
                  label={ContactInfoConstants.telephone}
                  classes={{ helperText: "errorHelperText" }}
                  helperText={mergedErrorMessages(ptelephoneExceptions)}
                  validationState={ptelephoneExceptions[0]?.type}
                >
                  <FormattedInput
                    name="phoneNo"
                    className="textFont"
                    value={phoneno || ""}
                    onBlur={(e) => onBlurEvent("phoneNo", e)}
                    onChange={(value) => {
                      handleChange("phoneNo", value);
                    }}
                    rifmOptions={{
                      accept: ContactInfoConstants.mobileRegex,
                      mask: (value) => 12 >= value.length,
                      format: formatMobileValueWithAppend,
                      append: (string) =>
                        string.length === 4 || string.length === 8
                          ? `${string}-`
                          : string,
                    }}
                  />
                </FormField>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="spaceText">
                <FormField
                  label={ContactInfoConstants.mobile}
                  classes={{ helperText: "errorHelperText" }}
                  helperText={mergedErrorMessages(mobilenumberExceptions)}
                  validationState={mobilenumberExceptions[0]?.type}
                >
                  <FormattedInput
                    name="mobileNumber"
                    className="textFont"
                    value={mobileno || ""}
                    onBlur={(e) => onBlurEvent("mobileNumber", e)}
                    onChange={(value) => {
                      handleChange("mobileNumber", value);
                    }}
                    rifmOptions={{
                      accept: ContactInfoConstants.mobileRegex,
                      mask: (value) => 12 >= value.length,
                      format: formatMobileValueWithAppend,
                      append: (string) =>
                        string.length === 4 || string.length === 8
                          ? `${string}-`
                          : string,
                    }}
                  />
                </FormField>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="spaceText">
                <FormField label={ContactInfoConstants.email}>
                  <Input
                    className="textFont"
                    readOnly
                    name="emailId"
                    value={data?.emailId}
                    onClick={(e) => setOpen(true)}
                  />
                </FormField>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="spaceText">
                <AriaAnnouncerProvider>
                  <FormField label={ContactInfoConstants.country}>
                    <ComboBox
                      className="fullwidthCountry textFont textBold"
                      selectedItem={countryval || []}
                      multiSelect
                      onChange={(_, item) => {
                        handleChange(_, item, "citizenship");
                      }}
                      source={countryNames}
                    />
                  </FormField>
                </AriaAnnouncerProvider>
              </div>
            </Grid>
          </div>
          <p className="noteSectionClass">{ContactInfoConstants.noteText}</p>
          <ButtonBar className="noteInfoClass">
            <OrderedButton onClick={handleNextClick} variant="cta">
              {ContactInfoConstants.next}
            </OrderedButton>
            <HiddenValidator
              validateTrigger={() => handleNextClick(false)}
              labels={ContactInfoConstants.next}
            />
          </ButtonBar>
          {dialogBoxOpen}
        </div>
      )}
    </>
  );
};
export default React.memo(ContactDetail);
