import React, { useState, useEffect, useCallback } from "react";
import { FormField, useValidation } from "@jpmuitk/form-field";
import Grid from "@material-ui/core/Grid";
import { Form } from "@jpmuitk/forms";
import { Input } from "@jpmuitk/input";
import { Button } from "@jpmuitk/button";
import { useSelector, useDispatch } from "react-redux";
import dynamicForm from "../../../components/form/DynamicForm";
import DebtDetails from "./DebtDetails";
import { updateData, getData, postData } from "./redux/action";
import Error from "../../../components/error/Error";
import Loader from "../../../components/loader/Loader";
import DialogBox from "../../../components/dialogBox/DialogBox";
import { DebtSummaryLabel, ERRORCODE } from "../../global.constants";
import "./DebtDetails.scss";
import "../../global.scss";
import ErrorBoundary from "../../../components/form/ErrorBoundary";
import { useHistory } from "react-router-dom";
import {
  getNextNavigation,
  getPreviousNavigation,
} from "../../../components/navigationPath/navigationPath";
import HiddenValidator from "../../../containers/HiddenValidator";
import { formHas_error } from "../../dynamic/returnToDashboard/redux/action/dashboardAction";

const DebtSummary = (props) => {
  const { createInitialList, addNewItem, removeAnItem } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const debtDetailsData = useSelector(
    (state) => state?.debtReducer?.debtDetails
  );
  const isLoading = useSelector((state) => state.debtReducer.isLoading);
  const error = useSelector((state) => state.debtReducer.error);
  const userId = useSelector((state) => state.AuthCheckReducer.loggedInUserId);
  const [debtDetails, setDebtDetails] = useState(debtDetailsData);
  const [openModal, setopenModal] = useState(false);
  const loanId = useSelector((state) => state.DashboardReducer?.currentLoan);

  // fetch fresh debtdetails data on mount
  useEffect(() => {
    if (loanId?.activeLoanId) {
      dispatch(
        getData(`getCurrentDebtSummary.do`, loanId?.activeLoanId, userId)
      );
    }
  }, []);

  useEffect(() => {
    setDebtDetails(debtDetailsData);
  }, [debtDetailsData]);

  const updateStore = () => {
    let data = debtDetails.filter((ele, i) => ele !== undefined);
    data.forEach((loan) => (loan.userId = userId));
    if (loanId?.activeLoanId) {
      dispatch(
        postData(
          `saveCurrentDebtSummary.do`,
          data,
          userId,
          loanId?.activeLoanId
        )
      );
    }
  };

  const handleDebtSection = useCallback((e) => {
    let debtList = createInitialList(e?.target?.value, loanId?.activeLoanId);
    setDebtDetails([...debtList]);
  }, []);

  const handleAddNewDebt = useCallback(() => {
    let debtList = addNewItem(debtDetails, loanId?.activeLoanId);
    setDebtDetails([...debtList]);
  }, [debtDetails]);

  const handleRemoveDebtSection = useCallback(
    (id) => {
      let debtList = removeAnItem(id, debtDetails, loanId?.activeLoanId);
      setDebtDetails([...debtList]);
    },
    [debtDetails]
  );

  const updateDebtList = useCallback(
    (list, ind) => {
      debtDetails[ind] = list;
      setDebtDetails([...debtDetails]);
      if (loanId?.activeLoanId) {
        dispatch(updateData(debtDetails));
      }
    },
    [debtDetails]
  );

  const handleNextClick = (sameComp = true) => {
    updateStore();
    if (!sameComp) {
      dispatch(formHas_error(false));
      return false;
    }
    history.push(getNextNavigation());
    window.scrollTo(0, 0);
  };
  const handlePreviousClick = () => {
    updateStore();
    history.push(getPreviousNavigation());
    window.scrollTo(0, 0);
  };

  const closeDialog = (value) => {
    setopenModal(value);
  };
  const validDebt = debtDetails.filter((ele, i) => ele !== undefined).length;
  const showDialogBox = openModal ? (
    <DialogBox
      id="currentDebtSummary"
      open={openModal}
      closeDialog={closeDialog}
    ></DialogBox>
  ) : null;
  const debtAddButton = validDebt ? (
    <Button variant="cta" onClick={handleAddNewDebt} aria-live="polite">
      {" "}
      {DebtSummaryLabel.btnAddDebt}
    </Button>
  ) : null;
  const isDisable = validDebt ? true : false;
  const isvalidaDebt = validDebt == 0 ? "" : validDebt;
  const handleClick = () => {
    if (validDebt) {
      setopenModal(true);
    }
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      {error == ERRORCODE ? (
        <Error />
      ) : (
        <div className={`dynamicContainer`} id="main" role="main">
          <h1 className={`headingClass`}>{DebtSummaryLabel.header}</h1>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Form fullWidthFields className={`formClass`}>
                <FormField
                  disabled={isDisable}
                  label={DebtSummaryLabel.noOfExistingLoan}
                  onClick={handleClick}
                >
                  <Input
                    name="debtListValue"
                    fullWidth
                    autoComplete="off"
                    value={isvalidaDebt}
                    onChange={handleDebtSection}
                  />
                </FormField>
              </Form>
              {/* create dynamic debt form */}
              {debtDetails.map((debt, ind) => {
                if (debt !== undefined) {
                  return (
                    <DebtDetails
                      key={ind}
                      ind={ind}
                      removeDebt={handleRemoveDebtSection}
                      updateRoot={updateDebtList}
                      defaultValue={JSON.stringify(debt)}
                    />
                  );
                }
              })}
              <div className={`footerContainer`}>{debtAddButton}</div>
              <p className={"debt-noteSection"}>{DebtSummaryLabel.note}</p>
              <div className={`headerContainer`}>
                <Button onClick={handlePreviousClick}>previous section</Button>
                <Button onClick={handleNextClick} variant="cta">
                  next section
                </Button>
                <HiddenValidator
                  validateTrigger={() => handleNextClick(false)}
                  labels={"next section"}
                />
              </div>
            </Grid>
          </Grid>
          {showDialogBox}
        </div>
      )}
    </>
  );
};

const initDebt = {
  loanId: "",
  debtId: "",
  lender: "",
  loanid: "",
  originalNoteDate: "",
  maturityDate: "",
  originalAmount: "",
  outstandingAmount: "",
  interestRate: "",
  monthlyPayment: "",
  commentId: "",
  commentVO: { commentId: "", comments: "" },
};

const dynamicFormProps = {
  initObj: { ...initDebt },
  idKey: "loanId",
};

export default dynamicForm(ErrorBoundary(DebtSummary), dynamicFormProps);
