import axios from "axios";
import {
  base_url_user,
  FETCH_RESTAURANTINFO_FAILURE,
  FETCH_RESTAURANTINFO_REQUEST,
  FETCH_RESTAURANTINFO_SUCCESS,
} from "../../../global.constants";

export const setData = (payload) => ({
  type: "SET_REST_DETAILS",
  payload,
});

export const updateData = (payload) => ({
  type: "UPDATE_REST_DETAILS",
  payload,
});

export const receiveUpdateStakeHolder = (payload) => ({
  type: "UPDATE_STAKE_HOLDER",
  payload,
});

export const dataStoreNext = (payload) => ({
  type: "DATA_STORED",
  payload,
});

export const fetchRestaurantInfo_request = () => {
  return {
    type: FETCH_RESTAURANTINFO_REQUEST,
  };
};

export const fetchRestaurantInfo_success = (data) => {
  return {
    type: FETCH_RESTAURANTINFO_SUCCESS,
    payload: data,
  };
};

export const fetchRestaurantInfo_failure = (error) => {
  return {
    type: FETCH_RESTAURANTINFO_FAILURE,
    payload: error,
  };
};
