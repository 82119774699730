import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@jpmuitk/theme';
import { Button } from '@jpmuitk/button';
import { ColumnLayout } from '@jpmuitk/column-layout';
import { Card } from '@jpmuitk/card';
import { Panel } from '@jpmuitk/panel';

import AppNav from './AppNav';

const style = ({ toolkit: { spacing } }) => {
  const spacingMixin = spacing.medium.spacing;
  return {
    button: {
      marginRight: spacingMixin(0.5),
    },
    card: {
      marginBottom: spacingMixin(2),
    },
    panel: {
      padding: spacingMixin(1),
      textAlign: 'center',
    },
    spinner: {
      display: 'inline-block',
    },
  };
};

const App = ({ classes }) => (
  <ColumnLayout container>
    <AppNav />
    <ColumnLayout item xs={12}>
      <Panel className={classes.panel}>
        <Card className={classes.card}>
          <div>
            <h1>Welcome to your new app!</h1>
            <br />
            <hr />
            <a
              href="https://go.jpmchase.net/uitoolkit/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Button className={classes.button}>Website</Button>
            </a>
            <a
              href="https://go.jpmchase.net/uitoolkit/documentation"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Button className={classes.button}>Documentation</Button>
            </a>
            <a
              href="https://go.jpmchase.net/uitoolkit/help"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Button className={classes.button}>Help</Button>
            </a>
            <a
              href="https://go.jpmchase.net/uitoolkit/releasenotes"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Button className={classes.button}>Release Notes</Button>
            </a>
          </div>
        </Card>
        <Card className={classes.card}>
          <div>
            <h2>Stack Overflow</h2>
            <p>
              Ask your questions on J.P. Morgan's Stack Overflow using the
              `jpm-ui-toolkit` hashtag:
            </p>
            <a
              href="https://jpmc.stackenterprise.co/questions/tagged/168"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Button>#jpm-ui-toolkit</Button>
            </a>
          </div>
        </Card>
        <Card className={classes.card}>
          <div>
            <h2>Symphony</h2>
            <p>
              You can message the JPM UI Toolkit team and engage with its users
              on the `JPM UI Toolkit Community`
            </p>
            <Button href="symphony:?streamId=J9fVZUa1ZI8tGpxpnBtV43___p8jUWNZdA&streamType=chatroom">
              JPM UI Toolkit Community
            </Button>
          </div>
        </Card>
      </Panel>
    </ColumnLayout>
  </ColumnLayout>
);

App.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

export default withStyles(style)(App);
