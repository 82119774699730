
import {
   FETCH_ACCOUNTANT_REQUEST, FETCH_ACCOUNTANT_SUCCESS, ACCOUNTANT_REQUEST,
   FETCH_ACCOUNTANT_FAILURE, SAVE_ACCOUNTANT_RECEIVE, SAVE_ACCOUNTANT_SUCCESS, SAVE_ACCOUNTANT_FAILURE,
} from '../../../../global.constants';


const initialState = {
   AccountantInfoData: [],
   isLoading: false,
   error: ''
}


const AccountantInformationReducer = (state = initialState, { type, payload } = {}) => {
   switch (type) {
      case FETCH_ACCOUNTANT_REQUEST:
         return Object.assign(state, {
            AccountantInfoData: payload,
            isLoading: false
         });

      case SAVE_ACCOUNTANT_RECEIVE:
         return Object.assign(state, {
            AccountantInfoData: payload,
            isLoading: false
         });

      case ACCOUNTANT_REQUEST:
         const loadState = {
            ...state,
            isLoading: true
         }
         return loadState
      case SAVE_ACCOUNTANT_SUCCESS:
      case FETCH_ACCOUNTANT_SUCCESS:
         return {

            stage: "success",

         };
      case SAVE_ACCOUNTANT_FAILURE:
      case FETCH_ACCOUNTANT_FAILURE:
         return Object.assign(state, {
            isLoading: false,
            error: payload
         });







      default:
         return state;
   }
};
export default AccountantInformationReducer;