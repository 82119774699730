import axios from 'axios';
import {
   FETCH_FINANCE_REQUEST,
   FETCH_FINANCE_SUCCESS,
   FETCH_FINANCE_FAILURE,
   SUCCESS_BANNER,
   base_url,
   base_url_loan
 
} from '../../../../global.constants';

export const fetchRequestFinance_request = (data) => {
    return {
      type: FETCH_FINANCE_REQUEST,
      payload: data
    }
  }


  export const fetchRequestFinance_success = (data) => {
    return {
      type: FETCH_FINANCE_SUCCESS,
      payload: data
    }
  }

  export const fetchRequestFinance_failure = (data) => {
    return {
      type: FETCH_FINANCE_FAILURE,
      payload: data
    }
  }


  export const successBanner = (isBanner) => {
    return {
      type: SUCCESS_BANNER,
      payload: isBanner
  
    }
  }



export const submitRequestFinanceFranchies = (loanId,userId) => {
  const payload = {"loanId": loanId,"userId": userId}

    return  async (dispatch) => {
       dispatch(fetchRequestFinance_request())
      const Url =  base_url_loan+`/api/v1/loansvc/ext/changeStatus.do`;
     axios.post(Url, payload)
     .then(response => {
        if (response && response?.status === 200){
        const  submitpayload = response?.data  
        dispatch(fetchRequestFinance_success(submitpayload))
        dispatch(successBanner(true))
        }
        else{
           dispatch(fetchRequestFinance_failure(response.status))
        }
     })
     .catch((e)=> {
       dispatch(fetchRequestFinance_failure(e.response.status))
     }); 
      
    }
   };