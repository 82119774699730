import { 
    POST_REGISTRATION_DATA,
    RECEIVE_REGISTRATION_INFORMATION_REQUEST,
    FAILURE_REGISTRATION_POST_REQUEST,ERRROR_DATA,
     } from '../../../../global.constants';

  const initialState = {
    submissionRegistartionData:[],
    isLoading : false,
    error:'',
    errorMessage:''
  }
  const NewRegistrationReducer = (state = initialState,{type, payload }={}) => {
    switch (type) {
      case RECEIVE_REGISTRATION_INFORMATION_REQUEST:
        return Object.assign(state, {
          isLoading: true,
        });
      case FAILURE_REGISTRATION_POST_REQUEST:
        return Object.assign(state, {
          isLoading: false,
          error: payload
      });

      case ERRROR_DATA:
        return Object.assign(state, {
          errorMessage:payload,
          isLoading: false
      });

      case POST_REGISTRATION_DATA:
        return Object.assign(state, {
          submissionRegistartionData:payload,
          isLoading: false,
          errorMessage:''
        }); 
      default:
        return state;
    }
  };
  
  export default NewRegistrationReducer;
  