import React from "react";
import Footer from "../../../components/footer/footer";
import "./InterestRateOptions.scss";
import { InterestRateOption, CONTACT_US_BANKER } from "../../static.constants";
import { Link } from "@jpmuitk/link";
const InterestRateOptions = () => {
  return (
    <div className="container" id="main">
      <h1>{InterestRateOption.hh}</h1>
      <p>
        {InterestRateOption.headerpara1}{" "}
        <Link href={CONTACT_US_BANKER.target_link}>
          {CONTACT_US_BANKER.heading_link}
        </Link>
        {InterestRateOption.headerpara2}
      </p>
      <div className="table-divs">
        <table className="staticTable">
          <thead>
            <tr>
              <th></th>
              <th>{InterestRateOption.th1}</th>
              <th>{InterestRateOption.th2}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{InterestRateOption.col01}</td>
              <td>
                <ul>
                  <li>{InterestRateOption.Multiple} </li>
                  <li>{InterestRateOption.Term}</li>
                  <li> {InterestRateOption.Working}</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>{InterestRateOption.Term2}</li>
                  <li>{InterestRateOption.Term3}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>{InterestRateOption.Payments}</td>
              <td>
                <ul>
                  <li>
                    {InterestRateOption.Multiple2}
                    <ol>
                      <li>{InterestRateOption.Level1}</li>
                      <li>{InterestRateOption.also}</li>
                    </ol>
                  </li>
                  <li>{InterestRateOption.Working}</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>{InterestRateOption.Leve2}</li>
                  <li>{InterestRateOption.also1}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>{InterestRateOption.prepayment}</td>
              <td>
                <ul>
                  <li>
                    {InterestRateOption.Multiple3}
                    <ol>
                      <li>{InterestRateOption.you}</li>
                    </ol>
                  </li>
                  <li>{InterestRateOption.Working2}</li>
                </ul>
              </td>
              <td>{InterestRateOption.you2}</td>
            </tr>
            <tr>
              <td>{InterestRateOption.conversion}</td>
              <td>
                <ul>
                  <li>
                    {InterestRateOption.Multiple4}
                    <ol>
                      <li>{InterestRateOption.please}</li>
                    </ol>
                  </li>
                  <li>{InterestRateOption.Working3}</li>
                </ul>
              </td>
              <td>{InterestRateOption.you3}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InterestRateOptions;
