import { MyProfileConstants } from "../containers/global.constants";
export const firstNameValidationRules = [
  {
    type: "warning",
    validate: (value) => value !== "",
    breakOnException: true,
    getMessage: () => "First name cannot be empty.",
  },
  {
    type: "warning",
    validate: (value) => value?.length <= 50,
    breakOnException: true,
    getMessage: () =>
      "First Name must be less than or equal to 50 alpha characters and only allows . , ~ - as special characters.",
  },
  {
    type: "warning",
    validate: (value) => /^[a-zA-Z\s.,~-]*$/.test(value),
    breakOnException: true,
    getMessage: () =>
      "First Name must be less than or equal to 50 alpha characters and only allows . , ~ - as special characters.",
  },
];
export const lastNameValidationRules = [
  {
    type: "warning",
    validate: (value) => value !== "",
    breakOnException: true,
    getMessage: () => "Last name cannot not be empty.",
  },
  {
    type: "warning",
    validate: (value) => value?.length <= 50,
    breakOnException: true,
    getMessage: () =>
      "Last Name must be less than or equal to 50 alpha characters and only allows . , ~ - as special characters..",
  },
  {
    type: "warning",
    validate: (value) => /^[a-zA-Z\s.,~-]*$/.test(value),
    breakOnException: true,
    getMessage: () =>
      "Last Name must be less than or equal to 50 alpha characters and only allows . , ~ - as special characters.",
  },
];
export const emailValidationRules = [
  {
    type: "warning",
    validate: (value) => value !== "",
    breakOnException: true,
    getMessage: () => "E-mail address cannot be empty",
  },
  {
    type: "warning",
    validate: (value) =>
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      ),
    breakOnException: true,
    getMessage: () => "Invalid E-mail Address",
  },
];
export const reEnteredEmailValidationRules = [
  {
    type: "warning",
    validate: (email, reemail) => email === reemail,
    breakOnException: true,
    getMessage: () => "E-mail Address does not match.",
  },
];

export const businessAddressInfoRules = [
  {
    type: "warning",
    validate: (value) => value != "",
    breakOnException: true,
    getMessage: () => "Address is mandatory",
  },
];

export const businessZipCodeRules = [
  {
    type: "",
    validate: (value) => value !== "",
    breakOnException: true,
    getMessage: () => "",
  },
  {
    type: "warning",
    validate: (value) => value.length <= 0 || value.length >= 10,
    breakOnException: true,
    getMessage: () => "Please enter valid Zip Code",
  },
];

export const businessOfficeZipCodeRules = [
  {
    type: "",
    validate: (value) => value !== "",
    breakOnException: true,
    getMessage: () => "",
  },
  {
    type: "warning",
    validate: (value) => value.length <= 0 || value.length >= 10,
    breakOnException: true,
    getMessage: () => "Please enter valid Zip Code",
  },
];

export const businessemailValidationRules = [
  {
    type: "",
    validate: (value) => value !== "",
    breakOnException: true,
    getMessage: () => "",
  },
  {
    type: "warning",
    validate: (value) =>
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      ),
    breakOnException: true,
    getMessage: () => "Please enter valid E-mail Address",
  },
];
export const businessphoneValidationRules = [
  {
    type: "warning",
    validate: (value) => value.length <= 0 || value.length >= 12,
    breakOnException: true,
    getMessage: () => "Please enter valid Phone number",
  },
];
export const businessOfficephoneValidationRules = [
  {
    type: "warning",
    validate: (value) => value.length <= 0 || value.length >= 12,
    breakOnException: true,
    getMessage: () => "Please enter valid Telephone Number",
  },
];

export const businessOtherOfficephoneValidationRules = [
  {
    type: "warning",
    validate: (value) => value.length >= 12,
    breakOnException: true,
    getMessage: () => "Please enter valid Telephone number",
  },
];

export const businessExecutiveAddressInfoRules = [
  {
    type: "warning",
    validate: (value) => value != "",
    breakOnException: true,
    getMessage: () => "Address is mandatory",
  },
];

export const regionValidationRules = [
  {
    type: "warning",
    validate: (value) => value !== MyProfileConstants.SELECT,
    breakOnException: true,
    getMessage: () => "Please enter the MC Donalds region",
  },
];

export const operatoridValidationRules = [
  {
    type: "warning",
    validate: (value) => value.length <= 0 || value.length >= 10,
    breakOnException: true,
    getMessage: () => `Please enter the valid Operator ID`,
  },
];

export const addressValidationRules = [
  {
    type: "error",
    validate: (value) => value !== "",
    breakOnException: true,
    getMessage: () => "Home Address is mandatory",
  },
];
export const restaurantAddressValidationRules = [
  {
    type: "error",
    validate: (value) => value !== "",
    breakOnException: true,
    getMessage: () => "Restaurant Address is mandatory",
  },
];
export const mgmtCompanyNameValidationRules = [
  {
    type: "error",
    validate: (value) => value !== "",
    breakOnException: true,
    getMessage: () => "Company or Individual name is mandatory",
  },
];
export const zipcodeValidationRules = [
  {
    type: "warning",
    validate: (value) => value.length <= 0 || value.length >= 10,
    breakOnException: true,
    getMessage: () => `Please enter valid Zipcode`,
  },
];
export const ptelephoneValidationRules = [
  {
    type: "warning",
    validate: (value) => value.length <= 0 || value.length >= 12,
    breakOnException: true,
    getMessage: () => `Please enter valid Residence Telephone number`,
  },
];
export const mobilenumberValidationRules = [
  {
    type: "warning",
    validate: (value) => value.length <= 0 || value.length >= 12,
    breakOnException: true,
    getMessage: () => `Please enter valid Mobile Telephone number`,
  },
];

export const socialsecurityValidationRules = [
  {
    type: "warning",
    validate: (value) => value !== "",
    breakOnException: true,
    getMessage: () => "Social Security Number is mandatory.",
  },
  {
    type: "warning",
    validate: (value) => value.length >= 11,
    breakOnException: true,
    getMessage: () => "Please enter valid Social Security Number",
  },
];

export const routingValidationRules = [
  {
    type: "",
    validate: (value) => value !== "",
    breakOnException: true,
    getMessage: () => "",
  },
  {
    type: "",
    validate: (value) => value.length >= 9,
    breakOnException: true,
    getMessage: () => "Please enter 9 digits",
  },
];

export const reentersocialsecurityValidationRules = [
  {
    type: "warning",
    validate: (value) => value !== "",
    breakOnException: true,
    getMessage: () => "Confirm Social Security Number is mandatory.",
  },
  {
    type: "warning",
    validate: (value) => value.length >= 11,
    breakOnException: true,
    getMessage: () => "Please enter valid Re-enter Social Security Number",
  },
];

export const dateofbirthValidationRules = [
  {
    type: "warning",
    validate: (value) => value !== "",
    breakOnException: true,
    getMessage: () => "Date of Birth is mandatory.",
  },
  {
    type: "warning",
    validate: (value) => value.length >= 10,
    breakOnException: true,
    getMessage: () => "Date of birth should be in mm/dd/yyyy format",
  },
];

export const redateofbirthValidationRules = [
  {
    type: "warning",
    validate: (value) => value !== "",
    breakOnException: true,
    getMessage: () => "Re-enter Date of Birth is mandatory",
  },
  {
    type: "warning",
    validate: (value) => value.length >= 10,
    breakOnException: true,
    getMessage: () => "Re-enter Date of birth should be in mm/dd/yyyy format",
  },
];

export const AccountemailValidationRules = [
  {
    type: "",
    validate: (value) => value !== "",
    breakOnException: true,
    getMessage: () => "",
  },

  {
    type: "warning",
    validate: (value) =>
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      ),
    breakOnException: true,
    getMessage: () => "Invalid E-mail Address",
  },
];
export const AccountmobilenumberValidationRules = [
  {
    type: "",
    validate: (value) => value.length != 0,
    breakOnException: true,
    getMessage: () => ``,
  },
  {
    type: "warning",
    validate: (value) => value.length >= 12,
    breakOnException: true,
    getMessage: () => `Please enter valid Mobile number`,
  },
];

export const AccountnumberValidationRules = [
  {
    type: "",
    validate: (value) => value === "",
    breakOnException: true,
    getMessage: () => "",
  },
  {
    type: "",
    validate: (value) => value.length,
    breakOnException: true,
    getMessage: () => "",
  },
];

export const ReAccountnumberValidationRules = [
  {
    type: "",
    validate: (value) => value !== "",
    breakOnException: true,
    getMessage: () => "",
  },
  {
    type: "",
    validate: (value) => value.length,
    breakOnException: true,
    getMessage: () => "Account does not match",
  },
];
