import {
  RECEIVE_UPDATE_CURRENT_GET_DATA,
  RECEIVE_UPDATE_CURRENT_SAVE_NEWFLASH_SUCCESS_DATA,
  FAILURE_UPDATE_GET_REQUEST,
  FAILURE_UPDATE_POST_REQUEST,
  ENDPOINT_SPINNER_UPDATE_CURRENT,
} from "../../../../global.constants";
const initialState = {
  updateCurrentData: [],
  statusMessage: "",
  submittednewFlashData: "",
  isLoading: false,
  error: "",
};
const UpdateCurrentReducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case RECEIVE_UPDATE_CURRENT_GET_DATA:
      return Object.assign(state, {
        isLoading: false,
        updateCurrentData: payload,
      });
    case FAILURE_UPDATE_POST_REQUEST:
    case FAILURE_UPDATE_GET_REQUEST:
      return Object.assign(state, {
        isLoading: false,
        error: payload,
      });
    case RECEIVE_UPDATE_CURRENT_SAVE_NEWFLASH_SUCCESS_DATA:
      return Object.assign(state, {
        statusMessage: "success",
        submittednewFlashData: payload,
        isLoading: false,
      });
    case ENDPOINT_SPINNER_UPDATE_CURRENT:
      return Object.assign(state, {
        isLoading: true,
      });
    default:
      return state;
  }
};

export default UpdateCurrentReducer;
