import React from "react"
import {HEADER1,CONTENT1,CONTENT2,HEADER2,CONTENT3,QUALITYDATA,
    SERVICEDATA,CREATIVITYDATA,VALUEDATA,CONTENT4} from "../../static.constants"
export default function HomeFind(){
    return(
        <div className="container" id="main">
            <h1 >{HEADER1}</h1>
            <p>{CONTENT1} <p>{CONTENT2}</p></p>
            <h1>{HEADER2}</h1>
            <p>{CONTENT3}</p>
            <table className="homeFindTable">
                <tr>
                    <th>Quality</th>
                    <td>{QUALITYDATA}</td>
                </tr>
                <tr>
                    <th>Service</th>
                    <td>{SERVICEDATA}</td>
                </tr>
                <tr>
                    <th>Creativity</th>
                    <td>{CREATIVITYDATA}</td>
                </tr>
                <tr>
                    <th>Value</th>
                    <td>{VALUEDATA}</td>
                </tr>
            </table>
            <p>{CONTENT4}</p>
        </div>
    )
        

  
}