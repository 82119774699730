import { POST_RECEIVE_BANKER_DATA,RECEIVE_BANKER_COMMENT_DATA,
    FAILURE_BANKER_COMMENT_DATA,POST_FALIURE_BANKER_DATA,SPINNER_ENDPOINT_BANKER } from '../../../../global.constants';

const initialState = {
    comment:'',
    isLoading : false,
    error:'',
}

const BankerCommentsReducer = (state = initialState, {
    type,
    payload
  } = {}) => {

    switch (type) {
        case SPINNER_ENDPOINT_BANKER:
            return Object.assign(state, {
                comment: payload,
                isLoading: true,
            });
        case RECEIVE_BANKER_COMMENT_DATA:
                return Object.assign(state, {
                    comment: payload,
                    isLoading: false,
                });  
        case POST_FALIURE_BANKER_DATA:
        case FAILURE_BANKER_COMMENT_DATA:
            return Object.assign(state, {
                isLoading: false,
                error: payload
            });
        case POST_RECEIVE_BANKER_DATA:
            return Object.assign(state, {
                comment: payload,
                isLoading: false,
            });
       ;
       
        default:
            return state
    }
}

export default BankerCommentsReducer;
