import { useSelector, useDispatch } from "react-redux";
import { Form } from "@jpmuitk/forms";
import React, { useState, useEffect } from "react";
import { Input } from "@jpmuitk/input";
import Grid from "@material-ui/core/Grid";
import { FormField, useValidation } from "@jpmuitk/form-field";
import { Button } from "@jpmuitk/button";
import {
  AccountemailValidationRules,
  AccountmobilenumberValidationRules,
} from "../../ValidationRules";
import {
  AccountantInformationConstants,
  FOURHUNDREDFOURERROR,
  PATH,
  PATHS,
} from "../../global.constants";
import {
  getAccountantData,
  saveAccountantData,
} from "./redux/action/AccountantInformationAction";
import { FormattedInput } from "@jpmuitk/formatted-input";
import { useHistory } from "react-router-dom";
import Loader from "../../../components/loader/Loader";
import Error from "../../../components/error/Error";
import {
  getNextNavigation,
  getPreviousNavigation,
} from "../../../components/navigationPath/navigationPath";
import HiddenValidator from "../../../containers/HiddenValidator";
import { formHas_error } from "../../dynamic/returnToDashboard/redux/action/dashboardAction";
const AccountantInformation = () => {
  const history = useHistory();
  const [mobileno, setMobileno] = useState("");
  const [emails, setEmail] = useState("");
  const [mobilenumberExceptions, validateMobileNumber] = useValidation(
    AccountmobilenumberValidationRules
  );
  const [emailExceptions, validateEmail] = useValidation(
    AccountemailValidationRules
  );
  const dispatch = useDispatch();
  const loanId = useSelector((state) => state.DashboardReducer?.currentLoan);
  const userId = useSelector((state) => state.AuthCheckReducer?.loggedInUserId);
  const AccountantData = useSelector(
    (state) => state.AccountantInformationReducer.AccountantInfoData
  );
  const isLoading = useSelector(
    (state) => state.AccountantInformationReducer.isLoading
  );
  const errors = useSelector(
    (state) => state.AccountantInformationReducer.error
  );
  const [AccountData, setAccountdata] = useState({});
  const mobile = mobilenumberExceptions[0]
    ? mobilenumberExceptions[0].type
    : "";
  const email = emailExceptions[0] ? emailExceptions[0].type : "";

  useEffect(() => {
    if (AccountantData) {
      setAccountdata(AccountantData);
      setMobileno(AccountantData?.phoneNumber);
      setEmail(AccountantData?.email);
    }
  }, [AccountantData]);

  useEffect(() => {
    if (loanId?.activeLoanId) {
      dispatch(getAccountantData(loanId?.activeLoanId, userId));
    }
  }, []);

  const updateStore = () => {
    if (AccountData && loanId?.activeLoanId) {
      let copyData = AccountData;
      copyData.loanId = loanId?.activeLoanId;
      copyData.userId = userId;
      setAccountdata(copyData);
      dispatch(saveAccountantData(AccountData, loanId?.activeLoanId, userId));
    }
  };

  const parseDigits = (string) => (string.match(/(\d+)/g) || []).join("");

  const formatMobileValueWithAppend = (string) => {
    const res = parseDigits(string)
      .split("")
      .reduce(
        (r, v, index) =>
          index === 3 || index === 6 ? `${r}-${v}` : `${r}${v}`,
        ""
      )
      .substr(0, 12);

    if (string.endsWith("-")) {
      if (res.length === 3) {
        return `${res}-`;
      }

      if (res.length === 7) {
        return `${res}-`;
      }
    }
    return res;
  };

  const handleNumberChange = (value) => {
    validateMobileNumber([]);
    setAccountdata({
      ...AccountData,
      phoneNumber: value,
    });
    setMobileno(value);
  };

  const handleFirmChange = (e) => {
    const { value } = e.target;
    setAccountdata({
      ...AccountData,
      firmName: value,
    });
  };

  const handleNameChange = (e) => {
    const { value } = e.target;
    setAccountdata({
      ...AccountData,
      name: value,
    });
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;

    setEmail(value);

    setAccountdata({
      ...AccountData,
      email: value,
    });
  };

  const onBlurEvent = (event) => {
    validateMobileNumber(event.target.value);
  };

  const handlePreviousClick = () => {
    if (emailExceptions.length === 0 && mobilenumberExceptions.length === 0) {
      updateStore();
      history.push(getPreviousNavigation());
      window.scrollTo(0, 0);
    }
  };

  const handleNextClick = (sameComp = true) => {
    if (emailExceptions.length === 0 && mobilenumberExceptions.length === 0) {
      updateStore();
      if (!sameComp) {
        dispatch(formHas_error(false));
        return false;
      }
      history.push(getNextNavigation());
      window.scrollTo(0, 0);
    }
  };

  const mergedErrorMessages = (exceptions) =>
    exceptions.map(({ type, message }) => `${message}`).join("");

  return (
    <>
      {isLoading ? <Loader /> : null}
      {errors == FOURHUNDREDFOURERROR ? <Error /> : null}

      <div className={`dynamicContainer`} id="main" role="main">
        <Form fullWidthFields className={`formClass`}>
          <h1>{AccountantInformationConstants.information}</h1>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormField label="Accounting Firm">
                <Input
                  name="firm"
                  fullWidth
                  autoComplete="off"
                  value={AccountData?.firmName}
                  onChange={handleFirmChange}
                />
              </FormField>
            </Grid>
            <Grid item xs={12}>
              <FormField label="Accountant's Name">
                <Input
                  name="name"
                  value={AccountData?.name}
                  autoComplete="off"
                  onChange={handleNameChange}
                />
              </FormField>
            </Grid>

            <Grid item xs={12}>
              <FormField
                label="Accountant's Telephone Number"
                classes={{ helperText: "errorHelperText" }}
                helperText={mergedErrorMessages(mobilenumberExceptions)}
                validationState={mobile}
              >
                <FormattedInput
                  name="number"
                  className="textFont"
                  value={mobileno || ""}
                  autoComplete="off"
                  onBlur={(e) => onBlurEvent(e)}
                  onChange={(value) => {
                    handleNumberChange(value);
                  }}
                  rifmOptions={{
                    accept: /[\d]/g,
                    mask: (value) => 12 >= value.length,
                    format: formatMobileValueWithAppend,
                    append: (string) =>
                      string.length === 4 || string.length === 8
                        ? `${string}-`
                        : string,
                  }}
                />
              </FormField>
            </Grid>

            <Grid item xs={12}>
              <FormField
                label="Accountant's E-mail Address"
                autoComplete="off"
                classes={{ helperText: "errorHelperText" }}
                helperText={mergedErrorMessages(emailExceptions)}
                validationState={email}
              >
                <Input
                  name="email"
                  value={emails}
                  onChange={handleEmailChange}
                />
              </FormField>
            </Grid>
          </Grid>
        </Form>
        <p className="noteClass">{AccountantInformationConstants.message}</p>
        <div className={`buttonClass`}>
          <Button onClick={handlePreviousClick}>previous section</Button>
          <Button variant="cta" onClick={handleNextClick}>
            next section
          </Button>
          <HiddenValidator
            validateTrigger={() => handleNextClick(false)}
            labels={"next section"}
          />
        </div>
      </div>
    </>
  );
};
export default AccountantInformation;
