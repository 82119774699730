import { getCommonValue } from "../utils/common-utils";
import _ from 'underscore';

const convertToLowerCaseString = (value) => {
    return value.toString().toLowerCase();
}

const StringUtils  = (request) => {
    const data = _.isArray(request.data) ? request.data[0] : request.data;
    if(!data || (!data.emailAddress && !data.loginId && !data.loanId && !data.userId && !data.loginId))
        return request;

    let stringToBeHashed = '';

    stringToBeHashed = stringToBeHashed.concat(
    (data.emailId || data.email || data.emailAddress) ? convertToLowerCaseString(data.emailId ? data.emailId : (data.email ? data.email : data.emailAddress)) : '',
    data.userId ? convertToLowerCaseString(data.userId) : '', 
    data.loanId ? convertToLowerCaseString(data.loanId) : '', 
    data.loginId ? convertToLowerCaseString(data.loginId): '',
    data.bizId ? convertToLowerCaseString(data.bizId) : '',
    data.contactId ? convertToLowerCaseString(data.contactId) : '',
    data.addressId ? convertToLowerCaseString(data.addressId) : '',
    data.mailingAddressId ? convertToLowerCaseString(data.mailingAddressId) : '');
    
    request.headers["x-header"] = getCommonValue(stringToBeHashed.trim());
    return request;
}

export default StringUtils;