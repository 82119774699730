import React from 'react';

const dynamicForm = (WrapperComp, props) => {
    const { initObj} = props;

    return class extends React.Component {

        createInitialList = (num = 0, loanId= '') => {
            initObj.loanId = loanId;
            if (/\d*/g.test(num)) {
                const val = Number(num);
                let listValue = val > 0 ? val : 0;
                return ([...Array(listValue)].map((ele, ind) => {
         
                    return { ...initObj };
                }));
            }
            return [];
        };

        addNewItem = (list = [], loanId='') => {
            initObj.loanId = loanId;
            
            list.push({ ...initObj });
            return [...list];
        };

        removeAnItem = (id, list = [], loanId='') => {
            initObj.loanId = loanId;
            list.splice(id, 1);
            //list[id] = undefined;
            return list;
        };

        render() {
  
            return (
                <WrapperComp
                    {...this.props}
                    createInitialList={this.createInitialList}
                    addNewItem={this.addNewItem}
                    removeAnItem={this.removeAnItem}
                />
            );
        }
    }
}

export default dynamicForm;
