import {
 RECEIVE_SEARCH_POST_DATA,
  FAILURE_SEARCH_POST_DATA,
  REQUEST_SEARCH_POST_DATA
} from '../../../../global.constants';

const initialState = {
  franchiseSearchVOList: {},
  isLoading:false,
  error:'',
  errorMsg: ''

}

const searchFranchiseReducer = (state = initialState, {
  type,
  payload
} = {}) => {
  switch (type) {
    case REQUEST_SEARCH_POST_DATA:
      return Object.assign({}, state, {
       isLoading:true
      });
     case RECEIVE_SEARCH_POST_DATA:
      return Object.assign({}, state, {
        franchiseSearchVOList: payload,
        isLoading:false
      });
    case FAILURE_SEARCH_POST_DATA:
      return Object.assign({}, state, {
        isLoading: false,
        error: payload,
        errorMsg: payload.status === 500 ? payload.response.data.message : ''
      });
    default:
      return state;
  }
};
export default searchFranchiseReducer;
