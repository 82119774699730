import React, { useEffect, useState } from "react";
import { Input } from "@jpmuitk/input";
import { FormField } from "@jpmuitk/form-field";
import { Dropdown } from "@jpmuitk/dropdown";
import Grid from "@material-ui/core/Grid";
import { RadioButtonGroup } from "@jpmuitk/radio-button";
import { DatePicker } from "@jpmuitk/date-picker";
import { Checkbox } from "@jpmuitk/checkbox";
import {
  boolTrue,
  collateral,
  DebtSummaryLabel,
  interestRate,
  lender,
  months,
  payments,
  POLConstants,
  PurposeOfLoanText,
  Tooltip_info,
  years,
  yearsEndValue,
  yearsStartValue,
} from "../../global.constants";
import "./purposeOfLoan.scss";
import moment from "moment";

function Loan(props) {
  const { Data, loan, update, entityDetails, storeRestaurent, structure } =
    props;
  const yearsDate = [...new Array(yearsStartValue)].map((ele, id) => {
    let yr = new Date().getFullYear() - yearsEndValue + id;
    return {
      value: yr,
      text: String(yr),
      id: yr,
    };
  });

  const [value, setValue] = useState(Data);
  const handleDropdown = (source, item, name) => {
    source.forEach((element, index) => {
      if (item == element) {
        handleUpdate(index, name);
      }
    });
  };
  const handleBorrowEntity = (source, item, name) => {
    source.forEach((element) => {
      if (item.borrowerName == element.borrowerName) {
        handleUpdate(element.borrowerId, name);
      }
    });
  };
  const handleDefaultBorrow = (item, id) => {
    let data = {};
    item?.forEach((element) => {
      if (element.borrowerId == id) {
        data = element;
      }
    });
    return data;
  };
  const handleCheckboxUpdate = (item, name, ind) => {
    storeRestaurent[ind].isRequired = item;
    let val = "";
    storeRestaurent.forEach((element) => {
      if (val && element.isRequired) {
        val = val + ",";
      }
      val = val + element.isRequired;
    });
    handleUpdate(val, name);
  };

  useEffect(() => {
    if (Data.collateralStoreList) {
      let data = Data.collateralStoreList.split(",");
      data.forEach((element) => {
        if (storeRestaurent) {
          storeRestaurent.forEach((ele) => {
            if (element == ele.nationalStoreNumber) {
              ele.isRequired = element;
            }
          });
        }
      });
    }
  }, [Data.collateralStoreList, storeRestaurent]);

  const handleUpdate = (item, name) => {
    const boolValue = false;
    const data = {
      name: name,
      value:
        (item && item != 0 && item != POLConstants.SELECT) || item == boolValue
          ? item
          : null,
    };
    update(data);
  };
  const handleDefaultSelected = (item, id) => {
    let data = item[POLConstants.numZero];
    if (id) {
      item.forEach((element, index) => {
        if (index == id) {
          data = element;
        }
      });
    }
    return data;
  };

  const numberRangeValidator = (
    val,
    maxDigits = POLConstants.numTen,
    decimal = POLConstants.numTwo
  ) => {
    let decimalPoint = new RegExp(
      `^\\d*\\.?[\\d]{0,${decimal}}$`,
      POLConstants.valG
    );
    let numb = val.replace(POLConstants.numValRegex, POLConstants.emptyString);
    if (
      numb.replace(POLConstants.numRangeRegex, POLConstants.emptyString)
        .length <= maxDigits &&
      decimalPoint.test(numb)
    ) {
      return true;
    }
    return false;
  };

  const updateCheck = (val, maxDigits, decimal, name) => {
    let valCheck =
      val == POLConstants.emptyString
        ? POLConstants.emptyString
        : val[val.length - 1] == POLConstants.dot;
    let valLenCheck =
      val[val.length - 1] == POLConstants.stringZero ? val : Number(val);
    let valFinal = valCheck ? val : valLenCheck;
    return numberRangeValidator(val, maxDigits, decimal) ? valFinal : name;
  };
  const updateState = (
    e,
    name,
    maxDigits = POLConstants.numTen,
    decimal = POLConstants.numTwo
  ) => {
    let val = e?.target?.value.replace(
      POLConstants.numValRegex,
      POLConstants.emptyString
    );
    value[name] = updateCheck(val, maxDigits, decimal, value[name]);
    setValue({ ...value });
    handleUpdate(value[name], name);
  };
  const numberCheck = (val, name) => {
    let valueCheck =
      val == POLConstants.emptyString ? POLConstants.emptyString : val;
    return numberRangeValidator(val) ? valueCheck : name;
  };
  const numberValidator = (e) => {
    const { name } = e?.target;
    let val = e?.target?.value.replace(
      POLConstants.numValRegex,
      POLConstants.emptyString
    );

    value[name] = numberCheck(val, value[name]);
    setValue({ ...value });
    handleUpdate(value[name], name);
  };

  const updatedefaultValue = (
    key,
    val,
    max = POLConstants.numTen,
    dec = POLConstants.numTwo
  ) => {
    if (!value[key]) {
      value[key] = val;
      setValue({ ...value });
    } else {
      let num = String(value[key]).replace(
        POLConstants.updateNumRegex,
        POLConstants.emptyString
      );
      let len = (num.length + dec) % max;
      if (len > dec || len === POLConstants.numZero) {
        value[key] = Number(value[key]).toFixed(dec);
      } else if (len < dec) {
        value[key] = Number(value[key]).toFixed(len);
      }
      setValue({ ...value });
    }
  };

  const formatUSD = (val) => {
    return val
      ? String(val).replace(POLConstants.usdFormat, POLConstants.comma)
      : null;
  };

  const defaultCheck = (valueCheck) => {
    return valueCheck ? valueCheck : POLConstants.emptyString;
  };
  const defaultSelect = (valueCheck) => {
    return valueCheck ? valueCheck : POLConstants.SELECT;
  };
  const { loanId, loanName } = loan;
  const getCodeMethod = () => {
    return (
      <>
        {Data?.otherLenderName == POLConstants.others ? (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormField label={DebtSummaryLabel.lender}>
                <Input
                  name="lenderName"
                  fullWidth
                  autoComplete="off"
                  value={defaultCheck(Data?.lenderName)}
                  onChange={(e) => handleUpdate(e?.target?.value, "lenderName")}
                />
              </FormField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className="dropdownloan">
                <FormField label={DebtSummaryLabel.originalDate}>
                  <DatePicker
                    dateFormat={"MM/DD/YYYY"}
                    date={moment(Data.originalNoteDate, "MM/DD/YYYY")}
                    onDateChange={(date) => {
                      date &&
                        handleUpdate(
                          date.format("MM/DD/YYYY"),
                          "originalNoteDate"
                        );
                    }}
                    PopperProps={{ placement: "bottom-end" }}
                    YearDropdownProps={{ source: yearsDate, listWidth: "80px" }}
                    StartInputProps={{ className: "datePickerBox" }}
                    CalendarProps={{ defaultInputValue: "01/01/1939" }}
                  />
                </FormField>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className="dropdownloan">
                <FormField label={DebtSummaryLabel.maturityDate}>
                  <DatePicker
                    dateFormat={"MM/DD/YYYY"}
                    date={moment(Data.maturityDate, "MM/DD/YYYY")}
                    onDateChange={(date) => {
                      date &&
                        handleUpdate(date.format("MM/DD/YYYY"), "maturityDate");
                    }}
                    PopperProps={{ placement: "bottom-end" }}
                    YearDropdownProps={{ source: yearsDate, listWidth: "80px" }}
                    StartInputProps={{ className: "datePickerBox" }}
                    CalendarProps={{ defaultInputValue: "01/01/1939" }}
                  />
                </FormField>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormField label={DebtSummaryLabel.originalAmt}>
                <Input
                  name="originalAmount"
                  fullWidth
                  autoComplete="off"
                  onChange={(e) => updateState(e, "originalAmount")}
                  onBlur={(e) =>
                    updatedefaultValue("originalAmount", POLConstants.valZero)
                  }
                  value={formatUSD(Data.originalAmount)}
                />
              </FormField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormField label={DebtSummaryLabel.currentPrincipalAmt}>
                <Input
                  name="principalBalance"
                  fullWidth
                  autoComplete="off"
                  onChange={(e) => updateState(e, "principalBalance")}
                  onBlur={(e) =>
                    updatedefaultValue("principalBalance", POLConstants.valZero)
                  }
                  value={formatUSD(Data.principalBalance)}
                />
              </FormField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormField label={DebtSummaryLabel.interest}>
                <Input
                  name="interestRate"
                  fullWidth
                  autoComplete="off"
                  onChange={(e) => updateState(e, "interestRate")}
                  onBlur={(e) =>
                    updatedefaultValue("interestRate", POLConstants.valZero)
                  }
                  value={formatUSD(Data.interestRate)}
                />
              </FormField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormField label={DebtSummaryLabel.scheduledPayment}>
                <Input
                  name="monthlyLoanPayment"
                  fullWidth
                  autoComplete="off"
                  onChange={(e) => updateState(e, "monthlyLoanPayment")}
                  onBlur={(e) =>
                    updatedefaultValue(
                      "monthlyLoanPayment",
                      POLConstants.valZero
                    )
                  }
                  value={formatUSD(Data.monthlyLoanPayment)}
                />
              </FormField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormField label={DebtSummaryLabel.lenderPhone}>
                <Input
                  name="lenderPhoneNumber"
                  fullWidth
                  autoComplete="off"
                  value={defaultCheck(Data?.lenderPhoneNumber)}
                  onChange={numberValidator}
                />
              </FormField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormField label={DebtSummaryLabel.LenderContact}>
                <Input
                  name="contactLender"
                  fullWidth
                  autoComplete="off"
                  value={defaultCheck(Data?.contactLender)}
                  onChange={(e) =>
                    handleUpdate(e?.target?.value, "contactLender")
                  }
                />
              </FormField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormField label={PurposeOfLoanText.loanNumbers}>
                <Input
                  name="loanOrObligationNo"
                  fullWidth
                  autoComplete="off"
                  value={defaultCheck(Data?.loanOrObligationNo)}
                  onChange={numberValidator}
                />
              </FormField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormField label={PurposeOfLoanText.NationalRestaurantNumber}>
                <Input
                  name="nationalStoreNumber"
                  fullWidth
                  autoComplete="off"
                  value={defaultCheck(Data?.nationalStoreNumber)}
                  onChange={numberValidator}
                />
              </FormField>
            </Grid>
          </>
        ) : null}
      </>
    );
  };

  return (
    <div className={`nestedLoanFormContainer`}>
      <div className={`headerContent`}>
        <h2 className={`headingClass`}>{loanName}</h2>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormField label={PurposeOfLoanText.RequestedAmount}>
            <Input
              name="requestedAmount"
              fullWidth
              autoComplete="off"
              onChange={(e) => updateState(e, "requestedAmount")}
              onBlur={(e) =>
                updatedefaultValue("requestedAmount", POLConstants.valZero)
              }
              value={formatUSD(Data.requestedAmount)}
            />
          </FormField>
        </Grid>

        {loanId == POLConstants.numThirten ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormField
              label={PurposeOfLoanText.PleaseExplain}
              helperText={PurposeOfLoanText.placeholderText}
              helperTextPlacement={"tooltip"}
              hasStatusIndicator
            >
              <span
                tabIndex="0"
                className="sr-only"
                role="tooltip"
                aria-label={Tooltip_info}
              ></span>
              <Input
                name="loanPurposeOther"
                fullWidth
                autoComplete="off"
                value={Data?.loanPurposeOther}
                onChange={(e) =>
                  handleUpdate(e?.target?.value, "loanPurposeOther")
                }
                multiline
              />
            </FormField>
          </Grid>
        ) : (
          ""
        )}

        {loanId == POLConstants.numOne ||
        loanId == POLConstants.numFive ||
        loanId == POLConstants.numTen ||
        loanId == POLConstants.numThere ||
        loanId == POLConstants.numFour ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormField label={PurposeOfLoanText.TotalProjectedCost}>
              <Input
                name="loanAmount"
                fullWidth
                autoComplete="off"
                onChange={(e) => updateState(e, "loanAmount")}
                onBlur={(e) =>
                  updatedefaultValue("loanAmount", POLConstants.valZero)
                }
                value={formatUSD(Data.loanAmount)}
              />
            </FormField>
          </Grid>
        ) : (
          ""
        )}

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormField label={PurposeOfLoanText.WhatistheBorrowingentity}>
            <Dropdown
              className={"dropDownField"}
              itemToString={(item) => (item ? item.borrowerName : "")}
              name="borrowingStore"
              source={entityDetails}
              selectedItem={handleDefaultBorrow(
                entityDetails,
                Data?.borrowingStore
              )}
              onSelect={(_, item) =>
                handleBorrowEntity(entityDetails, item, "borrowingStore")
              }
            />
          </FormField>
        </Grid>
        {loanId == POLConstants.numFive ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormField label="Previous Restaurant Number">
              <Input
                name="prevStoreNumber"
                fullWidth
                autoComplete="off"
                value={defaultCheck(Data?.prevStoreNumber)}
                onChange={numberValidator}
              />
            </FormField>
          </Grid>
        ) : (
          POLConstants.emptyString
        )}

        {loanId == POLConstants.numFive ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormField label="New Restaurant Number">
              <Input
                name="newStoreNumber"
                fullWidth
                autoComplete="off"
                value={defaultCheck(Data?.newStoreNumber)}
                onChange={numberValidator}
              />
            </FormField>
          </Grid>
        ) : (
          POLConstants.emptyString
        )}

        {loanId == POLConstants.numFive ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormField label="New Address">
              <Input
                name="newStoreAddress"
                fullWidth
                autoComplete="off"
                value={defaultCheck(Data?.newStoreAddress)}
                onChange={(e) =>
                  handleUpdate(e?.target?.value, "newStoreAddress")
                }
              />
            </FormField>
          </Grid>
        ) : (
          POLConstants.emptyString
        )}

        {loanId == POLConstants.numFive || loanId == POLConstants.numSix ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className="dropdownloan">
              <FormField label={PurposeOfLoanText.ProjectedGroundBreakDate}>
                <DatePicker
                  dateFormat={"MM/DD/YYYY"}
                  date={moment(Data.projGroundBrkDate, "MM/DD/YYYY")}
                  onDateChange={(date) => {
                    date &&
                      handleUpdate(
                        date.format("MM/DD/YYYY"),
                        "projGroundBrkDate"
                      );
                  }}
                  PopperProps={{ placement: "bottom-end" }}
                  YearDropdownProps={{ source: yearsDate, listWidth: "80px" }}
                  StartInputProps={{ className: "datePickerBox" }}
                  CalendarProps={{ defaultInputValue: "01/01/1939" }}
                />
              </FormField>
            </div>
          </Grid>
        ) : (
          POLConstants.emptyString
        )}

        {loanId == POLConstants.numOne ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className="dropdownloan">
              <FormField label={PurposeOfLoanText.RestaurantOpeningDate}>
                <DatePicker
                  dateFormat={"MM/DD/YYYY"}
                  date={moment(Data.openingDate, "MM/DD/YYYY")}
                  onDateChange={(date) => {
                    date &&
                      handleUpdate(date.format("MM/DD/YYYY"), "openingDate");
                  }}
                  PopperProps={{ placement: "bottom-end" }}
                  YearDropdownProps={{ source: yearsDate, listWidth: "80px" }}
                  StartInputProps={{ className: "datePickerBox" }}
                  CalendarProps={{ defaultInputValue: "01/01/1939" }}
                />
              </FormField>
            </div>
          </Grid>
        ) : (
          POLConstants.emptyString
        )}

        {loanId == POLConstants.numOne ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className="dropdownloan">
              <FormField label={PurposeOfLoanText.ProjectedGroundBreakDate}>
                <DatePicker
                  dateFormat={"MM/DD/YYYY"}
                  date={moment(Data.projGroundBrkDate, "MM/DD/YYYY")}
                  onDateChange={(date) => {
                    date &&
                      handleUpdate(
                        date.format("MM/DD/YYYY"),
                        "projGroundBrkDate"
                      );
                  }}
                  PopperProps={{ placement: "bottom-end" }}
                  YearDropdownProps={{ source: yearsDate, listWidth: "80px" }}
                  StartInputProps={{ className: "datePickerBox" }}
                  CalendarProps={{ defaultInputValue: "01/01/1939" }}
                />
              </FormField>
            </div>
          </Grid>
        ) : (
          POLConstants.emptyString
        )}

        {loanId == POLConstants.numTwo ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormField label={PurposeOfLoanText.SellerName}>
              <Input
                name="sellerName"
                fullWidth
                autoComplete="off"
                value={defaultCheck(Data?.sellerName)}
                onChange={(e) => handleUpdate(e?.target?.value, "sellerName")}
              />
            </FormField>
          </Grid>
        ) : (
          POLConstants.emptyString
        )}

        {loanId == POLConstants.numTwo ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormField label={PurposeOfLoanText.SellerEntityName}>
              <Input
                name="sellerEntityName"
                fullWidth
                autoComplete="off"
                value={defaultCheck(Data?.sellerEntityName)}
                onChange={(e) =>
                  handleUpdate(e?.target?.value, "sellerEntityName")
                }
              />
            </FormField>
          </Grid>
        ) : (
          POLConstants.emptyString
        )}

        {loanId == POLConstants.numTwo || loanId == POLConstants.numThere ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className="dropdownloan">
              <FormField label={PurposeOfLoanText.TargetPurchaseDate}>
                <DatePicker
                  dateFormat={"MM/DD/YYYY"}
                  date={moment(Data.purchaseDate, "MM/DD/YYYY")}
                  onDateChange={(date) => {
                    date &&
                      handleUpdate(date.format("MM/DD/YYYY"), "purchaseDate");
                  }}
                  PopperProps={{ placement: "bottom-end" }}
                  YearDropdownProps={{ source: yearsDate, listWidth: "80px" }}
                  StartInputProps={{ className: "datePickerBox" }}
                  CalendarProps={{ defaultInputValue: "01/01/1939" }}
                />
              </FormField>
            </div>
          </Grid>
        ) : (
          POLConstants.emptyString
        )}

        {loanId == POLConstants.numFour ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormField label={PurposeOfLoanText.Afterexercise}>
              <Input
                name="rentPercentage"
                fullWidth
                autoComplete="off"
                value={defaultCheck(Data?.rentPercentage)}
                onChange={numberValidator}
              />
            </FormField>
          </Grid>
        ) : (
          POLConstants.emptyString
        )}

        {loanId == POLConstants.numThirten ||
        loanId == POLConstants.numThere ||
        loanId == POLConstants.numTwo ||
        loanId == POLConstants.numFour ||
        loanId == POLConstants.numSix ||
        loanId == POLConstants.numOne ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormField label={PurposeOfLoanText.NationalRestaurantNumber}>
              <Input
                name="nationalStoreNumber"
                fullWidth
                autoComplete="off"
                value={defaultCheck(Data?.nationalStoreNumber)}
                onChange={numberValidator}
              />
            </FormField>
          </Grid>
        ) : (
          POLConstants.emptyString
        )}

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <RadioButtonGroup
            legend={PurposeOfLoanText.Whichrestaurantsarecollateral}
            name="chooseAllCollateral"
            className={"singleRowRadioBtn"}
            radios={collateral}
            onChange={(e) =>
              handleUpdate(
                e?.target?.value == "all"
                  ? POLConstants.alpabetY
                  : POLConstants.alpabetN,
                POLConstants.chooseAllCollateral
              )
            }
            value={
              Data?.chooseAllCollateral == POLConstants.alpabetY
                ? "all"
                : "specific"
            }
          />
          {Data?.chooseAllCollateral == POLConstants.alpabetN ? (
            <>
              {storeRestaurent?.map(
                (ele, ind) =>
                  ele &&
                  ele.nationalStoreNumber != null && (
                    <Checkbox
                      className="checkboxss"
                      checked={
                        Data.collateralStoreList
                          ? Data.collateralStoreList.includes(
                              ele.nationalStoreNumber
                            )
                          : false
                      }
                      label={ele.nationalStoreNumber}
                      onChange={(e) =>
                        handleCheckboxUpdate(
                          e?.target?.checked == boolTrue
                            ? ele.nationalStoreNumber
                            : POLConstants.emptyString,
                          "collateralStoreList",
                          ind
                        )
                      }
                    />
                  )
              )}
            </>
          ) : null}
        </Grid>

        {loanId == POLConstants.numNine ||
        loanId == POLConstants.numEight ||
        loanId == POLConstants.numSeven ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormField label={PurposeOfLoanText.NameofLender}>
              <Dropdown
                className={"dropDownField"}
                name="otherLenderName"
                source={lender}
                autoComplete="off"
                selectedItem={defaultSelect(Data?.otherLenderName)}
                onSelect={(_, item) =>
                  handleUpdate(
                    item == POLConstants.SELECT ? null : item,
                    "otherLenderName"
                  )
                }
              />
            </FormField>
          </Grid>
        ) : (
          POLConstants.emptyString
        )}
        {Data?.otherLenderName == "Chase" ? (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormField label={PurposeOfLoanText.loanNumbers}>
                <Input
                  name="loanOrObligationNo"
                  fullWidth
                  autoComplete="off"
                  value={defaultCheck(Data?.loanOrObligationNo)}
                  onChange={numberValidator}
                />
              </FormField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormField label={PurposeOfLoanText.NationalRestaurantNumber}>
                <Input
                  name="nationalStoreNumber"
                  fullWidth
                  autoComplete="off"
                  value={defaultCheck(Data?.nationalStoreNumber)}
                  onChange={numberValidator}
                />
              </FormField>
            </Grid>
          </>
        ) : (
          getCodeMethod()
        )}

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormField
            label={PurposeOfLoanText.Howwouldyoulikeyourloanstructured}
          >
            <Dropdown
              className={"dropDownField"}
              source={structure}
              selectedItem={handleDefaultSelected(
                structure,
                Data?.loanStructureId
              )}
              onSelect={(e, item) =>
                handleDropdown(structure, item, "loanStructureId")
              }
            />
          </FormField>
        </Grid>

        <div className="dropdownloan">
          <p className="desired-term">{POLConstants.desireLoan}</p>
        </div>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <FormField label={PurposeOfLoanText.Years}>
            <Dropdown
              name="desiredTermYears"
              className={"dropDownField"}
              source={years}
              selectedItem={handleDefaultSelected(
                years,
                Data?.desiredTermYears
              )}
              onSelect={(e, item) =>
                handleDropdown(years, item, "desiredTermYears")
              }
            />
          </FormField>
        </Grid>

        <Grid item xs={6} sm={6} md={6} lg={6}>
          <FormField label={PurposeOfLoanText.Months}>
            <Dropdown
              name="desiredTermMonths"
              className={"dropDownField"}
              source={months}
              selectedItem={handleDefaultSelected(
                months,
                Data?.desiredTermMonths
              )}
              onSelect={(_, item) =>
                handleDropdown(months, item, "desiredTermMonths")
              }
            />
          </FormField>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormField label={PurposeOfLoanText.DesiredPaymentStructure}>
            <Dropdown
              className={"dropDownField"}
              source={payments}
              selectedItem={handleDefaultSelected(
                payments,
                Data?.paymentStructureId
              )}
              onSelect={(_, item) =>
                handleDropdown(payments, item, "paymentStructureId")
              }
            />
          </FormField>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormField label={PurposeOfLoanText.DesiredInterestRateOption}>
            <Dropdown
              name="interestRateOption"
              className={"dropDownField"}
              source={interestRate}
              selectedItem={defaultSelect(Data?.interestRateOption)}
              onSelect={(_, item) => handleUpdate(item, "interestRateOption")}
            />
          </FormField>
          <p className={"helperText"}>
            {" "}
            <a href="/rates" target="_blank">
              {POLConstants.clickHere}
            </a>
            {POLConstants.currentRates}
          </p>
        </Grid>

        <Grid item xs={6} sm={6} md={6} lg={6}>
          <FormField label={PurposeOfLoanText.RequestedDateofFunding}>
            <DatePicker
              dateFormat={"MM/DD/YYYY"}
              date={moment(Data.fundingDate, "MM/DD/YYYY")}
              onDateChange={(date) => {
                date && handleUpdate(date.format("MM/DD/YYYY"), "fundingDate");
              }}
              PopperProps={{ placement: "bottom-end" }}
              YearDropdownProps={{ source: yearsDate, listWidth: "80px" }}
              StartInputProps={{ className: "datePickerBox" }}
              CalendarProps={{ defaultInputValue: "01/01/1939" }}
            />
          </FormField>
        </Grid>

        <Grid item xs={6} sm={6} md={6} lg={6}>
          <div className="asap-checkbox">
            <p>or</p>
            <Checkbox
              className="checkboxss"
              checked={Data.asap == POLConstants.alpabetY ? true : false}
              label="ASAP"
              onChange={(e) =>
                handleUpdate(
                  e?.target?.checked == boolTrue
                    ? POLConstants.alpabetY
                    : POLConstants.alpabetN,
                  POLConstants.asap
                )
              }
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Loan;
