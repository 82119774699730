import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { OrderedButton, ButtonBar } from "@jpmuitk/button-bar";
import {
  onlineRequest,
  MyProfileConstants,
  yearsStartValue,
  yearsEndValue,
} from "../../containers/global.constants";
import { FormField } from "@jpmuitk/form-field";
import { DatePicker } from "@jpmuitk/date-picker";
import { Form } from "@jpmuitk/forms";
import Grid from "@material-ui/core/Grid";
import { Dropdown } from "@jpmuitk/dropdown";
import { AriaAnnouncerProvider } from "@jpmuitk/aria-announcer";
import {
  getBankersData,
  getStatusData,
} from "../../containers/admin/onlineRequest/redux/action/SearchFiltersActions";
import "../../containers/admin/adminDasboard/AdminDashboard.scss";

const SearchBox = (props) => {
  const [ishyphen, setIshyphen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(
    MyProfileConstants.SELECT
  );
  const [requestedDateFrom, setRequestedDateFrom] = useState("");
  const [requestedDateTo, setRequestedDateTo] = useState("");
  const dispatch = useDispatch();
  const years = [...new Array(yearsStartValue)].map((ele, id) => {
    let yr = new Date().getFullYear() - yearsEndValue + id;
    return {
      value: yr,
      text: String(yr),
      id: yr,
    };
  });

  useEffect(() => {
    setTimeout(() => {
      dispatch(getBankersData());
      dispatch(getStatusData());
    }, 1000);
  }, []);

  const bankersdata = useSelector((state) => state.SearchReducer.bankerList);
  const statusdata = useSelector((state) => state.SearchReducer.statusList);
  const aplhaBankersList = [...bankersdata]?.sort((a, b) =>
    a.firstName.localeCompare(b.firstName)
  );

  const statuslists = statusdata?.map((x) => x.statusDescription);
  statuslists.unshift(MyProfileConstants.SELECT);

  const bankerlists = aplhaBankersList?.map((x) => ({
    firstName: x.firstName,
    userId: x.userId,
  }));
  bankerlists.unshift({ firstName: MyProfileConstants.SELECT, userId: null });
  const [selectedBanker, setSelectedBanker] = useState(bankerlists[0]);

  const handleAdvanceSearch = () => {
    setIshyphen(!ishyphen);
  };

  const handleSubmit = async () => {
    const n = selectedStatus.lastIndexOf(".");
    const sStatus = selectedStatus
      .substring(n + 1)
      .replace(/\s+/g, "")
      .toLowerCase();
    const stausLoan = props.page ? sStatus : selectedStatus;
    const data = {
      userId:
        selectedBanker?.userId === null
          ? selectedBanker?.userId
          : selectedBanker,
      reqParams: {
        banker:
          selectedBanker?.userId === null
            ? selectedBanker?.userId
            : selectedBanker,
        loanStatus:
          selectedStatus !== "Select" && selectedStatus ? stausLoan : "",
        requestedDateFrom: requestedDateFrom ? requestedDateFrom : "",
        requestedDateTo: requestedDateTo ? requestedDateTo : "",
      },
    };
    if (!ishyphen) {
      data.reqParams.requestedDateFrom = "";
      data.reqParams.requestedDateTo = "";
    }
    data.isPagination = true;
    data.page = 1;
    props.handleSubmits(data);
  };

  return (
    <Form className={`formClass`}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ButtonBar alignLeft>
            <OrderedButton variant="cta" onClick={props.showfrachise}>
              {onlineRequest.btn}
            </OrderedButton>
          </ButtonBar>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={`dropdownContainer`}
        >
          <div className="dropdownbankers">
            <FormField label="Banker">
              <AriaAnnouncerProvider>
                <Dropdown
                  classes={{ buttonLabel: "buttonLabelClass" }}
                  className={"dropDownField"}
                  source={bankerlists}
                  itemToString={(item) => (item ? item.firstName : "")}
                  onChange={(e, item) => setSelectedBanker(item?.userId)}
                  initialSelectedItem={bankerlists[0]}
                  // onChange={(e, selectedItem) => setSelectedBanker(selectedItem)}
                />
              </AriaAnnouncerProvider>
            </FormField>
          </div>
          <div className="dropdownstatus">
            <FormField label="Status">
              <AriaAnnouncerProvider>
                <Dropdown
                  classes={{ buttonLabel: "buttonLabelClass" }}
                  className={"dropDownField"}
                  source={statuslists}
                  onChange={(e, selectedItem) =>
                    setSelectedStatus(selectedItem)
                  }
                  selectedItem={selectedStatus}
                />
              </AriaAnnouncerProvider>
            </FormField>
          </div>
          <div className="search">
            <ButtonBar className={`searchButton`}>
              <OrderedButton variant="cta" onClick={handleSubmit}>
                {onlineRequest.btnsearch}
              </OrderedButton>
            </ButtonBar>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ButtonBar alignLeft>
            <OrderedButton
              className={`adv-search`}
              onClick={handleAdvanceSearch}
            >
              {ishyphen ? "- " : "+ "}
              {onlineRequest.btnadvsearch}
            </OrderedButton>
          </ButtonBar>
        </Grid>

        {ishyphen ? (
          <div className="datesfields">
            <div className="requestdate">
              <FormField label="Requested Date From">
                <DatePicker
                  className="textFont"
                  dateFormat="MM/DD/YYYY"
                  onInputValueChange={(val) => setRequestedDateFrom(val)}
                  YearDropdownProps={{ source: years, listWidth: "80px" }}
                  StartInputProps={{ className: "datePickerBox" }}
                  CalendarProps={{ defaultInputValue: "01/01/1939" }}
                />
              </FormField>
            </div>
            <div className="todate">
              <FormField label="Requested Date To">
                <DatePicker
                  className="textFont"
                  dateFormat="MM/DD/YYYY"
                  onInputValueChange={(val) => setRequestedDateTo(val)}
                  YearDropdownProps={{ source: years, listWidth: "80px" }}
                  StartInputProps={{ className: "datePickerBox" }}
                  CalendarProps={{ defaultInputValue: "01/01/1939" }}
                />
              </FormField>
            </div>
          </div>
        ) : null}
      </Grid>
    </Form>
  );
};

export default SearchBox;
