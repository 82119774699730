import axios from 'axios'
import { REQUEST_REFERRAL_POST_DATA, RECEIVE_REFERRAL_POST_DATA, FAILURE_REFERRAL_POST_DATA } from '../../../../global.constants'

export const fetchreferralInformationData = (payload) => async dispatch => {
    try {
        dispatch(referralReceivePostData(payload));
         await axios.post(
            `/api/`, payload)
            .then(response => {
                if (response && response.status === 200) {
                    dispatch(referralReceivePostData(payload));
                }
                else {
                    dispatch(referralFailurePostData())
                }
            });
        dispatch(referralReceivePostData(payload));
    }
    catch (e) {
        return e;
    }
};
export const referralRequestPostData = (payload) => ({
    type: REQUEST_REFERRAL_POST_DATA,
    payload
})
export const referralReceivePostData = (payload) => ({
    type: RECEIVE_REFERRAL_POST_DATA,
    payload
})
export const referralFailurePostData = (payload) => ({
    type: FAILURE_REFERRAL_POST_DATA,
    payload
})