import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React,{useRef}  from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { AriaAnnouncerProvider } from '@jpmuitk/aria-announcer';
import { ThemeProvider, createToolkitTheme } from '@jpmuitk/theme';
import '@jpmuitk/style/css/jpmuitk.css';
import { SkipLink } from '@jpmuitk/skip-link';
import './index.css';
import App from './App';
import ApplicationContainer from './containers/app/app-container'
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import centralStore from './store'
import axios from 'axios';
import https from './string-utils/string-utils';

const theme = createToolkitTheme('light');
const store = centralStore();

axios.interceptors.request.use((request) => {
  return https(request);
})

function Root() {
  const articleRef = useRef(null);
  const styles = {
    section: { padding: 0, border: 'none' },
    header: { fontSize: '1.17em' },
  };
  return (
    <Provider store={store}>
    <BrowserRouter>
      <AriaAnnouncerProvider>
        <ThemeProvider theme={theme}>
        <div className={`skipLink`}>
        <SkipLink  href="#main" targetRef={articleRef}>
          Skip to main content
        </SkipLink>
        </div>
      {/*  <article className={`articleClass`} id="main" ref={articleRef}>*/}
          <ApplicationContainer/>
        {/*   </article>*/}
        </ThemeProvider>
      </AriaAnnouncerProvider>
    </BrowserRouter>
    </Provider>
  );
}

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
