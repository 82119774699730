import axios from "axios";

import {
  base_url,
  FETCH_ACCOUNTANT_REQUEST,
  FETCH_ACCOUNTANT_SUCCESS,
  FETCH_PARTY_FAILURE,
  SAVE_ACCOUNTANT_FAILURE,
  SAVE_ACCOUNTANT_SUCCESS,
  SAVE_PARTY_RECEIVE,
  base_url_user,
} from "../../../../global.constants";

export const fetchParty_failure = (error) => {
  return {
    type: "FETCH_PARTY_FAILURE",
    payload: error,
  };
};

export const party_request = (payload) => {
  return {
    type: "PARTY_REQUEST",
    payload,
  };
};
export const party_receive = (payload) => {
  return {
    type: "PARTY_RECEIVE",
    payload,
  };
};

export const saveParty_receive = (data) => {
  return {
    type: "SET_ACC_DETAILS_LIST",
    payload: data,
  };
};

export const saveParty_failure = (error) => {
  return {
    type: "SAVE_PARTY_FAILURE",
    payload: error,
  };
};

export const getPartyData = (loanId, userId) => {
  const payload = { loanId: loanId, userId: userId };

  return async (dispatch) => {
    dispatch(party_request());
    const Url =
      base_url_user + `/api/v1/usersvc/ext/getThirdPartyWiringInfo.do`;
    axios
      .post(Url, payload)
      .then((response) => {
        if ((response && response.status == 200) || response.status == 202) {
          dispatch(setData(response.data?.recipientVOs));
        } else {
          dispatch(fetchParty_failure(response.status));
        }
      })
      .catch((e) => {
        dispatch(fetchParty_failure(e.response.status));
      });
  };
};

export const savePartyData = (data, userId, loanId) => {
  const reqPayload = {
    loanId: loanId,
    userId: userId,
    recipientVOs: data,
  };
  return async (dispatch) => {
    dispatch(party_receive());
    const Url = base_url_user + `/api/v1/usersvc/ext/saveWiringInfo.do`;
    axios
      .put(Url, reqPayload)
      .then((response) => {
        if (response && response.status === 200) {
          dispatch(saveParty_receive());
        } else {
          dispatch(saveParty_failure(response.status));
        }
      })
      .catch((e) => {
        dispatch(saveParty_failure(e.response.status));
      });
  };
};

export const updateData = (payload) => ({
  type: "UPDATE_ACC_DETAILS_LIST",
  payload,
});

export const setData = (payload) => ({
  type: "SET_ACC_DETAILS_LIST",
  payload,
});
