import React from "react";
import "./Error.scss";
import { ColumnLayout } from "@jpmuitk/column-layout";
import { Icon } from "@jpmuitk/icon";
import Grid from "@material-ui/core/Grid";
import { ErrorConstants } from "../../containers/global.constants";
import "../../containers/global.scss";

const Error = () => {
  return (
    <div className="dynamicContainer">
      <div className="error-top-container">
        <Icon className={`iconstyle`} name="error" size="medium" />
        <p className={`shortlyClass`}>{ErrorConstants.back}</p>
        <p className={`realClass`}>{ErrorConstants.update}</p>
        <p className={`advisorsClass`}>{ErrorConstants.available}</p>
      </div>
      <p className={`telephoneClass`}>{ErrorConstants.access}</p>
      <p>{ErrorConstants.reach}</p>
      <ColumnLayout>
        <Grid className={`tableGridClass`}>
          <ColumnLayout item xs={6}>
            <table className={`personTable`}>
              <tr>
                <td>{ErrorConstants.person}</td>
                <td>{ErrorConstants.threeFive}</td>
              </tr>
              <tr>
                <td>{ErrorConstants.businessBanking}</td>
                <td>{ErrorConstants.threeEight}</td>
              </tr>
              <tr>
                <td>{ErrorConstants.creditLoans}</td>
                <td>{ErrorConstants.fiveSix}</td>
              </tr>
              <tr>
                <td>{ErrorConstants.retirement}</td>
                <td>{ErrorConstants.sixOne}</td>
              </tr>
              <tr>
                <td>{ErrorConstants.privateClient}</td>
                <td>{ErrorConstants.twoSix}</td>
              </tr>
              <tr>
                <td className={`privateClass`}>
                  {ErrorConstants.privateClientBanker}
                </td>
              </tr>
              <tr>
                <td>{ErrorConstants.creditCards}</td>
                <td>{ErrorConstants.callThe}</td>
              </tr>
              <tr>
                <td>{ErrorConstants.online}</td>
                <td>{ErrorConstants.serviceOfficer}</td>
              </tr>
            </table>
          </ColumnLayout>
          <ColumnLayout item xs={6}>
            <table className={`mortgagesTable`}>
              <tr>
                <td>{ErrorConstants.mortgages}</td>
                <td>{ErrorConstants.threeSix}</td>
              </tr>
              <tr>
                <td>{ErrorConstants.autoLoansAndLeases}</td>
                <td>{ErrorConstants.sevenFive}</td>
              </tr>
              <tr>
                <td>{ErrorConstants.studentLoans}</td>
                <td>{ErrorConstants.zerFour}</td>
              </tr>
              <tr>
                <td>{ErrorConstants.jpmsecurities}</td>
                <td>{ErrorConstants.nineEight}</td>
              </tr>
              <tr>
                <td>{ErrorConstants.investment}</td>
                <td>{ErrorConstants.fourNine}</td>
              </tr>
            </table>
          </ColumnLayout>
        </Grid>
      </ColumnLayout>
    </div>
  );
};
export default Error;
