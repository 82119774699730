import axios from "axios";
import {
  GET_BANKERS_LIST,
  GET_STATUS_LIST,
  SUBMIT_SEARCH_PARAMS,
  EDIT_BANKERS_COMMENTS,
  SUBMIT_STATUS_CHANGE,
  base_url_admin,
  GET_LOAN_COMMENTS,
  CLEAR_LOAN_COMMENT,
  FETCH_STATUS_FAILURE,
  FETCH_BANKERS_FAILURE,
  FETCH_RESULTS_FAILURE,
  FETCH_COMMENTS_FAILURE,
  POST_FAILURE_BANKERS_COMMENTS,
  EDIT_BANKERS_COMMENTS_RECEIVED,
} from "../../../../global.constants";

const BASE_URL = base_url_admin + "/api/v1/adminsvc";

const errBuilder = (statu, msg) => {
  return {
    status: statu,
    response: {
      data: {
        message: msg,
      },
    },
  };
};

/* Banker List Data */
export const getBankersList = (payload) => ({
  type: GET_BANKERS_LIST,
  payload,
});

export const getBankersData = () => async (dispatch) => {
  const END_POINT = "/admin/getBankersList.do";
  try {
    const response = await axios.get(`${BASE_URL}${END_POINT}`);
    if (response && response.status === 200) {
      dispatch(getBankersList(response.data));
    } else {
      dispatch(
        fetchResults_failure(errBuilder(response.status, "Bad Request Error"))
      );
    }
  } catch (e) {
    dispatch(fetchResults_failure(errBuilder(404, "Not Found")));
    return e;
  }
};

/* GET Status Data */
export const getStatusList = (payload) => ({
  type: GET_STATUS_LIST,
  payload,
});

export const getStatusData = () => async (dispatch) => {
  const END_POINT = "/admin/getRequestStatus.do";
  try {
    const response = await axios.get(`${BASE_URL}${END_POINT}`);
    if (response && response.status === 200) {
      dispatch(getStatusList(response.data));
    } else {
      dispatch(fetchStatus_failure(response));
    }
  } catch (err) {
    dispatch(fetchStatus_failure(errBuilder(404, "Not Found")));
    return err;
  }
};

/* Search By Data */
export const submitSearchRequest = (payload) => {
  return {
    type: SUBMIT_SEARCH_PARAMS,
    payload,
  };
};

export const SearchRequest = (data) => async (dispatch) => {
  const END_POINT = `/admin/getOnlineRequests.do`;
  const pageNumber = data.isPagination ? `?pageNo=${data.page}` : "";
  localStorage.setItem("pageNo", parseInt(data.page) || 1);
  try {
    const response = await axios.put(
      `${BASE_URL}${END_POINT}${pageNumber}`,
      data.reqParams
    );
    if (response && response.status === 200) {
      dispatch(submitSearchRequest(response.data));
    } else {
      dispatch(
        fetchResults_failure(errBuilder(response.status, "Bad Request Error"))
      );
    }
  } catch (e) {
    dispatch(fetchResults_failure(errBuilder(404, "Not Found")));
    return e;
  }
};

/* Edit Bankers Comments */
export const editComment = (payload) => {
  return {
    type: EDIT_BANKERS_COMMENTS,
    payload,
  };
};

export const editCommentReceived = (payload) => ({
  type: EDIT_BANKERS_COMMENTS_RECEIVED,
  payload,
});

export const postFailureBankerComments = (payload) => ({
  type: POST_FAILURE_BANKERS_COMMENTS,
  payload,
});

export const editBankersComments = (payload, loanId, userId) => {
  const reqPayload = { ...payload, userId: userId, loanId: loanId };
  return async (dispatch) => {
    const url =
      base_url_admin + "/api/v1/adminsvc/admin/addUpdateBankerComment.do";
    axios
      .post(url, reqPayload)
      .then((res) => {
        if (res && res.status === 200) {
          dispatch(editCommentReceived());
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
};

/* Edit Status */
export const editStatus = (payload) => ({
  type: SUBMIT_STATUS_CHANGE,
  payload,
});

export const EditStatusData = (data) => async (dispatch) => {
  const payload = {
    ...data,
    userId: data.userId,
    loanId: data.loanId,
  };
  const END_POINT = `/admin/modifyRequestStatus.do`;
  try {
    const editCommts = await axios.post(`${BASE_URL}${END_POINT}`, payload);
    dispatch(editStatus(editCommts));
    dispatch(editComment(editCommts));
  } catch (e) {
    return e;
  }
};

/*Get Bankers Comment data */
export const getBankersComment = (payload) => {
  return {
    type: GET_LOAN_COMMENTS,
    payload,
  };
};

export const clearBankersComment = () => ({ type: CLEAR_LOAN_COMMENT });

export const getLoanComment = (data) => async (dispatch) => {
  const payload = { loanId: data.loanId };
  const END_POINT = `/admin/getBankerComments.do`;
  try {
    const resp = await axios.post(`${BASE_URL}${END_POINT}`, payload);
    dispatch(getBankersComment(resp));
    dispatch(editComment(resp));
  } catch (e) {
    return e;
  }
};

export const fetchStatus_failure = (error) => {
  return {
    type: FETCH_STATUS_FAILURE,
    payload: error,
  };
};

export const fetchBankers_failure = (error) => {
  return {
    type: FETCH_BANKERS_FAILURE,
    payload: error,
  };
};

export const fetchResults_failure = (error) => {
  return {
    type: FETCH_RESULTS_FAILURE,
    payload: error,
  };
};

export const fetchComments_failure = (error) => {
  return {
    type: FETCH_COMMENTS_FAILURE,
    payload: error,
  };
};
