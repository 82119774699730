import axios from 'axios';
import {
    FETCH_STATUS_REQUEST,
    FETCH_STATUS_FAILURE,
    FETCH_BANKERS_REQUEST,
    FETCH_BANKERS_FAILURE,
    FETCH_ADMIN_DASHBOARD_REQUEST,
    FETCH_ADMIN_DASHBOARD_FAILURE,
    FETCH_FRANCHISE_DATA_FAILURE,
    FETCH_FRANCHISE_DATA_REQUEST,
    REQUEST_BANKERS_REQUEST,
    base_url,
    base_url_loan,
    base_url_admin
} from '../../../../global.constants';

export const getBankersList = (payload) => async dispatch => {
    dispatch({ type: REQUEST_BANKERS_REQUEST });
    try {
        await axios.get(
            base_url_loan + `/api/v1/loansvc/admin/getBankersList.do`, payload)
            .then(response => {
                if (response && response.status === 200) {
                    dispatch(fetchStatus_request(response.data));
                }
                else {
                    dispatch(fetchBankers_failure(response.status))
                }
            });
    }
    catch (e) {
        dispatch(fetchBankers_failure(e.response))
        return e;
    }
};

export const getStatusList = (payload) => async dispatch => {
    dispatch({ type: REQUEST_BANKERS_REQUEST });
    try {
        await axios.get(
            base_url_loan + `/api/v1/loansvc/admin/getRequestStatus.do`, payload)
            .then(response => {
                if (response && response.status === 200) {
                    dispatch(fetchStatus_request(response.data));
                }
                else {
                    dispatch(fetchStatus_failure(response.status))
                }
            });
    }
    catch (e) {
        dispatch(fetchStatus_failure(e.response))
        return e;
    }
};
export const fetchStatus_request = (data) => {
    return {
        type: FETCH_STATUS_REQUEST,
        payload: data
    }
}

export const fetchStatus_failure = (error) => {
    return {
        type: FETCH_STATUS_FAILURE,
        payload: error
    }
}

export const fetchBankers_failure = (error) => {
    return {
        type: FETCH_BANKERS_FAILURE,
        payload: error
    }
}
export const getAdminDashboardData = (pageNo) => async dispatch => {
    dispatch({ type: REQUEST_BANKERS_REQUEST });
    try {
        await axios.get(
            base_url_admin + `/api/v1/adminsvc/admin/getFranchiseeDashboard.do?pageNo=${pageNo}`)
            .then(response => {
                if (response && response.status === 200) {
                    dispatch(fetchAdminDashboard_request(response.data));
                }
                else {
                    dispatch(fetchStatus_failure(response.status))
                }
            });
    }
    catch (e) {
        dispatch(fetchStatus_failure(e.response))
        return e;
    }
};
export const fetchAdminDashboard_request = (data) => {
    return {
        type: FETCH_ADMIN_DASHBOARD_REQUEST,
        payload: data
    }
}

export const fetchFranchises = (payload,page) => async dispatch => {
    try {
        dispatch({ type: REQUEST_BANKERS_REQUEST });
        await axios.post(
            base_url_admin + `/api/v1/adminsvc/admin/getFranchiseeDashboard.do?pageNo=${page}`, payload)
            .then(response => {
                if (response && response.status === 200) {
                    dispatch(fetchAdminDashboard_request(response.data));
                }
                else {
                    dispatch(fetchStatus_failure(response.status))
                }
            });
    }
    catch (e) {
        dispatch(fetchStatus_failure(e.response))
        return e;
    }
};















