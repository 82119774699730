import { Link } from "@jpmuitk/link";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Button } from "@jpmuitk/button";
import {
  postEditData,
  postResetData,
} from "./redux/actions/FranchiseProfileAction";
import "./FranchiseProfile.scss";
import { searchFranchise } from "../../global.constants";

const EditableActionCell = (props) => {
  let isCurrencyRow;
  const dispatch = useDispatch();
  if (props.params) {
    let editingCells = props.params.api.getEditingCells();
    isCurrencyRow = editingCells.some((cell) => {
      return cell.rowIndex === props.params.node.rowIndex;
    });
  }

  const onActionClick = useCallback((action, e) => {
    if (props.params.column.colId === searchFranchise.actions && action) {
      if (action === searchFranchise.edit) {
        props.params.api.setFocusedCell(
          props.params.node.rowIndex,
          props.params.columnApi.getDisplayedCenterColumns()[0].colId
        );
        props.params.api.startEditingCell({
          rowIndex: props.params.node.rowIndex,
          colKey: props.params.columnApi.getDisplayedCenterColumns()[0].colId,
        });
      }
      if (action === searchFranchise.save) {
        let oldEmail = props.params.data.emailAddress;
        props.params.api.stopEditing(false);
        window.scrollTo(0, 0);
        props.params.api.refreshCells({
          force: true,
        });
        const payload = {
          userId: props.params.data.userId,
          loginId: props.params.data.emailAddress,
          fullName: props.params.data.fullName,
          firstName: props.params.data.firstName,
          lastName: props.params.data.lastName || "",
          operatorId: props.params.data.operatorId,
          emailAddress: props.params.data.emailAddress,
          contactAltId: props.params.data.contactAltId,
          janusUserName: props.params.data.janusUserName,
          oldEmailAddress: oldEmail,
        };
        if (!payload.firstName) {
          return props.setError("First name cannot be empty");
        }
        if (!payload.lastName) {
          return props.setError("Last name cannot be empty");
        }
        if (!payload.emailAddress) {
          return props.setError("Email Address cannot be empty");
        }
        dispatch(postEditData(payload));
        window.scrollTo(0, 0);
      }
      if (action === searchFranchise.close) {
        props.params.api.stopEditing(true);
      }
      if (action === "Reset") {
        props.params.api.stopEditing(true);
        const payload = {
          userId: props.params.data.userId,
          loginId: props.params.data.emailAddress,
          operatorId: props.params.data.operatorId,
          emailAddress: props.params.data.emailAddress,
          contactAltId: props.params.data.contactAltId,
          janusUserName: props.params.data.janusUserName,
        };
        dispatch(postResetData(payload));
        window.scrollTo(0, 0);
        props.setShowBanner(true);
      }
    }
  }, []);
  return (
    <>
      {isCurrencyRow ? (
        <div className={`buttonContainer`}>
          <div className={`saveButtonClass`}>
            <div>
              {" "}
              <Button
                className={`gridButton`}
                variant="cta"
                onClick={() => onActionClick("Save")}
              >
                {searchFranchise.save}
              </Button>
            </div>
            <div className={`gridButton`}>
              <Link onClick={() => onActionClick("Close")}>Cancel</Link>
            </div>
          </div>
          <div>
            {" "}
            <Button
              className={`gridButton`}
              variant="cta"
              onClick={() => onActionClick("Reset")}
            >
              Reset Password
            </Button>
          </div>
        </div>
      ) : (
        <div className={`editActionClass`}>
          <Button
            variant="cta"
            className={`gridButton`}
            onClick={() => onActionClick("Edit")}
          >
            {searchFranchise.edit}{" "}
          </Button>
        </div>
      )}
    </>
  );
};

export default EditableActionCell;
