import {
  FETCH_FINANCE_REQUEST,
  FETCH_FINANCE_SUCCESS,
  FETCH_FINANCE_FAILURE,
  SUCCESS_BANNER
} from '../../../../global.constants';

const initialState = {
  financeData: [],
  error: '',
  isLoading: false,
  pdf: '',
  dashboardSuccesBanner: false,
}

const SubmitRequestFinanceFranchiesReducer = (state = initialState, {
  type,
  payload
}={}) => {
  switch (type) {
    case FETCH_FINANCE_REQUEST:
      return Object.assign(state, {
        isLoading: true,
      });

    case FETCH_FINANCE_SUCCESS:
      return Object.assign(state, {
        financeData: payload,
        isLoading: false,
      });

    case FETCH_FINANCE_FAILURE:
      return Object.assign(state, {
        error: payload,
        isLoading: false
      });
      case SUCCESS_BANNER:
      return Object.assign(state, {
        dashboardSuccesBanner: payload,
      });
    default:
      return state;
  }
};
export default SubmitRequestFinanceFranchiesReducer;
