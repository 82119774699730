import React, { useEffect, useState } from "react";
import { Button } from "@jpmuitk/button";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@jpmuitk/dialog";
import "./DialogBox.scss";
import { Icon } from "@jpmuitk/icon";
import {
  ContactInfoConstants,
  adminUpdaterates,
  DebtSummaryLabel,
  dialog,
  base_url_auth,
} from "../../containers/global.constants";
import {
  ReturntoDashboard,
  dialogModal,
  AdditionalCommentConstants,
  Tooltip_info,
} from "../../containers/global.constants";
import {
  cancelLoanRequest,
  fetchReturntoDashboard,
} from "../../containers/dynamic/returnToDashboard/redux/action/dashboardAction";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ButtonBar, OrderedButton } from "@jpmuitk/button-bar";

import { Input } from "@jpmuitk/input";
import { FormField } from "@jpmuitk/form-field";
import { Form } from "@jpmuitk/forms";
import Loader from "../loader/Loader";
import { getAuthCode } from "../../auth/redux/action/AuthCheckAction";
import { clearBankersComment } from "../../containers/admin/onlineRequest/redux/action/SearchFiltersActions";
import "../../containers/global.scss";
import axios from "axios";

const BasicDialogExample = (props) => {
  const cancelLoanId = useSelector(
    (state) => state.DashboardReducer.cancelLoanId
  );
  const dispatch = useDispatch();

  /* OnLine Request -- Starts */
  const commentsResp = useSelector((state) => state.OnLineReducer.getComment);
  const isLoadings = useSelector((state) => state.OnLineReducer.isLoading);
  const [comments, setComments] = useState("");
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const { setpopup } = props;

  useEffect(() => {
    setIsLoading(isLoadings);
  }, [isLoadings]);

  useEffect(() => {
    setComments(commentsResp?.data?.comments);
  }, [commentsResp]);

  const onSaveChanges = () => {
    setpopup(true);
    if (!props.dialogMsgs?.statusChangedFrom) {
      props.submitComments({
        userId: props.dialogMsgs?.userId,
        loanId: props.dialogMsgs?.loanId,
        theComments: comments,
      });
    } else {
      props.submitComments({
        userId: props.dialogMsgs?.userId,
        loanId: props.dialogMsgs?.loanId,
        theComments: comments,
        submit: true,
        loanAmount: props.dialogMsgs?.loanAmount,
        purposeName: props.dialogMsgs?.purposeName,
        franchisee: props.dialogMsgs?.franchisee,
        loanSubmitDate: props.dialogMsgs?.loanSubmitDate,
        purposes: props.dialogMsgs?.purposes,
        createdDate: props.dialogMsgs?.createdDate,
        noChangeStatus: props.dialogMsgs?.statusChangedTo,
        loanStatus: props.dialogMsgs?.statusChangedTo
          .replace(/\s+/g, "")
          .toLowerCase(),
      });
    }
    dispatch(clearBankersComment());
    props.setOpen(false);
  };

  const onClosing = () => {
    dispatch(clearBankersComment());
    props.setOpen(false);
  };

  /* OnLine Request -- Ends */

  const closeDialog = () => {
    props.closeDialog(false);
  };
  const handleSignOut = () => {
    history.push(`/fdao/signOut`);
    window.location.reload();
    props.closeDialog(false);
  };

  const closeDialogdashboard = () => {
    props.closeDialog(false);
  };

  const proceedWithCancel = () => {
    dispatch(cancelLoanRequest(cancelLoanId, props.userId));
    closeDialog();
  };

  const handleRefreshToken = () => {
    dispatch(getAuthCode({ env: "local" }));
    closeDialog();
  };

  const dialogTitle = (val) => {
    switch (val) {
      case "dashboard":
        return (
          <div className="clos">
            <Button
              className="returntoDashboard-dialogboxclose"
              aria-label="close dialog button"
              onClick={closeDialog}
            >
              <Icon size={14} name="close" />
            </Button>
            <DialogTitle>{ReturntoDashboard.cancelMsg}</DialogTitle>
          </div>
        );
      case "contact":
        return <DialogTitle>{ContactInfoConstants.dialogContent}</DialogTitle>;

      case "updatecurrent":
        return <DialogTitle>{adminUpdaterates.dialogContent}</DialogTitle>;

      case "currentDebtSummary":
        return <DialogTitle>{DebtSummaryLabel.dialogTitle}</DialogTitle>;

      case "sessionDailog":
        return (
          <DialogTitle>
            <Icon className="sesssion-warning" size={18} name="warning" />
            {"Your sessions is about to get expire!"}
            <Icon
              size={14}
              className="session-dialogboxclose"
              onClick={closeDialog}
              name="close"
            />
          </DialogTitle>
        );

      default:
        return null;
    }
  };

  return (
    <>
      {props.id !== "onLineReq" ? (
        <Dialog open={props.open}>
          {dialogTitle(props.id)}
          <DialogContent className="dialogContent">
            <h3>
              {props.id === "dashboard" ? ReturntoDashboard.dialogMsg : null}
            </h3>
            <p>
              {props.id === "sessionDailog"
                ? `You will be logged out in 30 minutes. Do you want to stay signed in?`
                : null}
            </p>
          </DialogContent>
          <DialogActions>
            {props.id === "sessionDailog" ? (
              <ButtonBar>
                <OrderedButton onClick={handleRefreshToken} variant="cta">
                  YES, KEEP ME LOGGED IN
                </OrderedButton>
                <OrderedButton onClick={handleSignOut}>
                  NO, SIGN ME OUT
                </OrderedButton>
              </ButtonBar>
            ) : null}

            {props.id === dialog.dashboard ? (
              <Button
                className={`commonStylingButton`}
                onClick={proceedWithCancel}
                variant="cta"
              >
                {" "}
                {ContactInfoConstants.okButton}{" "}
              </Button>
            ) : null}
            {props.id === dialog.dashboard ? (
              <Button onClick={closeDialogdashboard} variant="cta">
                {" "}
                {ReturntoDashboard.cancel}{" "}
              </Button>
            ) : null}
            {props.id === dialog.contact ? (
              <Button onClick={closeDialog} variant="cta">
                {" "}
                {ContactInfoConstants.okButton}{" "}
              </Button>
            ) : null}
            {props.id === dialog.currentDebtSummary ? (
              <Button onClick={closeDialog} variant="cta">
                {" "}
                {"Close"}{" "}
              </Button>
            ) : null}
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          maxWidth="md"
          height={!props.dialogMsgs?.statusChangedFrom ? 600 : "auto"}
          onClose={onClosing}
          open={props.open}
        >
          {!props.dialogMsgs?.statusChangedFrom ? (
            <>
              {commentsResp === null || (isLoading && <Loader />)}
              <DialogTitle onClose={onClosing}>
                <h1>{dialogModal.title}</h1>
              </DialogTitle>
              <DialogContent classes={{ root: "dialogClass" }}>
                <Form className={`bankerClass`}>
                  <div className={`modalContainer`}>
                    <div>
                      <FormField label="Franchisee Name">
                        <Input
                          value={props.dialogMsgs?.name}
                          disabled
                          className="txtBold"
                        />
                      </FormField>
                    </div>
                    <div>
                      <FormField label="Loan Creation Date">
                        <Input
                          value={props.dialogMsgs?.loanCreationData}
                          disabled
                          className="txtBold"
                        />
                      </FormField>
                    </div>
                    <div>
                      <FormField
                        label="Comments"
                        helperText={AdditionalCommentConstants.placeholderText}
                        helperTextPlacement={"tooltip"}
                        hasStatusIndicator
                      >
                        <span
                          tabIndex="0"
                          className="sr-only"
                          role="tooltip"
                          aria-label={Tooltip_info}
                        ></span>
                        <Input
                          multiline
                          value={comments}
                          onChange={(e) => setComments(e.target.value)}
                        ></Input>
                      </FormField>
                    </div>
                  </div>
                </Form>
              </DialogContent>
            </>
          ) : (
            <>
              <DialogContent className="dialogContent">
                <h3>
                  Are you sure to change status from{" "}
                  {props.dialogMsgs?.statusChangedFrom.toUpperCase()} to{" "}
                  {props.dialogMsgs?.statusChangedTo.toUpperCase()}?
                </h3>
              </DialogContent>
            </>
          )}

          <DialogActions>
            <ButtonBar>
              <OrderedButton variant="cta" onClick={onSaveChanges}>
                {props.dialogMsgs?.statusChangedFrom
                  ? "Save"
                  : dialogModal.save}
              </OrderedButton>
              {props.showCancel && (
                <OrderedButton
                  className={`commonStylingButton`}
                  onClick={onClosing}
                >
                  {dialogModal.cancel}
                </OrderedButton>
              )}
            </ButtonBar>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default BasicDialogExample;
