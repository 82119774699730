
import axios from 'axios'
import { REQUEST_GET_COMMENT_DATA, FAILURE_COMMENT_DATA, FAILURE_GET_COMMENT_DATA, RECEIVE_COMMENT_DATA, RECEIVE_GET_COMMENT_DATA, base_url,base_url_loan} from '../../../../global.constants'
export const getCommentData = (loanId, userId) => async dispatch => { 
    const payload = {"loanId": loanId, "userId": userId}
    try {
        dispatch({type : REQUEST_GET_COMMENT_DATA});
        await axios.post(
            base_url_loan+`/api/v1/loansvc/ext/getAdditionalComments.do`, payload)
            .then(response => {
                if (response && response.status === 200) {
                   dispatch(receiveGetCommentData(response.data));
                }
                else {
                     dispatch(failureGetCommentData(response.status))
                }
            });
    }
    catch (e) {
         dispatch(failureGetCommentData(e.response?.status))
        return e;
    }
};

export const postComment = (payload, loanId,userId) => async dispatch => {
    const reqPayload = {...payload, "userId": userId, "loanId": loanId}
    try {
        dispatch({ type: REQUEST_GET_COMMENT_DATA});
     await axios.put(
        base_url_loan+`/api/v1/loansvc/ext/saveAdditionalCommentsInformation.do`, reqPayload)
            .then(response => {
                if (response && response.status === 200) {
                    dispatch(receiveCommentData());
                }
                else {
                    dispatch(failureCommentData(response.status))
                }
            });
     }
    catch (e) {
        dispatch(failureCommentData(e.response?.status))
        return e;
    }
};

export const receiveCommentData = (payload) => ({
    type: RECEIVE_COMMENT_DATA,
    payload
})

export const failureCommentData = (payload) => ({
    type: FAILURE_COMMENT_DATA,
    payload
})

export const receiveGetCommentData = (payload) => ({
    type: RECEIVE_GET_COMMENT_DATA,
    payload
})
export const failureGetCommentData = (payload) => ({
    type: FAILURE_GET_COMMENT_DATA,
    payload
})


