import React from "react";
import { Link } from "@jpmuitk/link";
import { PersonalFinancialStatement } from "../../static.constants";
import "../../global.scss";
import { getFileAPI } from "../../commonactions/redux/actions/action";

const FinancialStatement = () => {
  return (
    <div className={`container`} id="main">
      <h1>{PersonalFinancialStatement.financialStatement}</h1>
      <div>
        <p className={`italic`}>
          {PersonalFinancialStatement.documentRequired}
        </p>
        <div>
          {PersonalFinancialStatement.review}
          <Link href={PersonalFinancialStatement.requiredUrl} className="bold">
            {PersonalFinancialStatement.information}
          </Link>
          {PersonalFinancialStatement.content}
          <p>
            {PersonalFinancialStatement.fillInformation}
            <mark>{PersonalFinancialStatement.highlighted}</mark>
            {PersonalFinancialStatement.section}
            <Link
              href=""
              onClick={(e) => getFileAPI(e, "pfs", "pdf")}
              target="_blank"
              aria-label={`${PersonalFinancialStatement.personalStatement} External link opens as PDF`}
              className="bold"
            >
              {PersonalFinancialStatement.personalStatement}
            </Link>
          </p>
          {PersonalFinancialStatement.providedContent}{" "}
          <Link
            href=""
            onClick={(e) => getFileAPI(e, "pfs", "pdf")}
            target="_blank"
            aria-label={`${PersonalFinancialStatement.financialStatement} External link opens as PDF`}
            className="bold"
          >
            {PersonalFinancialStatement.financialStatement}
          </Link>
          {PersonalFinancialStatement.format}
          <mark>{PersonalFinancialStatement.highlighted}</mark>
          {PersonalFinancialStatement.pages}
          <mark>{PersonalFinancialStatement.pageNo}</mark>
        </div>
      </div>
    </div>
  );
};
export default FinancialStatement;
