import axios from 'axios';
import { FETCH_PHYSICALMAILINGADDRESS_REQUEST, FETCH_PHYSICALMAILINGADDRESS_SUCCESS, FETCH_PHYSICALMAILINGADDRESS_FAILURE , 
    FETCH_MAILINGADDRESS_REQUEST, FETCH_MAILINGADDRESS_SUCCESS, FETCH_MAILINGADDRESS_FAILURE , FETCH_HOMEINFOADDRESS_REQUEST,
    FETCH_HOMEINFOADDRESS_SUCCESS, FETCH_HOMEINFOADDRESS_FAILURE, FETCH_CONTACTINFOADDRESS_REQUEST, 
    FETCH_CONTACTINFOADDRESS_SUCCESS,FETCH_CONTACTINFOADDRESS_FAILURE,
    SAVE_PHYSICALADDRESS_REQUEST, SAVE_PHYSICALADDRESS_SUCCESS,SAVE_PHYSICALADDRESS_FAILURE, base_url,base_url_user

} from '../../../../global.constants';

export const fetchPhysicalAddress_request = (data)=>{
   return {
       type: FETCH_PHYSICALMAILINGADDRESS_REQUEST,
       
   }
}
export const fetchPhysicalAddress_success = (data)=>{
    return {
        type: FETCH_PHYSICALMAILINGADDRESS_SUCCESS,
        payload : data
    }
}
export const fetchPhysicalAddress_failure = (error)=>{
    return {
        type: FETCH_PHYSICALMAILINGADDRESS_FAILURE,
        payload : error 
    }
}

export const fetchMailingaddress_request = ()=>{
    return {
        type: FETCH_MAILINGADDRESS_REQUEST,
        
    }
}

export const fetchMailingaddress_success = (data)=>{
    return {
        type:FETCH_MAILINGADDRESS_SUCCESS,
        payload : data
    }
}
export const fetchMailingaddress_failure = ()=>{
    return {
       type: FETCH_MAILINGADDRESS_FAILURE,
    }
}

export const fetchHomeInfoaddress_request = ()=>{
    return {
        type:FETCH_HOMEINFOADDRESS_REQUEST,
        
    }
}
export const fetchHomeInfoaddress_success = (data)=>{
    return {
        type:FETCH_HOMEINFOADDRESS_SUCCESS,
        payload : data
    }
}
export const fetchHomeInfoaddress_failure = ()=>{
    return {
        type:FETCH_HOMEINFOADDRESS_FAILURE,
    }
}

export const fetchContactInfoaddress_request = ()=>{
  return{
    type:FETCH_CONTACTINFOADDRESS_REQUEST,
   
  }
}
export const fetchContactInfoaddress_success = (data)=>{
    return{
        type:FETCH_CONTACTINFOADDRESS_SUCCESS,
        payload : data
    }
  }
  export const fetchContactInfoaddress_failure = ()=>{
    return{
        type: FETCH_CONTACTINFOADDRESS_FAILURE,
    }
  }

  
  
  export const savePhysicalAddress_request = ()=>{
    return{
        type: SAVE_PHYSICALADDRESS_REQUEST,
    }
  }

  export const savePhysicalAddress_success = ()=>{
    return{
        type: SAVE_PHYSICALADDRESS_SUCCESS,
    }
  }
  export const savePhysicalAddress_failure = ()=>{
    return{
        type: SAVE_PHYSICALADDRESS_FAILURE,
    }
  }






export const   getPhysicalAddressData = (loanId) => {
  const payload = {"loanId":loanId}
 return  async (dispatch) => {
    dispatch(fetchPhysicalAddress_request())
    const Url = base_url_user+`/api/v1/usersvc/ext/getPhysicalAddress.do`
   
  axios.post(Url, payload)
  .then(response => {
     if (response && response.status === 200){
        const  Physicalpayload = response.data
        dispatch(fetchPhysicalAddress_success(Physicalpayload))
     }
     else{
        dispatch(fetchPhysicalAddress_failure(response.status))
     }
   
  })
  .catch((e)=> {
    dispatch(fetchPhysicalAddress_failure(e.response.status))
  }); 
   
 }
};


export const fetchMailingaddressdata = (loanId)=>{
  const payload = {"loanId":loanId}
    return  async (dispatch) => {
        dispatch(fetchMailingaddress_request())
        const Url = base_url_user+ `/api/v1/usersvc/ext/copyAddress.do?addressType=MAILING`
      axios.post(Url, payload)
      .then(response => {
         if (response && response.status === 200){
            let mailingaddrespayload = response.data;
            dispatch(fetchMailingaddress_success(mailingaddrespayload))
         }
         else{
            dispatch(fetchMailingaddress_failure(response.status))
         }
      })
      .catch((e)=> {
        dispatch(fetchMailingaddress_failure(e.response.status))
      }); 
       
     }

}


export const fetchHomeInfoaddressdata = (loanId)=>{
  const payload = {"loanId":loanId}
    return  async (dispatch) => {
        dispatch(fetchHomeInfoaddress_request())
      const Url = base_url_user+ `/api/v1/usersvc/ext/copyAddress.do?addressType=HOME`
      axios.post(Url, payload)
      .then(response => {
         if (response && response.status === 200){
            let homeaddrespayload = response.data;
            dispatch(fetchHomeInfoaddress_success(homeaddrespayload))
         }
         else{
            dispatch(fetchHomeInfoaddress_failure(response.status))
         }
      })
      .catch((e)=> {
        dispatch(fetchHomeInfoaddress_failure(e.response.status))
      }); 
       
     }

}

export const fetchContactInfoaddressdata = (loanId)=>{
  const payload = {"loanId":loanId}
    return  async (dispatch) => {
        dispatch(fetchContactInfoaddress_request("Request"))
        const Url = base_url_user+`/api/v1/usersvc/ext/copyAddress.do?addressType=OFFICE`
      axios.post(Url, payload)
      .then(response => {
         if (response && response.status === 200){
            let officeaddrespayload = response.data;
            dispatch(fetchContactInfoaddress_success(officeaddrespayload))
         }
         else{
            dispatch(fetchContactInfoaddress_failure(response.status))
         }
      })
      .catch((e)=> {
        dispatch(fetchContactInfoaddress_failure(e.response.status))
      }); 
       
     }

}


export const savePhysicalAddressData = ( data, loanId, userId) => {
 return async (dispatch) => {
    dispatch(savePhysicalAddress_request())
    const Url = base_url_user+ `/api/v1/usersvc/ext/updatePhysicalAddress.do`
  axios.put(Url , data )
  .then(response => {
     if (response && response.status === 200){
        dispatch(savePhysicalAddress_success())
     }
     else{
        dispatch(savePhysicalAddress_failure())
     }
   
  })
  .catch((error)=> {
    dispatch(savePhysicalAddress_failure(error))
  }); 
   
 }
}