import React from "react";
import { Link } from "@jpmuitk/link";
import { RegistrationSuccessData } from "../../global.constants";
import "../../global.scss";

const RegistrationSuccess = () => {
  return (
    <div className={`authenticationContainer`} id="main">
      <h1>{RegistrationSuccessData.header}</h1>
      <p className={`marginTop20`}>
        {RegistrationSuccessData.accountCreatedInfo}
      </p>
      <ul className="listItems listStyleCustom">
        <li>An e-mail with your User ID and a temporary password.</li>
      </ul>
      <p className={`marginTop30`}>
        {RegistrationSuccessData.registrationProcessInfo}
      </p>
      <ol className="listItems">
        <li>
          {RegistrationSuccessData.linkRegistrationProcessStep1}
          <Link
            target="_blank"
            href={
              window.location.hostname.includes("dev") ||
              window.location.hostname.includes("uat")
                ? RegistrationSuccessData.janusUatLink
                : RegistrationSuccessData.janusProdLink
            }
          >
            link
          </Link>
          {RegistrationSuccessData.linkRegistrationProcessStep2}
        </li>
        {RegistrationSuccessData.registrationProcessSteps.map((step) => (
          <li>{step}</li>
        ))}
        <li>
          {RegistrationSuccessData.registrationProcessLastStep}{" "}
          <Link target="_blank" href="/newReturningUser">
            application
          </Link>
        </li>
      </ol>
      <p className={`marginTop30`}>{RegistrationSuccessData.assistanceInfo}</p>
      <p className={`marginTop20 marginBottom45`}>
        {RegistrationSuccessData.Click}
        <Link target="_blank" href="/home">
          here
        </Link>
        {RegistrationSuccessData.returnHome}
      </p>
    </div>
  );
};
export default RegistrationSuccess;
