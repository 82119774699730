import {
    REQUEST_LOGGEDIN_USER,
    FETCH_LOGGEDIN_USER,
    FETCH_LOGGEDINUSR_FAILURE,
    FETCH_LOGGEDIN_USER_ID,
    FETCH_LOGGEDINUSER_ID_FAILURE,
    FETCH_AUTH_CODE,
    FETCH_AUTHCODE_FAILURE,
    REQUEST_AUTH_CODE, 
    FETCH_LOGGEDIN_ADMIN_ID,
    FETCH_LOGGEDIN_USER_NAME
} from '../../../containers/global.constants';

const initialState = {
    loggedInUser: [],
    AdminloggedInUser:'',
    isLoading: false,
    loggedInUserId:'',
    error: '',
    authCode: '',
    authCodeError: '',
    errorMsg: '',
    loggedInUserName: '',
}

export const AuthCheckReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case REQUEST_LOGGEDIN_USER:
            return {
                ...state,
                isLoading: true
            }
        case FETCH_LOGGEDIN_USER:
            return {
                ...state,
                loggedInUser: payload,
                loggedInUserName: payload?.fullName
              
            }
        case FETCH_LOGGEDINUSR_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload,
                errorMsg: payload?.status === 500 ? payload?.response?.error : ''
            }
        case FETCH_LOGGEDIN_USER_ID:
            return {
                ...state,
                isLoading: false,
                loggedInUserId: payload
            }
        case FETCH_LOGGEDIN_USER_NAME:
            return {
                ...state,
                loggedInUserName: payload
            }
        case  FETCH_LOGGEDIN_ADMIN_ID:
            return {
                    ...state,
                    isLoading: false,
                    AdminloggedInUser:payload
                }

        case FETCH_LOGGEDINUSER_ID_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload,
                errorMsg: payload?.status === 500 ? payload?.response?.error : ''
            }
        case REQUEST_AUTH_CODE:
            return {
                ...state,
                isLoading: true
            }
        default:
            return state;
    }

}


export const AuthCodeReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_AUTHCODE_FAILURE:
        return {
            ...state,
            isLoading: false,
            authCodeError: payload
        }
    case FETCH_AUTH_CODE:
        return {
            ...state,
            authCode: payload
        }
    default:
        return state;
}
}

