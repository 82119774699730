import React from 'react';
import { miscellaneousDetails } from '../../global.constants';
import { FormField } from '@jpmuitk/form-field';
import Grid from '@material-ui/core/Grid';
import { Input } from '@jpmuitk/input';
import { Button } from '@jpmuitk/button';
import './MiscellaneousDetails.scss';
import {isNumber} from '../../global.constants';

const Owners = (props) => {
    const {  remove, data, ind,itemDeleted } = props;
    const handleChange = (e) => {
        let value = e.target.value
        props.setNewUpdate((state) => {
            return {
                ...state,
                otherOwnerDetails: state['otherOwnerDetails'].map((x,i) => {
                    if (i===ind) {
                      
                        return {
                            ...x,
                            owrName: value
                        }
                    }
                    else return x
                })
            }
        })
    }
    const handleYearChange = (e) => {
        let value = e.target.value
        props.setNewUpdate((state) => {
            return {
                ...state,
                otherOwnerDetails: state['otherOwnerDetails'].map((x,i) => {
                    if (i===ind) {
                        return {
                            ...x,
                            approvedYear: value
                        }
                    }

                    else return x
                })
            }
        })
    }
    const handleRelationChange = (e) => {
        let value = e.target.value
        props.setNewUpdate((state) => {
            return {
                ...state,
                otherOwnerDetails: state['otherOwnerDetails'].map((x,i) => {
                    if (i===ind) {
                        return {
                            ...x,
                            relationship: value
                        }
                    }

                    else return x
                })
            }
        })
    }

    return (
        <div className={`nestedOwnerDetailsClass`}>
            <div className={`headerContainer`}>
                <h2 className={`headingClass`}>
                    {`${miscellaneousDetails.ownerSubHeader} ${ind+1}`}
                </h2>
                <Button
                    onClick={() =>  remove(ind)}
                >
                    {miscellaneousDetails.deleteButton}
                    {itemDeleted && ind===0 ? <span class="sr-only" aria-live="assertive" role="alert" aria-label= {`Owner/Operators ${itemDeleted+1} deleted`} ></span> : "" }
                </Button>
            </div>
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <FormField
                        label={miscellaneousDetails.nameOfOwner}
                    >
                        <Input
                            name='notes'
                            fullWidth
                            autoComplete='off'
                            value={data?.owrName}
                            onChange={handleChange}
                        />
                    </FormField>
                </Grid>
                <Grid item xs={12}>
                    <FormField label={miscellaneousDetails.approved}>
                        <Input
                            name='year'
                            fullWidth
                            autoComplete='off'
                            value={data?.approvedYear}
                            onChange={handleYearChange}
                            inputProps={{ maxLength: 4 }}
                            onKeyPress={(e) => isNumber(e, e.target.name)}
                        />
                    </FormField>
                </Grid>
                <Grid item xs={12}>
                    <FormField label={miscellaneousDetails.relation}>
                        <Input
                            name='notes'
                            fullWidth
                            autoComplete='off'
                            value={data?.relationship}
                            onChange={handleRelationChange}
                        />
                    </FormField>
                </Grid>
            </Grid>
        </div >
    )
}
export default Owners;