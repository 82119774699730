import React, { useState } from 'react';
import { AppHeaderNext } from '@jpmuitk/app-header';

const AppNav = () => {
  const [viewIndex, setViewIndex] = useState(0);

  const handleNavItemClick = (payload) => {
    setViewIndex(payload.index);
  };

  const handleActionItemClick = (props) => {
    console.log('action', 'onItemClick', props);
  };

  const navigationProps = {
    currentIndex: viewIndex,
    onItemClick: handleNavItemClick,
  };

  const navigationSource = [{ itemId: 'home', label: 'Home' }];

  const actionProps = {
    iconSize: 12,
    onItemClick: handleActionItemClick,
  };

  const actionSource = [
    { itemId: 'search', overflowLabel: 'Search', iconName: 'search' },
    {
      itemId: 'messages',
      ariaLabel: 'Messages 50',
      overflowLabel: 'Messages',
      badgeContent: 50,
    },
  ];

  return (
    <AppHeaderNext
      actionProps={actionProps}
      actionSource={actionSource}
      appTitle="App Name"
      navigationProps={navigationProps}
      navigationSource={navigationSource}
    />
  );
};

export default AppNav;
