import axios from 'axios';
import { FETCH_BUSSINESSADDRESSINFO_REQUEST, FETCH_BUSSINESSADDRESSINFO_SUCCESS, 
    FETCH_BUSSINESSADDRESSINFO_FAILURE ,  
    SAVE_BUSINESSADDRESDATA_REQUEST , 
    SAVE_BUSINESSADDRESDATA_SUCCESS,
    SAVE_BUSINESSADDRESDATA_FAILURE,
    FETCH_COPYHOMEADDRESS_REQUEST,
    FETCH_COPYHOMEADDRESS_SUCCESS,
    FETCH_COPYHOMEADDRESS_FAILURE,base_url,base_url_user

} from '../../../../global.constants';

export const fetchBusinessAddress_request = ()=>{
   return {
       type: FETCH_BUSSINESSADDRESSINFO_REQUEST,
       
   }
}
export const fetchBusinessAddress_success = (data)=>{
    return {
        type: FETCH_BUSSINESSADDRESSINFO_SUCCESS,
        payload : data
    }
}
export const fetchBusinessAddress_failure = (error)=>{
    return {
        type: FETCH_BUSSINESSADDRESSINFO_FAILURE,
        payload : error 
    }
}



export const fetchCopyhomeaddress_request = ()=>{
    return {
        type: FETCH_COPYHOMEADDRESS_REQUEST,
    }
}


export const fetchCopyhomeaddress_success = (data)=>{
    return {
        type: FETCH_COPYHOMEADDRESS_SUCCESS,
        payload : data 
    }
}

export const fetchCopyhomeaddress_failure = ()=>{
    return {
        type: FETCH_COPYHOMEADDRESS_FAILURE,
    }
}


export const savebusinessaddress_request = ()=>{
    return {
        type: SAVE_BUSINESSADDRESDATA_REQUEST,
        
    }
}


export const savebusinessaddress_success = ()=>{
    return {
        type: SAVE_BUSINESSADDRESDATA_SUCCESS,
        
    }
}

export const savebusinessaddress_failure = ()=>{
    return {
        type: SAVE_BUSINESSADDRESDATA_FAILURE,
       
    }
}


export const   getBusinessAddressData = (loanId, userId) => {
    const payload={ "loanId": loanId, "userId": userId}
 return  async (dispatch) => {
    dispatch(fetchBusinessAddress_request())
    const Url = base_url_user+ `/api/v1/usersvc/ext/getBusinessAddressInfo.do`
    axios.post(Url, payload)
  .then(response => {
     if (response && response?.status === 200){
     const  bussinesspayload = response?.data  
     dispatch(fetchBusinessAddress_success(bussinesspayload))
     }
     else{
        dispatch(fetchBusinessAddress_failure(response.status))
     }
  })
  .catch((e)=> {
    dispatch(fetchBusinessAddress_failure(e.response.status))
  }); 
   
 }
};

export const   getCopyhomeaddress = (loanId, userId) => {
    const payload={"loanId": loanId, "userId": userId}
    return  async (dispatch) => {
       dispatch(fetchCopyhomeaddress_request())
    const Url = base_url_user+`/api/v1/usersvc/ext/getHomeAddress.do`
     axios.post(Url, payload)
     .then(response => {
        if (response && response.status === 200){
        const  homedata = response.data  
           dispatch(fetchCopyhomeaddress_success(homedata))
        }
        else{
          
           dispatch(fetchCopyhomeaddress_failure(response.status))
        }
     })
     .catch((e)=> {
       dispatch(fetchCopyhomeaddress_failure(e.response.status))
     }); 
      
    }
   };


export const saveBusinessAddressData = ( data,loanId, userId) => {
 return async (dispatch) => {
    dispatch(savebusinessaddress_request())
    const Url = base_url_user+`/api/v1/usersvc/ext/updateBusinessAddressInfo.do`
  axios.put(Url , data)
.then(response => {
     if (response && response.status === 200){
        dispatch(savebusinessaddress_success())
     }
     else{
        dispatch(savebusinessaddress_failure())
     }
   
  })
  .catch((error)=> {
    dispatch(fetchBusinessAddress_failure(error))
  }); 
   
 }
}