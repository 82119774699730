import React, { useEffect, useState } from "react";
import "./BusinessAddressInfo.scss";
import { FormField, useValidation } from "@jpmuitk/form-field";
import Grid from "@material-ui/core/Grid";
import { Input } from "@jpmuitk/input";
import { FormattedInput } from "@jpmuitk/formatted-input";
import { OrderedButton, ButtonBar } from "@jpmuitk/button-bar";
import { Button } from "@jpmuitk/button";
import { Dropdown } from "@jpmuitk/dropdown";
import { useSelector, useDispatch } from "react-redux";
import {
  getBusinessAddressData,
  getCopyhomeaddress,
  saveBusinessAddressData,
} from "./redux/action/businessAction";
import { getCountrystatesData } from "../../commonactions/redux/actions/action";
import {
  BussinesAdressInfo,
  ERRORCODE,
  Tooltip_info,
  Accessibility_text,
} from "../../global.constants";
import { useHistory } from "react-router-dom";
import Error from "../../../components/error/Error";
import Loader from "../../../components/loader/Loader";
import HiddenValidator from "../../../containers/HiddenValidator";
import "../../global.scss";
import { formHas_error } from "../../dynamic/returnToDashboard/redux/action/dashboardAction";
import {
  businessZipCodeRules,
  businessOfficeZipCodeRules,
  businessAddressInfoRules,
  businessExecutiveAddressInfoRules,
  businessemailValidationRules,
  businessphoneValidationRules,
  businessOfficephoneValidationRules,
  businessOtherOfficephoneValidationRules,
} from "../../ValidationRules";
import {
  getNextNavigation,
  getPreviousNavigation,
} from "../../../components/navigationPath/navigationPath";
import { Banner } from "@jpmuitk/banner";
import { AriaAnnouncerProvider } from "@jpmuitk/aria-announcer";
// Userstory FFANEW-31
const BusinessAddressInfo = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const perfferedtype = ["Select", "Office", "Home", "Mobile"];
  const [copymailingbusinessaddress, setCopyMailingbusinessaddress] =
    useState(true);
  const businessAddressData = useSelector(
    (state) => state.BussinessAddressInfoReducer.BusinessAddressInfoData
  );
  const isLoading = useSelector(
    (state) => state.BussinessAddressInfoReducer.isLoading
  );
  const copycontactAddressData = useSelector(
    (state) => state.BussinessAddressInfoReducer.CopymailingaddressData
  );
  const errors = useSelector(
    (state) => state.BussinessAddressInfoReducer.error
  );
  const userId = useSelector((state) => state.AuthCheckReducer.loggedInUserId);
  const [bussinessData, setBussinessdata] = useState({});
  const countrystateData = useSelector(
    (state) => state.CountrystatesReducer.Countrystates
  );
  const loanId = useSelector((state) => state.DashboardReducer?.currentLoan);
  const newcountrystatesarray = countrystateData?.map((x) => x.stateName);
  const [addrssZipExceptions, validateAddresszip] =
    useValidation(businessZipCodeRules);
  const [officeZipExceptions, validateOfficezip] = useValidation(
    businessOfficeZipCodeRules
  );
  const [addrssExceptions, validateAddress] = useValidation(
    businessAddressInfoRules
  );
  const [addemailExceptions, validateAddmail] = useValidation(
    businessemailValidationRules
  );
  const [addphoneExceptions, validateAddphone] = useValidation(
    businessphoneValidationRules
  );
  const [executiveaddrssExceptions, validateexecutiveAddress] = useValidation(
    businessExecutiveAddressInfoRules
  );
  const [officephoneExceptions, validateofficephone] = useValidation(
    businessOfficephoneValidationRules
  );
  const [officeStatusIndicator, setOfficestatusindicator] = useState(false);
  const [mailingStatusIndicator, setMailingstatusindicator] = useState(false);
  const [copyhomespan, setCopyhomespan] = useState(false);
  const [copymailspan, setCopymailspan] = useState(false);
  const mergedErrorMessages = (exceptions) =>
    exceptions.map(({ type, message }) => `${message}`).join("");
  const mailingHelperText = !mergedErrorMessages(addrssExceptions)
    ? true
    : false;
  const officeAddresHelperText = !mergedErrorMessages(executiveaddrssExceptions)
    ? true
    : false;
  useEffect(() => {
    if (businessAddressData) {
      setBussinessdata(businessAddressData);
    }
  }, [businessAddressData]);

  useEffect(() => {
    if (copycontactAddressData) {
      setBussinessdata({
        ...bussinessData,
        officeAddress: copycontactAddressData?.officeAddress
          ? copycontactAddressData?.officeAddress
          : "",
        officeCity: copycontactAddressData?.officeCity
          ? copycontactAddressData?.officeCity
          : "",
        officeStateName: copycontactAddressData?.officeStateName,
        officeStateId: copycontactAddressData?.officeStateId,
        officeZipCode: copycontactAddressData?.officeZipCode,
      });
    }
  }, [copycontactAddressData]);

  useEffect(() => {
    if (loanId?.activeLoanId) {
      dispatch(getBusinessAddressData(loanId?.activeLoanId, userId));
      if (countrystateData) {
        dispatch(getCountrystatesData());
      }
    }
  }, []);

  const updateStore = () => {
    if (bussinessData) {
      saveBusinessData();
    }
  };

  const handleChange = (e, selectedItem, fieldname) => {
    const { name } = e.target;
    var itemSelected = selectedItem;
    if (fieldname === "mailingStateName") {
      const selectedRegion = countrystateData?.find(
        (x) => x.stateName === selectedItem
      );
      setBussinessdata({
        ...bussinessData,
        mailingStateName: selectedItem == "Select" ? "" : selectedItem,
        mailingStateId: selectedRegion?.stateId,
      });
    } else if (fieldname === "officeStateName") {
      const selectedRegion = countrystateData?.find(
        (x) => x.stateName === selectedItem
      );
      setBussinessdata({
        ...bussinessData,
        officeStateName: itemSelected == "Select" ? "" : selectedItem,
        officeStateId: selectedRegion?.stateId,
      });
    } else if (fieldname === "prefPhoneType") {
      setBussinessdata({
        ...bussinessData,
        prefPhoneType: itemSelected,
      });
    } else {
      if (name === "mailingCity") {
        if (bussinessData.mailingAddress == "") {
          validateAddress(bussinessData.mailingAddress);
        }
      }
      if (name === "mailingAddress") {
        if (e.target.value != "") {
          validateAddress(e.target.value);
        }
      }

      if (name === "officeCity") {
        if (bussinessData.officeAddress == "") {
          validateexecutiveAddress(bussinessData.officeAddress);
        }
      }
      if (name === "officeAddress") {
        if (e.target.value != "") {
          validateexecutiveAddress(e.target.value);
        }
      }
      if (name == "addlEmail") {
        validateAddmail("");
      }

      setBussinessdata({
        ...bussinessData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handlezipChange = (value, id) => {
    if (id === "mailingZipCode") {
      validateAddresszip("");
      if (bussinessData.mailingAddress == "") {
        validateAddress(bussinessData.mailingAddress);
      }

      setBussinessdata({
        ...bussinessData,
        mailingZipCode: value,
      });
    }
    if (id === "officeZipCode") {
      validateOfficezip("");
      if (bussinessData.officeAddress == "") {
        validateexecutiveAddress(bussinessData.officeAddress);
      }
      setBussinessdata({
        ...bussinessData,
        officeZipCode: value,
      });
    }
    if (id === "officePhoneNo") {
      validateofficephone([]);
      setBussinessdata({
        ...bussinessData,
        officePhoneNo: value,
      });
    }

    if (id == "otherPhoneNo") {
      setBussinessdata({
        ...bussinessData,
        otherPhoneNo: value,
      });
    }

    if (id === "addlPhoneNo") {
      validateAddphone([]);
      setBussinessdata({
        ...bussinessData,
        addlPhoneNo: value,
      });
    }
  };
  const onBlurValidation = (e, id) => {
    const { value } = e.target;
    if (id == "mailingZipCode") {
      if (value.length != 10 && value.length != 5) {
        validateAddresszip(value);
      } else {
        validateAddresszip([]);
      }
    } else if (id == "officeZipCode") {
      if (value.length != 10 && value.length != 5) {
        validateOfficezip(value);
      } else {
        validateOfficezip([]);
      }
    }
  };

  const copyMailingaddress = (e) => {
    setCopyMailingbusinessaddress(true);
    setCopymailspan(true);
    if (copymailingbusinessaddress) {
      setBussinessdata({
        ...bussinessData,
        officeAddress:
          bussinessData.mailingAddress === null ||
          bussinessData.mailingAddress === undefined ||
          bussinessData.mailingAddress === ""
            ? ""
            : bussinessData.mailingAddress,
        officeCity:
          bussinessData.mailingCity === null ||
          bussinessData.mailingCity === undefined ||
          bussinessData.mailingCity === ""
            ? ""
            : bussinessData.mailingCity,
        officeStateName: bussinessData.mailingStateName,
        officeStateId: bussinessData.mailingStateId,
        officeZipCode: bussinessData.mailingZipCode,
      });
    }
  };

  const copyContactInfoaddress = (e) => {
    if (loanId?.activeLoanId) {
      dispatch(getCopyhomeaddress(loanId?.activeLoanId, userId));
    }
  };

  const saveBusinessData = (e) => {
    const {
      mailingStateName,
      mailingCity,
      mailingZipCode,
      mailingAddress,
      mailingAddressId,
    } = bussinessData;
    const {
      officeStateName,
      officeCity,
      officeZipCode,
      officeAddress,
      officeAddressId,
    } = bussinessData;
    const {
      addlEmail,
      addlPhoneNo,
      addlPhoneId,
      officePhoneNo,
      officePhoneId,
    } = bussinessData;

    bussinessData.mailingAddressId =
      mailingStateName && mailingCity && mailingZipCode && mailingAddress
        ? mailingAddressId
        : null;
    bussinessData.officeAddressId =
      officeStateName && officeCity && officeZipCode && officeAddress
        ? officeAddressId
        : null;
    bussinessData.addlEmail = addlEmail ? addlEmail : null;
    bussinessData.addlPhoneId = addlPhoneNo ? addlPhoneId : null;
    bussinessData.addlPhoneNo = addlPhoneNo ? addlPhoneNo : null;
    bussinessData.officePhoneNo = officePhoneNo ? officePhoneNo : null;
    bussinessData.officePhoneId = officePhoneNo ? officePhoneId : null;
    if (loanId?.activeLoanId) {
      let copyData = bussinessData;
      copyData.loanId = loanId.activeLoanId;
      setBussinessdata(copyData);
      dispatch(
        saveBusinessAddressData(bussinessData, loanId?.activeLoanId, userId)
      );
    }
  };

  const handleNextClick = (sameComp = true) => {
    checkAndHandleNavigation(getNextNavigation(), sameComp);
    window.scrollTo(0, 0);
  };

  const checkAndHandleNavigation = (navigationData, sameComp = true) => {
    const allMailingEmpty =
      !bussinessData.mailingStateName &&
      !bussinessData.mailingCity &&
      !bussinessData.mailingZipCode &&
      !bussinessData.mailingAddress;

    const allOfficeEmpty =
      !bussinessData.officeStateName &&
      !bussinessData.officeCity &&
      !bussinessData.officeZipCode &&
      !bussinessData.officeAddress;

    if (
      (allMailingEmpty && allOfficeEmpty) ||
      (allMailingEmpty && bussinessData.officeAddress) ||
      (allOfficeEmpty && bussinessData.mailingAddress) ||
      (bussinessData.officeAddress && bussinessData.mailingAddress)
    ) {
      updateStore();
      if (!sameComp) {
        dispatch(formHas_error(false));
        return false;
      }
      history.push(navigationData);
      window.scrollTo(0, 0);
    } else {
      if (!allMailingEmpty) {
        if (
          bussinessData.mailingAddress == "" ||
          bussinessData.mailingAddress == null
        ) {
          validateAddress("");
          setMailingstatusindicator(true);
        }
      }
      if (!allOfficeEmpty) {
        if (
          bussinessData.officeAddress == "" ||
          bussinessData.officeAddress == null
        ) {
          validateexecutiveAddress("");
          setOfficestatusindicator(true);
        }
      }
    }
  };

  const handlePreviousClick = () => {
    checkAndHandleNavigation(getPreviousNavigation());
    window.scrollTo(0, 0);
  };

  const parseDigits = (string) => (string.match(/(\d+)/g) || []).join("");
  const formatValue = (string) => {
    const digits = parseDigits(string);
    const chars = digits.split("");
    return chars
      .reduce((r, v, index) => (index === 5 ? `${r}-${v}` : `${r}${v}`), "")
      .substr(0, 10);
  };

  const formatValueWithAppend = (string) => {
    const res = formatValue(string);

    if (string.endsWith("-")) {
      if (res.length === 5) {
        return `${res}-`;
      }
    }
    return res;
  };

  const formatMobileValueWithAppend = (string) => {
    const res = parseDigits(string)
      .split("")
      .reduce(
        (r, v, index) =>
          index === 3 || index === 6 ? `${r}-${v}` : `${r}${v}`,
        ""
      )
      .substr(0, 12);
    if (string.endsWith("-")) {
      if (res.length === 3) {
        return `${res}-`;
      }

      if (res.length === 7) {
        return `${res}-`;
      }
    }
    return res;
  };

  let addrLabel = "Address";

  return (
    <>
      {isLoading ? <Loader /> : null}
      {errors == ERRORCODE ? (
        <Error />
      ) : (
        <div className={`dynamicContainer`} id="main" role="main">
          <div className="business-address-info">
            <h1 className="business-header">{BussinesAdressInfo.header}</h1>
            <AriaAnnouncerProvider>
              <Banner state="info" className="business_banner">
                Please Note: Business Mailing Address cannot be a P.O. Box.
              </Banner>
            </AriaAnnouncerProvider>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div>
                  <FormField
                    label={
                      <>
                        {addrLabel}
                        <span
                          className="addRequired"
                          role="img"
                          aria-label={addrLabel}
                        >
                          {" "}
                        </span>
                      </>
                    }
                    classes={{ helperText: "errorHelperText" }}
                    helperText={mergedErrorMessages(addrssExceptions)}
                    validationState={addrssExceptions[0]?.type}
                    helperTextPlacement={!mergedErrorMessages(addrssExceptions)}
                    hasStatusIndicator={
                      mailingStatusIndicator
                        ? mailingStatusIndicator
                        : mailingHelperText
                    }
                  >
                    <Input
                      autoComplete="off"
                      name="mailingAddress"
                      onChange={handleChange}
                      value={bussinessData?.mailingAddress}
                      className="textFont"
                      role="tooltip"
                      aria-label={Tooltip_info}
                      multiline
                    />
                  </FormField>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div>
                  <span className=""></span>
                  <span className="">
                    <FormField label="City">
                      <Input
                        autoComplete="off"
                        name="mailingCity"
                        onChange={handleChange}
                        value={bussinessData?.mailingCity}
                      />
                    </FormField>
                  </span>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div>
                  <span className=""></span>
                  <span className="">
                    <div>
                      <FormField label="State">
                        <Dropdown
                          selectedItem={
                            bussinessData?.mailingStateName
                              ? bussinessData?.mailingStateName
                              : "Select"
                          }
                          id="mailingStateName"
                          value={bussinessData?.mailingStateName}
                          name="mailingStateName"
                          onChange={(e, selectedItem) => {
                            handleChange(e, selectedItem, "mailingStateName");
                          }}
                          source={newcountrystatesarray}
                          className={"dropDownField"}
                          aria-expanded="true"
                        />
                      </FormField>
                    </div>
                  </span>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div>
                  <span className=""></span>
                  <span className="">
                    <FormField
                      label="Zip"
                      classes={{ helperText: "errorHelperText" }}
                      helperText={mergedErrorMessages(addrssZipExceptions)}
                      validationState={addrssZipExceptions[0]?.type}
                    >
                      <FormattedInput
                        value={String(bussinessData?.mailingZipCode)}
                        onChange={(value) => {
                          handlezipChange(value, "mailingZipCode");
                        }}
                        onBlur={(e) => {
                          onBlurValidation(e, "mailingZipCode");
                        }}
                        inputProps={{ name: "mailingZipCode" }}
                        rifmOptions={{
                          accept: /[\d]/g,
                          mask: (value) => 10 >= value.length,
                          format: formatValueWithAppend,
                          append: (string) =>
                            string.length === 5 ? `${string}` : string,
                        }}
                      />
                    </FormField>
                  </span>
                </div>
              </Grid>

              <Grid item xs={12}>
                <h1 className="business-header">
                  {BussinesAdressInfo.header2}
                </h1>
                <div>
                  <ButtonBar alignLeft stackAtBreakpoint={0}>
                    <OrderedButton variant="cta" onClick={copyMailingaddress}>
                      {BussinesAdressInfo.buttoncopy}
                      {copymailspan ? (
                        <span
                          class="sr-only"
                          aria-live="assertive"
                          role="alert"
                        >
                          {Accessibility_text.BusinessMailAddress}
                        </span>
                      ) : (
                        ""
                      )}
                    </OrderedButton>
                    <OrderedButton
                      className={`copyAddressButton`}
                      variant="cta"
                      onClick={copyContactInfoaddress}
                    >
                      {BussinesAdressInfo.buttoncopy2}
                      {copyhomespan ? (
                        <span
                          class="sr-only"
                          aria-live="assertive"
                          role="alert"
                        >
                          {Accessibility_text.PhysicalHomeAddress}
                        </span>
                      ) : (
                        ""
                      )}
                    </OrderedButton>
                  </ButtonBar>
                </div>
              </Grid>

              <Grid item xs={12}>
                <FormField
                  label="Address"
                  classes={{ helperText: "errorHelperText" }}
                  helperText={mergedErrorMessages(executiveaddrssExceptions)}
                  validationState={executiveaddrssExceptions[0]?.type}
                  helperTextPlacement={
                    !mergedErrorMessages(executiveaddrssExceptions)
                  }
                  hasStatusIndicator={
                    officeStatusIndicator
                      ? officeStatusIndicator
                      : officeAddresHelperText
                  }
                >
                  <Input
                    autoComplete="off"
                    name="officeAddress"
                    onChange={handleChange}
                    value={bussinessData?.officeAddress}
                    className="textFont"
                    multiline
                    role="tooltip"
                    aria-label={Tooltip_info}
                  />
                </FormField>
              </Grid>

              <Grid item xs={12}>
                <FormField label="City">
                  <Input
                    autoComplete="off"
                    name="officeCity"
                    onChange={handleChange}
                    value={bussinessData?.officeCity}
                  />
                </FormField>
              </Grid>

              <Grid item xs={12}>
                <FormField label="State">
                  <Dropdown
                    name="officeStateName"
                    onChange={(e, selectedItem) => {
                      handleChange(e, selectedItem, "officeStateName");
                    }}
                    selectedItem={
                      bussinessData?.officeStateName
                        ? bussinessData?.officeStateName
                        : "Select"
                    }
                    source={newcountrystatesarray}
                    className={"dropDownField"}
                    id="region"
                    aria-expanded="true"
                  />
                </FormField>
              </Grid>

              <Grid item xs={12}>
                <FormField
                  label="Zip"
                  classes={{ helperText: "errorHelperText" }}
                  helperText={mergedErrorMessages(officeZipExceptions)}
                  validationState={officeZipExceptions[0]?.type}
                >
                  <FormattedInput
                    value={String(bussinessData?.officeZipCode)}
                    autoComplete="off"
                    onChange={(value) => {
                      handlezipChange(value, "officeZipCode");
                    }}
                    onBlur={(e) => {
                      onBlurValidation(e, "officeZipCode");
                    }}
                    inputProps={{ name: "officeZipCode" }}
                    rifmOptions={{
                      accept: /[\d]/g,
                      mask: (value) => 10 >= value.length,
                      format: formatValueWithAppend,
                      append: (string) =>
                        string.length === 5 ? `${string}` : string,
                    }}
                  />
                </FormField>
              </Grid>

              <Grid item xs={12}>
                <FormField
                  label="Office Telephone Number"
                  classes={{ helperText: "errorHelperText" }}
                  helperText={mergedErrorMessages(officephoneExceptions)}
                  validationState={officephoneExceptions[0]?.type}
                >
                  <FormattedInput
                    value={String(bussinessData?.officePhoneNo)}
                    autoComplete="off"
                    onChange={(value) => {
                      {
                        handlezipChange(value, "officePhoneNo");
                      }
                    }}
                    onBlur={(e) => {
                      validateofficephone(e.target.value);
                    }}
                    name="officePhoneNo"
                    rifmOptions={{
                      accept: /[\d]/g,
                      mask: (value) => 12 >= value.length,
                      format: formatMobileValueWithAppend,
                      append: (string) =>
                        string.length === 4 || string.length === 8
                          ? `${string}-`
                          : string,
                    }}
                  />
                </FormField>
              </Grid>

              <Grid item xs={12}>
                <FormField label="Which telephone number is the best number to reach you?">
                  <Dropdown
                    name="prefPhoneType"
                    onChange={(e, selectedItem) => {
                      handleChange(e, selectedItem, "prefPhoneType");
                    }}
                    selectedItem={
                      bussinessData?.prefPhoneType
                        ? bussinessData?.prefPhoneType
                        : "Select"
                    }
                    source={perfferedtype}
                    id="prefPhoneType"
                    className={"dropDownField"}
                  />
                </FormField>
              </Grid>
              <Grid item xs={12}>
                <h1 className="business-header-add">
                  {BussinesAdressInfo.header3}
                </h1>
              </Grid>
              <Grid item xs={12}>
                <p className="authorize">{BussinesAdressInfo.authorize}</p>
              </Grid>
              <Grid item xs={12}>
                <FormField label="Name">
                  <Input
                    name="addlContName"
                    autoComplete="off"
                    onChange={handleChange}
                    value={bussinessData?.addlContName}
                  />
                </FormField>
              </Grid>

              <Grid item xs={12}>
                <FormField label="Title">
                  <Input
                    name="addlContTitle"
                    autoComplete="off"
                    onChange={handleChange}
                    value={bussinessData?.addlContTitle}
                  />
                </FormField>
              </Grid>

              <Grid item xs={12}>
                <FormField
                  label="Phone Number"
                  classes={{ helperText: "errorHelperText" }}
                  helperText={mergedErrorMessages(addphoneExceptions)}
                  validationState={addphoneExceptions[0]?.type}
                >
                  <FormattedInput
                    value={String(bussinessData?.addlPhoneNo)}
                    autoComplete="off"
                    onChange={(value) => {
                      if (handlezipChange) {
                        handlezipChange(value, "addlPhoneNo");
                      }
                    }}
                    onBlur={(e) => validateAddphone(e.target.value)}
                    name="addlPhoneNo"
                    rifmOptions={{
                      accept: /[\d]/g,
                      mask: (value) => 12 >= value.length,
                      format: formatMobileValueWithAppend,
                      append: (string) =>
                        string.length === 4 || string.length === 8
                          ? `${string}-`
                          : string,
                    }}
                  />
                </FormField>
              </Grid>

              <Grid item xs={12}>
                <FormField
                  label="E-mail Address"
                  autoComplete="off"
                  classes={{ helperText: "errorHelperText" }}
                  helperText={mergedErrorMessages(addemailExceptions)}
                  validationState={addemailExceptions[0]?.type}
                >
                  <Input
                    name="addlEmail"
                    autoComplete="off"
                    onChange={handleChange}
                    onBlur={(e) => validateAddmail(e.target.value)}
                    value={bussinessData?.addlEmail}
                  />
                </FormField>
              </Grid>
              <p className={`noteSectionClass noteSectAlign`}>
                {BussinesAdressInfo.note}
              </p>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className={`business-buttonContainer`}>
                  <Button onClick={handlePreviousClick}>
                    previous section
                  </Button>
                  <HiddenValidator
                    validateTrigger={() => handleNextClick(false)}
                    labels={"next section"}
                  />
                  <Button variant="cta" onClick={handleNextClick}>
                    next section
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(BusinessAddressInfo);
