import { DataGrid } from '@jpmuitk/data-grid';
import React,{ useCallback, useState } from 'react';
import CustomPagination from '../../../components/pagination/pagination';

const EditFranchiseProfile = (props) => {
    const [gridApi, setGridApi] = useState();
    const onRowEditing = (params) => {
        params.api.refreshCells({
            columns: ['actions'],
            rowNodes: [params.node],
            force: true
        })
       
    }
    const getRowHeight= (params) => {
      
        let data = 50;
       const updateHeight= params?.data?.firstName.match(/.{1,17}/g)
       if(updateHeight.length > 1) {
       updateHeight.forEach((x,i) => {
           data= data + 8 
       })
    }
         return data;
     }
    const onGridReady = (params) => {
        params.api.sizeColumnsToFit();
        console.log('---gridApi---', gridApi);
        setGridApi(params.api);

    }
    function headerHeightGetter() {
        var columnHeaderTexts = [
            ...document.querySelectorAll('.ag-header-cell-text'),
        ];
        var clientHeights = columnHeaderTexts.map(
            headerText => headerText.clientHeight
        );
        var tallestHeaderTextHeight = Math.max(...clientHeights);
    
        return tallestHeaderTextHeight;
    }
    function headerHeightSetter(params) {
        var padding = 20;
        var height = headerHeightGetter() + padding;
        params.api.setHeaderHeight(height);
    }
   const handlePageChange = (e, page) => {
      props.handlePageChange(page)
    }

    const handlekeydown = useCallback((e)=>{
    if(e.event.key === "Enter"){
        console.log(e);
        console.log(e.event.key);
        e.api.setFocusedCell(e.node.rowIndex, e.columnApi.getDisplayedCenterColumns()[0].colId);
            e.api.startEditingCell({
                rowIndex: e.node.rowIndex,
                colKey: e.columnApi.getDisplayedCenterColumns()[0].colId
            }) 
    }
    },[])

    return (
        <>
            <DataGrid
                classes={{root:'dataGrid'}}
                columnDefs={props.dataGridExampleColumns}
                rowData={props.dataGridExampleRow}
               tooltipShowDelay={0}
                pagination={false}
                getRowHeight={getRowHeight}
                onGridReady={onGridReady}
                domLayout={'autoHeight'}
               editType={'fullRow'}
              
              
        
                defaultColDef={{ editable: true,headerComponentParams:  {
                    template:
                        '<div class="ag-cell-label-container" role="presentation">' +
                        '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                        '  <div ref="eLabel" class="ag-header-cell-label"  role="presentation">' +
                        '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;text-align: center;"></span>' +
                        '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                        '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                        '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                        '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                        '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                        '  </div>' +
                        '</div>',
                }}}
                onRowEditingStopped={onRowEditing}
                onRowEditingStarted={onRowEditing}
                onCellKeyPress={handlekeydown}
                onColumnResized={headerHeightSetter}
                onFirstDataRendered={headerHeightSetter}
                frameworkComponents={{
                    franchiseEmailCellRenderer: props.cellRenderer,
                    disableCellRenderer: props.disableCellRenderer,
                    firstNameCellRenderer: props.firstNameCellRenderer,
                    lastNameCellRenderer: props.lastNameCellRenderer
                }}
            />
            <div className={`paginationClass`}>
            <CustomPagination 
            count ={props.franchiseData?.totalPages}
            handlePageChange={handlePageChange}
            page= {props.franchiseData?.currentPage}
            />
            </div>
        </>
    );
}

export default EditFranchiseProfile;


