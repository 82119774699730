import axios from 'axios';
import {  SAVE_RETURN_FAILURE,SAVE_RETURN_SUCCESS,
    SAVE_RETURN_RECEIVE,REQUEST_RETURN,REQUEST_EXIST, base_url, base_url_auth, ENABLE_LOADER} from '../../../../global.constants';

export const saveReturn_receive = (payload)=>{
    return {
        type: SAVE_RETURN_RECEIVE,
        payload
    }
 }
export const saveReturn_failure = (payload)=>{
    return {
        type: SAVE_RETURN_FAILURE,
        payload
    }
}

export const saveReturn_success = ()=>{
    return {
        type: SAVE_RETURN_SUCCESS,
    }
}

export const saveReturnUser = (payload) => async dispatch => {

    try {
        dispatch({type : ENABLE_LOADER, payload});
         await axios.post(base_url_auth+`/api/v1/validateUser`, payload)
            .then(response => {

                if (response && response.status === 200) {

                    if( response.data.errorCode === "USER_NOT_FOUND")
                    {
                       dispatch({type : REQUEST_RETURN , payload});
                    }
                    else{
                    dispatch({type : REQUEST_EXIST});
                    }
                }
                else {
                    dispatch(saveReturn_failure())
                }
            });
     }
    catch (e) {
        dispatch(saveReturn_failure())
        return e;
    }
    };
