import React, { useEffect, useState } from "react";
import { FormField } from "@jpmuitk/form-field";
import Grid from "@material-ui/core/Grid";
import { Form } from "@jpmuitk/forms";
import { Input } from "@jpmuitk/input";
import { Button } from "@jpmuitk/button";
import "react-dates/lib/css/_datepicker.css";
import "../../global.scss";
import "./RestDetails.scss";

const StockHolderDetails = ({ remove, defaultValue, updateState, ind }) => {
  return (
    <div className={`nestedFormContainer`}>
      <div className={`restuarantHeaderContainer shareholderHeaderContainer`}>
        <h2 className={`headingClass`}>{`Shareholder ${ind + 1}`}</h2>
        <Button onClick={() => remove(ind)}>delete this shareholder</Button>
      </div>

      <Form fullWidthFields className={`formClass`}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormField label="Legal Name">
              <Input
                name="stockHolderName"
                fullWidth
                autoComplete="off"
                value={defaultValue?.stockHolderName}
                onChange={(e) => updateState(e, ind)}
              />
            </FormField>
          </Grid>
          <Grid item xs={12}>
            <FormField label="Title (as shown on Organization documents)">
              <Input
                name="stockHolderTitle"
                fullWidth
                autoComplete="off"
                value={defaultValue?.stockHolderTitle}
                onChange={(e) => updateState(e, ind)}
              />
            </FormField>
          </Grid>
          <Grid item xs={12}>
            <FormField label="% ownership">
              <Input
                name="ownershipPercent"
                fullWidth
                autoComplete="off"
                value={defaultValue?.ownershipPercent}
                onChange={(e) => updateState(e, ind)}
              />
            </FormField>
          </Grid>
        </Grid>
      </Form>
    </div>
  );
};

export default StockHolderDetails;
