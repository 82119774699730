import {RECEIVE_RATES_REQUEST,RATES_SUCCESS,RATES_FAILURE} from '../../../../global.constants'

const initialstate={
    indicativeRates :
          {
           business_floating: null,
           business_three_years_fixed: null,
           business_five_years_fixed: null,
           business_seven_years_fixed: null,
           business_ten_years_fixed: null,
           current_date: null,
           current_time: null            
         }
  }
   
  const IndicativeRatesReducer = (state=initialstate,{type,payload}={})=>{
     switch(type){
       case RECEIVE_RATES_REQUEST:
         const newState = {
           ...state,
           indicativeRates:payload
         }
         return newState
      case RATES_SUCCESS:
      case RATES_FAILURE:
        state.indicativeRates= payload
        return
     
        default:
         return state
     }
  }
  
  export default IndicativeRatesReducer;