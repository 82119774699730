import {
  FETCH_PHYSICALMAILINGADDRESS_REQUEST, FETCH_PHYSICALMAILINGADDRESS_SUCCESS, FETCH_PHYSICALMAILINGADDRESS_FAILURE,
  FETCH_MAILINGADDRESS_REQUEST, FETCH_MAILINGADDRESS_SUCCESS, FETCH_MAILINGADDRESS_FAILURE, FETCH_HOMEINFOADDRESS_REQUEST,
  FETCH_HOMEINFOADDRESS_SUCCESS, FETCH_HOMEINFOADDRESS_FAILURE, FETCH_CONTACTINFOADDRESS_REQUEST,
  FETCH_CONTACTINFOADDRESS_SUCCESS, FETCH_CONTACTINFOADDRESS_FAILURE,
  SAVE_PHYSICALADDRESS_REQUEST, SAVE_PHYSICALADDRESS_SUCCESS, SAVE_PHYSICALADDRESS_FAILURE
} from '../../../../global.constants';


const initialState = {
  PhysicalAddressInfoData: [],
  CopymailingAddress: [],
  HomemailingAddress: [],
  ContactmailingAddress: [],
  error: '',
  isLoading: false
}



const PhysicalAddressInfoReducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case FETCH_MAILINGADDRESS_REQUEST:
    case FETCH_HOMEINFOADDRESS_REQUEST:
    case FETCH_CONTACTINFOADDRESS_REQUEST:
    case SAVE_PHYSICALADDRESS_REQUEST:
    case FETCH_PHYSICALMAILINGADDRESS_REQUEST:
      return Object.assign(state, {
        isLoading: true
      });

    case FETCH_PHYSICALMAILINGADDRESS_SUCCESS:
      return Object.assign(state, {
        PhysicalAddressInfoData: payload,
        isLoading: false
      });
    case FETCH_HOMEINFOADDRESS_FAILURE:
    case FETCH_MAILINGADDRESS_FAILURE:
    case SAVE_PHYSICALADDRESS_SUCCESS:
    case SAVE_PHYSICALADDRESS_FAILURE:
    case FETCH_CONTACTINFOADDRESS_FAILURE:
      return Object.assign(state, {
        isLoading: false
      });
    case FETCH_PHYSICALMAILINGADDRESS_FAILURE:
      return Object.assign(state, {
        isLoading: false,
        error: payload
      });


    case FETCH_MAILINGADDRESS_SUCCESS:
      return Object.assign(state, {
        CopymailingAddress: payload,
        isLoading: false
      });

    case FETCH_HOMEINFOADDRESS_SUCCESS:
      return Object.assign(state, {
        HomemailingAddress: payload,
        isLoading: false
      });

    case FETCH_CONTACTINFOADDRESS_SUCCESS:
      return Object.assign(state, {
        ContactmailingAddress: payload,
        isLoading: false
      });
    default:
      return state;
  }
};
export default PhysicalAddressInfoReducer;