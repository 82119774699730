import axios from "axios";
import {
  FETCH_FRANCHISEPROFILE_FAILURE,
  FETCH_FRANCHISEPROFILE_REQUEST,
  FAILURE_EDIT_DATA,
  RECEIVE_EDIT_DATA,
  RECEIVE_SEARCH_DATA,
  FAILURE_SEARCH_DATA,
  RECEIVE_RESET_DATA,
  FAILURE_RESET_DATA,
  FAILURE_DISABLE_DATA,
  REQUEST_SEARCH_DATA,
  base_url,
  base_url_admin,
  base_url_auth,
} from "../../../../global.constants";

export const fetchFranchiseProfile_request = (data) => {
  return {
    type: FETCH_FRANCHISEPROFILE_REQUEST,
    payload: data,
  };
};

export const fetchFranchiseProfile_failure = (error) => {
  return {
    type: FETCH_FRANCHISEPROFILE_FAILURE,
    payload: error,
  };
};

export const postSearchData = (payload, pageNo) => async (dispatch) => {
  dispatch({ type: REQUEST_SEARCH_DATA });
  const pageNoCheck = pageNo === undefined ? (pageNo = 0) : pageNo;
  try {
    await axios
      .post(
        base_url_admin +
          `/api/v1/adminsvc/admin/getFranchiseePwdDashboard.do/${pageNoCheck}/10`,
        payload
      )
      .then((response) => {
        if (response && (response.status === 200 || response.status === 204)) {
          dispatch(receiveSearchData(response.data));
        } else {
          dispatch(failureSearchData(response));
        }
      });
  } catch (e) {
    dispatch(failureSearchData(e.response));
    return e;
  }
};

export const receiveSearchData = (payload) => ({
  type: RECEIVE_SEARCH_DATA,
  payload,
});
export const failureSearchData = (payload) => ({
  type: FAILURE_SEARCH_DATA,
  payload,
});
export const postEditData = (payload) => async (dispatch) => {
  try {
    dispatch({ type: REQUEST_SEARCH_DATA });
    await axios
      .put(
        base_url_auth + `/api/v1/authsvc/admin/updateFranchiseeProfile.do`,
        payload
      )
      .then((response) => {
        if (response && response.status === 200) {
          dispatch(receiveEditData(response));
        } else {
          dispatch(failureEditData(response.status));
        }
      });
  } catch (e) {
    dispatch(failureEditData(e.response));
    return e;
  }
};

export const receiveEditData = (payload) => ({
  type: RECEIVE_EDIT_DATA,
  payload,
});
export const failureEditData = (payload) => ({
  type: FAILURE_SEARCH_DATA,
  payload,
});

export const postResetData = (payload) => async (dispatch) => {
  try {
    dispatch({ type: REQUEST_SEARCH_DATA });
    await axios
      .put(
        base_url_auth + `/api/v1/authsvc/admin/resetFranchiseePassword.do`,
        payload
      )
      .then((response) => {
        if (response && response.status === 200) {
          dispatch(receiveResetData());
        } else {
          dispatch(failureResetData(response?.status));
        }
      });
  } catch (e) {
    dispatch(failureResetData(e.response?.status));
    return e;
  }
};

export const receiveResetData = (payload) => ({
  type: RECEIVE_EDIT_DATA,
  payload,
});

export const failureResetData = (payload) => ({
  type: FAILURE_SEARCH_DATA,
  payload,
});

export const postDisableData = (payload, searchPayload) => async (dispatch) => {
  try {
    dispatch({ type: REQUEST_SEARCH_DATA });
    await axios
      .post(
        base_url_admin +
          `/api/v1/adminsvc/admin/enableDisableFranchiseInIBID.do`,
        payload
      )
      .then((response) => {
        if (response && response.status === 200) {
          dispatch(postSearchData(searchPayload));
        } else {
          dispatch(failureDisableData(response));
        }
      });
  } catch (e) {
    dispatch(failureDisableData(e.response));
    return e;
  }
};

export const failureDisableData = (payload) => ({
  type: FAILURE_DISABLE_DATA,
  payload,
});
