import React from 'react';
import { OrderedButton } from '@jpmuitk/button-bar';
import { Button } from '@jpmuitk/button';

const HiddenValidator = (props) => {
    const { labels, validateTrigger, ordBtn } = props;
    return (
        <>
            {ordBtn ?
                <OrderedButton
                    onClick={validateTrigger}
                    variant='cta'
                    style={{ "visibility": "hidden", "width": "0", "position": "absolute" }}
                    id="hiddenValidatr">{labels}</OrderedButton> :
                <Button
                    onClick={validateTrigger}
                    variant='cta'
                    style={{ "visibility": "hidden", "width": "0", "position": "absolute" }}
                    id="hiddenValidatr">{labels}</Button>}
        </>
    );
};

export default HiddenValidator;