import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import EditFranchiseProfile from "./EditFranchiseProfile";
import EditableActionCell from "./EditableActionCell";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { Form } from "@jpmuitk/forms";
import { Input } from "@jpmuitk/input";
import { OrderedButton, ButtonBar } from "@jpmuitk/button-bar";
import Grid from "@material-ui/core/Grid";
import { FormField } from "@jpmuitk/form-field";
import {
  searchFranchise,
  FOURHUNDREDFOUR,
  FIVEHUNDRED,
  FOURZEROONE,
} from "../../global.constants";
import "./FranchiseProfile.scss";
import { postSearchData } from "./redux/actions/FranchiseProfileAction";
import { Link } from "@jpmuitk/link";
import { Button } from "@jpmuitk/button";
import { useHistory } from "react-router-dom";
import { Banner } from "@jpmuitk/banner";
import WarningDialog from "./ModalContainer";
import Loader from "../../../components/loader/Loader";
import Error from "../../../components/error/Error";
import {
  currentLoanId,
  returnTodashboardflag,
} from "../../dynamic/returnToDashboard/redux/action/dashboardAction";
import {
  fetchUserId_success,
  fetchUserName,
} from "../../../auth/redux/action/AuthCheckAction";

const FranchiseProfile = (props) => {
  const [Message, setMessage] = useState(false);
  const franchiseData = useSelector(
    (state) => state.FranchiseProfileReducer.franchiseSearchVOList
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const [apiData, setapiData] = useState([]);
  const [franchiseeName, setFranchiseeName] = useState("");
  const [operatorId, setOperatorId] = useState("");
  const [userid, setUserId] = useState("");
  const [janusUserName, setjanusUserName] = useState("");
  const [error, setError] = useState("");
  const [showBanner, setShowBanner] = useState(false);

  const [openModal, setOpenModal] = useState({
    open: false,
    isActive: null,
    data: {},
  });
  const [clearGrid, setClearGrid] = useState(true);
  const [showError, setShowError] = useState(showError);
  const isLoading = useSelector(
    (state) => state.FranchiseProfileReducer.isLoading
  );
  const isAdminLoading = useSelector(
    (state) => state.AuthCheckReducer.isLoading
  );
  const errors = useSelector((state) => state.FranchiseProfileReducer.error);
  const errorMsg = useSelector(
    (state) => state.FranchiseProfileReducer.errorMsg
  );
  const emailErrorMsg = useSelector(
    (state) => state.FranchiseProfileReducer.emailError
  );

  const PATH = "/dashboard";

  useEffect(() => {
    return () => setClearGrid(true);
  }, []);

  useEffect(() => {
    if (errors) return setShowError(errors);
    setShowError("");
  }, [errors]);
  useEffect(() => {
    if (franchiseData) {
      if (!Boolean(franchiseData.franchiseProfile)) return setapiData([]);
      setapiData(franchiseData.franchiseProfile);
    }
  }, [franchiseData]);

  const editableActionCell = (params) => {
    return (
      <EditableActionCell
        setError={setError}
        params={params}
        setShowBanner={setShowBanner}
      />
    );
  };

  const handleClear = () => {
    setError("");
    setMessage(true);
    setFranchiseeName("");
    setUserId("");
    setOperatorId("");
    setjanusUserName("");
    setClearGrid(true);
  };

  const handleSearch = () => {
    if (userid || franchiseeName || operatorId || janusUserName) {
      const payload = {
        fullName: franchiseeName,
        loginId: userid,
        operatorId: operatorId,
        janusUserName: janusUserName,
      };
      dispatch(postSearchData(payload, 0));
      setClearGrid(false);
      setError("");
      return;
    }
    setError(searchFranchise.errorMessage);
  };

  const handleEdit = (userId, fullName) => {
    dispatch(fetchUserId_success(userId));
    dispatch(fetchUserName(fullName));
    dispatch(
      returnTodashboardflag({ adminFlag: true, menuId: "franchiseeProfile" })
    );
    history.push("/dashboard");
  };
  const franchiseEmailCellRenderer = (params) => {
    return (
      <div className={`emailCellClass`}>
        <Link
          onClick={() =>
            handleEdit(
              params.data?.userId,
              `${params.data?.firstName} ${params.data?.lastName}`
            )
          }
          className={`cursor`}
        >
          {params.data.emailAddress}
        </Link>
      </div>
    );
  };
  const firstNameCellRenderer = (params) => {
    return (
      <span
        style={{
          width: params.column.actualWidth,
          wordBreak: "break-word",
          whiteSpace: "break-spaces",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        {params.data.firstName}
      </span>
    );
  };
  const lastNameCellRenderer = (params) => {
    return (
      <span
        style={{
          width: params.column.actualWidth,
          wordBreak: "break-word",
          whiteSpace: "break-spaces",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        {params.data.lastName}
      </span>
    );
  };
  const handleDisabled = (params) => {
    setOpenModal({
      ...openModal,
      isActive: params.data.enabled,
      open: true,
      data: params.data,
    });
  };

  const disableCellRenderer = (params) => {
    let disabledField = "";
    params.data.enabled
      ? (disabledField = searchFranchise.no)
      : (disabledField = searchFranchise.yes);
    window.scrollTo(0, 0);
    if (!params.data.registrationComplete) disabledField = "";
    return (
      <div className={`disableCellClass`}>
        <Link onClick={() => handleDisabled(params)} className={`cursor`}>
          {disabledField}
        </Link>
      </div>
    );
  };

  const handleClose = () => {
    setShowBanner(false);
  };
  const handlePageChange = (page) => {
    const payload = {
      fullName: franchiseeName,
      loginId: userid,
      operatorId: operatorId,
      janusUserName: janusUserName,
    };
    dispatch(postSearchData(payload, page - 1));
    window.scrollTo(0, 0);
  };
  const dataGridExampleColumns = [
    {
      headerName: "Franchisee First Name",
      field: "firstName",
      tooltipField: "firstName",
      suppressMenu: true,
      cellRenderer: "firstNameCellRenderer",
      headerTooltip: "Franchisee First Name",
      maxWidth: 120,
      minWidth: 100,
      flex: 1,
      sortable: true,
      resizable: true,
    },
    {
      headerName: "Franchisee Last Name",
      field: "lastName",
      suppressMenu: true,
      headerTooltip: "Franchisee Last Name",
      cellRenderer: "lastNameCellRenderer",
      maxWidth: 120,
      minWidth: 100,
      flex: 1,
      sortable: true,
      resizable: true,
    },
    {
      headerName: "Operator ID",
      field: "operatorId",
      suppressMenu: true,
      maxWidth: 80,
      headerTooltip: "Operator ID",
      minWidth: 80,
      flex: 1,
      sortable: false,
      resizable: true,
    },
    {
      headerName: "E-mail Address",
      field: "emailAddress",
      suppressMenu: true,
      headerTooltip: "E-mail Address",
      tooltipField: "emailAddress",
      maxWidth: 180,
      minWidth: 170,
      flex: 1,
      cellRenderer: "franchiseEmailCellRenderer",
      sortable: true,
      resizable: true,
    },
    {
      headerName: "Janus User Name",
      field: "janusUserName",
      headerTooltip: "Janus User Name",
      suppressMenu: true,
      maxWidth: 120,
      minWidth: 80,
      flex: 1,
      sortable: true,
      resizable: true,
      editable: (params) => (params.data.janusUserName ? false : true),
    },
    {
      headerName: "WCRM Contact AltID",
      field: "contactAltId",
      suppressMenu: true,
      headerTooltip: "WCRM Contact Alt ID",
      maxWidth: 80,
      minWidth: 60,
      flex: 1,
      editable: false,
      sortable: true,
      resizable: true,
    },
    {
      headerName: "Registration Complete",
      field: "registrationComplete",
      suppressMenu: true,
      headerTooltip: "Registration Complete",
      maxWidth: 80,
      minWidth: 60,
      flex: 1,
      editable: false,
      sortable: true,
      resizable: true,
      valueFormatter: (params) => {
        if (params.data.registrationComplete) return searchFranchise.yes;
        return params.data.registrationComplete === false
          ? searchFranchise.no
          : searchFranchise.emptyString;
      },
    },
    {
      headerName: "Disabled",
      field: "enabled",
      suppressMenu: true,
      headerTooltip: "Disabled",
      maxWidth: 60,
      minWidth: 40,
      flex: 1,
      sortable: true,
      resizable: true,
      editable: false,
      cellRenderer: "disableCellRenderer",
    },
    {
      headerName: "Edit Franchisee Info",
      field: "actions",
      cellRendererFramework: editableActionCell,
      editable: false,
      headerTooltip: "Edit Franchisee Info",
      flex: 1,
      suppressMenu: true,
      minWidth: 120,
      sortable: false,
      resizable: true,
    },
  ];

  return (
    <>
      {isLoading || isAdminLoading ? <Loader /> : null}
      {showError === FOURHUNDREDFOUR ? <Error /> : null}
      {showError === FIVEHUNDRED ? errorMsg : null}
      {!showError ? (
        <div
          className={`adminContainer profileContainer`}
          id="main"
          role="main"
        >
          <div className={`errorClass`} role="alert">
            {" "}
            {error && <span>{error}</span>}
            {emailErrorMsg && <span>{emailErrorMsg}</span>}
          </div>
          <div
            id="myGrid"
            className="ag-theme-alpine franchiseProfileContainer"
          >
            {showBanner && (
              <Banner state="success" onClose={handleClose}>
                {searchFranchise.success}
              </Banner>
            )}
            <Form fullWidthFields className={`formClass`}>
              <h1 className={`profileClass`}>
                {searchFranchise.searchCriteria}
              </h1>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormField label="Franchisee Name">
                    <Input
                      onChange={(e) => setFranchiseeName(e.target.value)}
                      value={franchiseeName}
                    ></Input>
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField label="Operator ID">
                    <Input
                      onChange={(e) => setOperatorId(e.target.value)}
                      value={operatorId}
                    ></Input>
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField label="User ID">
                    <Input
                      onChange={(e) => setUserId(e.target.value)}
                      value={userid}
                    ></Input>
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField label="Janus User Name">
                    <Input
                      onChange={(e) => setjanusUserName(e.target.value)}
                      value={janusUserName}
                    ></Input>
                  </FormField>
                </Grid>
              </Grid>
            </Form>
            <ButtonBar>
              <OrderedButton onClick={handleSearch} variant="cta">
                {searchFranchise.search}
              </OrderedButton>
              <OrderedButton
                className={`commonStylingButton`}
                onClick={handleClear}
              >
                {searchFranchise.clearCriteria}
                {Message ? (
                  <span
                    class="sr-only"
                    aria-live="assertive"
                    role="alert"
                    aria-label={`Cleared`}
                  ></span>
                ) : (
                  ""
                )}
              </OrderedButton>
            </ButtonBar>
            {!clearGrid && (
              <EditFranchiseProfile
                handlePageChange={handlePageChange}
                dataGridExampleColumns={dataGridExampleColumns}
                dataGridExampleRow={apiData}
                cellRenderer={franchiseEmailCellRenderer}
                disableCellRenderer={disableCellRenderer}
                firstNameCellRenderer={firstNameCellRenderer}
                lastNameCellRenderer={lastNameCellRenderer}
                franchiseData={franchiseData}
              />
            )}
            <WarningDialog
              openModal={openModal}
              setOpenModal={setOpenModal}
              franchiseeName={franchiseeName}
              userid={userid}
              operatorId={operatorId}
              janusUserName={janusUserName}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default FranchiseProfile;
