
import axios from 'axios';
import {
  FETCH_COUNTRYSTATES_REQUEST,
  FETCH_COUNTRYSTATES_SUCCESS,
  FETCH_COUNTRYSTATES_FAILURE,
  FETCH_REGION_REQUEST, FETCH_REGION_SUCCESS, FETCH_REGION_FAILURE,
  COUNTRY_ACTION_FAILURE,
  COUNTRY_ACTION_SUCCESS,
  base_url,
  base_url_user



} from '../../../global.constants';

export const fetchCountrystates_request = (data)=>{
    return {
        type: FETCH_COUNTRYSTATES_REQUEST,
        payload : data
    }
}

//States
export const fetchCountrystates_success = (data) => {
  return {
    type: FETCH_COUNTRYSTATES_SUCCESS,
    payload: data
  }
}
export const fetchCountrystates_failure = (data) => {
  return {
    type: FETCH_COUNTRYSTATES_FAILURE,
    payload: data
  }
}

//Country
export const fetchcountry_failure = (data) => {
  return {
    type: COUNTRY_ACTION_FAILURE,
    payload: data
  }
}

export const receiveCountryData_success = (data) => {
  return {
    type: COUNTRY_ACTION_SUCCESS,
    payload: data
  }
}

export const getCountrystatesData = () => {
    return (dispatch) => {
      // dispatch(fetchCountrystates_request())
      const Url = base_url_user+'/api/v1/usersvc/getAllStates';
      axios.get(Url)
        .then(response => {
          if (response && response.status === 200) {
            const statepayload = response.data
            dispatch(fetchCountrystates_success(statepayload))
          }
          else {
            dispatch(fetchCountrystates_failure(response.status))
          }

        })
        .catch((e) => {
          dispatch(fetchCountrystates_failure(e.response?.status))
        });

    }
};

export const getCountryNamesData = () => async dispatch => {
    try {
      await axios.get(base_url_user + `/api/v1/usersvc/getCountries`).then(response => {
        if (response && response.status === 200) {
          dispatch(receiveCountryData_success(response.data));
        }
        else{
          dispatch(fetchcountry_failure(response.status))
        }
      });
    }
    catch (e) {
      dispatch(fetchcountry_failure(e.response?.status))
    }
};


export const fetchRegion_request = (data)=>{
  return {
      type: FETCH_REGION_REQUEST,
      payload : data
  }
}

export const fetchRegion_success = (data)=>{
  return {
      type: FETCH_REGION_SUCCESS,
      payload : data
  }
}

export const fetchRegion_failure = (data)=>{
  return {
      type: FETCH_REGION_FAILURE,
      payload : data
  }
}

export const  getRegionData = ()  => {
return(dispatch) => {
  // dispatch(fetchCountrystates_request())
  //const Url = base_url_user+'/api/v1/usersvc/getregions';
  const Url = base_url_user+'/api/v1/getRegions';
axios.get(Url)
.then(response => {
   if (response && response.status === 200){
       const statepayload = response.data
      dispatch(fetchRegion_success(statepayload))
   }
   else{
      dispatch(fetchRegion_failure())
   }

})
.catch((error)=> {
  dispatch(fetchRegion_failure(error))
});

}
}
export const getFileAPI = async (e,fileName,fileType, downloadFileName) =>{
  e.preventDefault();
  const type = fileType == 'pdf' ? 'application/pdf' : 'msword' ? 'application/msword' : ''
  return await axios.get(base_url+`/api/v1/form/${fileName}`,{
        responseType: 'arraybuffer'
       }).then(response => {
        const file = new Blob([response.data], {type: type})

          const fileURL = URL.createObjectURL(file)
         if (downloadFileName) {
           var link = document.createElement('a')
           link.href = fileURL
           link.download = downloadFileName
           link.click()
         }
         else {
           window.open(fileURL, '_blank');
         }
        })
      .catch(error=> {
      });

 }
