import React, { useEffect, useState } from "react"
import './RegionInformation.scss'
import { useSelector, useDispatch } from 'react-redux';
import { RegionInformationConstants,FOURHUNDREDFOURERR } from '../../global.constants'
import { FormField} from '@jpmuitk/form-field';
import { Form } from '@jpmuitk/forms';
import { OrderedButton, ButtonBar } from '@jpmuitk/button-bar';
import { Dropdown } from '@jpmuitk/dropdown';
import { useHistory } from 'react-router-dom'
import { getRegionInfoData,saveRegionInfoData } from './redux/action/regionaction'
import { getRegionData} from '../../commonactions/redux/actions/action';
import Loader from '../../../components/loader/Loader';
import Error from  '../../../components/error/Error'
import { AriaAnnouncerProvider } from '@jpmuitk/aria-announcer';
import { getNextNavigation, getPreviousNavigation } from "../../../components/navigationPath/navigationPath";
import HiddenValidator from '../../../containers/HiddenValidator';
import { formHas_error } from '../../dynamic/returnToDashboard/redux/action/dashboardAction';
import '../../global.scss';
const RegionInformation = () => {
    const [data, setData] = useState({});
    const RegionInfo = useSelector(state => state.RegionInformationReducer.Regions);
    const regn = useSelector(state => state.CountrystatesReducer.Regionname);
    const isLoading = useSelector(state => state.RegionInformationReducer.isLoading);
    const errors = useSelector(state => state.RegionInformationReducer.error)
    const loanId = useSelector(state => state.DashboardReducer?.currentLoan);
    const userId = useSelector(state => state.AuthCheckReducer?.loggedInUserId);
    const dispatch = useDispatch();
    const history = useHistory();
    const [error, setError] = useState(error);
    const [region, setRegion] = useState([]);
    const newcountrystatesarray = region?.map(x => x.regionName)
    newcountrystatesarray.unshift(RegionInformationConstants.select)
   
useEffect(() =>{
    if(RegionInfo)
    {
        setData(RegionInfo)
    }

},[RegionInfo])

useEffect(() => {
    if (errors) return setError(errors)
    setError('')
}, [errors]);


const updateStore = () => {
    if (data && loanId?.activeLoanId) {
      dispatch(saveRegionInfoData(data,loanId?.activeLoanId,userId));
    }
}

useEffect(() => {
    if (regn) {
      setRegion(regn)
    }
  }, [regn])

    useEffect(() =>{
        if(RegionInfo){
            setData({...data,regionName: RegionInfo?.regionName})
           
        }
    },[RegionInfo])

   


      useEffect(() => {
          if(loanId?.activeLoanId){
        dispatch(getRegionInfoData(loanId?.activeLoanId,userId));
        if(regn)
        {
        dispatch(getRegionData());
        }}
    }, [])



    const handleChange = (e,selectedItem) => {
       
        var itemSelected = selectedItem;
      const selectedRegion = regn?.find(x => x.regionName === selectedItem)
      setData({
        ...data, regionName: itemSelected == "Select" ? "" : selectedItem, regionId: selectedRegion?.regionId
      })

    }
    const handleNextClick = (sameComp=true) => {
        updateStore();
        if(!sameComp) {
            dispatch(formHas_error(false));
            return false;
        }
        history.push(getNextNavigation())
        window.scrollTo(0, 0);
    }
    const handlPreviousClick = () => {
        updateStore();
        history.push(getPreviousNavigation())
        window.scrollTo(0, 0);
    }
    return (
        <>
        {isLoading ? <Loader /> : null}
           {error === FOURHUNDREDFOURERR ? <Error /> : null}
        <AriaAnnouncerProvider>
             <div className='dynamicContainer' id="main" role="main">
       
            <Form className={`formClass`}>
                <h1 className={`headingClass`}>{RegionInformationConstants.region}</h1>
                <FormField label="Region">
               
                    <Dropdown
                        className={'dropDownField'}
                        selectedItem={data?.regionName ? data?.regionName  : "Select"}
                        source={newcountrystatesarray}
                         name='regionName'
                        onChange={(e, selectedItem) => { handleChange(e, selectedItem) }}
                       
                    />
                        
                </FormField>
            </Form>
            <p className='noteClass'>{RegionInformationConstants.note}</p>
            <ButtonBar className='buttonClass' >
                <OrderedButton className={'nextSectionClass'} variant="cta"
                    onClick={handleNextClick}>{RegionInformationConstants.nextSection}</OrderedButton>
                <OrderedButton onClick={handlPreviousClick}>{RegionInformationConstants.previousSection}</OrderedButton>
                <HiddenValidator validateTrigger={() => handleNextClick(false)} labels={'next section'} ordBtn={true}/>
            </ButtonBar>
            
        </div>
        
         </AriaAnnouncerProvider>
        </>
    )
}
export default RegionInformation;
