import React, { useState, useEffect } from "react";
import "./AdminDashboard.scss";
import { onlineRequest } from "../../global.constants";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@jpmuitk/button";
import { DataGrid } from "@jpmuitk/data-grid";
import SearchFranchise from "../searchFranchise/searchFranchise";
import { useHistory } from "react-router-dom";
import { adminDashboardHeaders } from "./AdminDashboardHeader";
import {
  getAdminDashboardData,
  getBankersList,
  getStatusList,
  fetchFranchises,
} from "../adminDasboard/redux/action/AdminDasboardAction";
import "react-dates/lib/css/_datepicker.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import {
  searchFranchise,
  MyProfileConstants,
  FOURHUNDREDFOUR,
  FIVEHUNDRED,
} from "../../global.constants";
import Loader from "../../../components/loader/Loader";
import Error from "../../../components/error/Error";
import SearchBox from "../../../components/searchBox/SearchBox";
import CustomPagination from "../../../components/pagination/pagination";
import { getPreviewPdf } from "../../commonactions/redux/actions/PreviewAction";
import { returnTodashboardflag } from "../../dynamic/returnToDashboard/redux/action/dashboardAction";

const AdminDashboard = () => {
  const admindashboardData = useSelector(
    (state) => state.AdminDashboardReducer.AdminDasboard
  );
  const bankersdata = useSelector(
    (state) => state.AdminDashboardReducer.bankers
  );
  const statusdata = useSelector((state) => state.AdminDashboardReducer.status);
  const bankerlist = bankersdata?.map((x) => x.firstName);
  const statuslist = statusdata?.map((x) => x.statusDescription);
  statuslist.unshift(MyProfileConstants.SELECT);
  bankerlist.unshift(MyProfileConstants.SELECT);
  const [gridApi, setGridApi] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [showfrachisee, setShowfranchisee] = useState(false);
  const [error, setError] = useState(error);
  const [errMsg, setErrMsg] = useState(0);
  const [searchedBy, setSearchedBy] = useState(false);
  const [searchInputs, setSearchInputs] = useState({});
  const isLoading = useSelector(
    (state) => state.AdminDashboardReducer.isLoading
  );
  const isAdminLoading = useSelector(
    (state) => state.AuthCheckReducer.isLoading
  );
  const errors = useSelector((state) => state.AdminDashboardReducer.error);

  useEffect(() => {
    setTimeout(() => {
      dispatch(getAdminDashboardData(0));
    }, 1000);
  }, []);

  const onGridReady = ({ api }) => {
    api.sizeColumnsToFit();
    setGridApi(api);
  };

  const handlePageChange = (e, page) => {
    if (searchedBy) {
      dispatch(fetchFranchises(searchInputs, page - 1));
    } else {
      dispatch(getAdminDashboardData(page - 1));
    }
    window.scrollTo(0, 0);
  };

  const actionCellRenderer = (params) => {
    const handlePreview = () => {
      dispatch(getPreviewPdf(params?.data?.loanId, params?.data?.userId));
    };
    return (
      <Button className={`gridButton`} variant="cta" onClick={handlePreview}>
        {searchFranchise.preview}
      </Button>
    );
  };

  const purposeCellRenderer = (params) => {
    const purposes = params.value;
    return (
      <div className={`purposeClass`}>
        {purposes.map((purpose) => (
          <span style={{ width: params.column.actualWidth }}>
            {purpose?.purposeName}.<br />
          </span>
        ))}
      </div>
    );
  };

  const handlesearchFranchies = (data) => {
    setSearchedBy(true);
    setSearchInputs(data.reqParams);
    dispatch(fetchFranchises(data.reqParams, 0));
    window.scrollTo(0, 0);
  };

  const getRowHeight = (params) => {
    let data = 24;
    if (params?.data?.purposes && params?.data?.purposes?.length) {
      data = data * params?.data?.purposes?.length;
    }
    return data;
  };
  if (showfrachisee) {
    dispatch(returnTodashboardflag({ menuId: "adminDashboard" }));
    history.push("./searchFranchise");
    return null;
  } else {
    return (
      <>
        {isLoading || isAdminLoading ? <Loader /> : null}
        {errors == FOURHUNDREDFOUR ? (
          <Error />
        ) : (
          <div
            className="adminContainer requestContainer"
            id="main"
            role="main"
          >
            <div
              id="myGrid"
              className="ag-theme-alpine franchiseProfileContainer"
            >
              <h1 className={`dashboardHeader`}>{onlineRequest.header2}</h1>
              <SearchBox
                handleSubmits={handlesearchFranchies}
                showfrachise={() => setShowfranchisee(true)}
                page="dashboard"
              />

              <DataGrid
                classes={{ root: "dataGridAdminDashboard" }}
                columnDefs={adminDashboardHeaders}
                rowData={admindashboardData.loans}
                onGridReady={onGridReady}
                getRowHeight={getRowHeight}
                domLayout={"autoHeight"}
                frameworkComponents={{
                  actionCellRenderer: actionCellRenderer,
                  purposeCellRenderer: purposeCellRenderer,
                }}
              />
            </div>
            <div className={`paginationClass`}>
              {admindashboardData.totalPages && (
                <CustomPagination
                  count={admindashboardData.totalPages}
                  handlePageChange={handlePageChange}
                  page={admindashboardData.currentPage}
                />
              )}
            </div>
          </div>
        )}
      </>
    );
  }
};
export default AdminDashboard;
