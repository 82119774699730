import React from "react";
import "./LandingPage.scss";
import { adminLandingPage } from "../../global.constants";
import { Link } from "@jpmuitk/link";
import Loader from "../../../components/loader/Loader";
import { useSelector } from "react-redux";
// Userstory FFANEW-171
const LandingPage = () => {
  const isLoading = useSelector((state) => state.AuthCheckReducer.isLoading);
  return (
    <>
      {isLoading ? <Loader /> : null}
      <div className="dynamicContainer adminHomeContainer" id="main">
        <p>
          {adminLandingPage.mailContent}
          <Link
            href={adminLandingPage.url}
            role="button"
            target="_blank"
            aria-label={`${adminLandingPage.mailId} Opens as external link`}
            classes={{ root: "mailStyle" }}
          >
            {adminLandingPage.mailId}
          </Link>
        </p>
      </div>
    </>
  );
};
export default LandingPage;
