import React, { useState } from "react";
import { ToolbarButton } from "@jpmuitk/toolbar";
import { Icon } from "@jpmuitk/icon";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const ContactUs = (key) => {
  const history = useHistory();
  const isMobile = useMediaQuery({ query: "(max-width: 545px)" });
  const handleContactUs = () => {
    history.push("/contactUs");
  };
  return (
    <ToolbarButton
      className="secondaryNavButton"
      onItemClick={handleContactUs}
      key={key}
    >
      <Icon name="call" accessibleText="call"></Icon>{" "}
      {isMobile ? "" : " Contact Us"}
    </ToolbarButton>
  );
};

export default ContactUs;
