import React from "react";
import "./AnnualFinancial.scss";
import { Link } from "@jpmuitk/link";
import {
  ANNUALCONTENT,
  ADDRESS,
  FAXDATA,
  ENDCONTENT,
  ANNUALFINANCIALHEADING,
  NAME,
  FAX,
  ALLFINANCIAL,
  URL,
  CONTENT,
  ALLYEARFINANCE,
} from "./../../static.constants";
import { ColumnLayout } from "@jpmuitk/column-layout";

const AnnualFinancial = () => {
  return (
    <div className={`container`} id="main">
      <h1>{ANNUALFINANCIALHEADING}</h1>
      <div>
        <p>{ANNUALCONTENT}</p>
        <p>
          {ALLFINANCIAL}
          <Link href={`mailto:${URL}`} role="button">
            {URL}
          </Link>
          {CONTENT}
        </p>
        <p>{ALLYEARFINANCE}</p>
      </div>
      <div className="paddingLeft100">
        <ColumnLayout container>
          <ColumnLayout item xs={6} xl={6}>
            <div className={`data`}>
              <p className={`regular`}>{NAME}</p>
              {ADDRESS.map((item) => {
                return <p key={item}>{item}</p>;
              })}
            </div>
          </ColumnLayout>
          <ColumnLayout item xs={6} xl={6}>
            <div className={`faxnumber`}>
              <p className={`regular`}>{FAX}</p>
              {FAXDATA.map((item) => {
                return <p key={item}>{item}</p>;
              })}
            </div>
          </ColumnLayout>
        </ColumnLayout>
      </div>
      <p>{ENDCONTENT}</p>
    </div>
  );
};
export default AnnualFinancial;
