import { RECEIVE_ADMIN_LEFT_NAV_DATA, RECEIVE_ADMIN_LEFT_NAV_FAILURE } from "../../../global.constants";
const initialState = {
  adminLeftnavData: [],
  statusMessage: ''
}
const adminLeftNavReducer = (state = initialState, { type, payload }={}) => {
  switch (type) {
    case RECEIVE_ADMIN_LEFT_NAV_DATA:
      return Object.assign(state, {
        adminLeftnavData: payload
      });
    case RECEIVE_ADMIN_LEFT_NAV_FAILURE:
      return Object.assign(state, {
        statusMessage: payload
      });
    default:
      return state;
  }
};

export default adminLeftNavReducer;