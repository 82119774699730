import React from 'react';
import { Link } from '@jpmuitk/link';
import {OnlineloanRequest} from './../../static.constants';


const LoanRequest = () => {
    return (
        <div className={`container`} id="main">
            <h1 ><span>{OnlineloanRequest.LegalRelease}</span>
                <Link href={OnlineloanRequest.LoanRequestUrl} target="_blank"  className={`loanRequestClass`}>
                    <span className={`subLoanHeading`}>
                        {OnlineloanRequest.OnlineLoanRequest}
                    </span>
                </Link></h1>
            <div>
                <p>{OnlineloanRequest.content}</p>
                <p>{OnlineloanRequest.subContent}
                </p>
            </div>
        </div>
    )
}
export default LoanRequest;