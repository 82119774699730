import {
    FETCH_PERVIEW_SUCCESS,
    FETCH_PERVIEW_FAILURE,
    FETCH_PERVIEW_REQUEST,
  } from '../../../global.constants';
  
  const initialState = {
    error: '',
    isLoading: false,
  }
  
  const PreviewReducer = (state = initialState, {
    type,
    payload
  }={}) => {
    switch (type) {
      case FETCH_PERVIEW_REQUEST:
        return Object.assign(state, {
          pdf: payload,
          isLoading: true
        });
      case FETCH_PERVIEW_SUCCESS:
        return Object.assign(state, {
          isLoading: false
        });
      case FETCH_PERVIEW_FAILURE:
        return Object.assign(state, {
          isLoading: false,
          error: payload
        });
  
      default:
        return state;
    }
  };
  export default PreviewReducer;