import { dynamic_Menu } from "../../containers/global.constants"

export const getPreviousNavigation = () => {
    const pathName = window.location.href.split('/').slice(-1)
    const currentIndex = dynamic_Menu.findIndex(menu => menu.id == pathName);
    return dynamic_Menu[currentIndex-1]?.id ||  ''

}
export const getNextNavigation = () => {
    const pathName = window.location.href.split('/').slice(-1)
    const currentIndex = dynamic_Menu.findIndex(menu => menu.id == pathName);
    return dynamic_Menu[currentIndex+1].id ||  ''
}