import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormField } from "@jpmuitk/form-field";
import Grid from "@material-ui/core/Grid";
import { Form } from "@jpmuitk/forms";
import { Input } from "@jpmuitk/input";
import { OrderedButton, ButtonBar } from "@jpmuitk/button-bar";
import "./BankersComments.scss";
import {
  AdditionalCommentConstants,
  ERRORCODE,
  Tooltip_info,
} from "../../global.constants";
import Loader from "../../../components/loader/Loader";
import Error from "../../../components/error/Error";
import {
  postBankerComment,
  getBankerCommentData,
} from "./redux/actions/BankerCommentAction";
import {
  getNextNavigation,
  getPreviousNavigation,
} from "../../../components/navigationPath/navigationPath";

const BankersComments = () => {
  const [data, setData] = useState("");
  const history = useHistory();
  const commentValue = useSelector(
    (state) => state.BankerCommentsReducer.comment
  );
  const isLoading = useSelector(
    (state) => state.BankerCommentsReducer.isLoading
  );
  const errors = useSelector((state) => state.BankerCommentsReducer.error);
  const loanId = useSelector((state) => state.DashboardReducer?.currentLoan);
  const loggedInUserId = useSelector(
    (state) => state.AuthCheckReducer?.loggedInUserId
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBankerCommentData(loanId?.activeLoanId));
  }, []);

  useEffect(() => {
    return history.listen(() => {
      updateStore();
    });
  }, [history, data]);

  useEffect(() => {
    if (commentValue) {
      setData(commentValue.comments);
    }
  }, [commentValue]);

  const updateStore = () => {
    const payload = {
      comments: data,
    };
    if (payload) {
      dispatch(
        postBankerComment(payload, loanId?.activeLoanId, loggedInUserId)
      );
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setData(value);
  };

  const handlePreviousClick = () => {
    setData(commentValue);
    history.push("./additionalComments");
    window.scrollTo(0, 0);
  };

  const handleNextClick = () => {
    setData(commentValue);
    history.push("./submitRequesttoChaseFranchiseFinance");
    window.scrollTo(0, 0);
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      {errors == ERRORCODE ? (
        <Error />
      ) : (
        <div className={`dynamicContainer`} id="main">
          <Form fullWidthFields className={`formClass`}>
            <h1 className={`headingClass`}>
              {AdditionalCommentConstants.bankerComments}
            </h1>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormField
                  label={AdditionalCommentConstants.comments}
                  helperText={AdditionalCommentConstants.placeholderText}
                  helperTextPlacement={"tooltip"}
                  hasStatusIndicator
                >
                  <span
                    tabIndex="0"
                    className="sr-only"
                    role="tooltip"
                    aria-label={Tooltip_info}
                  ></span>
                  <Input
                    inputProps={{ maxLength: 2000 }}
                    onChange={handleChange}
                    className={`inputClass`}
                    multiline
                    value={data}
                    role="tooltip"
                    aria-label={Tooltip_info}
                  ></Input>
                </FormField>
              </Grid>
            </Grid>
          </Form>
          <p className={`noteSectionClass`}>
            {AdditionalCommentConstants.noteText}
          </p>
          <div className={`buttonDiv`}>
            <ButtonBar className={`buttonbarClass`}>
              <OrderedButton onClick={handlePreviousClick}>
                {AdditionalCommentConstants.previous}
              </OrderedButton>
              <OrderedButton onClick={handleNextClick} variant="cta">
                {AdditionalCommentConstants.next}
              </OrderedButton>
            </ButtonBar>
          </div>
        </div>
      )}
    </>
  );
};
export default BankersComments;
