import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./OnlineRequest.scss";
import { Input, StaticInputAdornment } from "@jpmuitk/input";
import { FormField } from "@jpmuitk/form-field";
import Grid from "@material-ui/core/Grid";
import { Dropdown } from "@jpmuitk/dropdown-next";
import { DataGrid } from "@jpmuitk/data-grid";
import OnlineRequestHeader from "./OnlineRequestHeader.js";
import { Link } from "react-router-dom";
import "react-dates/lib/css/_datepicker.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import DialogBox from "../../../components/dialogBox/DialogBox";
import { Redirect } from "react-router-dom";
import { Button } from "@jpmuitk/button";
import { Icon } from "@jpmuitk/icon";
import {
  SearchRequest,
  EditBankersComment,
  EditStatusData,
  getLoanComment,
  editBankersComments,
} from "../onlineRequest/redux/action/SearchFiltersActions";
import { Dialog, DialogTitle } from "@jpmuitk/dialog";
import SearchBox from "../../../components/searchBox/SearchBox";
import Loader from "../../../components/loader/Loader";
import {
  onlineRequest,
  FOURHUNDREDFOUR,
  FIVEHUNDRED,
  POLConstants,
  FOURZEROONE,
} from "../../global.constants";
import { getPreviewPdf } from "../../commonactions/redux/actions/PreviewAction";
import CustomPagination from "../../../components/pagination/pagination";
import {
  currentLoanId,
  returnTodashboardflag,
} from "../../dynamic/returnToDashboard/redux/action/dashboardAction";
import { successBanner } from "../../dynamic/submitRequest/redux/actions/submitRequestAction";
import Error from "../../../components/error/Error";
import {
  fetchUserId_success,
  fetchUserName,
} from "../../../auth/redux/action/AuthCheckAction";

const OnlineRequest = () => {
  const [openModal, setOpenModal] = useState(false);
  const searchedData = useSelector((state) => state.SearchReducer.searchedData);
  const [searchedDatas, setSearchedDatas] = useState([]);
  const [dialogMsgs, setDialogMsgs] = useState({});
  const [showfrachisee, setShowfranchisee] = useState(false);
  const [searchInputs, setSearchInputs] = useState({
    banker: null,
    loanStatus: "",
    requestedDateFrom: "",
    requestedDateTo: "",
  });
  const [lder, setLder] = useState(true);
  const dispatch = useDispatch();

  const [err, setErr] = useState(false);
  const [popup, setpopup] = useState(false);
  const [popupText, setpopupText] = useState(false);
  const [errMsg, setErrMsg] = useState(0);
  const errors = useSelector((state) => state.SearchReducer.error);
  const errorMsg = useSelector((state) => state.SearchReducer.errorMsg);
  const isAdminLoading = useSelector(
    (state) => state.AuthCheckReducer.isLoading
  );
  const loanId = useSelector((state) => state.DashboardReducer?.currentLoan);
  const loggedInUserId = useSelector(
    (state) => state.AuthCheckReducer?.loggedInUserId
  );
  const [showCancel, setshowCancel] = useState(false);
  /* Onload the datagrid will load */
  useEffect(() => {
    setTimeout(() => {
      dispatch(
        SearchRequest({
          isPagination: true,
          page: 1,
          reqParams: searchInputs,
        })
      );
    }, 1000);
  }, []);

  /* any errors in api calls */
  useEffect(() => {
    let eMsg = errors;
    setErr(true);
    setErrMsg(eMsg.status);
  }, [errorMsg, errors]);

  /* Setting api responses to state */
  useEffect(() => {
    setSearchedDatas(searchedData);
    setLder(false);
  }, [searchedData]);

  /* custom pagination function call*/
  const handlePageChange = (e, page) => {
    setLder(true);
    dispatch(
      SearchRequest({
        isPagination: true,
        page: page,
        reqParams: searchInputs,
      })
    );
    window.scrollTo(0, 0);
  };

  /* Data Grid Initiator with screenfit columns */
  const onGridReady = ({ api }) => {
    api.sizeColumnsToFit();
  };

  /* function callback from search component */
  const handleSubmit = (data) => {
    setLder(true);
    setSearchedDatas([]); //clearing data array to fill new set
    setSearchInputs(data.reqParams);
    dispatch(SearchRequest(data));
    window.scrollTo(0, 0);
  };

  /* Data Grid - Franchise Cell adjustments and functions */
  const franchiseeCellRenderer = (params) => {
    const frnCh = params.data;
    return (
      <span
        className={`franchiseeNameCell`}
        style={{
          width: params.column.actualWidth,
          wordBreak: "break-word",
          whiteSpace: "break-spaces",
          height: "100%",
          textAlign: "center",
        }}
      >
        {frnCh?.franchisee}
      </span>
    );
  };

  const purposeCellRenderer = (params) => {
    const purposes = params?.data?.purposes;
    return (
      <div className={`onlineRequestPurposeClass`}>
        {" "}
        {purposes?.map((purpose) => (
          <span style={{ width: params.column.actualWidth }}>
            {purpose?.purposeName}.
          </span>
        ))}
      </div>
    );
  };

  const dateLinkCellRenderer = (params) => {
    const handlePreview = () => {
      dispatch(getPreviewPdf(params.data?.loanId, params?.data?.userId));
    };
    return (
      <Link className={`cursor`} onClick={handlePreview}>
        {params.data?.createdDate}
      </Link>
    );
  };

  /* Data Grid - Create Date adjustments and functions */
  const createDateCellRenderer = (params) => {
    const crtDate = params.data;
    return <span>{crtDate?.createdDate}</span>;
  };
  const closeDialog = () => {
    setpopup(false);
  };
  const usAmtConvertor = (val) =>
    val.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  const amountCellRenderer = (params) => {
    const dtas =
      params.data?.loanAmount && params.data?.loanAmount > 0
        ? usAmtConvertor(params.data?.loanAmount)
        : "";
    return <span>{dtas}</span>;
  };

  /* Data Grid - Dynamic Row height adjustments */
  function setHeightRows(params) {
    let data = 50;
    if (params?.data?.purposes && params?.data?.purposes?.length) {
      data = (data - 24) * params?.data?.purposes?.length;
    }
    return data < 50 ? 50 : data;
  }

  /* Data Grid - Status Cell adjustments and functions */
  const statusCell = (val, type) => {
    let defaultArray = [
      onlineRequest.inProgress,
      onlineRequest.submitted,
      onlineRequest.cancelled,
      onlineRequest.receivedByChase,
    ];
    switch (val) {
      case onlineRequest.inProgress:
        return type
          ? [defaultArray[0]]
          : defaultArray.filter(
              (v) =>
                v !== onlineRequest.receivedByChase &&
                v !== onlineRequest.submitted
            );
      case onlineRequest.receivedByChase:
        return type
          ? [defaultArray[3]]
          : defaultArray.filter((v) => v === onlineRequest.receivedByChase);
      case onlineRequest.cancelled:
        return type
          ? [defaultArray[2]]
          : defaultArray.filter((v) => v === onlineRequest.cancelled);
      case onlineRequest.submitted:
      default:
        return type ? [defaultArray[1]] : defaultArray;
    }
  };

  /* Data Grid - Status Cell adjustments and functions */
  const statusCellRenderer = (params) => {
    const disableStatus =
      params.data?.loanStatus === "cancelled" ||
      params.data?.loanStatus === "receivedbychase"
        ? true
        : false;
    const vals = {
      fromStatus: params.data?.loanStatus,
      userId: params.data?.userId,
      loanId: params.data?.loanId,
      rowLinks: false,
      loanAmount: params.data?.loanAmount,
      purposes: params.data?.purposes,
      purposeName: params.data?.purposeName,
      franchisee: params.data?.franchisee,
      loanSubmitDate: params.data?.loanSubmitDate,
      createdDate: params.data?.createdDate,
    };
    return (
      <FormField>
        <Dropdown
          onChange={(e, item) => {
            setpopupText(true);
            setshowCancel(true);
            opensDialog(
              vals,
              item,
              statusCell(params.data?.loanStatus, true)[0]
            );
          }}
          disabled={disableStatus}
          initialSelectedItem={statusCell(params.data?.loanStatus, true)[0]}
          source={statusCell(params.data?.loanStatus)}
        />
      </FormField>
    );
  };

  /* Data Grid - on Click of Edit Loan Status */
  const handleEdit = (loanId, userId, franchiseName) => {
    const currentloan = {
      isLeftnavEnabled: true,
      activeLoanId: loanId,
    };
    dispatch(successBanner(false));
    dispatch(currentLoanId(currentloan));
    dispatch(fetchUserId_success(userId));
    dispatch(fetchUserName(franchiseName));
    dispatch(
      returnTodashboardflag({ adminFlag: true, menuId: "on-LineRequests" })
    );
    window.scrollTo(0, 0);
  };

  /* Data Grid - Banker Comment Cell adjustments and functions */
  const bankerCommentCellRenderer = (params) => {
    const propsVals = {
      name: params.data?.franchisee,
      loanCreationData: params.data?.createdDate,
      userId: params.data?.userId,
      loanId: params.data?.loanId,
      rowLinks: true,
    };

    return (
      <div>
        {params.data?.loanStatus === "In Progress" ? (
          <Link
            to="/contactInformation"
            onClick={() =>
              handleEdit(
                params.data?.loanId,
                params.data?.userId,
                params.data?.franchisee
              )
            }
            className={`cursor editLink`}
          >
            {onlineRequest.editLoanRequest}
          </Link>
        ) : null}
        <div className="dsFlex">
          <Link
            onClick={(e) => {
              setpopupText(false);
              setshowCancel(false);
              opensDialog(propsVals);
            }}
            data-props={propsVals}
            className={`cursor editLink`}
          >
            {onlineRequest.bankersComments}
          </Link>
        </div>
      </div>
    );
  };

  /* Open Dialog with different props Edit Comment and Edit Status*/
  function opensDialog(theEleVal, val = "", oldVal = "") {
    if (val !== "" && val.toLowerCase() !== oldVal.toLowerCase()) {
      theEleVal.statusChangedFrom = oldVal;
      theEleVal.statusChangedTo = val;
    } else if (val !== "" && val.toLowerCase() === oldVal.toLowerCase()) {
      return;
    }
    if (val === "") {
      dispatch(
        getLoanComment({
          loanId: theEleVal.loanId,
        })
      );
    }
    setDialogMsgs(theEleVal);
    setOpenModal(true);
  }

  /* Edit Status and Edit Comments callback function*/
  const submitComments = (data) => {
    let dt;
    if (data.submit) {
      dt = {
        userId: data.userId,
        loanId: data.loanId,
        loanStatus: data.loanStatus,
      };
      statusCell(data.loanStatus);
      dispatch(EditStatusData(dt));
      setLder(true);
      setTimeout(() => {
        dispatch(
          SearchRequest({
            isPagination: true,
            page: localStorage.getItem("pageNo"),
            reqParams: searchInputs,
          })
        );
      }, 3000);
    } else {
      dt = {
        userId: data.userId,
        loanId: data.loanId,
        comments: data.theComments,
      };
      dispatch(editBankersComments(dt, data.loanId, data.userId));
    }
  };

  if (showfrachisee) {
    dispatch(returnTodashboardflag({ menuId: "on-LineRequests" }));
    return <Redirect to="/searchFranchise" />;
  }

  return (
    <>
      {lder || (isAdminLoading && <Loader />)}
      {errMsg === FOURHUNDREDFOUR ? (
        <Error />
      ) : (
        <div className="adminContainer" id="main" role="main">
          <h1>{onlineRequest.header}</h1>
          <SearchBox
            handleSubmits={handleSubmit}
            page="ORequest"
            showfrachise={() => setShowfranchisee(true)}
          />
          <div id="myGrid" className="ag-theme-alpine">
            {errMsg === FIVEHUNDRED && errorMsg}
            <DataGrid
              className="online-data-grid"
              classes={{ root: "dataGridAdminDashboard" }}
              columnDefs={OnlineRequestHeader}
              rowData={searchedDatas?.loans}
              pagination={false}
              paginationPageSize={10}
              onGridReady={onGridReady}
              domLayout={"autoHeight"}
              frameworkComponents={{
                bankerCommentCellRenderer: bankerCommentCellRenderer,
                dateLinkCellRenderer: dateLinkCellRenderer,
                statusCellRenderer: statusCellRenderer,
                purposeCellRenderer: purposeCellRenderer,
                franchiseeCellRenderer: franchiseeCellRenderer,
                createDateCellRenderer: createDateCellRenderer,
                amountCellRenderer: amountCellRenderer,
              }}
              getRowHeight={setHeightRows}
            />

            <div className={`dashboard-pagination paginations`}>
              <div className="fltRght">
                {searchedDatas.totalPages && (
                  <CustomPagination
                    count={searchedDatas.totalPages}
                    handlePageChange={handlePageChange}
                    page={searchedDatas.currentPage}
                  />
                )}
              </div>
            </div>
            <div className="formFields">
              <Grid item xs={12}>
                <div className="request">
                  <FormField label={onlineRequest.totalNumberofRequests}>
                    <Input
                      autoComplete="off"
                      disabled
                      name="numberofrequest"
                      value={
                        searchedDatas?.totalLoans
                          ? searchedDatas?.totalLoans
                          : 0
                      }
                    />
                  </FormField>
                </div>
                <div className="dollar">
                  <FormField label="Total Amount from Requests">
                    <Input
                      autoComplete="off"
                      disabled
                      name=""
                      startAdornment={
                        <StaticInputAdornment>$</StaticInputAdornment>
                      }
                      value={
                        searchedDatas?.totalAmount
                          ? usAmtConvertor(searchedDatas?.totalAmount)
                          : 0
                      }
                    />
                  </FormField>
                </div>
              </Grid>
              {openModal && (
                <DialogBox
                  showCancel={showCancel}
                  setpopup={setpopup}
                  id="onLineReq"
                  open={openModal}
                  setOpen={setOpenModal}
                  dialogMsgs={dialogMsgs}
                  submitComments={submitComments}
                />
              )}
            </div>
          </div>
        </div>
      )}
      {popup ? (
        <Dialog open={popup}>
          <div className="clos">
            <Button
              className="returntoDashboard-dialogboxclose"
              aria-label="close dialog button"
              onClick={closeDialog}
            >
              <Icon size={14} name="close" />
            </Button>
            <DialogTitle>{onlineRequest.successMsg}</DialogTitle>
          </div>
          {popupText
            ? onlineRequest.statusUpdated
            : onlineRequest.commentsSaved}
        </Dialog>
      ) : (
        POLConstants.emptyString
      )}
    </>
  );
};
export default OnlineRequest;
