import {
  FETCH_RETURNTODASHBOARD_REQUEST,
  FETCH_RETURNTODASHBOARD_SUCCESS,
  FETCH_RETURNTODASHBOARD_FAILURE,
  CANCELLAONREQUEST_REQUEST,
  CANCELLAONREQUEST_SUCCESS,
  CANCELLAONREQUEST_FAILURE,
  CREATENEWREQUEST_FAILURE,
  RETURNTOADMINDASH,
  CURRENT_LOANID,
  CANCEL_LOANID,
  FORM_HAS_ERROR
} from '../../../../global.constants';

const initialState = {
  returnToDashboard: [],
  currentLoan: [],
  cancelLoanId: "",
  error: '',
  isLoading: false,
  cancelReq: false,
  formError: true,
  menuId:'',
  returnTodashboardflag: false
}

const ReturnToDashboardReducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case FETCH_RETURNTODASHBOARD_REQUEST:
      return Object.assign({}, state, {
        isLoading: true
      });
    case FETCH_RETURNTODASHBOARD_SUCCESS:
      return Object.assign({}, state, {
        returnToDashboard: payload,
        isLoading: false
      });
    case FETCH_RETURNTODASHBOARD_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        error: payload
      });
    case CURRENT_LOANID:
      return Object.assign({}, state, {
        currentLoan: payload
      });
    case CANCEL_LOANID:
      return Object.assign({}, state, {
        cancelLoanId: payload
      });
      case RETURNTOADMINDASH:
        return Object.assign({}, state, {
          returnTodashboardflag: payload?.adminFlag,
          menuId: payload?.menuId
        });
    case CANCELLAONREQUEST_FAILURE:
    case CREATENEWREQUEST_FAILURE:
      return Object.assign({}, state, {
        isLoading: false
      });
    case CANCELLAONREQUEST_REQUEST:
      return Object.assign({}, state, {
        cancelReq: false,
        isLoading: true
      });
    case CANCELLAONREQUEST_SUCCESS:
      return Object.assign({}, state, {
        cancelReq: true,
        isLoading: false
      });
    case FORM_HAS_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        formError: payload
      });
    default:
      return state;
  }
};
export default ReturnToDashboardReducer;