import React, { useState, useEffect, useCallback } from "react";
import { FormField } from "@jpmuitk/form-field";
import Grid from "@material-ui/core/Grid";
import { Form } from "@jpmuitk/forms";
import { Input } from "@jpmuitk/input";
import { Button } from "@jpmuitk/button";
import { useSelector, useDispatch } from "react-redux";
import dynamicForm from "../../../components/form/DynamicForm";
import ThirdPartyWiringDetails from "./ThirdPartyWiringDetails";
import {
  getPartyData,
  updateData,
  savePartyData,
} from "./redux/action/ThirdPartyWiringAction";
import { ThirdPartyLabel, FOURHUNDREDERR } from "../../global.constants";
import { getCountrystatesData } from "../../commonactions/redux/actions/action";
import HiddenValidator from "../../../containers/HiddenValidator";
import { formHas_error } from "../../dynamic/returnToDashboard/redux/action/dashboardAction";
import Loader from "../../../components/loader/Loader";
import Error from "../../../components/error/Error";
import "./ThirdPartyWiringDetails.scss";
import "../../global.scss";
import { Dialog, DialogTitle, DialogActions } from "@jpmuitk/dialog";

import { useHistory } from "react-router-dom";
import {
  getNextNavigation,
  getPreviousNavigation,
} from "../../../components/navigationPath/navigationPath";

const PATHS = "/physicalMailingAddress";
const PATH = "/accountantInformation";

const ThirdPartyWiring = (props) => {
  const history = useHistory();

  const { createInitialList, addNewItem, removeAnItem } = props;

  const dispatch = useDispatch();
  const AccountDetailsData = useSelector(
    (state) => state?.ThirdPartyWiringReducer?.AccountDetails
  );
  const isLoading = useSelector(
    (state) => state.ThirdPartyWiringReducer.isLoading
  );
  const userId = useSelector((state) => state.AuthCheckReducer?.loggedInUserId);
  const errors = useSelector((state) => state.ThirdPartyWiringReducer.error);
  const [accountDetails, setAccountDetails] = useState(AccountDetailsData);
  const loanId = useSelector((state) => state.DashboardReducer?.currentLoan);
  const [deletespan, setDeletespan] = useState(false);
  const [addspan, setAddspan] = useState({
    isAdded: false,
    newlyAddedIndex: null,
  });

  // fetch fresh Accountdetails data on mount
  useEffect(() => {
    if (loanId?.activeLoanId) {
      dispatch(getPartyData(loanId?.activeLoanId, userId));
      dispatch(getCountrystatesData());
    }
  }, [loanId]);

  useEffect(() => {
    setAccountDetails(AccountDetailsData);
    setDeletespan(false);
    setAddspan({});
  }, [AccountDetailsData]);

  const updateStore = () => {
    if (accountDetails && loanId?.activeLoanId) {
      accountDetails.forEach((loan) => (loan.userId = userId));
      dispatch(savePartyData(accountDetails, userId, loanId?.activeLoanId));
    }
  };

  const handleAccountSection = useCallback((e) => {
    let accountList = createInitialList(e?.target?.value, loanId?.activeLoanId);
    setAccountDetails([...accountList]);
  }, []);

  const handleAddNewAccount = useCallback(() => {
    let accountList = addNewItem(accountDetails, loanId?.activeLoanId);
    setAddspan({
      ...addspan,
      isAdded: true,
      newlyAddedIndex: accountList.length,
    });
    setAccountDetails([...accountList]);
  }, [accountDetails]);

  const handleRemoveAccountSection = useCallback(
    (id) => {
      setDeletespan(true);
      setDeletespan(id);
      let accountList = removeAnItem(id, accountDetails, loanId?.activeLoanId);
      setAccountDetails([...accountList]);
    },
    [accountDetails]
  );

  const updateAccountList = (list, ind) => {
    accountDetails[ind] = list;
    setAccountDetails([...accountDetails]);
    if (loanId?.activeLoanId) {
      dispatch(updateData(accountDetails));
    }
  };

  const handlePreviousClick = () => {
    updateStore();
    history.push(getPreviousNavigation());
    window.scrollTo(0, 0);
  };

  const handleNextClick = (sameComp = true) => {
    let data = accountDetails.filter((ele, i) => ele !== undefined);
    updateStore();
    if (!sameComp) {
      dispatch(formHas_error(false));
      return false;
    }
    history.push(getNextNavigation());
    window.scrollTo(0, 0);
  };

  const validDebt = accountDetails?.filter(
    (ele, i) => ele !== undefined
  ).length;

  const [popup, setpopup] = useState(false);

  return (
    <>
      {isLoading ? <Loader /> : null}
      {errors == FOURHUNDREDERR ? <Error /> : null}
      <div className={`dynamicContainer`} id="main" role="main">
        <h1 className={`headingClass`}>{ThirdPartyLabel.header}</h1>
        <Form fullWidthFields className={`formClass`}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormField
                disabled={validDebt ? true : false}
                label={ThirdPartyLabel.wiring}
                onClick={() => (validDebt ? setpopup(true) : "")}
              >
                <Input
                  name="debtListValue"
                  fullWidth
                  autoComplete="off"
                  value={validDebt == 0 ? "" : validDebt}
                  onChange={handleAccountSection}
                />
              </FormField>
              {accountDetails?.map((rpt, ind) => {
                if (rpt !== undefined) {
                  return (
                    <ThirdPartyWiringDetails
                      key={ind}
                      ind={ind}
                      itemDeleted={deletespan}
                      removerpt={handleRemoveAccountSection}
                      updateRoot={updateAccountList}
                      defaultValue={rpt}
                    />
                  );
                }
              })}

              <div className={`footerContainers`}>
                {validDebt ? (
                  <Button variant="cta" onClick={handleAddNewAccount}>
                    {ThirdPartyLabel.btnAddrpt}
                    {addspan.isAdded ? (
                      <span
                        class="sr-only"
                        aria-live="assertive"
                        role="alert"
                        aria-label={` Recipient ${addspan.newlyAddedIndex} Added`}
                      ></span>
                    ) : (
                      ""
                    )}
                  </Button>
                ) : (
                  ""
                )}

                {validDebt ? (
                  <div className={"noteSectio"}>
                    {ThirdPartyLabel.transaction}
                    <p className="requireds">{ThirdPartyLabel.security}</p>
                  </div>
                ) : (
                  ""
                )}

                <p className={"noteSections"}>{ThirdPartyLabel.note}</p>

                <div className={`headerContainer`}>
                  <Button onClick={handlePreviousClick}>
                    previous section
                  </Button>
                  <Button variant="cta" onClick={handleNextClick}>
                    next section
                  </Button>
                  <HiddenValidator
                    validateTrigger={() => handleNextClick(false)}
                    labels={"next section"}
                  />
                </div>
              </div>
            </Grid>
          </Grid>

          {popup ? (
            <Dialog open={popup}>
              <DialogTitle>
                To add or delete recipients, please click add or delete buttons
                below.
              </DialogTitle>
              <DialogActions>
                <Button onClick={() => setpopup(false)} variant="cta">
                  {" "}
                  Close{" "}
                </Button>
              </DialogActions>
            </Dialog>
          ) : (
            ""
          )}
        </Form>
      </div>
    </>
  );
};

const initrpt = {
  loanId: "",
  recipientId: "",
  recipientName: "",
  city: "",
  state: "",
  routingNumber: "",
  accountNumber: "",
  Reaccount: "",
  bankName: "",
  bankRepresentative: "",
  bankRepContactNumber: "",
  specialInstruction: "",
  wiredAmount: "",
};

const dynamicFormProps = {
  initObj: { ...initrpt },
  idKey: "loanId",
};

export default dynamicForm(ThirdPartyWiring, dynamicFormProps);
