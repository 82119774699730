export const dynamic_Menu = [
  { id: "dashboard", name: "Return to Dashboard" },
  { id: "myProfile", name: "My Profile" },
  {
    id: "contactInformation",
    name: "Contact Information",
    className: "mandatoryleftnav",
  },
  {
    id: "businessAddressInformation",
    name: "Business Address Information",
    className: "mandatoryleftnav",
  },
  {
    id: "restaurantInformationSummaryDetails",
    name: "Restaurant Information Summary/Details",
    className: "mandatoryleftnav",
  },
  {
    id: "purposeofLoanRequest",
    name: "Purpose of Loan Request",
    className: "mandatoryleftnav",
  },
  {
    id: "currentDebtSummaryDetails",
    name: "Current Debt Summary/Details",
    className: "mandatoryleftnav",
  },
  {
    id: "regionInformation",
    name: "Region Information",
    className: "mandatoryleftnav",
  },
  {
    id: "accountantInformation",
    name: "Accountant Information",
    className: "mandatoryleftnav",
  },
  {
    id: "thirdPartyWiringInstructions",
    name: "Third Party Wiring Instructions",
    className: "mandatoryleftnav",
  },
  {
    id: "physicalMailingAddress",
    name: "Physical Mailing Address",
    className: "mandatoryleftnav",
  },
  {
    id: "miscellaneousDataRequired",
    name: "Miscellaneous Data Required",
    className: "mandatoryleftnav",
  },
  {
    id: "additionalComments",
    name: "Additional Comments",
    className: "mandatoryleftnav",
  },
  {
    id: "submitRequesttoChaseFranchiseFinance",
    name: "Submit Request to Chase Franchise Finance",
    className: "mandatoryleftnav",
  },
];
export const adminleftnavdata = [
  {
    id: "updateCurrentRates&Message",
    name: "News Flash",
    order: 1,
  },
  { id: "on-LineRequests", name: "On-Line Requests", order: 2 },
  { id: "adminDashboard", name: "Dashboard", order: 3 },
  { id: "franchiseeProfile", name: "Franchisee Profile", order: 4 },
];
export const errorMessageStyle = { style: { color: "#cd4c0a" } };
export const base_url = "";
export const base_url_user = "";
export const base_url_admin = "";
export const base_url_loan = "";
export const base_url_auth = "";
export const FLOATING = "Floating:";
export const YEARS = "5 year fixed:";
export const FOURHUNDREDFOUR = 404;
export const FOURZEROONE = 401;
export const RECEIVE_RATES_REQUEST = "RECEIVE_RATES_REQUEST";
export const RATES_SUCCESS = "RATES_SUCCESS";
export const RATES_FAILURE = "RATES_FAILURE";
export const PERCENT = "%";
export const FOURHUNDREDFOURERROR = 404;
export const FOURHUNDREDFOURERR = 404;
export const FOURHUNDREDERR = 404;
export const FIVEHUNDRED = 500;
export const PATHS = "/thirdPartyWiringInstructions";
export const PATH = "/regionInformation";

//FOOTER CONTENTS

//FOOTER CONTENTS
export const PARA = `McDonald's, the Golden Arches Logo and the McDonald's Building Designs are trademarks of McDonald's Corporation and its affiliates, used with permission. McDonald's does not endorse or control this program, but permits Chase to make this offer available. McDonald's is not responsible or liable for any products or services offered by Chase.`;
export const ERRORCODE = 404;

//HOME CONTENTS

export const HOMEHEADER1 = `Chase Franchise Finance`;
export const CONTENTH1 = `Building on a Golden Past to Deliver a Promising Future`;
export const HOMEHEADER2 = `We Understand Your Business`;
export const H2CONTENT1 = `As a provider of financial services to the McDonald's System for more than 50 years, Chase Franchise Finance thoroughly understands your business. This focus allows us to closely follow changes that may impact your financing needs. With this understanding, Chase can be responsive as you grow your business.`;
export const H2CONTENT2 = ` The members of the Chase Franchise Finance team are banking professionals whose primary responsibility is to service the financing needs of the McDonald's System. `;
export const FIND = `Find out more`;
export const LINKCONTENT = `Click to begin our quick and easy Interactive Loan Request.`;
export const BUTTON = `Interactive loan request`;
export const CONTENT6 = `COVID-19 UPDATE Please be sure to scan and return loan documents via email to prevent any delays.  Please contact your banker with any questions regarding assistance during these times.`;
export const CONTENT6p = `If you are locked out or would like any assistance we are here to help you. Please call your banker directly or client service professional at `;
export const PHONENUMBER = `1-800-543-4622 `;
export const CONTENT6p1 = `option 0.`;

//homefind CONTENTS

export const HEADER1 = `Chase Franchise Finance Understands Your Business`;
export const CONTENT1 = `As a financing source specifically designed for and dedicated to Owner/Operators of McDonald's franchises, Chase Franchise Finance thoroughly understands your business. This focus allows us to closely follow changes that may impact your financing needs. With this understanding, Chase can be responsive as you grow your business.`;
export const CONTENT2 = `The members of the Chase Franchise Finance team are banking professionals whose primary responsibility is to service the financing needs of the McDonald's System. Each team member has many years of banking experience and most have spent a considerable part of their banking careers working with Franchise Owner/Operators.`;
export const HEADER2 = `Our Commitment to the Franchise Business`;
export const CONTENT3 = `Chase is committed to similar goals of quality, service, creativity, and value that have made McDonald's the successful brand that it is today.`;
export const QUALITYDATA = `A professional staff that thoroughly understands your business.`;
export const SERVICEDATA = `Commitment to providing a quick and accurate assessment of your loan request - and dedication to providing you timely funding to fuel your growth and develop your business.`;
export const CREATIVITYDATA = ` Exemplary products and financial solutions that were specifically developed to accommodate the financing needs of McDonald's Owner/Operators.`;
export const VALUEDATA = `Competitive rates to improve your bottom line.`;
export const CONTENT4 = `These attributes have made Chase a premier lender to McDonald's Owner/Operators. Chase has a long standing relationship with the McDonald's Franchise System. One of Chase's predecessor organizations, American National Bank, was one of the first banks to finance McDonald's and its founder, Ray Kroc. Chase is committed to providing flexible financial solutions to McDonald's Owner/Operators.`;
export const OnlineloanRequest = {
  LegalRelease: "Legal Release For ",
  LoanRequestUrl:
    "https://www.chasemcdfinance.com/ffw-web/ext/newOrReturningUser.do",
  OnlineLoanRequest: "Online Loan Requests",
  content: `During the review of submitted requests, Chase may obtain a credit report on the principals (stockholders, proprietors, and partners) or the business entity included on the loan request. As permitted by law, Chase may share account information and information contained in a submitted request and in any credit report on the principals with other Chase affiliates for the purpose of considering eligibility for their products or services including loans and lines of credit. Further, Chase and McDonald's may share with each other any information they may have with respect to the requester's operation(s) as a Franchisee of McDonald's as such information may relate to the services provided to the requester by Chase.`,
  subContent: `By submitting a request to Chase, the requester certifies that the information contained in the online request, attachments, and addendums are true, complete and correct to the best of the requester's knowledge. The requester further certifies that the proceeds of the loan being requested will be used for McDonald's business-related purposes and not for personal, family, tax, or household purposes.`,
};
export const Form = {
  forms: "Forms",
  paymentFormUrl:
    "https://www.chasemcdfinance.com/ffw-public/documents/FFWAdditionaPaymentForm.pdf",
  addressFormUrl:
    "https://www.chasemcdfinance.com/ffw-public/documents/AddressChangeForm.pdf",
  autoDebitFormUrl:
    "https://www.chasemcdfinance.com/ffw-public/documents/AutodebitForm.pdf",
  drawRequestFormUrl:
    "https://www.chasemcdfinance.com/ffw-public/documents/AdvanceLoanRequestForm.pdf",
  irsFormUrl: "https://www.irs.gov/pub/irs-pdf/fw9.pdf",
  paymentForm: "Additional Payment Form",
  addressForm: "Address Change Form",
  autoDebitForm: "Auto-Debit Form",
  drawRequestForm: "Draw Request Form - All Loan types",
  irsForm: "IRS Form W-9",
};

export const DownloadPrintableStatement = {
  downloadStatement: "Franchise Loan Request",
  documentRequired: "Document Required:",
  review:
    "Review the Required Information for your loan type (Term Loan or Line of Credit) to determine which forms are required. Your Chase Franchise Finance banker will notify you if additional information is needed.",
  theInformation: "The ",
  printableStatement: "Franchisee Loan Request",
  printablelUrl:
    "https://www.chasemcdfinance.com/ffw-public/documents/LoanRequestForm.pdf",
  isavaliableStatement:
    " is available for download in Adobe Acrobat (.pdf) format.",
  providedContent: "You can also enter your request online through our ",
  onlineloanrequestUrl:
    "https://www.chasemcdfinance.com/ffw-web/ext/newOrReturningUser.do",
  requestStatement: "Online Loan Request",
};

//loan product Summary CONTENTS

export const LOANPRODUCTSUMMARY_HEADER1 = `Loan Products Summary`;
export const LOANPRODUCT_SUBHEADER = `The following does not purport to summarize all terms, conditions and other provisions that may be contained in any loan documentation. All loans and lines of
credit are subject to credit approval and satisfaction of due diligence requirements.`;
export const LOANPRODUCT_FOOTER = `McDonald's, the Golden Arches Logo and the McDonald's Building Designs are trademarks of McDonald's Corporation and its affiliates, used with permission. McDonald's does not endorse
or control this program, but permits Chase to make this offer available. McDonald's is not responsible or liable for any products or services offered by Chase.`;
export const MUTIPLE = [
  "Purchase New or Existing Restaurant(s)",
  "Exercise BFL(s)/Relocate/Rebuild",
  "Refinance/Restructure Existing Debt",
  "Reinvestment/Remodel",
  "PlayPlaces",
  "Purchase Equipment",
  "Purchase McOpCo",
  "Coinvestment",
  "Other Special Business Purposes",
];

//Contact Us CONTENTS
export const CONTACTADDRESS = [
  "Toll-Free Number",
  " 800.543.4622",
  "  Fax Number",
  "866.342.9609",
  "Hours of Operation",
  " 8:00 a.m. - 4:45 p.m. CT",
];
export const CONTACTMAILADDRESS = [
  "Mailing Address",
  "Chase Franchise Finance",
  " 712 Main St.",
  "9th Floor Central TX2-N050",
  " Houston, Texas 77002",
];
export const CONTACT_US = {
  header: `Contact Us`,
  subheader: `Our goal is to make our financing information highly accessible so you always have acess to the most current information available. You can access our information
    through our printed literature, through this website via email, and through our toll-free telephone line where you can easily be connected with one of our team
    members.`,
  query: `If you have any questions about your loan request or the information required for processing, please feel free to contact us. When you have completed the request
              for financing and the personal financial statement, please send them along with the other required items to:`,
  subheader1: `Chase Franchise Finance Bankers`,

  subheader2: `Program Managers`,
  subheader3: `Client Service Professionals`,
  finance: `This program was designed to provide financing for New and Existing Domestic McDonald's Franchisees. Please call us for more information on international
              financing opportunities. Please check back with us periodically for updates.`,
  info: `Chase is unable to provide information on obtaining a McDonald's franchise. For that type of information, please visit the `,
  site: `McDonald's web site`,

  mcdonald: "http://www.mcdonalds.com/us/en/home.html",
  bankers: [
    {
      name: "LaTisha Land-Joiner",
      mobile: "800.543.4622, Option 16",
      mail: "latisha.k.land-joiner@chase.com",
      url: "mailto:latisha.k.land-joiner@chase.com",
      imgSrc: "LaTishaWeb.jpg",
    },
    {
      name: " Dona Wingard",
      mobile: "800.543.4622, Option 11",
      mail: "dona.wingard@chase.com",
      url: "mailto:dona.wingard@chase.com",
      imgSrc: "DonaWeb.jpg",
    },

    {
      name: "Charles Kosmas",
      mobile: "800.543.4622, Option 9",
      mail: "charles.kosmas@chase.com",
      url: "mailto:charles.kosmas@chase.com",
      imgSrc: "CharlieWeb.jpg",
    },

    {
      name: "Lisa C. Lim",
      mobile: "800.543.4622, Option 6",
      mail: "lisa.c.lim@chase.com",
      url: "mailto:lisa.c.lim@chase.com",
      imgSrc: "LisaLim.jpg",
    },

    {
      name: "Benn D. Copeland",
      mobile: "800.543.4622, Option 0",
      mail: "benn.d.copeland@chase.com",
      url: "mailto:benn.d.copeland@chase.com",
      imgSrc: "BennWeb.jpg",
    },

    {
      name: " Angela J. Newbould",
      mobile: "800.543.4622, Option 0",
      mail: "angela.j.newbould@chase.com",
      url: "mailto:angela.j.newbould@chase.com",
      imgSrc: "AngelaWeb.jpg",
    },
  ],
  projectManagers: [
    {
      name: " Troy Ahrens",
      mobile: "800.543.4622, Option 12",
      mail: "troy.ahrens@chase.com",
      url: "mailto:troy.ahrens@chase.com",
      imgSrc: "TroyWeb.jpg",
    },
  ],

  clientServiceProfessionals: [
    {
      name: "Etherine Elliot",
      mobile: "800.543.4622, option 0",
      mail: "etherine.elliott@chase.com",
      url: "mailto:etherine.elliott@chase.com",
      imgSrc: "elliott_etherine.jpg",
    },
    {
      name: "Marisol Ramirez",
      mobile: "800.543.4622, option 0",
      mail: "Marisol.ramirez@chase.com",
      url: "mailto:Marisol.ramirez@chase.com",
      imgSrc: "MarisolWeb.jpg",
    },

    {
      name: "Tracy Knighton",
      designation: "Client Service Assistant Manager",
      mobile: "800.543.4622, option 0",
      mail: "Tracy.a.knighton@chase.com",
      url: "mailto:Tracy.a.knighton@chase.com",
      imgSrc: "Tracy.jpg",
    },
    {
      name: "Terri Bellow",
      designation: "Client Service Manager",
      mobile: "800.543.4622, option 0",
      mail: "Terri.l.bellow@chase.com",
      url: "mailto:Terri.l.bellow@chase.com",
      imgSrc: "TerriWeb.jpg",
    },
  ],
};

// Required information

export const RequiredInformationData = {
  header: "Required Information for Loan and Line of Credit Requests",
  info: "You will be notified if additional information is needed.",
  purchaseNewRestaurant: "Purchase New Restaurant",
  purchaseExistingRestaurant:
    "Purchase Existing Restaurant from Another Operator",
  exercise: "Exercise BFL, Relocate or Rebuild Restaurant",
  purchaseMc: "Purchase McOpCo",
  purcRefinance: "Refinance / Restructure Existing Debt",
  Refinance: "Refinance Existing Building Loan",
  assumeExisting: "Assume Existing IMCO Building Loan from Another Operator",
  reInvestment:
    "Reinvestment, Remodel, PlayPlace, Equipment Purchases and Special Programs",
  lineOfCredit: "Line of Credit",
  signedAndCompleted: "Signed and Completed Application ",
  cross: "X",
  diligence: "Diligence Fee ($250)",
  standAloneRequest:
    "(For a stand-alone request; $100 if request accompanies a term loan request)",
  current: "Current ",
  personalFinancialStatement: "Personal Financial Statement",
  lastFiscalYearEnd:
    "Last 3 fiscal year-end consolidated and individual Profit and Loss Statements and Balance Sheets of all restaurants owned",
  mostCurrentConsolidated:
    "Most current consolidated and individual year-to-date Profit and Loss Statements and Balance Sheets of all restaurants owned",
  consolidatedIndividualYear:
    "Consolidated and individual year-to-date Profit and Loss Statements and Balance Sheets of all restaurants owned for comparable prior year period",
  existingProfitLoss:
    " Existing Profit and Loss Statements for Past trailing Twelve Month period",
  profitLossStatements:
    "Profit and Loss Statements and Balance Sheets for Management Company, if applicable for all comparable fiscal year and interim periods of historical financials provided on restaurants",
  costBreakdown: "Cost breakdown of expenses being financed ",
  oneYearProjectedProfit:
    "One year of Projected Profit and Loss Statements on New Restaurant(s)",
  copyDriversLicense: `Copy of Driver's License `,
  organizationDocuments: `Organization Documents: If a Corporation - Recorded Articles of Incorporation `,
  organizationDocumentsLiability: `Organization Documents: If a Limited Liability Company - Recorded Articles of Organization, Updated Member Certificate, LLC Operating Agreement`,
  organizationDocumentsJointVenture: `Organization Documents: If a Joint Venture, General or Limited Partnership - Executed Partnership Documents `,
  newExistingClients: `W-9s For New Clients or If Not On File For Existing Clients`,
};
export const REQUEST_POST_DATA = "REQUEST_POST_DATA";
export const RECEIVE_POST_DATA = "RECEIVE_POST_DATA";
export const FAILURE_POST_DATA = "FAILURE_POST_DATA";
export const RECEIVE_ERR_DATA = `RECEIVE_ERR_DATA`;

export const RECEIVE_GET_DATA = "RECEIVE_GET_DATA";
export const FAILURE_GET_DATA = "FAILURE_GET_DATA";
export const REQUEST_GET_DATA = "REQUEST_GET_DATA";
export const RECEIVE_PROFILE_DATA = "RECEIVE_PROFILE_DATA";

export const RECEIVE_REGION_DATA = "RECEIVE_REGION_DATA";
export const FAILURE_REGION_DATA = "FAILURE_REGION_DATA";
export const REQUEST_REGION_DATA = "REQUEST_REGION_DATA";

export const RECEIVE_COMMENT_DATA = "RECEIVE_COMMENT_DATA";
export const FAILURE_COMMENT_DATA = "FAILURE_COMMENT_DATA";

export const REQUEST_RETURN = "REQUEST_RETURN";
export const REQUEST_EXIST = "REQUEST_EXIST";

export const RECEIVE_GET_COMMENT_DATA = "RECEIVE_GET_COMMENT_DATA";
export const FAILURE_GET_COMMENT_DATA = "FAILURE_GET_COMMENT_DATA";

export const SUBMIT_CONTACT_DATA = "SUBMIT_CONTACT_DATA";
export const RECEIVE_CONTACT_GET_DATA = "RECEIVE_CONTACT_GET_DATA";

export const RECEIVE_CONTACT_INFORMATION_REQUEST =
  "RECEIVE_CONTACT_INFORMATION_REQUEST";
export const END_POINT_CONTACT_INFORMATION_REQUEST =
  "END_POINT_CONTACT_INFORMATION_REQUEST";
export const FAILURE_CONTACT_GET_REQUEST = "FAILURE_CONTACT_GET_REQUEST";
export const FAILURE_CONTACT_POST_REQUEST = "FAILURE_CONTACT_POST_REQUEST";

export const POST_REGISTRATION_DATA = " POST_REGISTRATION_DATA";
export const ERRROR_DATA = "ERRROR_DATA";
export const RECEIVE_REGISTRATION_INFORMATION_REQUEST =
  "RECEIVE_REGISTRATION_INFORMATION_REQUEST";
export const FAILURE_REGISTRATION_POST_REQUEST =
  " FAILURE_REGISTRATION_POST_REQUEST";

export const RECEIVE_UPDATE_CURRENT_GET_DATA =
  "RECEIVE_UPDATE_CURRENT_GET_DATA";
export const RECEIVE_UPDATE_CURRENT_FAILURE_DATA =
  "RECEIVE_UPDATE_CURRENT_FAILURE_DATA";
export const RECEIVE_UPDATE_CURRENT_SAVE_NEWFLASH_SUCCESS_DATA =
  "RECEIVE_UPDATE_CURRENT_SAVE_NEWFLASH_SUCCESS_DATA";
export const RECEIVE_ADMIN_LEFT_NAV_DATA = "RECEIVE_ADMIN_LEFT_NAV_DATA";
export const RECEIVE_ADMIN_LEFT_NAV_FAILURE = "RECEIVE_ADMIN_LEFT_NAV_FAILURE";
export const FFW_EXT_USER_ALREADY_EXISTS = "FFW.EXT.USER.ALREADY.EXISTS";
export const FFW_EXT_EMAIL_ALREADY_EXISTS = "isFranchiseeEmailExists";
export const MyProfileConstants = {
  SELECT: `Select`,
  alertMessage: `ALERT: If you are changing your e-mail address, you will be automatically logged out while our system makes the necessary changes to update your email address. Please log back in after 3-5 minutes with your new email address`,
  myprofile: `My Profile`,
  emailRegex: `/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/`,
  invalidEmail: `Invalid Email-Address`,
  emptyRegion: `Please enter the MC Donalds region`,
  change: `Change`,
  cancel: `Cancel`,
  firstNameError: `First Name must be less than or equal to 50 alpha characters and only allows . , ~ - as special characters.`,
  lastNameError: `Last Name must be less than or equal to 50 alpha characters and only allows . , ~ - as special characters.`,
  successBannerMessage: `Franchisee profile updated successfully`,
  loggedout: `We have received your request to update your profile. Please wait 3-5 minutes to log in again. Current user session will be automatically logged out.`,
  cancelBannerMessage: `User has cancelled the action`,
  sucess: `success`,
  error: `error`,
  existingUser: `Email already used in FFW.`,
};
export const ReferralInformationConstants = {
  referral: "Referral",
  referalcontent:
    "Please share with us the name of the person who suggested Chase Franchise Finance to you.",
  sourcecode: "Source Code",
  sourcecontent:
    "If you were referred to our website by a letter, correspondence, or other advertisement or promotion, please enter the source code if one was identified. Otherwise, leave this blank.",
  previousSection: "previous section",
  nextSection: "next section",
  note: "Please Note: Data will be saved automatically on click of Next Section or Previous Section button.",
};
export const RegionInformationConstants = {
  region: "What region are you in?",
  note: "Please Note: Data will be saved automatically on click of Next Section or Previous Section button.",
  nextSection: "next section",
  previousSection: "previous section",
  required: "* Required by Patriot Act. See Link below.",
  select: "Select",
};
export const AccountantInformationConstants = {
  information: "Tell us about your accountant",
  message:
    "Please note: Data will be saved Automatically on Click of Next Section or Previous Section button",
  successBannerMessage: `Franchisee profile updated successfully`,
};

export const REQUEST_REFERRAL_POST_DATA = "REQUEST_REFERRAL_POST_DATA";
export const RECEIVE_REFERRAL_POST_DATA = "RECEIVE_REFERRAL_POST_DATA";
export const FAILURE_REFERRAL_POST_DATA = "FAILURE_REFERRAL_POST_DATA";

export const REQUEST_REGION_POST_DATA = "REQUEST_REGION_POST_DATA";
export const RECEIVE_REGION_POST_DATA = "RECEIVE_REGION_POST_DATA";
export const FAILURE_REGION_POST_DATA = "FAILURE_REGION_POST_DATA";

export const REQUEST_REGIONINFO_DATA = "REQUEST_REGIONINFO_DATA";
export const RECEIVE_REGIONINFO_DATA = "RECEIVE_REGIONINFO_DATA";
export const FAILURE_REGIONINFO_DATA = "FAILURE_REGIONINFO_DATA";
export const Tooltip_info = `Tooltip: This is a Multiline Input with text which wraps
onto more than one line. Help information Read only`;

export const Info_act = `Required by Patriot Act.This information is being encrypted for your security`;
export const Info_security = `This information is being encrypted for your security`;

export const AdditionalCommentConstants = {
  placeholderText: `This is a Multiline Input with text which wraps
onto more than one line.`,
  noteText: `Please Note: Data will be saved automatically on click of Next Section or Previous Section button.`,
  additionalInformation: `Please provide us with any additional information you would like us to consider as we process your loan request.`,
  anythingElse: `Additional Comments`,
  previous: `Previous Section`,
  next: `Next Section`,
  bankerComments: `Banker Comments`,
  comments: `Comments`,
};
export const NewReturningUserData = {
  header: "New User OR Returning User",
  info: `If you are new to the Chase Franchise Finance Website, please click New User.
    If you are a returning user please click Returning User and enter your e-mail address.`,
  newUser: "NEW USER",
  returningUser: "RETURNING USER",
  cancel: "Cancel",
  validate: "Validate",
};
export const RegistrationData = {
  header: "Required One Time On-Line Registration Information",
  info: "Please provide us with some information about yourself so we can create your account. After your account is created, we will send you an e-mail with a link to log in for the first time.",
  cancel: "Cancel",
  register: "Register",
  SELECT: "Select",
  errorInfo: `Sorry, we can't find you in our records. We need some information about you in order to create your account. After your account is created, we will send you an e-mail with a link to log in for the first time.`,
  existingUser: "User already registered with FFW",
};
export const RegistrationSuccessData = {
  header: "One Time On-Line Registration Success",
  accountCreatedInfo: "Your account has been created! You will receive:",
  registrationProcessInfo:
    "To complete the registration process and begin your loan request:",
  linkRegistrationProcessStep1: "Click on this ",
  linkRegistrationProcessStep2:
    " and you will be directed to a login page where you can enter your User ID (e-mail address or Janus username) and temporary password.",
  janusUatLink: "https://jpmcsso-uat.jpmorgan.com/sso/action/login",
  janusProdLink: "https://jpmcsso.jpmorgan.com/sso/action/login ",
  registrationProcessSteps: [
    "Enter the authentication code to register your device.",
    "Create your permanent password.",
    "Answer the security questions. This will help us verify who you are in the event you forget your password.",
  ],
  registrationProcessLastStep:
    "Once your profile is completed successfully, navigate as returning user in the ",
  assistanceInfo:
    "If you need assistance, please call your banker directly or reach out to a Customer Service Professional at 1-800-543-4622, option 0.",
  Click: "Click ",
  returnHome: " to be returned to our home page.",
};

//AccountantInformation
export const FETCH_ACCOUNTANT_REQUEST = "FETCH_ACCOUNTANT_REQUEST";
export const FETCH_ACCOUNTANT_SUCCESS = "FETCH_ACCOUNTANT_SUCCESS";
export const FETCH_ACCOUNTANT_FAILURE = "FETCH_ACCOUNTANT_FAILURE";
export const SAVE_ACCOUNTANT_SUCCESS = "SAVE_ACCOUNTANT_SUCCESS";
export const SAVE_ACCOUNTANT_FAILURE = "SAVE_ACCOUNTANT_FAILURE";
export const SAVE_ACCOUNTANT_RECEIVE = "SAVE_ACCOUNTANT_RECEIVE";
export const ACCOUNTANT_REQUEST = "ACCOUNTANT_REQUEST";

//RegionInformation
export const FETCH_REGIONINFO_REQUEST = "FETCH_REGIONINFO_REQUEST";
export const FETCH_REGIONINFO_SUCCESS = "FETCH_REGIONINFO_SUCCESS";
export const FETCH_REGIONINFO_FAILURE = "FETCH_REGIONINFO_FAILURE";
export const SAVE_REGIONINFO_SUCCESS = "SAVE_REGIONINFO_SUCCESS";
export const SAVE_REGIONINFO_FAILURE = "SAVE_REGIONINFO_FAILURE";
export const SAVE_REGIONINFO_RECEIVE = "SAVE_REGIONINFO_RECEIVE";
export const REGION_REQUEST = "REGION_REQUEST";

export const FETCH_PARTY_REQUEST = "FETCH_PARTY_REQUEST";
export const FETCH_PARTY_SUCCESS = "FETCH_PARTY_SUCCESS";
export const FETCH_PARTY_FAILURE = "FETCH_PARTY_FAILURE";
export const SAVE_PARTY_SUCCESS = "SAVE_PARTY_SUCCESS";
export const SAVE_PARTY_FAILURE = "SAVE_PARTY_FAILURE";
export const SAVE_PARTY_RECEIVE = "SAVE_PARTY_RECEIVE";

export const SAVE_RETURN_SUCCESS = "SAVE_RETURN_SUCCESS";
export const SAVE_RETURN_FAILURE = "SAVE_RETURN_FAILURE";
export const SAVE_RETURN_RECEIVE = "SAVE_RETURN_RECEIVE";

// BussinesAdressInfo

export const FETCH_BUSSINESSADDRESSINFO_REQUEST =
  "FETCH_BUSSINESSADDRESSINFO_REQUEST";
export const FETCH_BUSSINESSADDRESSINFO_SUCCESS =
  "FETCH_BUSSINESSADDRESSINFO_SUCCESS";
export const FETCH_BUSSINESSADDRESSINFO_FAILURE =
  "FETCH_BUSSINESSADDRESSINFO_FAILURE";

// Restaurant Info
export const FETCH_RESTAURANTINFO_REQUEST = "FETCH_RESTAURANTINFO_REQUEST";
export const FETCH_RESTAURANTINFO_SUCCESS = "FETCH_RESTAURANTINFO_SUCCESS";
export const FETCH_RESTAURANTINFO_FAILURE = "FETCH_RESTAURANTINFO_FAILURE";

export const FETCH_COUNTRYSTATES_REQUEST = "FETCH_COUNTRYSTATES_REQUEST";
export const FETCH_COUNTRYSTATES_SUCCESS = "FETCH_COUNTRYSTATES_SUCCESS";
export const FETCH_COUNTRYSTATES_FAILURE = "FETCH_COUNTRYSTATES_FAILURE";

export const FETCH_REGION_REQUEST = "FETCH_REGION_REQUEST";
export const FETCH_REGION_SUCCESS = "FETCH_REGION_SUCCESS";
export const FETCH_REGION_FAILURE = "FETCH_REGION_FAILURE";

export const COUNTRY_ACTION_FAILURE = "COUNTRY_ACTION_FAILURE";
export const COUNTRY_ACTION_SUCCESS = "COUNTRY_ACTION_SUCCESS";

export const FETCH_COPYHOMEADDRESS_REQUEST = "FETCH_COPYHOMEADDRESS_REQUEST";
export const FETCH_COPYHOMEADDRESS_SUCCESS = "FETCH_COPYHOMEADDRESS_SUCESS";
export const FETCH_COPYHOMEADDRESS_FAILURE = "FETCH_COPYHOMEADDRESS_FAILURE";

export const SAVE_BUSINESSADDRESDATA_REQUEST =
  "SAVE_BUSINESSADDRESDATA_REQUEST";
export const SAVE_BUSINESSADDRESDATA_SUCCESS =
  "SAVE_BUSINESSADDRESDATA_SUCCESS";
export const SAVE_BUSINESSADDRESDATA_FAILURE =
  "SAVE_BUSINESSADDRESDATA_FAILURE";

export const BussinesAdressInfo = {
  header: `Business Mailing Address`,
  header2: `Executive Business Office`,
  header3: `Additional Contact Information`,
  buttoncopy: `copy business mailing address`,
  buttoncopy2: `copy home address`,
  authorize: `I authorize and request that you contact the person listed below for any questions or additional information to
            process this loan request.`,
  required: ` * Required as mandatory.`,
  note: `Please Note: Data will be saved automatically on click of Next Section or Previous Section button.`,
  placeholderText: `This is a Multiline Input with text which wraps
onto more than one line.`,
};

export const DebtSummaryLabel = {
  header: "What other debts do you have?",
  noOfExistingLoan:
    "Please indicate the number of loans/leases that you have with institutions OTHER THAN Chase Franchise Finance",
  debtHeading: "Debt",
  lender: "Name of Lender",
  originalDate: "Original Note Date (mm/dd/yyyy)",
  maturityDate: "Maturity Date (mm/dd/yyyy)",
  originalAmt: "Original Amount ($)",
  currentPrincipalAmt: "Current Principal Balance ($)",
  outstandingAmt: "Current Amount Outstanding ($)",
  interest: "Interest Rate",
  scheduledPayment: "Scheduled Monthly Loan Payment (P&I)",
  lenderPhone: `Lender's Phone Number`,
  LenderContact: "Contact at Lender",
  monthlyPayment: "Total Monthly Payment Including Principal and Interest ($)",
  comments: "Comments or notes related to Debts",
  btnAddDebt: "add another debt",
  btnDelDebt: "delete this debt",
  note: "Please Note: Data will be saved automatically on click of Next Section or Previous Section button.",
  placeholderText: `This is a Multiline Input with text which wraps onto more than one line.`,
  dialogTitle:
    "To add or delete information, please click add or delete buttons below.",
};
export const ContactInfoConstants = {
  heading: `Contact Details`,
  SELECT: `Select`,
  next: `Next Section`,
  noteText: `Please Note: Date will be saved automatically on click of Next Section or Previous Section button`,
  dateNotmatchError: `Re-enter Date of Birth does not match`,
  ssnNotmatchError: `Social security number does not match`,
  primaryAddress: `Address is mandatory`,
  requiredInfo: ` Required by Patriot Act. See Link below.`,
  mandatory: "*",
  encrypted: "**",
  requiredAdditionalInfo: ` This information is being encrypted for your security.`,
  dialogContent: `In order to make changes to name or e-mail, please update in My Profile.`,
  okButton: `OK`,
  placeholderText: `This is a Multiline Input with text which wraps
onto more than one line.`,
  digitsRegex: /(\d+)/g,
  dateFormat: `MM/DD/YYYY`,
  numbersRegex: /[^0-9]/g,
  mobileRegex: /[\d]/g,
  dobRegex: /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
  greaterthanValidation: `123456789012`,
  lessthanValidation: `1234567`,
  addressNotEmpty: `Address is not empty`,
  fullname: `Franchisee’s Full Name`,
  operator: `Operator ID (10 digits. Example: 1000012345)`,
  primary: `Primary Residence Address`,
  city: `City`,
  state: "State",
  zip: `Zip`,
  ssn: `Social Security Number`,
  ressn: `Re-enter Social Security Number`,
  dob: `Date of Birth (mm/dd/yyyy)`,
  redob: `Re-enter Date of Birth(mm/dd/yyyy)`,
  telephone: `Primary Residence Telephone Number`,
  mobile: `Mobile Telephone Number`,
  email: `E-mail Address`,
  country: `Countries of Citizenship`,
};

export const dialog = {
  dashboard: `dashboard`,
  contact: `contact`,
  currentDebtSummary: `currentDebtSummary`,
};
export const SubmitRequestConstants = {
  header:
    "By Submitting your request, you confirm that you agree to the following Legal Release Terms and Conditions.",
  submitTerms1: `During the review of submitted requests, Chase may obtain a credit report on the principals (stockholders, proprietors, and partners) or the business entity included on the loan request. The principals have the right to ask for the name and address of all consumer-reporting agencies that provided Chase credit reports. If the request is approved and a loan is funded, Chase may at any time obtain additional credit reports on the principals or business entity. Chase also reserves the right to request additional information if needed including the right to request copies of Federal Income Tax Returns from the Internal Revenue Service. Chase affiliates may, at any time, visit and inspect the collateral and other properties of the Borrower. As permitted by law, Chase may share account information and information contained in a submitted request and in any credit report on the principals with other Chase affiliates for the purpose of considering eligibility for their products or services including loans and lines of credit. Further, Chase and McDonalds may share with each other any information they may have with respect to the requester's operation(s) as a Franchisee of McDonald's as such information may relate to the services provided to the requester by Chase.`,
  submitTerms2: `By submitting a request to Chase, the requester certifies that the information contained in the online request, attachments, and addendums are true, complete and correct to the best of the requester's knowledge. The requester further certifies that the proceeds of the loan being requested will be used for McDonald's business-related purposes and not for personal, family, tax, or household purposes.`,
  section326: "Section 326 of the USA Patriot Act",
  importantInfo:
    "Important Information About Procedures for Opening a New Account",
  complyInfo: `We comply with Section 326 of the USA PATRIOT Act. To help the government fight the funding of terrorism and money laundering activities, federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens an account.`,
  notice: "Notice for Ohio Applicants",
  noticeInfo1: `The Ohio laws against discrimination require that all creditors make credit equally available to all credit worthy customers, and that credit reporting agencies maintain separate credit histories on each individual upon request. The Ohio civil rights commission administers compliance with this laws.`,
  noticeInfo2: `What this means for you: When you open an account, we will ask for your name, address, date of birth, and other information that will allow us to identify you. We may also ask to see your driver's license or other identifying documents.`,
  previousSection: "PREVIOUS SECTION",
  preview: "PREVIEW",
  submitRequest: "SUBMIT REQUEST",
  submitAlert: "By submitting the request, I agree to terms & conditions.",
  OK: "OK",
  Cancel: "Cancel",
};

export const FETCH_FINANCE_REQUEST = "FETCH_FINANCE_REQUEST";
export const FETCH_FINANCE_SUCCESS = "FETCH_FINANCE_SUCCESS";
export const FETCH_FINANCE_FAILURE = "FETCH_FINANCE_FAILURE";

export const FETCH_PERVIEW_SUCCESS = "FETCH_PERVIEW_SUCCESS";
export const FETCH_PERVIEW_FAILURE = "FETCH_PERVIEW_FAILURE";
export const FETCH_PERVIEW_REQUEST = "FETCH_PERVIEW_REQUEST";

export const RETURNTOADMINDASH = "RETURNTOADMINDASH";
export const SUCCESS_BANNER = "SUCCESS_BANNER";

/////Return to Dashboard

export const ReturntoDashboard = {
  content: `This is the list of your previously created loan requests.`,
  btn: `CREATE NEW REQUEST`,
  welcome: `Welcome`,
  name: `Username !`,
  cancelMsg: "Cancel Loan Request",
  dialogMsg: "Are you sure want to cancel this loan?",
  ok: "Ok",
  cancel: "Cancel",
  sucessMsg:
    "Your loan request has been submitted. You will be contacted by a Chase Franchise Finance banker for next steps. Thank you for choosing Chase Franchise Finance.",
};

export const FETCH_LOAD_REQUEST = "FETCH_LOAD_REQUEST";
export const FETCH_RETURNTODASHBOARD_REQUEST =
  "FETCH_RETURNTODASHBOARD_REQUEST";
export const FETCH_RETURNTODASHBOARD_SUCCESS =
  "FETCH_RETURNTODASHBOARD_SUCCESS";
export const FETCH_RETURNTODASHBOARD_FAILURE =
  "FETCH_RETURNTODASHBOARD_FAILURE";
export const CANCELLAONREQUEST_REQUEST = "CANCELLAONREQUEST_REQUEST";
export const CANCELLAONREQUEST_SUCCESS = "CANCELLAONREQUEST_SUCCESS";
export const CANCELLAONREQUEST_FAILURE = "CANCELLAONREQUEST_FAILURE";
export const CURRENT_LOANID = "CURRENT_LOANID";
export const CANCEL_LOANID = "CANCEL_LOANID";
export const CREATENEWREQUEST_FAILURE = "CREATENEWREQUEST_FAILURE";

export const FETCH_STATUS_REQUEST = "FETCH_STATUS_REQUEST";
export const FETCH_STATUS_FAILURE = "FETCH_STATUS_FAILURE";
export const FETCH_BANKERS_REQUEST = "FETCH_BANKERS_REQUEST";
export const FETCH_BANKERS_FAILURE = "FETCH_BANKERS_FAILURE";
export const FETCH_ADMIN_DASHBOARD_REQUEST = "FETCH_ADMIN_DASHBOARD_REQUEST";
export const FETCH_ADMIN_DASHBOARD_FAILURE = "FETCH_ADMIN_DASHBOARD_FAILURE";
export const FETCH_FRANCHISE_DATA_FAILURE = "FETCH_FRANCHISE_DATA_FAILURE";
export const FETCH_FRANCHISE_DATA_REQUEST = "FETCH_FRANCHISE_DATA_REQUEST";
export const REQUEST_BANKERS_REQUEST = "REQUEST_BANKERS_REQUEST";

////////Physical Mailing Address
export const PhysicalMailingaddress = {
  header: "Where should we send your loan documents?",
  note: "Please Note: Data will be saved automatically on click of Next Section or Previous Section button.",
  buttoncopy: "copy home address",
  buttoncopy2: "copy office address",
  buttoncopy3: "copy mailing address",
  placeholderText: `This is a Multiline Input with text which wraps
onto more than one line.`,
};

export const FETCH_PHYSICALMAILINGADDRESS_REQUEST =
  "FETCH_PHYSICALMAILINGADDRESS_REQUEST";
export const FETCH_PHYSICALMAILINGADDRESS_SUCCESS =
  "FETCH_PHYSICALMAILINGADDRESS_SUCCESS";
export const FETCH_PHYSICALMAILINGADDRESS_FAILURE =
  "FETCH_PHYSICALMAILINGADDRESS_FAILURE";

export const FETCH_MAILINGADDRESS_REQUEST = "FETCH_MAILINGADDRESS_REQUEST";
export const FETCH_MAILINGADDRESS_SUCCESS = "FETCH_MAILINGADDRESS_SUCCESS";
export const FETCH_MAILINGADDRESS_FAILURE = "FETCH_MAILINGADDRESS_FAILURE";

export const FETCH_HOMEINFOADDRESS_REQUEST = "FETCH_ HOMEINFOADDRESS_REQUEST";
export const FETCH_HOMEINFOADDRESS_SUCCESS = "FETCH_ HOMEINFOADDRESS_SUCCESS";
export const FETCH_HOMEINFOADDRESS_FAILURE = "FETCH_ HOMEINFOADDRESS_FAILURE";

export const FETCH_CONTACTINFOADDRESS_REQUEST =
  "FETCH_CONTACTINFOADDRESS_REQUEST";
export const FETCH_CONTACTINFOADDRESS_SUCCESS =
  "FETCH_CONTACTINFOADDRESS_SUCCESS";
export const FETCH_CONTACTINFOADDRESS_FAILURE =
  "FETCH_CONTACTINFOADDRESS_FAILURE";

export const SAVE_PHYSICALADDRESS_REQUEST = "SAVE_PHYSICALADDRESS_REQUEST";
export const SAVE_PHYSICALADDRESS_SUCCESS = "SAVE_PHYSICALADDRESS_SUCCESS";
export const SAVE_PHYSICALADDRESS_FAILURE = "SAVE_PHYSICALADDRESS_FAILURE";

export const RestarauntSummary = {
  placeholderText: `This is a Multiline Input with text which wraps
onto more than one line.`,
  header: "How many restaurants do you have?",
  ownRestaurant: "Number of restaurants currently owned?",
  purchasingRestaurant:
    "Number of restaurants you are purchasing or building at this time?",
  note: "Please Note: Data will be saved automatically on click of Next Section or Previous Section button.",
  btnAddDebt: "add another restaurant",
  btnDelDebt: "delete this restaurant",
  mgntCompany: "Is there a management company?",
  compnyLegalName: "What is the Legal Name?",
  entityStructured: "How is the entity structured?",
  stateIncorpration: "State of Incorporation",
  noOfStockholders: "How many shareholders (please list all)",
  restAddLabel: "Restaurant Address",
};

export const miscellaneousDetails = {
  header: "Miscellaneous Details",
  approverYear: "In what year did you become an approved Owner/Operator?",
  anyOtherApprover:
    "Are there any other approved Owner/Operators in your organization?",
  totalOwner: "How many are approved Owner/Operators?",
  ownerSubHeader: "Owner/Operators",
  ownerSubName: "Enter the name for Owner/Operator",
  ownerSubApproverYear:
    "Enterthe Year they became an approved Owner/Operator in YYYY.",
  ownerSubRelationship: "What is their relationship to you?",
  obligations:
    "Are there any obligations not listed on your financial statements for which you or your business are an endorser, guarantor or co-signer?",
  lawsuit: "Are you or any of your affiliates party to any claim or lawsuit?",
  affiliates: "Have you or any of your affiliates ever declared bankruptcy?",
  affiliatesTaxes: "Do you or any of your affiliates owe or dispute any taxes?",
  plsExplain: 'Please explain any "Yes" answers',
  recognition: `Please list any recognition or awards you have received from McDonald's`,
  committees: `Please list any teams or committees that you have been a part of with McDonald's`,
  politicalPositions:
    "Have you held any political positions in the past or do you hold any political positions currently (City, State, Federal or otherwise)?",
  politicalStart: "Start Date (mm/dd/yyyy)",
  politicalEnd: "End Date (mm/dd/yyyy)",
  currentPosition: "Is the position currently held?",
  deleteButton: "delete this owner/operator",
  addButton: "Add Another Owner/Operator",
  positionDetails: "Describe the position",
  next: "next section",
  previous: "previous section",
  popup:
    "To add or delete information, please click add or delete buttons below.",
  close: "Close",
  bankDetails:
    "What bank(s) do you have your main business operating account(s) with? (List all that apply)",
  nameOfOwner: "Enter the Name for Owner/Operator",
  approved: "Enter the Year they became an approved Owner/Operator in YYYY.",
  relation: "What is their relationship to you?",
  note: "Please Note: Data will be saved automatically on click of Next Section or Previous Section button.",
  placeholder: `This is a Multiline Input with text which wraps
onto more than one line.`,
};
export const adminLandingPage = {
  mailContent: `If you are experiencing technical issues with the Franchise Finance Website for Administrators, please e-mail `,
  mailId: ` franchise_finance_tech@jpmchase.com`,
  mail: `franchise_finance_tech@jpmchase.com`,
  url: `mailto:franchise_finance_tech@jpmchase.com`,
};

export const adminUpdaterates = {
  header: `Rates & Messages`,
  messageHeader: "News Flash Message",
  save: `Save Changes`,
  cancel: `Cancel`,
  ckeditor: `News Flash Text`,
  cancelContent: `Flash Message changes have not been saved`,
  saveContent: `Your changes have been saved`,
};
export const RECEIVE_GET_MISCELLANIOUS_DATA = "RECEIVE_GET_MISCELLANIOUS_DATA";
export const FAILURE_GET_MISCELLANIOUS_DATA = "FAILURE_GET_MISCELLANIOUS_DATA";
export const RECEIVE_MISCELLANIOUS_POST_DATA =
  "RECEIVE_MISCELLANIOUS_POST_DATA";
export const FAILURE_MISCELLANIOUS_POST_DATA =
  "FAILURE_MISCELLANIOUS_POST_DATA";

/////Left Navigation

export const FETCH_LeftNavigation_REQUEST = "FETCH_LeftNavigation_REQUEST";
export const FETCH_LeftNavigation_SUCCESS = "FETCH_LeftNavigation_SUCCESS";
export const FETCH_LeftNavigation_FAILURE = "FETCH_LeftNavigation_FAILURE";

export const FETCH_SEARCHFRANCHISE_REQUEST = "FETCH_SEARCHFRANCHISE_REQUEST";
export const FETCH_SEARCHFRANCHISE_FAILURE = "FETCH_SEARCHFRANCHISE_FAILURE";
export const RECEIVE_SEARCH_POST_DATA = "RECEIVE_SEARCH_POST_DATA";
export const FAILURE_SEARCH_POST_DATA = "FAILURE_SEARCH_POST_DATA";

export const FETCH_FRANCHISEPROFILE_REQUEST = "FETCH_FRANCHISEPROFILE_REQUEST";
export const FETCH_FRANCHISEPROFILE_FAILURE = "FETCH_FRANCHISEPROFILE_FAILURE";
export const RECEIVE_SEARCH_DATA = "RECEIVE_SEARCH_DATA";
export const FAILURE_SEARCH_DATA = "FAILURE_SEARCH_DATA";
export const RECEIVE_EDIT_DATA = "RECEIVE_EDIT_DATA";
export const FAILURE_EDIT_DATA = "FAILURE_EDIT_DATA";
export const RECEIVE_RESET_DATA = "RECEIVE_RESET_DATA";
export const FAILURE_RESET_DATA = "FAILURE_RESET_DATA";
export const FAILURE_DISABLE_DATA = "FAILURE_DISABLE_DATA";
export const POST_RECEIVE_BANKER_DATA = "POST_RECEIVE_BANKER_DATA";
export const POST_FALIURE_BANKER_DATA = "POST_FALIURE_BANKER_DATA";
export const RECEIVE_BANKER_COMMENT_DATA = "RECEIVE_BANKER_COMMENT_DATA";
export const FAILURE_BANKER_COMMENT_DATA = "FAILURE_BANKER_COMMENT_DATA";
export const REQUEST_GET_COMMENT_DATA = "REQUEST_GET_COMMENT_DATA";
export const REQUEST_SEARCH_POST_DATA = "REQUEST_SEARCH_POST_DATA";
export const REQUEST_SEARCH_DATA = "REQUEST_SEARCH_DATA";

export const SPINNER_ENDPOINT_BANKER = "SPINNER_ENDPOINT_BANKER";

export const FAILURE_UPDATE_GET_REQUEST = " FAILURE_UPDATE_GET_REQUEST";
export const FAILURE_UPDATE_POST_REQUEST = " FAILURE_UPDATE_POST_REQUEST";
export const ENDPOINT_SPINNER_UPDATE_CURRENT = "ENDPOINT_SPINNER";

export const ThirdPartyLabel = {
  header: "Wiring Instructions",
  wiring: "How many wiring instructions do you want to add?",
  debtHeading: "Recipient",
  accountName: `Name of Recipient's Bank`,
  city: `Recipient Bank's City`,
  state: `Recipient Bank's State`,
  routing: "ABA/Routing Number",
  accountnumber: "Account Number to Credit",
  reaccountnumber: "Re-enter Account Number",
  exact: "Exact Name on Account",
  namebank: "Name of Bank Contact",
  telephone: "Telephone Number of Contact",
  special: "Special Instructions",
  totalAmount: "Total Amount to be Wired",
  btnAddrpt: "Add Another Recipient",
  btnDelrpt: "Delete This Recipient",
  note: "Please Note: Data will be saved automatically on click of Next Section or Previous Section button.",
  placeholderText: `This is a Multiline Input with text which wraps
onto more than one line.`,
  transaction: `If this transaction is a purchase from another operator, a lien search will be pulled to determine if the selling operator has existing debt with a banking institution. If there is an existing debt, a payoff letter from the institution is required a weekbefore closing this transaction. The seller operator’s wiring instructions will also be required.`,
  security: `This information is being encrypted for your security.`,
};

export const RegExps = {
  recipientName: new RegExp(/[^0-9A-Za-z/s]/g),
  accountAndRoutingNumbers: new RegExp(/[^0-9]/g, ""),
};

export const ThirdPartyConstants = {
  ssnNotmatchError: `Account Numbers do not match`,
  pherror: "Please enter valid Telephone number",
  route: "Please enter 9 digits",
};
export const RECEIVE_GET_MISCELLANEOUS_DATA = "RECEIVE_GET_MISCELLANEOUS_DATA";
export const FAILURE_GET_MISCELLANEOUS_DATA = "FAILURE_GET_MISCELLANEOUS_DATA";
export const RECEIVE_MISCELLANEOUS_POST_DATA =
  "RECEIVE_MISCELLANEOUS_POST_DATA";
export const FAILURE_MISCELLANEOUS_POST_DATA =
  "FAILURE_MISCELLANEOUS_POST_DATA";
export const FAILURE_TO_DELETE = "FAILURE_TO_DELETE";
export const REQUEST_GET_MISCELLANEOUS_DATA = "REQUEST_GET_MISCELLANEOUS_DATA";

export const searchFranchise = {
  searchCriteria: `Enter Your Franchisee Search Criteria`,
  search: `Search`,
  clearCriteria: `Clear Criteria`,
  noteText: `Please enter your search criteria below. To see a complete list of Franchisees, leave the criteria blank and choose “Search”`,
  success: `Password has been successfully reset for User.`,
  errorMessage: `Please enter the search criteria`,
  edit: `Edit`,
  save: `Save`,
  preview: `Preview`,
  yes: `Yes`,
  no: `No`,
  emptyString: "",
  close: "Close",
  actions: "actions",
};

export const onlineRequest = {
  header: `Online Requests`,
  header2: `Dashboard`,
  btn: `Search for a franchisee`,
  btnsearch: `SEARCH`,
  btnadvsearch: `Advanced Search`,
  inProgress: `In Progress`,
  receivedByChase: `Received By Chase`,
  cancelled: `Cancelled`,
  submitted: `Submitted`,
  totalNumberofRequests: `Total Number of Requests`,
  numberOfRequest: `numberofrequest`,
  editLoanRequest: `Edit Loan Request`,
  bankersComments: `Bankers Comments`,
  commentsSaved: `Comments saved successfully!`,
  successMsg: `Success`,
  statusUpdated: `Status updated successfully`,
};
export const dialogModal = {
  title: `Edit Banker Comments`,
  save: `SAVE COMMENTS`,
  cancel: `CANCEL`,
};
export const FETCH_ADMINDASHBOARD_REQUEST = "FETCH_ADMINDASHBOARD_REQUEST";
export const FETCH_ADMINDASHBOARD_SUCCESS = "FETCH_ADMINDASHBOARD_SUCCESS";
export const FETCH_ADMINDASHBOARD_FAILURE = "FETCH_ADMINDASHBOARD_FAILURE";

export const PurposeOfLoanText = {
  header: "Loan Purpose",
  howManyLoan: "How many loans are you requesting?",
  btnAddLoan: "Add Another REQUEST",
  btnDelLoan: "Delete this request",
  note: "Please Note: Data will be saved automatically on click of Next Section or Previous Section button.",
  subHeader: "Request",
  loanPurpose: "Loan Purpose",
  revolverSection: "EQ Revolver",
  previous: `previous section`,
  nextsection: `next section`,
  loanNumbers: "Loan Number(s)",
  RequestedAmount: "Requested Amount [$]",
  PleaseExplain: "Please Explain",
  placeholderText: `This is a Multiline Input with text which wraps
onto more than one line.`,
  NationalRestaurantNumber: "National Restaurant Number",
  TotalProjectedCost: "Total Cost",
  TotalCost: "Total Cost [$]",
  NameofLender: "Name of Lender",
  WhatistheBorrowingentity: "What is the Borrowing entity?",
  RestaurantOpeningDate: "Restaurant Opening Date (mm/dd/yyyy)",
  ProjectedGroundBreakDate: "Projected Ground Break Date (mm/dd/yyyy)",
  Afterexercise:
    "After exercise, what is the new rent percentage for this location ?",
  SellerName: `Seller's: Owner/Operator Name(s)`,
  SellerEntityName: `Seller's Entity Names`,
  TargetPurchaseDate: "Target Purchase Date (mm/dd/yyyy)",
  Whichrestaurantsarecollateral: "Which restaurants are collateral?",
  Howwouldyoulikeyourloanstructured: "How would you like your loan structured?",
  Years: "Years",
  Months: "Months",
  DesiredPaymentStructure: "Desired Payment Structure",
  DesiredInterestRateOption: "Desired Interest Rate Option",
  RequestedDateofFunding: "Requested Date of Funding (mm/dd/yyyy)",
};
export const RECEIVE_GET__HOMEBANNER = "RECEIVE_GET__HOMEBANNER";
export const SuccessfulProfileConstants = {
  successfulHeading: "Successful",
  profileContent: "Your profile has been successfully updated. Click ",
  ok: `'OK'`,
  redirect: " to be redirected.",
  okButton: "ok",
  needHelp: "Need Help?",
  contact: "Contact your ",
  banker: "banker",
  clientSupport: " or a Client Support Professional at 800-543-4622, option 0",
};
export const forgotUsername = {
  header: "Forgot Username",
  help: "Need Help?",
  contact:
    "Contact your or a Client Support Professional at 800-543-4622, option 0",
};

export const forgotUsername1 = {
  header: "E-mail shared successfully",
  cont: "An e-mail has been sent to your registered e-mail address with your userame. If you do not recieve an e-mail within 15 minutes, please try again or contact Customer Care.",
  help: "Need Help?",
  close: "Close this Window",
  contact:
    "Contact your or a Client Support Professional at 800-543-4622, option 0",
};

export const security = {
  header: "Security Questions",
  text: "Please take a moment to select the secret questions you want used in the event you forget your password in the future.If you forget your password, you will be asked for the answers to your secret questions and prompted to choose a new password.",
  text2:
    "You can select or edit your password questions at another time by accessing your Janus account profile under My Profile.",
  text3:
    "Please select a question from each of the following groups and provide an answer for each one.",
  help: "Need Help?",
  contact:
    "Contact your or a Client Support Professional at 800-543-4622, option 0",
};

export const authenticationConstants = {
  header: "We need to send you an Authentication Code",
  note: "We need to confirm your identity to ensure your accounts are secure. We do this by sending a temporary Authentication Code to the email address you provided us in the past.",
  confirmNote: `Choose how you'd like to receive this Authentication Code and click "Next." On the next page you'll enter the Authentication Code you receive.`,
  next: "NEXT",
  privacyPolicy: "Privacy policy",
  noteText: `Note: For security reasons, we've hidden parts of your contact information with "***".`,
  helpText: "Need Help?",
  contactText:
    "Contact your or a Client Support Professional at 800-543-4622, option 0",
};

export const temporaryAuthentication = {
  header: "Enter your temporary Authentication Code",
  emailText:
    "An email is on its way with your temporary Authentication Code. Once you receive your code, enter it below to verify your identity, and click Next.",
  registerText: "Register this computer?",
  checkboxText: `Yes, register this computer (not recommended for a public computer).
    Uncheck the box if you do not want to register this computer.`,
  authenticationText: `Haven't received your Authentication Code?`,
  helpText:
    "We email Authentication Codes immediately, but many factors may influence how fast you receive them. Please check your spam folder to determine if it was delivered there. If you have not received your authentication code within 15 minutes, you can request a new code below, or contact Customer Care for help.",
  requestText: "Request a new Authentication Code",
};
export const ErrorConstants = {
  back: "We’ll be back shortly",
  update: "For real time updates follow @ChaseSupport on Twitter.",
  available: "Our Advisors are available from 7 AM to 9 PM EST.",
  access:
    "Telephone bankers may be able to access some account information while our site is unavailable.",
  reach: "You can reach them at one of the numbers below:",
  person: "Personal Checking, Savings & CD",
  threeFive: "1-800-935-9935",
  businessBanking: "Business Banking",
  threeEight: "1-800-242-7338",
  creditLoans: "Home Equity Line of Credit Loans",
  fiveSix: "1-800-836-5656",
  retirement: "Retirement self-directed Accounts",
  sixOne: "1-800-776-6061",
  privateClient: "Chase Private Client",
  twoSix: "1-888-994-5626",
  privateClientBanker: "(or contact your Private Client Banker)",
  creditCards: "Credit Cards",
  callThe: "Call the number on the back of your credit card",
  online: "Chase Commercial Online",
  serviceOfficer:
    "Call your Client Service Professional or Client Service Officer",
  mortgages: "Mortgages",
  threeSix: "1-800-848-9136",
  autoLoansAndLeases: "Auto Loans & Leases",
  sevenFive: "1-800-336-6675",
  studentLoans: "Student Loans",
  zerFour: "1-800-487-4404",
  jpmsecurities: "J.P.Morgan Securities",
  nineEight: "1-888-807-6898",
  investment: "Investment/Requirements",
  fourNine: "1-800-392-5749",
};
export const GET_BANKERS_LIST = "GET_BANKERS_LIST";
export const GET_STATUS_LIST = "GET_STATUS_LIST";
export const SUBMIT_SEARCH_PARAMS = "SUBMIT_SEARCH_PARAMS";
export const GET_ALL_REQUEST = "GET_ALL_REQUEST";
export const EDIT_BANKERS_COMMENTS = "EDIT_BANKERS_COMMENTS";
export const EDIT_BANKERS_COMMENTS_RECEIVED = "EDIT_BANKERS_COMMENTS_RECEIVED";
export const POST_FAILURE_BANKERS_COMMENTS = "POST_FAILURE_BANKERS_COMMENTS";
export const SUBMIT_STATUS_CHANGE = "SUBMIT_STATUS_CHANGE";
export const GET_LOAN_COMMENTS = "GET_LOAN_COMMENTS";
export const SUBMIT_COMMENTS = "SUBMIT_COMMENTS";
export const CLEAR_LOAN_COMMENT = "CLEAR_LOAN_COMMENT";
export const FETCH_RESULTS_FAILURE = "FETCH_RESULTS_FAILURE";
export const FETCH_COMMENTS_FAILURE = "FETCH_COMMENTS_FAILURE";
export const interestRate = ["Select", "Fixed", "Floating"];
export const payments = [
  "Select",
  "Level Payment",
  "Level principal",
  "Seasonal",
];
export const months = [
  "Select",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];
export const yearsStartValue = 131;
export const yearsEndValue = 83;
export const years = [
  "Select",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
];
export const boolTrue = true;
export const lender = ["Select", "Chase", "Others"];
export const structureData = [
  "Select",
  "Business Term Loan",
  "EQ Revolver (12 months interest only - 5years term out )",
  "Line of Credit/Working Capital (12 months Interest Only - annually renewed)",
  "Multiple Draw Loan (87 months - first 3 months Interest Only)",
  "NEP Building Term Loan",
];
export const structure2 = [
  "Select",
  "Business Term Loan",
  "NEP Building Term Loan",
];
export const structure3 = [
  "Select",
  "Line of Credit/Working Capital (12 months Interest Only - annually renewed)",
];
export const structure4 = [
  "Select",
  "EQ Revolver (12 months interest only - 5years term out )",
];
export const child = {
  loanId: "",
  purposeTypeId: 1,
  loanAmount: null,
  purposeName: null,
  updatedDate: null,
  updatedBy: null,
  sellerName: null,
  sellerEntityName: null,
  requestedAmount: null,
  rentPercentage: null,
  purchaseDate: null,
  projGroundBrkDate: null,
  principalBalance: null,
  prevStoreNumber: null,
  parentPurposeId: null,
  otherLoanNumber: null,
  lenderName: null,
  originalNoteDate: null,
  originalAmount: null,
  openingDate: null,
  newStoreNumber: null,
  newStoreAddress: null,
  nationalStoreNumber: null,
  monthlyLoanPayment: null,
  maturityDate: null,
  loanStructureId: null,
  loanPurposeOther: null,
  loanOrObligationNo: null,
  lenderPhoneNumber: null,
  otherLenderName: null,
  interestRateOption: null,
  interestRate: null,
  fundingDate: null,
  createdDate: null,
  desiredTermYears: null,
  desiredTermMonths: null,
  paymentStructureId: null,
  contactLender: null,
  collateralStoreList: null,
  borrowingStore: null,
  asap: "N",
  chooseAllCollateral: "Y",
  createdBy: null,
  childPurposeVO: null,
};
export const collateral = [
  {
    label: "use all restaurants as collateral",
    value: "all",
  },
  {
    label: "list specific restaurants",
    value: "specific",
  },
];
export const POLConstants = {
  SELECT: "Select",
  numValRegex: /,/g,
  numRangeRegex: /\./g,
  valG: "g",
  emptyString: "",
  comma: ",",
  dot: ".",
  stringZero: "0",
  updateNumRegex: /[,.]/g,
  numOne: 1,
  numZero: 0,
  numTwo: 2,
  numThere: 3,
  numFour: 4,
  numEleven: 11,
  numFive: 5,
  numTen: 10,
  numSeven: 7,
  numNine: 9,
  numEight: 8,
  numSix: 6,
  numThirten: 13,
  numTwlve: 12,
  numFourten: 14,
  numFiftheen: 15,
  usdFormat: /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
  restaurantInformationSummaryDetails: "/restaurantInformationSummaryDetails",
  currentDebtSummaryDetails: "/currentDebtSummaryDetails",
  deleteAdd:
    "To add or delete information, please click add or delete buttons below.",
  buildingSiteCost: "Building and Site Cost",
  coInvestment: "Co-Investment",
  close: " Close ",
  valZero: "0.00",
  desireLoan: "Desired Term of the Loan",
  clickHere: "Click here ",
  currentRates: " to view current rates. ",
  others: "Others",
  chooseAllCollateral: "chooseAllCollateral",
  deleteLoanPurpose: "/deleteLoanPurpose.do/",
  saveLoanPurpose: "/saveLoanPurpose.do/",
  threeNineThree: "/393",
  asap: "asap",
  alpabetY: "Y",
  alpabetN: "N",
};

export const LOANS = [
  {
    loanId: "",
    loanName: "Select",
  },
  {
    loanId: 1,
    loanName: "Purchase (build) a New Restaurant",
  },
  {
    loanId: 2,
    loanName: "Purchase an Existing Restaurant from another Operator",
  },
  {
    loanId: 3,
    loanName: "Purchase a McOpCo",
  },
  {
    loanId: 4,
    loanName: "Exercise a BFL",
  },
  {
    loanId: 5,
    loanName: "Relocate an Existing Restaurant",
  },
  {
    loanId: 6,
    loanName: "Rebuild an Existing Restaurant",
  },
  {
    loanId: 7,
    loanName: "Refinance Existing Debt",
  },
  {
    loanId: 8,
    loanName: "Refinance Existing Building Loan",
  },
  {
    loanId: 9,
    loanName: "Assume Existing Building Loan from another Operator",
  },
  {
    loanId: 10,
    loanName: "Reinvestment/Remodel",
  },
  {
    loanId: 11,
    loanName: "Line of Credit/Working Capital",
  },
  {
    loanId: 12,
    loanName: "EQ Revolver",
  },
  {
    loanId: 13,
    loanName: "Other",
  },
];
export const MisDetailsConstants = {
  yesOrNo: [
    {
      label: "Yes",
      value: "Y",
    },
    {
      label: "No",
      value: "N",
    },
  ],
  emptyString: "",
};
export const CONSTANT_NO = "N";
export const CONSTANT_YES = "Y";
export const isNumber = (evt, name) => {
  var theEvent = evt || window.event;
  var key = theEvent.keyCode || theEvent.which;
  var regx;
  key = String.fromCharCode(key);
  regx = name === "debtListValue" ? /^[0-9]{1,2}$/g : /^[0-9]{1,4}$/g;
  if (!regx.test(key)) {
    evt.preventDefault();
    return false;
  }
  return true;
};
export const FiltersOption = [
  {
    filterData: "All",
    filterValue: "All",
  },
  {
    filterData: "Cancelled",
    filterValue: "cancelled",
  },
  {
    filterData: "Inprogress",
    filterValue: "inprogress",
  },
  {
    filterData: "Received by Chase",
    filterValue: "receivedbychase",
  },
  {
    filterData: "Submitted",
    filterValue: "submitted",
  },
];
export const FORM_HAS_ERROR = "FORM_HAS_ERROR";
export const Accessibility_text = {
  PhysicalHomeAddress: "Home Address is copied",
  PhysicalOfficeAddress: "Office Address is copied",
  PhysicalMailAddress: "Mailing Address is copied",
  BusinessMailAddress: "Business Mailing Address is copied",
};

export const logout_url = "https://google.com";
export const REQUEST_LOGGEDIN_USER = "REQUEST_LOGGEDIN_USER";
export const FETCH_LOGGEDIN_USER = "FETCH_LOGGEDIN_USER";
export const FETCH_LOGGEDINUSR_FAILURE = "FETCH_LOGGEDINUSR_FAILURE";
export const FETCH_LOGGEDIN_USER_ID = "FETCH_LOGGEDIN_USER_ID";
export const FETCH_LOGGEDIN_USER_NAME = "FETCH_LOGGEDIN_USER_NAME";
export const FETCH_LOGGEDINUSER_ID_FAILURE = "FETCH_LOGGEDINUSER_ID_FAILURE";
export const FETCH_LOGGEDIN_ADMIN_ID = "FETCH_LOGGEDIN_ADMIN_ID";

export const REQUEST_AUTH_CODE = "REQUEST_AUTH_CODE";
export const FETCH_AUTH_CODE = "FETCH_AUTH_CODE";
export const FETCH_AUTHCODE_FAILURE = "FETCH_AUTHCODE_FAILURE";
export const ENABLE_LOADER = "ENABLE_LOADER";
export const DISABLE_LOADER = "DISABLE_LOADER";

export const ERROR_MESSAGE = "Error occured. Please try again";
export const UNAUTHORIZED_URL = {
  UatEnv:
    "https://idauatg2.jpmorganchase.com/adfs/oauth2/authorize/?resource=JPMC:URI:RS-109890-84672-faaweb-UAT&client_id=PC-109890-SID-119267-UAT&redirect_uri=/adfs/oauth2/authorize",
  ProdEnv:
    "https://idag2.jpmorganchase.com/adfs/oauth2/authorize/?resource=JPMC:URI:RS-109890-84953-faaweb-PROD&client_id=PC-109890-SID-121196-PROD&redirect_uri=/adfs/oauth2/authorize",
};
