import {
  RECEIVE_UPDATE_CURRENT_GET_DATA,
  RECEIVE_UPDATE_CURRENT_SAVE_NEWFLASH_SUCCESS_DATA,
  FAILURE_UPDATE_GET_REQUEST,
  FAILURE_UPDATE_POST_REQUEST,
  ENDPOINT_SPINNER_UPDATE_CURRENT,
  base_url,
  base_url_user,
} from "../../../../global.constants";
import axios from "axios";

export const getUpdateCurrentData = () => async (dispatch) => {
  dispatch(spinnerendpointUpdateCurrent());
  try {
    await axios
      .get(base_url_user + `/api/v1/adminsvc/admin/getNewsFlash`)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(receiveUpdateCurrentData(response.data));
        } else {
          dispatch(failureGetUpdateCurrentData(response.status));
        }
      });
  } catch (e) {
    dispatch(failureGetUpdateCurrentData(e.response.status));
  }
};

export const saveUpdateCurrentData =
  (responsedata, userId) => async (dispatch) => {
    const reqPayload = { ...responsedata, userId: userId };
    dispatch(spinnerendpointUpdateCurrent());
    await axios
      .put(
        base_url_user + `/api/v1/adminsvc/admin/updateCurrRatesAndMsgs.do`,
        reqPayload
      )
      .then((response) => {
        if (response?.status === 202 || response?.status === 200) {
          dispatch(newFlashData(response.data));
        } else {
          dispatch(failurePostUpdateCurrentData(response.status));
        }
      })
      .catch((e) => {
        dispatch(failurePostUpdateCurrentData(e.response.status));
      });
  };

export const receiveUpdateCurrentData = (data) => {
  return {
    type: RECEIVE_UPDATE_CURRENT_GET_DATA,
    payload: data,
  };
};
export const failureGetUpdateCurrentData = (data) => {
  return {
    type: FAILURE_UPDATE_GET_REQUEST,
    payload: data,
  };
};
export const failurePostUpdateCurrentData = (data) => {
  return {
    type: FAILURE_UPDATE_POST_REQUEST,
    payload: data,
  };
};
export const newFlashData = (data) => {
  return {
    type: RECEIVE_UPDATE_CURRENT_SAVE_NEWFLASH_SUCCESS_DATA,
    payload: data,
  };
};
export const spinnerendpointUpdateCurrent = (data) => {
  return {
    type: ENDPOINT_SPINNER_UPDATE_CURRENT,
    payload: data,
  };
};
