import { FAILURE_COMMENT_DATA, REQUEST_GET_COMMENT_DATA, RECEIVE_COMMENT_DATA, RECEIVE_GET_COMMENT_DATA, FAILURE_GET_COMMENT_DATA, } from '../../../../global.constants';

const initialState = {
    comment: '',
    isLoading: false,
    error: ''
}

const CommentsReducer = (state = initialState, { type, payload }={}) => {

    switch (type) {
        case RECEIVE_COMMENT_DATA:
            const newState = {
                ...state,
                comment: payload,
                isLoading: false,
                error: ''
            }
            return newState
        case RECEIVE_GET_COMMENT_DATA:
            const commentState = {
                ...state,
                comment: payload,
                isLoading: false,
                error: ''
            }
            return commentState
        case REQUEST_GET_COMMENT_DATA:
            const loadState = {
                ...state,
                isLoading: true
            }
            return loadState
        case FAILURE_GET_COMMENT_DATA:

            const errorState = {
                ...state,
                isLoading: false,
                error: payload
            }
            return errorState
        case FAILURE_COMMENT_DATA:

            const errorPostState = {
                ...state,
                isLoading: false,
                error: payload
            }
            return errorPostState

        default:
            return state
    }
}

export default CommentsReducer;