import React, { useEffect, useState } from "react";
import { FormField } from "@jpmuitk/form-field";
import Grid from "@material-ui/core/Grid";
import { Form } from "@jpmuitk/forms";
import { Input } from "@jpmuitk/input";
import { Button } from "@jpmuitk/button";
import dynamicForm from "../../../components/form/DynamicForm";
import LoanDetails from "./LoanDetails";
import "../purposeOfLoan/purposeOfLoan.scss";
import { useSelector, useDispatch } from "react-redux";
import { POLConstants, PurposeOfLoanText } from "../../global.constants";
import { Dialog, DialogTitle, DialogActions } from "@jpmuitk/dialog";
import {
  getPurposeData,
  getEntityData,
  postData,
  postDeleteData,
  getStoreData,
} from "./redux/action/loanAction";
import { useHistory } from "react-router-dom";
import Loader from "../../../components/loader/Loader";
import {
  getNextNavigation,
  getPreviousNavigation,
} from "../../../components/navigationPath/navigationPath";
import HiddenValidator from "../../../containers/HiddenValidator";
import { formHas_error } from "../../dynamic/returnToDashboard/redux/action/dashboardAction";

function PurposeOfLoan(props) {
  const dispatch = useDispatch();
  const { createInitialList, addNewItem } = props;
  const details = useSelector((state) => state?.loanReducer?.loanDetails);
  const [error, setError] = useState(error);
  const isLoading = useSelector((state) => state.loanReducer.isLoading);
  const errors = useSelector((state) => state.loanReducer.error);
  const borrowEntity = useSelector(
    (state) => state?.loanReducer?.initialBorrowData
  );
  const storeRestaurentData = useSelector(
    (state) => state?.loanReducer?.storeRestaurents
  );
  const history = useHistory();
  const [loanDetails, setLoanDetails] = useState(details);
  const [totalAmount, setTotalAmount] = useState(0);
  const loanId = useSelector((state) => state.DashboardReducer?.currentLoan);
  const [popup, setpopup] = useState(false);
  const [deletespan, setDeletespan] = useState(false);
  const [addspan, setAddspan] = useState({
    isAdded: false,
    newlyAddedIndex: null,
  });
  const userId = useSelector((state) => state.AuthCheckReducer.loggedInUserId);
  const [idlr, setIdlr] = useState(true);

  const aplhaBorrowEntity = borrowEntity?.sort((a, b) =>
    a.borrowerName.localeCompare(b.borrowerName)
  );

  const updateStore = () => {
    saveAPI(false);
  };
  useEffect(() => {
    if (errors) return setError(errors);
    setError(POLConstants.emptyString);
  }, [errors]);
  useEffect(() => {
    if (loanId?.activeLoanId) {
      dispatch(getPurposeData(loanId?.activeLoanId, userId));
      dispatch(getEntityData(loanId?.activeLoanId, userId));
      dispatch(getStoreData(loanId?.activeLoanId, userId));
    }
  }, [loanId?.activeLoanId]);

  useEffect(() => {
    setTimeout(() => {
      setLoanDetails(details);
      setDeletespan(false);
      setAddspan({});
      setIdlr(false);
    }, 2000);
  }, [details]);

  useEffect(() => {
    let data = 0;
    loanDetails.forEach((ele) => {
      if (ele.requestedAmount) {
        data = data + parseInt(ele.requestedAmount);
      }
      if (ele.childPurposeVO && ele.childPurposeVO.requestedAmount) {
        data = data + parseInt(ele.childPurposeVO.requestedAmount);
      }
    });
    setTotalAmount(data);
  }, [loanDetails]);

  const handleLoanSection = (e) => {
    let loanList = createInitialList(e?.target?.value, loanId?.activeLoanId);
    setLoanDetails([...loanList]);
  };

  const handleAddNewLoan = () => {
    let loanList = addNewItem(loanDetails, loanId?.activeLoanId);
    setAddspan({ ...addspan, isAdded: true, newlyAddedIndex: loanList.length });
    setLoanDetails([...loanList]);
  };

  const formatUSD = (val) => {
    return val
      ? String(val).replace(POLConstants.usdFormat, POLConstants.comma)
      : null;
  };

  const handleRemoveLoanSection = (id, loanData) => {
    setDeletespan(true);
    setDeletespan(id);
    let loanList = [];
    loanDetails.forEach((element, index) => {
      if (index != id) {
        loanList.push(element);
      }
    });
    if (loanDetails[id].purposeId) {
      let removedData = {
        purposeId: loanDetails[id].purposeId,
        childPurposeVO: loanDetails[id].childPurposeVO
          ? { purposeId: loanDetails[id].childPurposeVO.purposeId }
          : null,
        loanId: loanId?.activeLoanId,
        userId: userId,
      };
      let url = POLConstants.deleteLoanPurpose;
      dispatch(postDeleteData(url, removedData));
    }
    setLoanDetails([...loanList]);
  };
  const saveAPI = (path, sameComp = true) => {
    if (path) {
      updateStore();
      if (!sameComp) {
        dispatch(formHas_error(false));
        return false;
      }
      history.push(path);
      window.scrollTo(0, 0);
    } else {
      let data = loanDetails.filter((ele, i) => ele !== undefined);
      let postedData = [];
      data.forEach((element) => {
        if (
          element.purposeTypeId != POLConstants.numOne &&
          element.purposeTypeId != POLConstants.numFive &&
          element.purposeTypeId != POLConstants.numSix
        ) {
          element.childPurposeVO = null;
        }
        if (element.purposeTypeId) {
          postedData.push(element);
        }
      });
      let url = POLConstants.saveLoanPurpose;
      if (loanId?.activeLoanId) {
        postedData.forEach((loan) => (loan.userId = userId));
        //postedData['userId'] = userId;
        dispatch(postData(url, postedData, userId, loanId?.activeLoanId));
      }
    }
  };

  const loanDetailsNos = loanDetails.filter((ele) => ele !== undefined).length;

  return (
    <>
      {idlr ? <Loader /> : null}

      {
        <div className={`dynamicContainer`} id="main" role="main">
          <div className={`headerContainer`}>
            <h1 className={`headingClass`}>{PurposeOfLoanText.header}</h1>
          </div>
          <Form fullWidthFields className={`formClass`}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormField
                  label={PurposeOfLoanText.howManyLoan}
                  disabled={loanDetailsNos ? true : false}
                  onClick={() =>
                    loanDetailsNos ? setpopup(true) : POLConstants.emptyString
                  }
                >
                  <Input
                    name="totalLoans"
                    fullWidth
                    autoComplete="off"
                    value={
                      loanDetailsNos == POLConstants.numZero
                        ? POLConstants.emptyString
                        : loanDetailsNos
                    }
                    onChange={handleLoanSection}
                  />
                </FormField>
                {loanDetails?.map(
                  (ele, ind) =>
                    ele && (
                      <LoanDetails
                        data={ele}
                        entityDetails={aplhaBorrowEntity}
                        storeRestaurentData={storeRestaurentData}
                        itemDeleted={deletespan}
                        remove={handleRemoveLoanSection}
                        setLoanDetails={setLoanDetails}
                        loanDetails={loanDetails}
                        id={ind}
                        key={ele.loanId}
                      />
                    )
                )}
                {loanDetailsNos ? (
                  <div className={`pad-bottom`}>
                    <FormField label="Total Requested Amount">
                      <Input
                        name="totalAmount"
                        disabled={true}
                        fullWidth
                        autoComplete="off"
                        value={formatUSD(totalAmount)}
                      />
                    </FormField>
                  </div>
                ) : (
                  POLConstants.emptyString
                )}
              </Grid>
            </Grid>
          </Form>
          {loanDetails?.length > 0 && (
            <div className={`footerContainer`}>
              {loanDetails?.length ? (
                <Button variant="cta" onClick={handleAddNewLoan}>
                  {PurposeOfLoanText.btnAddLoan}
                  {addspan.isAdded ? (
                    <span
                      class="sr-only"
                      aria-live="assertive"
                      role="alert"
                      aria-label={` New Request ${addspan.newlyAddedIndex} Added`}
                    ></span>
                  ) : (
                    ""
                  )}
                </Button>
              ) : (
                POLConstants.emptyString
              )}
            </div>
          )}
          <p className={"noteSectionClass"}>{PurposeOfLoanText.note}</p>
          <div className={`headerContainer`}>
            <Button onClick={() => saveAPI(getPreviousNavigation())}>
              {PurposeOfLoanText.previous}
            </Button>
            <Button onClick={() => saveAPI(getNextNavigation())} variant="cta">
              {PurposeOfLoanText.nextsection}
            </Button>
            <HiddenValidator
              validateTrigger={() => saveAPI(getNextNavigation(), false)}
              labels={"next section"}
            />
          </div>
          {popup ? (
            <Dialog open={popup}>
              <DialogTitle>{POLConstants.deleteAdd}</DialogTitle>
              <DialogActions>
                <Button onClick={() => setpopup(false)} variant="cta">
                  {POLConstants.close}
                </Button>
              </DialogActions>
            </Dialog>
          ) : (
            POLConstants.emptyString
          )}
        </div>
      }
    </>
  );
}

const initLoan = {
  loanId: "",
  purposeTypeId: "",
  loanAmount: null,
  purposeName: null,
  updatedDate: null,
  updatedBy: null,
  sellerName: null,
  sellerEntityName: null,
  requestedAmount: null,
  rentPercentage: null,
  purchaseDate: null,
  projGroundBrkDate: null,
  principalBalance: null,
  prevStoreNumber: null,
  parentPurposeId: null,
  otherLoanNumber: null,
  lenderName: null,
  originalNoteDate: null,
  originalAmount: null,
  openingDate: null,
  newStoreNumber: null,
  newStoreAddress: null,
  nationalStoreNumber: null,
  monthlyLoanPayment: null,
  maturityDate: null,
  loanStructureId: null,
  loanPurposeOther: null,
  loanOrObligationNo: null,
  lenderPhoneNumber: null,
  otherLenderName: null,
  interestRateOption: null,
  interestRate: null,
  fundingDate: null,
  createdDate: null,
  desiredTermYears: null,
  desiredTermMonths: null,
  paymentStructureId: null,
  contactLender: null,
  collateralStoreList: null,
  borrowingStore: null,
  asap: "N",
  chooseAllCollateral: "Y",
  createdBy: null,
  childPurposeVO: {
    loanId: "",
    purposeTypeId: "",
    loanAmount: null,
    purposeName: null,
    updatedDate: null,
    updatedBy: null,
    sellerName: null,
    sellerEntityName: null,
    requestedAmount: null,
    rentPercentage: null,
    purchaseDate: null,
    projGroundBrkDate: null,
    principalBalance: null,
    prevStoreNumber: null,
    parentPurposeId: null,
    otherLoanNumber: null,
    lenderName: null,
    originalNoteDate: null,
    originalAmount: null,
    openingDate: null,
    newStoreNumber: null,
    newStoreAddress: null,
    nationalStoreNumber: null,
    monthlyLoanPayment: null,
    maturityDate: null,
    loanStructureId: null,
    loanPurposeOther: null,
    loanOrObligationNo: null,
    lenderPhoneNumber: null,
    otherLenderName: null,
    interestRateOption: null,
    interestRate: null,
    fundingDate: null,
    createdDate: null,
    desiredTermYears: null,
    desiredTermMonths: null,
    paymentStructureId: null,
    contactLender: null,
    collateralStoreList: null,
    borrowingStore: null,
    asap: "N",
    chooseAllCollateral: "Y",
    createdBy: null,
    childPurposeVO: null,
  },
};
const dynamicFormProps = {
  initObj: { ...initLoan },
  idKey: "loanId",
};

export default dynamicForm(PurposeOfLoan, dynamicFormProps);
