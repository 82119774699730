import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { FormField } from '@jpmuitk/form-field';
import Grid from '@material-ui/core/Grid';
import { Form } from '@jpmuitk/forms';
import { Input } from '@jpmuitk/input';
import { OrderedButton, ButtonBar } from '@jpmuitk/button-bar';
import './AdditionalComments.scss';
import { AdditionalCommentConstants,FOURHUNDREDFOUR, Tooltip_info} from "../../global.constants";
import { postComment, getCommentData } from './redux/actions/AdditionalCommentAction'
import Loader from '../../../components/loader/Loader';
import Error from '../../../components/error/Error';
import { getNextNavigation, getPreviousNavigation } from "../../../components/navigationPath/navigationPath";
import HiddenValidator from '../../../containers/HiddenValidator';
import { formHas_error } from '../../dynamic/returnToDashboard/redux/action/dashboardAction';
import '../../global.scss';

const PREV_PATH = '/miscellaneousDataRequired'
const NEXT_PATH = '/submitRequesttoChaseFranchiseFinance'

const AdditionalComments = () => {
    const [data, setData] = useState('');
    const [error, setError] = useState(error);
    const [showBanner, setShowBanner] = useState(false);
    const history = useHistory();
    const commentValue = useSelector(state => state.CommentsReducer.comment);
    const isLoading = useSelector(state => state.CommentsReducer.isLoading);
    const errors = useSelector(state => state.CommentsReducer.error)
    const loanId = useSelector(state => state.DashboardReducer?.currentLoan);
    const userId = useSelector(state => state.AuthCheckReducer?.loggedInUserId);
    const returnTodashboardflag = useSelector(state => state.DashboardReducer.returnTodashboardflag); 
    const dispatch = useDispatch();

    useEffect(() => {
        if (errors) return setError(errors)
        setError('')
    }, [errors]);

    const updateStore = () => {
        if (data && loanId?.activeLoanId) {
            const payload = {
                "commentId": '',
                "comments": data
            }
            dispatch(postComment(payload, loanId?.activeLoanId,userId));
        }
    }

    const handleChange = (e) => {
        const { value } = e.target
        setData(value)
    }

    const handlePreviousClick = () => {
        setData(commentValue)
        updateStore();
        history.push(getPreviousNavigation())
        window.scrollTo(0, 0);
    }

    const handleNextClick = (sameComp=true) => {
        setData(commentValue)
        updateStore();
        if(!sameComp) {
            dispatch(formHas_error(false));
            return false;
        }
        if(returnTodashboardflag){
            history.push('/bankersComments')
        }
        else{
            history.push(getNextNavigation())
        }
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        if (commentValue) {
           setData(commentValue.comments)
        }
    }, [commentValue]
    )

    useEffect(() => {
        if (loanId?.activeLoanId) {
        dispatch(getCommentData(loanId?.activeLoanId, userId));
    }}, [])

    return (
        <>
        {isLoading ? <Loader /> : null}
           {error? <Error /> : showBanner}
        {!error ? <div className={`dynamicContainer`} id="main" role="main">
            
           <Form fullWidthFields className={`formClass`}>
                <h1 className={`headingClass`}>{AdditionalCommentConstants.anythingElse}</h1>
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        <FormField
                            label={AdditionalCommentConstants.additionalInformation}
                            helperText={AdditionalCommentConstants.placeholderText}
                            helperTextPlacement={'tooltip'}
                            hasStatusIndicator>
                            <span tabIndex='0' className='sr-only' role='tooltip' aria-label={Tooltip_info}></span>
                            <Input inputProps={{ maxLength: 2000 }} onChange={handleChange} className={`inputClass`} multiline value={data} role='tooltip' aria-label={Tooltip_info}>
                            </Input>
                        </FormField>
                    </Grid>
                </Grid>
            </Form>
            <p className={`noteSectionClass`}>{AdditionalCommentConstants.noteText}</p>
            <div className={`addcomments-buttonDiv`}>
                <ButtonBar className={`buttonbarClass`}>
                    <OrderedButton onClick={handlePreviousClick}>{AdditionalCommentConstants.previous}</OrderedButton>
                    <OrderedButton className={'nextSectionClass'} onClick={handleNextClick} variant="cta">{AdditionalCommentConstants.next}</OrderedButton>
                    <HiddenValidator validateTrigger={() => handleNextClick(false)} labels={'next section'} />
                </ButtonBar>
            </div> 
        </div>: null}
        </>
    )
}
export default AdditionalComments;
