
import {SAVE_RETURN_RECEIVE,SAVE_RETURN_SUCCESS,SAVE_RETURN_FAILURE,REQUEST_RETURN,REQUEST_EXIST, ENABLE_LOADER, DISABLE_LOADER} from '../../../../global.constants';
  
  const initialState = {
    ReturnUser : [],
    newUserErrorCode: false,
    success: false,
    newmail:''
  }

  const ReturnUserReducer = (state = initialState,{type, payload }={})=>{
   switch (type){
     
         case REQUEST_RETURN:
            const loadState = {
                ...state,
                newUserErrorCode: true,
                newmail: payload
            }
            return loadState



            case REQUEST_EXIST:
               const State = {
                   ...state,
                   success: true
               }
               return State
      
      case SAVE_RETURN_SUCCESS:
             return {
             stage : "success",
           
          };
           
       case SAVE_RETURN_FAILURE:
          
        return {
            stage : "error",
          
         };
           
         case ENABLE_LOADER:
          return {
              isLoading : true,
           };
           case DISABLE_LOADER:
            return {
                isLoading : false,
             };
         default:
             return state;
   }
  };

  export default ReturnUserReducer;
