import { RECEIVE_CONTACT_GET_DATA, 
  SUBMIT_CONTACT_DATA,
  RECEIVE_CONTACT_INFORMATION_REQUEST,
  FAILURE_CONTACT_GET_REQUEST,
  FAILURE_CONTACT_POST_REQUEST } from '../../../../global.constants';
const initialState = {
  contactinfoData: [],
  submissionContactData:[],
  isLoading : false,
  error:''
}
const ContactInfoReducer = (state = initialState,{type, payload }={}) => {
  switch (type) {
    case RECEIVE_CONTACT_INFORMATION_REQUEST:
      return Object.assign(state, {
        isLoading: true,
      });
     case FAILURE_CONTACT_GET_REQUEST:
        return Object.assign(state, {
          error: payload,
          isLoading: false,    
      });
    case RECEIVE_CONTACT_GET_DATA:
      return Object.assign(state, {
        contactinfoData: payload,
        isLoading: false
      });
    case FAILURE_CONTACT_POST_REQUEST:
      return Object.assign(state, {
        isLoading: false,
        error: payload
    });
    case SUBMIT_CONTACT_DATA:
      return Object.assign(state, {
        submissionContactData:payload,
        isLoading: false
      }); 
    default:
      return state;
  }
};

export default ContactInfoReducer;
