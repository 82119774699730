import React from "react";
import "./RequiredInformation.scss";

import { RequiredInformationData } from "./../../static.constants";
import { Link } from "@jpmuitk/link";

function RequiredInformation() {
  return (
    <div className="container" id="main">
      <h1>{RequiredInformationData.header}</h1>
      <p>{RequiredInformationData.info}</p>
      <div>
        <table className="infoTable">
          <thead>
            <tr>
              <th></th>
              <th>{RequiredInformationData.purchaseNewRestaurant}</th>
              <th>{RequiredInformationData.purchaseExistingRestaurant}</th>
              <th>{RequiredInformationData.exercise}</th>
              <th>{RequiredInformationData.purchaseMc}</th>
              <th>{RequiredInformationData.purcRefinance}</th>
              <th>{RequiredInformationData.Refinance}</th>
              <th>{RequiredInformationData.assumeExisting}</th>
              <th>{RequiredInformationData.reInvestment}</th>
              <th>{RequiredInformationData.lineOfCredit}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{RequiredInformationData.signedAndCompleted}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
            </tr>
            <tr>
              <td>{RequiredInformationData.diligence}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td id="stand-alone">
                {RequiredInformationData.cross}{" "}
                {RequiredInformationData.standAloneRequest}
              </td>
            </tr>
            <tr>
              <td>
                <span>{RequiredInformationData.current}</span>
                <Link href="/requiredInformation/personalFinancialStatements">
                  {RequiredInformationData.personalFinancialStatement}
                </Link>
              </td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
            </tr>
            <tr>
              <td>{RequiredInformationData.lastFiscalYearEnd}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
            </tr>
            <tr>
              <td>{RequiredInformationData.mostCurrentConsolidated}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
            </tr>
            <tr>
              <td>{RequiredInformationData.consolidatedIndividualYear}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
            </tr>
            <tr>
              <td>{RequiredInformationData.existingProfitLoss}</td>
              <td></td>
              <td>{RequiredInformationData.cross}</td>
              <td></td>
              <td>{RequiredInformationData.cross}</td>
              <td></td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{RequiredInformationData.profitLossStatements}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
            </tr>
            <tr>
              <td>{RequiredInformationData.costBreakdown}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{RequiredInformationData.cross}</td>
              <td></td>
            </tr>
            <tr>
              <td>{RequiredInformationData.oneYearProjectedProfit}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td></td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td></td>
            </tr>
            <tr>
              <td>{RequiredInformationData.copyDriversLicense}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
            </tr>
            <tr>
              <td>{RequiredInformationData.organizationDocuments}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
            </tr>
            <tr>
              <td>{RequiredInformationData.organizationDocumentsLiability}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
            </tr>
            <tr>
              <td>
                {RequiredInformationData.organizationDocumentsJointVenture}
              </td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
            </tr>
            <tr>
              <td>{RequiredInformationData.newExistingClients}</td>
              <td>{RequiredInformationData.cross}</td>
              <td></td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
              <td>{RequiredInformationData.cross}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default RequiredInformation;
