import axios from "axios";
import { base_url, base_url_user } from "../../containers/global.constants";

const baseURL = base_url_user + "/api/v1/usersvc";

export const getAPI = (url, loanId, userId, callback) => {
  const payload = { loanId: loanId, userId: userId };
  url = baseURL + url;

  return async (dispatch) => {
    try {
      await axios.post(url, payload).then((response) => {
        if (response && response?.status === 200) {
          dispatch(callback(response?.data));
        }
      });
    } catch (e) {
      return e;
    }
  };
};

export const postAPI = async (url, payload) => {
  try {
    url = baseURL + url;
    const response = await axios.put(url, payload);
    return response.data;
  } catch (error) {
    console.error("Something went wrong!", error);
    return error;
  }
};

export const deleteAPI = async (url, payload, loanId, userId) => {
  try {
    url = baseURL + url;
    const response = await axios.post(url, {
      data: payload,
      loanId: loanId,
      userId: userId,
    });
    return response.data;
  } catch (error) {
    console.error("Something went wrong!", error);
    return error;
  }
};
