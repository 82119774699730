import axios from 'axios'
import { base_url, RECEIVE_GET__HOMEBANNER ,base_url_user} from '../../../../global.constants'

export const getHomePageBannerData = () => async dispatch => {
    try {

        await axios.get(
            base_url_user+`/api/v1/getNewsFlash`)
            .then(response => {
                if (response && response.status === 200) {
                    dispatch(receiveHomeBanner(response?.data));
                }
            });
    }
    catch (e) {
        return e;
    }
};


export const receiveHomeBanner = (data) => ({
    type: RECEIVE_GET__HOMEBANNER,
    payload: data
})
