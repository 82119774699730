export const adminDashboardHeaders = [
  {
    headerName: "Creation Date",
    field: "createdDate",
    filter: false,
    sortable: true,
    resizable: true,
    maxWidth: 120,
    minWidth: 80,
    suppressMenu: true,
    flex: 1,
  },
  {
    headerName: "Purpose of Request",
    field: "purposes",
    cellRenderer: "purposeCellRenderer",
    filter: false,
    sortable: false,
    resizeable: true,
    maxWidth: 350,
    minWidth: 150,
    suppressMenu: true,
    flex: 1,
  },
  {
    headerName: "Status",
    field: "loanStatus",
    filter: false,
    sortable: true,
    resizable: true,
    maxWidth: 150,
    minWidth: 100,
    suppressMenu: true,
    flex: 1,
  },
  {
    headerName: "Action",
    field: "",
    cellRenderer: "actionCellRenderer",
    filter: false,
    suppressMenu: true,
    sortable: false,
    minWidth: 200,
    //maxWidth: 300,
    flex: 1,
  },
];
