import { REQUEST_REFERRAL_POST_DATA, RECEIVE_REFERRAL_POST_DATA, FAILURE_REFERRAL_POST_DATA } from '../../../../global.constants'

const initialState = {
    referralData: {
        referalCode: '',
        sourceCode: ''
    }
}

const ReferralInformationReducer = (state = initialState, { type, payload }={}) => {

    switch (type) {
        case RECEIVE_REFERRAL_POST_DATA:
            const newState = {
                ...state,
                referralData: payload
            }
            return newState
        case FAILURE_REFERRAL_POST_DATA:
        case REQUEST_REFERRAL_POST_DATA:
            state.referralData = payload
            return
       
        default:
            return state
    }
}

export default ReferralInformationReducer;