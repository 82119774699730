
import React, { useEffect, useState } from "react"
import './PhysicalMailingAddress.scss'
import { FormField, useValidation } from '@jpmuitk/form-field';

import Grid from '@material-ui/core/Grid';
import { Input  } from '@jpmuitk/input';
import { FormattedInput } from '@jpmuitk/formatted-input';
import { RadioButtonGroup } from '@jpmuitk/radio-button';
import { Button } from '@jpmuitk/button';
import { OrderedButton, ButtonBar } from '@jpmuitk/button-bar';
import { Dropdown } from '@jpmuitk/dropdown';
import { useSelector, useDispatch } from 'react-redux';
import '../../global.scss';
import { getPhysicalAddressData, savePhysicalAddressData, fetchHomeInfoaddressdata, fetchContactInfoaddressdata, fetchMailingaddressdata } from "./redux/action/physicalMailingAction";
import { getCountrystatesData } from '../../commonactions/redux/actions/action';
import { PhysicalMailingaddress,ERRORCODE, Tooltip_info , Accessibility_text } from '../../global.constants';
import { businessOfficeZipCodeRules, businessphoneValidationRules, businessAddressInfoRules } from '../../ValidationRules';
import { useHistory } from 'react-router-dom';
import Error from '../../../components/error/Error';
import Loader from "../../../components/loader/Loader";
import { AriaAnnouncerProvider } from '@jpmuitk/aria-announcer';
import { getNextNavigation, getPreviousNavigation } from "../../../components/navigationPath/navigationPath";
import HiddenValidator from '../../../containers/HiddenValidator';
import { formHas_error } from '../../dynamic/returnToDashboard/redux/action/dashboardAction';

// Userstory FFANEW-42
const PhysicalMailingAddress = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const physicalAddressData = useSelector(state => state.PhysicalAddressInfoReducer.PhysicalAddressInfoData);
  const isLoading = useSelector(state => state.PhysicalAddressInfoReducer.isLoading);
  const error = useSelector(state => state.PhysicalAddressInfoReducer.error);
  const copymailingaddresData = useSelector(state => state.PhysicalAddressInfoReducer.CopymailingAddress);
  const homemailingaddresData = useSelector(state => state.PhysicalAddressInfoReducer.HomemailingAddress);
  const contactmailingaddresData = useSelector(state => state.PhysicalAddressInfoReducer.ContactmailingAddress);
  const loanId = useSelector(state => state.DashboardReducer?.currentLoan);
  const [physicalData, setPhysicaldata] = useState({});
  const [countrystates, setCountrystates] = useState([]);
  const countrystateData = useSelector(state => state.CountrystatesReducer.Countrystates);
  const newcountrystatesarray = countrystates?.map(x => x.stateName)
  const [officeZipExceptions, validateOfficezip] = useValidation(businessOfficeZipCodeRules);
  const [telephoneExceptions, validateTelephone] = useValidation(businessphoneValidationRules);
  const [addrssExceptions, validateAddress] = useValidation(businessAddressInfoRules);
  const mergedErrorMessages = exceptions => exceptions.map(({ type, message }) => `${message}`).join('');
  const physicalHelperText = !mergedErrorMessages(addrssExceptions) ? true : false
  const userId = useSelector(state => state.AuthCheckReducer.loggedInUserId);

  const [copymailspan, setCopymailspan] = useState(false);
  const [copyhomespan, setCopyhomespan] = useState(false);
  const [copyofficespan, setCopyofficespan] = useState(false);


 
  const signforPackage = [{
    label: 'Yes',
    value: 'y'
  },
  {
    label: 'No',
    value: 'n'
  }
  ];


  
  useEffect(() => {
    if (physicalAddressData) {
      setPhysicaldata(physicalAddressData)
    }
  }, [physicalAddressData]);

 

  useEffect(() => {
    if (countrystateData) {
      setCountrystates(countrystateData)
    }
  }, [countrystateData])

const updateStore = () => {
    if (physicalData) {
      savephysicalmailingData();
    }
}

  useEffect(() => {
    if (loanId?.activeLoanId) {
    dispatch(getPhysicalAddressData(loanId?.activeLoanId));
    dispatch(getCountrystatesData());
  }}, []);



  const handleChange = (e, selectedItem, fieldname) => {
    const { value, name } = e.target;
    if (name === "addressVO.address") {
      if (value !== "") {
        validateAddress(value)
      }
      setPhysicaldata({
        ...physicalData, addressVO: { ...physicalData.addressVO, address: value }
      });
    }
    else if (name === "addressVO.city") {
      if (physicalData.addressVO?.address === "") {
        validateAddress(physicalData.addressVO.address)
      }
      setPhysicaldata({
        ...physicalData, addressVO: { ...physicalData.addressVO, city: value }
      });
    }
    else if (fieldname === "addressVO.stateName") {
      const selectedRegion = countrystateData?.find(x => x.stateName === selectedItem)
     
      setPhysicaldata({
        ...physicalData, addressVO: { ...physicalData.addressVO, stateName: selectedItem == "Select" ? "" : selectedItem , stateId: selectedRegion?.stateId }
      });
    }

    else {
      setPhysicaldata({
        ...physicalData, [e.target.name]: e.target.value
      });
    }
  }

  const handlezipChange = (value, id) => {
    if (id === "addressVO.zipCode") {
      validateOfficezip([]) 
      if (physicalData.addressVO?.address === "") {
        validateAddress(physicalData.addressVO.address)
      }
      setPhysicaldata({
        ...physicalData, addressVO: { ...physicalData.addressVO, zipCode: value }
      });
    }
    if (id === "phoneNumber") {
      setPhysicaldata({
        ...physicalData, phoneNumber: value
      });
    }
  }
 
  

  const onBlurValidation = (e, id)=>{
    const {value } = e.target;
  if(id == "addressVO.zipCode"){
   if(value.length != 10 && value.length != 5){
    validateOfficezip(value) 
    }
    else{
      validateOfficezip([]) 
    }
  }
  }


 

useEffect(() => {
  if (copymailingaddresData) {
   
    setPhysicaldata({
      ...physicalData,
       addressVO: { ...physicalData.addressVO, 
      address: copymailingaddresData?.addressVO?.address ? copymailingaddresData?.addressVO?.address : '' ,
      city : copymailingaddresData?.addressVO?.city ? copymailingaddresData?.addressVO?.city : '',
      stateName : copymailingaddresData?.addressVO?.stateName,
      stateId   :copymailingaddresData?.addressVO?.stateId,
      zipCode: copymailingaddresData?.addressVO?.zipCode,
      },phoneNumber : copymailingaddresData?.phoneNumber,
      phoneId : copymailingaddresData?.phoneNumber ? copymailingaddresData?.phoneId : null
    });
  }
}, [copymailingaddresData ]);

useEffect(() => {
  if (homemailingaddresData) {
    setPhysicaldata({
      ...physicalData,
       addressVO: { ...physicalData.addressVO, 
      address: homemailingaddresData?.addressVO?.address ? homemailingaddresData?.addressVO?.address : '' ,
      city : homemailingaddresData?.addressVO?.city ? homemailingaddresData?.addressVO?.city : '',
      stateName : homemailingaddresData?.addressVO?.stateName,
      stateId   : homemailingaddresData?.addressVO?.stateId,
      zipCode: homemailingaddresData?.addressVO?.zipCode,
      }, phoneNumber : homemailingaddresData?.phoneNumber
    });
  }
}, [homemailingaddresData]);

useEffect(()=>{
  if (contactmailingaddresData) {
    setPhysicaldata({
      ...physicalData,
       addressVO: { ...physicalData.addressVO,
      address: contactmailingaddresData?.addressVO?.address ? contactmailingaddresData?.addressVO?.address : '' ,
      city : contactmailingaddresData?.addressVO?.city ? contactmailingaddresData?.addressVO?.city : '',
      stateName : contactmailingaddresData?.addressVO?.stateName,
      stateId   : contactmailingaddresData?.addressVO?.stateId,
      zipCode: contactmailingaddresData?.addressVO?.zipCode,
      },phoneNumber : contactmailingaddresData?.phoneNumber
    });
  }
}, [contactmailingaddresData])

  const copyMailingaddress = () => {
    setCopymailspan(true)
    if (loanId?.activeLoanId) {
    dispatch(fetchMailingaddressdata(loanId?.activeLoanId));
  }}

  
  const copyContactInfoaddress = () => {
    setCopyofficespan(true)
    if (loanId?.activeLoanId) {
    dispatch(fetchContactInfoaddressdata(loanId?.activeLoanId));
  }};
  const copyHomeInfoaddress = () => {
    setCopyhomespan(true)
    if (loanId?.activeLoanId) {
    dispatch(fetchHomeInfoaddressdata(loanId?.activeLoanId));
  }}

  const savephysicalmailingData = () => {

    const { stateName, city, zipCode, address, addressId } = physicalData.addressVO;
    const { phoneNumber , phoneId } = physicalData;
    physicalData.addressVO.addressId = stateName && city && zipCode && address ? addressId : null
    physicalData.phoneId = phoneNumber ? phoneId : null

    if (loanId?.activeLoanId) {
      dispatch(savePhysicalAddressData(physicalData, loanId?.activeLoanId, userId));
  }}


  const checkAndHandleNavigation = (navigationData, sameComp)=>{
    if (
      (!physicalData.addressVO.stateName &&
      !physicalData.addressVO.zipCode &&
      !physicalData.addressVO.city && 
      !physicalData.phoneNumber &&
      !physicalData.addressVO.address) || physicalData.addressVO.address )
      {
        updateStore();
        if(!sameComp) {
          dispatch(formHas_error(false));
          return false;
        }
            history.push(navigationData)
          
          } else {
            
                 validateAddress("");
    }
  }
  const handleNextClick = (sameComp=true) => {
    checkAndHandleNavigation(getNextNavigation(), sameComp);
    window.scrollTo(0, 0);
  }
  

  const handlePreviousClick = (sameComp=true) => {
    checkAndHandleNavigation(getPreviousNavigation(), sameComp);
    window.scrollTo(0, 0);
  }



  ///// input field validation start 
  const parseDigits = string => (string.match(/(\d+)/g) || []).join('');
  const formatValue = (string) => {
    const digits = parseDigits(string);
    const chars = digits.split('');
    return chars
      .reduce(
        (r, v, index) => (index === 5 ? `${r}-${v}` : `${r}${v}`),
        ''
      )
      .substr(0, 10);
  };

  const formatValueWithAppend = string => {
    const res = formatValue(string);

    if (string.endsWith('-')) {
      if (res.length === 5) {
        return `${res}-`;
      }

    }
    return res;
  };


  const formatMobileValueWithAppend = string => {
    const res = parseDigits(string).split('')
      .reduce(
        (r, v, index) => (index === 3 || index === 6 ? `${r}-${v}` : `${r}${v}`),
        ''
      )
      .substr(0, 12);
    if (string.endsWith('-')) {
      if (res.length === 3) {
        return `${res}-`;
      }

      if (res.length === 7) {
        return `${res}-`;
      }
    }
    return res;
  }

  ///// input field validation end


  return (
  <>
 
  
    {error == ERRORCODE ?  <Error/> :
    <div className={`dynamicContainer`} id="main" role="main">
      <div className="physical-mailing-address">
        <h1 className="business-header">{PhysicalMailingaddress.header}</h1>
        <Grid container spacing={2} >
          <Grid item xs={12}>
            <FormField label="Recipient Name">
              <Input autoComplete='off' name="recipientName" onChange={handleChange}
                value={physicalData?.recipientName} />
            </FormField>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>

            <ButtonBar alignLeft stackAtBreakpoint={0}>
              <OrderedButton variant="cta" onClick={copyHomeInfoaddress}>{PhysicalMailingaddress.buttoncopy}
              {  copyhomespan ?  <span class="sr-only"  aria-live="assertive" role="alert">{Accessibility_text.PhysicalHomeAddress}</span> : "" }
              </OrderedButton>
              <OrderedButton className={`copyAddressButton`} variant="cta" onClick={copyContactInfoaddress}>{PhysicalMailingaddress.buttoncopy2}
              { copyofficespan ?  <span class="sr-only"  aria-live="assertive" role="alert">{Accessibility_text.PhysicalOfficeAddress} </span> : "" }
              </OrderedButton>
              <OrderedButton  className={`copyAddressButton`}variant="cta" onClick={copyMailingaddress}>{PhysicalMailingaddress.buttoncopy3}
              { copymailspan ?  <span class="sr-only"  aria-live="assertive" role="alert">{Accessibility_text.PhysicalMailAddress}</span> : "" }
              </OrderedButton>
            </ButtonBar>
          </Grid>

          <Grid item xs={12}>
          <FormField 
              label="Physical Address" 
              classes={{ helperText: 'errorHelperText' }}
             
              helperText={mergedErrorMessages(addrssExceptions) || PhysicalMailingaddress.placeholderText}
              validationState={(addrssExceptions[0]?.type)}
              helperTextPlacement={!mergedErrorMessages(addrssExceptions) && 'tooltip'}
              hasStatusIndicator={true}
            >
              <span tabIndex='0' role='tooltip' className='sr-only' aria-label={Tooltip_info}></span>

              <Input autoComplete='off' name="addressVO.address"
                onChange={handleChange}
                value={physicalData?.addressVO?.address}
                className="textFont"
                multiline
                role='tooltip' aria-label={Tooltip_info}
              />
            </FormField>
          </Grid>

          <Grid item xs={12}>
            <FormField label="City" >
              <Input autoComplete='off' name="addressVO.city"
                onChange={handleChange}
                value={physicalData?.addressVO?.city} />
            </FormField>
          </Grid>

          <Grid item xs={12}>
            <FormField label="State">
            <AriaAnnouncerProvider>
              <Dropdown
                id="physicalmailingStateName"
                selectedItem={physicalData?.addressVO?.stateName ? physicalData?.addressVO?.stateName  : "Select"}
                name="addressVO.stateName"
                onChange={(e, selectedItem) => { handleChange(e, selectedItem, "addressVO.stateName") }}
                source={newcountrystatesarray}
                className={"dropDownField "}
              />
               </AriaAnnouncerProvider>
            </FormField>
          </Grid>
          <Grid item xs={12}>
            <FormField label="Zip"
              classes={{ helperText: 'errorHelperText' }}
              helperText={mergedErrorMessages(officeZipExceptions)}
              validationState={(officeZipExceptions[0]?.type)}
            >
              <FormattedInput value={String(physicalData?.addressVO?.zipCode)} autoComplete='off'
                onChange={value => { handlezipChange(value, 'addressVO.zipCode'); }}
                onBlur={ e => {onBlurValidation(e,'addressVO.zipCode')}}
                inputProps={{ name: "addressVO.zipCode" }}
                rifmOptions={{
                  accept: /[\d]/g, mask: value => 10 >= value.length,
                  format: formatValueWithAppend,
                  append: string => string.length === 5 ? `${string}` : string
                }}
              />
            </FormField>
          </Grid>

          <Grid item xs={12}>
            <FormField label="Telephone Number at this location (required)"
              classes={{ helperText: 'errorHelperText' }}
              helperText={mergedErrorMessages(telephoneExceptions)}
              validationState={(telephoneExceptions[0]?.type)}>
              <FormattedInput value={String(physicalData.phoneNumber)} autoComplete='off'
                onChange={value => {
                  handlezipChange(value, 'phoneNumber');
                }}
                onBlur={(e) =>  validateTelephone(e.target.value)}
                inputProps={{ name: "phoneNumber" }}
                rifmOptions={{
                  accept: /[\d]/g, mask: value => 10 >= value.length,
                  format: formatMobileValueWithAppend,
                  append: (string) => string.length === 4 || string.length === 8 ? `${string}-` : string
                }}
              />
            </FormField>
          </Grid>

          <Grid item xs={12}>
            <div className='padding8'>
              <RadioButtonGroup
                legend="Will someone be at this location to sign for the package?"
                name="signForPackage"
                className={'singleRowRadioBtn'}
                onChange={handleChange}
                radios={signforPackage}
                value={String(physicalData.signForPackage)}
              />
            </div>
          </Grid>
          <p className="noteClass noteSectAlign">{PhysicalMailingaddress.note}</p>
          <Grid item xs={12} sm={12} md={12} lg={12}>

                <div className={`physical-buttonContainer`}>
                    <Button onClick={handlePreviousClick}>previous section</Button>
                    <Button variant="cta" onClick={handleNextClick}>next section</Button>
                    <HiddenValidator validateTrigger={() => handleNextClick(false)} labels={'next section'} />
                </div>
            </Grid>

        </Grid>
      </div>
    </div> }
    </>
  )
}


export default PhysicalMailingAddress;
