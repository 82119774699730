import React, { useState, useEffect } from 'react'; 
import { useSelector} from 'react-redux';
import { FormField, useValidation } from '@jpmuitk/form-field';
import Grid from '@material-ui/core/Grid';
import { Form } from '@jpmuitk/forms';
import { Input } from '@jpmuitk/input';
import { Dropdown } from '@jpmuitk/dropdown';
import { Button } from '@jpmuitk/button';
import { ThirdPartyLabel, ThirdPartyConstants,Tooltip_info,Info_security, RegExps } from '../../global.constants';
import '../../global.scss';
import './ThirdPartyWiringDetails.scss';
import {
   routingValidationRules
} from '../../ValidationRules';
import { FormattedInput } from '@jpmuitk/formatted-input';
import { AriaAnnouncerProvider } from '@jpmuitk/aria-announcer';


const numberValidator = {
    "wiredAmount": true
}

const ThirdPartyWiringDetails = (props) => {
    const parseDigits = string => (string.match(/(\d+)/g) || []).join('');
    const [accountName, setAccountname] = useState("");
    const [recipientwithoutmask , setRecipientwithoutmask] = useState(""); 
    const [routing, setRouting] = useState("");
    const [account, setAccount] = useState("");
    const [reaccount, setReaccount] = useState("");
    const { removerpt, defaultValue, updateRoot ,ind, itemDeleted } = props;
    const countrystateData = useSelector(state => state.CountrystatesReducer.Countrystates);
    const [routingExceptions, validateRouting] = useValidation(routingValidationRules);
    const [staty, setState] = useState('')
    const id = defaultValue.recipientId;

   const[errormsg,setErrormsg] = useState('')
   const[error,setError] = useState('')
    const mergedErrorMessages = exceptions => exceptions.map(({ type, message }) => `${message}`).join('');

    const [accountboolean, setAccountboolean] = useState(false);
    const[phone,setPhone] = useState(false)
    const [commonboolean, setCommonboolean] = useState(false);
    
    const states = countrystateData.map(countryState =>  countryState.stateName)

    const [valu, setValue] = useState(defaultValue);

     const acco =  accountboolean ? ThirdPartyConstants.ssnNotmatchError : ''
     const phono = phone ? ThirdPartyConstants.pherror : ''

    useEffect(() => {
       
        if(defaultValue){
            if (!commonboolean) { 
                var receiptname = defaultValue?.recipientName;
                 setAccountname(receiptname)

                var route = defaultValue?.routingNumber;
                setRouting(route)

                var accounts = defaultValue?.accountNumber;
                setAccount(accounts)

                var reaccounts = defaultValue?.accountNumber;
                setReaccount(reaccounts)


             
       
        setValue(defaultValue);
        setState(countrystateData.find(state => state.stateId === defaultValue.state) && countrystateData.find(state => state.stateId === defaultValue.state).stateName);
            }  
    }
    }, [props.defaultValue,countrystateData])


    useEffect(()=>{
        valu.recipientName = accountName
         valu.routingNumber = routing
         valu.accountNumber = account


    },[accountName,routing,account])

    useEffect(() => {


        if (account.length === reaccount.length) {
            if (account !== reaccount) {
                setAccountboolean(true)
            }
            else {
                setAccountboolean(false);
               
            }
        }
        
        
         else if(account.length > reaccount.length ||
         account.length < reaccount.length)
         {

           
             setAccountboolean(true)

         }
        
    }, [account, reaccount]);

    useEffect(()=>{
        if(valu.bankRepContactNumber?.length < 12)
      {
         
            setPhone(true)
         
      }
      else{
           setPhone(false)
           
      }
     
      },[valu])

        
    const numberRangeValidator = (val, maxDigits, decimal) => {
        let decimalPoint = new RegExp(`^\\d*\\.?[\\d]{0,${decimal}}$`, 'g');
        let numb = val.replace(/,/gm, '');
        if (numb.replace(/\./g, '').length <= maxDigits && decimalPoint.test(numb)) {

            return true;
        }
        return false;
    }

    const formatUSD = (val) => {

        return String(val).replace(/\B(?<!.\\d*)(?=(\d{3})+(?!\d))/g, ",")

    }

    

    const formatMobileValueWithAppend = string => {
        const res = parseDigits(string).split('')
            .reduce(
                (r, v, index) => (index === 3 || index === 6 ? `${r}-${v}` : `${r}${v}`),
                ''
            )
            .substr(0, 12);
        if (string.endsWith('-')) {
            if (res.length === 3) {
                return `${res}-`;
            }

            if (res.length === 7) {
                return `${res}-`;
            }
        }
        return res;
    };

    const handleChange = (name, value,e) => {
         setCommonboolean(true); 
        if(name === 'city')

        {
            setValue({ ...valu,city: value })
            updateRoot({ ...valu,city: value }, ind);
           }

       else if (name === "bankRepContactNumber") {
    
            setValue({ ...valu, bankRepContactNumber: value })
            updateRoot({ ...valu, bankRepContactNumber: value }, ind);
            
        }

        else if (name === "bankName") {
    
            setValue({ ...valu, bankName: value })
            updateRoot({ ...valu, bankName: value }, ind);
            
        }

        else if (name === "bankRepresentative") {
    
            setValue({ ...valu, bankRepresentative: value })
            updateRoot({ ...valu, bankRepresentative: value }, ind);
            
        }

        else if (name === "specialInstruction") {
    
            setValue({ ...valu, specialInstruction: value })
            updateRoot({ ...valu,specialInstruction: value }, ind);
            
        }


    }

    const updateState = (e, key, maxDigits = 10, decimal = 2) => {
      

        if (numberValidator[key]) {
            let val = e?.target?.value.replace(/,/g, '');
             let valses = val[val.length - 1] == '.' ? val : Number(val)
             let vals =  val == '' ? '' : valses
            valu[key] = numberRangeValidator(val, maxDigits, decimal) ?
              vals : valu[key];

        } else {
            valu[key] = e?.target?.value;
        }
        setValue({ ...valu });
        updateRoot({ ...valu }, ind);
    }

    const updatedefaultValue = (key, val,max = 10, dec = 2) => {
        if (!valu[key]) {
            valu[key] = val;
            setValue({ ...valu });
            updateRoot({ ...valu }, ind);
        }

        else {
            let num = String(valu[key]).replace(/[,.]/g, '');
            let len = (num.length + dec) % max;
            if (len > dec || len === 0) {
                valu[key] = Number(valu[key]).toFixed(dec);
            } else if (len < dec) {
                valu[key] = Number(valu[key]).toFixed(len);
            }
            setValue({ ...valu });
            updateRoot({ ...valu }, ind)
        }

    }

    const handledrop = (val, key) => {
        valu[key] = countrystateData.find(state => state.stateName === val) && countrystateData.find(state => state.stateName === val).stateId;
        setState(val)
        setValue({ ...valu });
        updateRoot({ ...valu }, ind);
    }

    const onBlurEvent = (event) => {
        
        validateRouting(event.target.value);
    
    }

    return (
        <div className={`nestedThirdFormContainer`}>
            <div className={`headerContainer`}>
                <h2 className={`headingClass`}>
                    {`${ThirdPartyLabel.debtHeading} ${ind + 1}`}
                </h2>
                <Button onClick={() => removerpt(ind)}>{ThirdPartyLabel.btnDelrpt}
                 {itemDeleted && ind===0 ? <span class="sr-only" aria-live="assertive" role="alert" aria-label= {`Recipient ${itemDeleted+1} deleted`} ></span> : "" }
                </Button>
            </div>

            <Form fullWidthFields id={`debt-form-${id}`} className={`formClass`}>
                <Grid container spacing={2} >
                    <Grid item xs={12} >
                     <FormField className="reqfield" label = {<>{ThirdPartyLabel.accountName}<span className="require" role ='img' aria-label={Info_security}></span></>}>
                            <Input  
                                name="recipientName"
                                className="textFont"
                                autoComplete='off'
                                type="password"
                                value={accountName}
                                inputProps={{
                                    onPaste: (e) => {
                                        e.preventDefault()
                                        return false;

                                    }, onCopy: (e) => {
                                        e.preventDefault()
                                        return false;
                                    },
                                }}

                                onChange={(e) => setAccountname(e.target.value.replace(RegExps.recipientName, ''))}
                               
                            />
                        </FormField>
                    </Grid>

                    <Grid item xs={12}>
                        <FormField label={ThirdPartyLabel.city}  >
                            <Input
                                name={`city`}
                                fullWidth
                                autoComplete='off'
                                onChange={(e) => handleChange(e.target.name,e.target.value)}
                                value={valu.city}
                            />
                        </FormField>
                    </Grid>

                    <Grid item xs={12}>
                        <FormField label={ThirdPartyLabel.state}>
                        <AriaAnnouncerProvider>
                            <Dropdown
                                className={'dropDownField'}
                                name='states'
                                selectedItem={staty}
                                source={states}
                                onSelect={(e, selectedItem) => {handledrop(selectedItem, 'state') }}
                            />
                            </AriaAnnouncerProvider>
                        </FormField>

                    </Grid>
                    <Grid item xs={12}>
                    <FormField className="reqfield" label ={<>{ThirdPartyLabel.routing}<span className="require" role ='img'  aria-label={Info_security}></span></>}
                      classes={{ helperText: 'errorHelperText' }}
                    helperText={mergedErrorMessages(routingExceptions)}
                    validationState={routingExceptions[0]?.type}
                    >
                            <Input
                                name="routingNumber"
                                autoComplete='off'
                                type="password"
                                value={routing}
                                inputProps={{
                                    maxLength:"9",
                                }}
                                onBlur={(e) => onBlurEvent(e)}
                                onChange={(e) => setRouting(e.target.value.replace(RegExps.accountAndRoutingNumbers, ''))}
                            />
                        </FormField>
                    </Grid>
                    <Grid item xs={12}>
                        <FormField className="reqfield" label ={<>{ThirdPartyLabel.accountnumber}<span className="require" role ='img'  aria-label={Info_security}></span></>}
                            classes={{ helperText: 'errorHelperText' }}
                           
                        >
                            <Input
                                
                                name="accountNumber"
                                className="textFont"
                                type="password"
                                value={account}
                                inputProps={{
                                       onPaste: (e) => {
                                        e.preventDefault()
                                        return false;

                                    }, onCopy: (e) => {
                                        e.preventDefault()
                                        return false;
                                    },
                                    
                                     onKeyDown: (event) => {
                                        if(event.ctrlKey){
                                        event.preventDefault()
                                        return false;
                                        }
                                    },


                                }}

                                autoComplete = "off"
                                onBlur ={() => {
                                    setErrormsg(reaccount === '' ? '' : acco)
                                }}
                                onChange={(e) => setAccount(e.target.value.replace(RegExps.accountAndRoutingNumbers, ''))}
                            />
                        </FormField>
                    </Grid>
                    <Grid item xs={12}>
                        <FormField className="reqfield" label ={<>{ThirdPartyLabel.reaccountnumber}<span className="require" role ='img'  aria-label={Info_security}></span></>}
                            classes={{ helperText: 'errorHelperText' }}
                            helperText={errormsg}
                        >
                            <Input
                               
                                name="Reaccount"
                                className="textFont"
                                type="password"
                                autoComplete='off'
                                value={reaccount}
                                inputProps={{
                                        onPaste: (e) => {
                                            e.preventDefault()
                                            return false;

                                        }, onCopy: (e) => {
                                            e.preventDefault()
                                            return false;
                                        },
                                        onKeyDown: (e) => {
                                            if(e.ctrlKey){
                                            e.preventDefault()
                                            return false;
                                            }
                                        },
                                    }}

                                    onBlur ={() => {
                                        setErrormsg(reaccount === '' ? '' : acco)
                                    }}
                                onChange={(e) => setReaccount(e.target.value.replace(RegExps.accountAndRoutingNumbers, ''))}
                            />
                        </FormField>
                    </Grid>

                    <Grid item xs={12}>
                        <FormField label={ThirdPartyLabel.exact}>
                            <Input
                                name={`bankName`}
                                fullWidth
                                autoComplete='off'
                                onChange={(e) => handleChange(e.target.name,e.target.value)}
                                value={valu.bankName}
                            />
                        </FormField>
                    </Grid>


                    <Grid item xs={12}>
                        <FormField label={ThirdPartyLabel.namebank}>
                            <Input
                                name={`bankRepresentative`}
                                fullWidth
                                autoComplete='off'
                                onChange={(e) => handleChange(e.target.name,e.target.value)}
                                value={valu.bankRepresentative}
                            />
                        </FormField>
                    </Grid>

                    <Grid item xs={12}>
                        <FormField label={ThirdPartyLabel.telephone}
                         classes={{ helperText: 'errorHelperText' }}
                            helperText={error}
                        >
                            <FormattedInput autoComplete='off'
                                value={valu?.bankRepContactNumber || ''}
                               
                                onChange={value => { handleChange('bankRepContactNumber', value) }}
                               onBlur={( )=>{
                              setError(valu.bankRepContactNumber === '' ? '' : phono)
  
                            }}
                                
                                
                                name='bankRepContactNumber'
                                
                                rifmOptions={{
                                    accept: /[\d]/g, mask: value => 12 >= value.length,
                                    format: formatMobileValueWithAppend,
                                    append: (string) => string.length === 4 || string.length === 8 ? `${string}-` : string
                                }} />
                        </FormField>
                    </Grid>

                    <Grid item xs={12}>
                        <FormField label={ThirdPartyLabel.special} helperText= {ThirdPartyLabel.placeholderText}
                        helperTextPlacement={'tooltip'}
                        hasStatusIndicator >
                            <Input
                                name={`specialInstruction`}
                                fullWidth
                                autoComplete='off'
                                onChange={(e) => handleChange(e.target.name,e.target.value)}
                                value={valu.specialInstruction}
                                 role='tooltip' aria-label={Tooltip_info}
                            />
                        </FormField>
                    </Grid>

                    <Grid item xs={12}>
                        <FormField label={ThirdPartyLabel.totalAmount}>
                            <Input
                                name='wiredAmount'
                                fullWidth
                                autoComplete='off'
                                onBlur={() => updatedefaultValue('wiredAmount', '0.00')}
                                onChange={(e) => updateState(e, 'wiredAmount',10,2)}
                                value={valu?.wiredAmount ? formatUSD(valu?.wiredAmount):''}
                            />
                        </FormField>
                    </Grid>
                </Grid>
            </Form>
        </div>
    );
}
export default React.memo(ThirdPartyWiringDetails); 
