const OnlineRequestHeader = [
  {
    headerName: "Creation Date",
    field: "createdDate",
    filter: false,
    sortable: true,
    suppressMenu: true,
    resizable: true,
    cellRenderer: "dateLinkCellRenderer",
    maxWidth: 150,
    minWidth: 120,
    flex: 1,
  },
  {
    headerName: "Submitted Date",
    field: "loanSubmitDate",
    sortable: true,
    suppressMenu: true,
    filter: false,
    resizable: true,
    cellRenderer: "createDateCellRenderer",
    maxWidth: 120,
    minWidth: 100,
    flex: 1,
  },
  {
    headerName: "Franchisee",
    field: "franchisee",
    sortable: true,
    suppressMenu: true,
    resizable: true,
    cellRenderer: "franchiseeCellRenderer",
    tooltipField: "franchisee",
    filter: false,
    maxWidth: 180,
    minWidth: 120,
    flex: 1,
  },
  {
    headerName: "Loan Purpose(s)",
    field: "purposeName",
    sortable: true,
    suppressMenu: true,
    resizable: true.valueOf,
    cellRenderer: "purposeCellRenderer",
    filter: false,
    maxWidth: 250,
    minWidth: 200,
    flex: 1,
    autoHeight: true,
    wrapText: true,
  },
  {
    headerName: "Total Amount",
    field: "loanAmount",
    sortable: true,
    suppressMenu: true,
    resizable: true,
    cellRenderer: "amountCellRenderer",
    filter: false,
    maxWidth: 100,
    minWidth: 100,
    flex: 1,
  },
  {
    headerName: "Status",
    field: "loanStatus",
    filter: false,
    sortable: true,
    suppressMenu: true,
    resizable: true,
    cellRenderer: "statusCellRenderer",
    maxWidth: 150,
    minWidth: 120,
    flex: 1,
  },
  {
    headerName: "Action",
    field: "action",
    filter: false,
    sortable: true,
    suppressMenu: true,
    resizable: true,
    cellRenderer: "bankerCommentCellRenderer",
    minWidth: 150,
    flex: 1,
  },
];
export default OnlineRequestHeader;
