import React from 'react';
import { ButtonBar, OrderedButton } from '@jpmuitk/button-bar';
import { useDispatch } from 'react-redux';
import { postDisableData } from './redux/actions/FranchiseProfileAction';
import {
    Dialog,
    DialogActions,
    DialogTitle,
} from '@jpmuitk/dialog';

const WarningDialog = (props) => {
    const dispatch = useDispatch();
    const handleRequestClose = () => {
        props.setOpenModal({
            ...props.openModal,
            open: false
        })
    };

    const handleConfirm = () => {
        const payload = {
            "userId": props.openModal.data.userId,
            "loginId": props.openModal.data.loginId,
            "emailAddress": props.openModal.data.emailAddress,
            "enabled": !props.openModal.data.enabled,
            "contactAltId": props.openModal.data.contactAltId,
            "janusUserName": props.openModal.data.janusUserName

        }
      
        const searchPayload = {
            "fullName": props.franchiseeName,
          //  "userId": props.userId,
            "loginId": props.userid,
            "operatorId": props.operatorId,
            "janusUserName": props.janusUserName
        }
        dispatch(postDisableData(payload, searchPayload));
        handleRequestClose();
    };

    return (
        <>
            <Dialog
                onClose={handleRequestClose}
                open={props.openModal.open}
                enableBackdropClick
                state="info">
                <DialogTitle>{!props.openModal.isActive ? 'Are you sure you want to enable this user' : 'Are you sure you want to disable this user'}</DialogTitle>
                <DialogActions>
                    <ButtonBar>
                        <OrderedButton onClick={handleConfirm} variant="cta">Ok</OrderedButton>
                        <OrderedButton onClick={handleRequestClose}>Cancel</OrderedButton>
                    </ButtonBar>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default WarningDialog;