import { RECEIVE_GET__HOMEBANNER } from '../../../../global.constants'

const initialState = {
    homePageBanner:{}     
}

const HomePageReducer = (state = initialState, { type, payload }={}) => {

    switch (type) {
        case RECEIVE_GET__HOMEBANNER:
            const homeBannerState = {
                ...state,
                homePageBanner: payload,
            }
            return homeBannerState
        
        default:
            return state
    }
}

export default HomePageReducer;