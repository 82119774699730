import axios from "axios";
import {
  RECEIVE_GET_MISCELLANEOUS_DATA,
  FAILURE_GET_MISCELLANEOUS_DATA,
  RECEIVE_MISCELLANEOUS_POST_DATA,
  FAILURE_MISCELLANEOUS_POST_DATA,
  FAILURE_TO_DELETE,
  base_url,
  REQUEST_GET_MISCELLANEOUS_DATA,
  base_url_loan,
} from "../../../../global.constants";

export const getMiscellaneousDetailsData =
  (loanId, userId) => async (dispatch) => {
    const payload = { loanId: loanId, userId: userId };
    try {
      dispatch({ type: REQUEST_GET_MISCELLANEOUS_DATA });
      await axios
        .post(base_url_loan + `/api/v1/loansvc/ext/getMiscInfo.do`, payload)
        .then((response) => {
          if (response && response.status === 200) {
            dispatch(receiveMiscellaneousData(response?.data[0]));
          } else {
            dispatch(failureGetMiscellaneousData(response.status));
          }
        });
    } catch (e) {
      dispatch(failureGetMiscellaneousData(e.response.status));
      return e;
    }
  };

export const postMiscellaneousDetailsData = (data, loanId, userId) => {
  const payload = { ...data, loanId: loanId, userId: userId };
  return async (dispatch) => {
    dispatch({ type: REQUEST_GET_MISCELLANEOUS_DATA });
    const Url = base_url_loan + `/api/v1/loansvc/ext/updateMiscInfo.do`;
    await axios
      .put(Url, payload)
      .then((response) => {
        if (response && response.status === 200) {
          dispatch(receiveMiscellaneousPostData());
        } else {
          dispatch(failureMiscellaneousPostData(response.status));
        }
      })
      .catch((e) => {
        dispatch(failureMiscellaneousPostData(e.response.status));
      });
  };
};
export const deleteMiscellaneousDetailsData = (data, loanId, userId) => {
  const payload = { owrDtlId: data, loanId: loanId, userId: userId };
  return async (dispatch) => {
    const Url = base_url_loan + `/api/v1/loansvc/ext/deleteOwner.do`;
    axios
      .post(Url, payload)
      .then((response) => {
        if (response && response.status === 200) {
          console.log(response.status);
        }
      })
      .catch((error) => {
        dispatch(failureToDelete(error));
      });
  };
};
export const receiveMiscellaneousData = (data) => ({
  type: RECEIVE_GET_MISCELLANEOUS_DATA,
  payload: data,
});
export const failureGetMiscellaneousData = (payload) => ({
  type: FAILURE_GET_MISCELLANEOUS_DATA,
  payload,
});

export const receiveMiscellaneousPostData = (payload) => ({
  type: RECEIVE_MISCELLANEOUS_POST_DATA,
  payload,
});
export const failureMiscellaneousPostData = (error) => ({
  type: FAILURE_MISCELLANEOUS_POST_DATA,
  payload: error,
});

export const failureToDelete = (error) => ({
  type: FAILURE_TO_DELETE,
  payload: error,
});
