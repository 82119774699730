import React, {
  useEffect,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import { DataGrid } from "@jpmuitk/data-grid";
import { Pill } from "@jpmuitk/pill";
import { Button } from "@jpmuitk/button";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchReturntoDashboard,
  currentLoanId,
  createNewRequest,
  cancelLoanId,
} from "./redux/action/dashboardAction";
import { successBanner } from "../submitRequest/redux/actions/submitRequestAction";
import "./ReturnToDashboard.scss";
import "../../global.scss";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import {
  ReturntoDashboard,
  ERRORCODE,
  FiltersOption,
} from "../../global.constants";
import DialogBox from "../../../components/dialogBox/DialogBox";
import { Link } from "react-router-dom";
import Error from "../../../components/error/Error";
import Loader from "../../../components/loader/Loader";
import { getPreviewPdf } from "../../commonactions/redux/actions/PreviewAction";
import CustomPagination from "../../../components/pagination/pagination";

let filterValue = "All";
const YearFilter = forwardRef((props, ref) => {
  const [filter, setFilter] = useState("All");
  const dispatch = useDispatch();
  useImperativeHandle(ref, () => {
    return {
      doesFilterPass(params) {
        if (filter === "All") return true;
        return params.data.option === filter.toLowerCase();
      },
    };
  });

  const onFilterChange = (event) => {
    setFilter(event.target.value);
    filterValue = event.target.value;
    dispatch(
      fetchReturntoDashboard(
        sessionStorage.getItem("loggedInUserId"),
        1,
        true,
        filterValue
      )
    );
  };

  useEffect(() => {
    props.filterChangedCallback();
  }, [filter]);

  return (
    <select
      name="filterOptions"
      className="rtd-filter"
      id="filteroptions"
      onChange={onFilterChange}
    >
      {FiltersOption.map((opt) => (
        <option value={opt.filterValue}>{opt.filterData}</option>
      ))}
    </select>
  );
});

// UserStory FFANEW-21

const ReturnToDashboard = () => {
  const dashboardData = useSelector(
    (state) => state.DashboardReducer.returnToDashboard
  );
  const isBanner = useSelector(
    (state) => state.SubmitRequestFinanceFranchiesReducer.dashboardSuccesBanner
  );
  const isLoading = useSelector((state) => state.DashboardReducer.isLoading);
  const error = useSelector((state) => state.DashboardReducer.error);
  const cancelReq = useSelector((state) => state.DashboardReducer.cancelReq);
  const dispatch = useDispatch();
  const history = useHistory();
  const [apiData, setapiData] = useState({});
  const leftNavigation = true;
  const currentLoan = useSelector(
    (state) => state.DashboardReducer.currentLoan
  );
  const loggedInUserId = useSelector(
    (state) => state.AuthCheckReducer?.loggedInUserId
  );
  const loggedInUser = useSelector(
    (state) => state.AuthCheckReducer?.loggedInUser
  );

  sessionStorage.setItem("loggedInUserId", loggedInUserId);
  const userName = useSelector(
    (state) => state.AuthCheckReducer?.loggedInUserName
  );
  const user = useSelector((state) => state.AuthCheckReducer?.user);
  const [openModal, setopenModal] = useState(false);
  const [lder, setLder] = useState(false);

  useEffect(() => {
    if (dashboardData) {
      if (dashboardData.loans) {
        dashboardData.loans = dashboardData?.loans?.map((loan) => ({
          ...loan,
          option: loan.loanStatus,
          loanStatus: loan.loanStatus,
        }));
      }
      setapiData(dashboardData);
    }
    setLder(false);
  }, [dashboardData]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (currentLoan?.activeLoanId) {
      dispatch(currentLoanId({}));
    }
  }, []);

  useEffect(() => {
    setLder(true);
    if (!!loggedInUserId) {
      setTimeout(() => {
        dispatch(
          fetchReturntoDashboard(
            loggedInUserId,
            localStorage.getItem("pageNo"),
            true
          )
        );
      }, 2000);
    }
  }, [cancelReq, loggedInUserId]);

  useEffect(() => {
    localStorage.setItem("pageNo", 1);
  }, []);

  const onGridReady = ({ api }) => {
    api.sizeColumnsToFit();
  };

  const closeDialog = (value) => {
    setopenModal(value);
  };

  var canceldialogbox = openModal ? (
    <DialogBox
      id="dashboard"
      userId={loggedInUserId}
      open={openModal}
      closeDialog={closeDialog}
    ></DialogBox>
  ) : null;
  function MyRenderer(params) {
    const handleEdit = () => {
      const currentloan = {
        isLeftnavEnabled: leftNavigation,
        activeLoanId: params?.data?.loanId,
      };
      dispatch(successBanner(false));
      dispatch(currentLoanId(currentloan));
    };
    const handlePreview = () => {
      dispatch(successBanner(false));
      dispatch(getPreviewPdf(params?.data?.loanId, loggedInUserId));
    };
    const handleCancel = () => {
      dispatch(successBanner(false));
      dispatch(cancelLoanId(params?.data?.loanId));
      setopenModal(true);
    };

    return (
      <span className="my-renderer">
        {params.value != null && (
          <div>
            {params.value === "In Progress" && (
              <Link
                to="/contactInformation"
                onClick={handleEdit}
                className="edit-btn commonStylingButton"
              >
                EDIT
              </Link>
            )}
            <Pill
              label="PREVIEW"
              className={
                params.value === "In Progress"
                  ? "preview-btn"
                  : "preview-only-btn"
              }
              onClick={handlePreview}
            />
            {params.value === "In Progress" && (
              <span
                label="CANCEL"
                className="cancel-btn bold copyAddressButton"
                onClick={handleCancel}
              >
                CANCEL
              </span>
            )}
          </div>
        )}
      </span>
    );
  }

  const handleCreateNewRequest = () => {
    dispatch(successBanner(false));
    dispatch(createNewRequest(leftNavigation, loggedInUserId));
    history.push("/contactInformation");
    window.scrollTo(0, 0);
  };

  const handlePageChange = (e, page) => {
    if (!!page)
      dispatch(fetchReturntoDashboard(loggedInUserId, page, true, filterValue));
    dispatch(successBanner(false));
  };
  const getRowHeight = (params) => {
    let data = 24;
    if (params?.data?.purposes && params?.data?.purposes?.length) {
      if (params?.data?.purposes?.length > 9) {
        let purposeH = data * params?.data?.purposes?.length - 3;
        return purposeH;
      }
      if (params?.data?.purposes?.length > 2) {
        let purposeH = data * params?.data?.purposes?.length + 15;
        return purposeH;
      }
      data = data * params?.data?.purposes?.length;
    }
    return data;
  };
  const dataGridExampleColumns = [
    {
      headerName: "Creation Date",
      field: "createdDate",
      filter: false,
      sortable: true,
      resizable: true,
      maxWidth: 110,
      suppressMenu: true,
      minWidth: 110,
    },
    {
      headerName: "Purpose of Request",
      field: "purposes",
      cellRenderer: "purposeCellRenderer",
      filter: false,
      sortable: false,
      resizable: true,
      maxWidth: 350,
      minWidth: 200,
      suppressMenu: true,
      flex: 1,
    },
    {
      headerName: "Status",
      field: "loanStatus",
      filter: "yearFilter",
      sortable: true,
      resizable: true,
      suppressMenu: true,
      maxWidth: 200,
      minWidth: 150,
    },
    {
      headerName: "Options",
      field: "option",
      suppressMenu: true,
      sortable: false,
      resizable: true,
      cellRendererFramework: MyRenderer,
      filter: false,
      flex: 1,
      minWidth: 200,
    },
  ];

  return (
    <>
      {isLoading || lder ? <Loader /> : null}
      {error == ERRORCODE ? (
        <Error />
      ) : (
        <div
          className="dynamicContainer widthContent requestContainer"
          id="main"
          role="main"
        >
          <div
            id="myGrid"
            className="ag-theme-alpine returnTodashboardContainer"
          >
            <div className="success-banner" aria-live="assertive" role="alert">
              {isBanner ? ReturntoDashboard.sucessMsg : null}
            </div>
            <div className={`returnContainer`}>
              <h1 className="rtd-header">Hi</h1>
              <h1 className="rtd-header2">{userName}!</h1>
              <h1 className="rtd-header3">
                Welcome to your Franchise Finance Dashboard
              </h1>
            </div>
            <p className={`rtd-content-banner`}>{ReturntoDashboard.content}</p>

            <DataGrid
              classes={{ root: "dataGridAdminDashboard" }}
              className="return-to-dashboard-data-grid"
              columnDefs={dataGridExampleColumns}
              rowData={apiData?.loans}
              pagination={false}
              domLayout={"autoHeight"}
              paginationPageSize={10}
              onGridReady={onGridReady}
              getRowHeight={getRowHeight}
              frameworkComponents={{
                yearFilter: YearFilter,
                purposeCellRenderer: function (params) {
                  const purposes = params?.data?.purposes;
                  return (
                    <div className={`returnPurposeClass`}>
                      {purposes?.map((purpose, i) => (
                        <span
                          style={{
                            width: params.column.actualWidth,
                            wordBreak: "break-word",
                            whiteSpace: "break-spaces",
                          }}
                        >
                          {purpose?.purposeName}.
                        </span>
                      ))}
                    </div>
                  );
                },
              }}
            />
          </div>
          {!!apiData.totalPages && (
            <div className={`paginationClass`}>
              <CustomPagination
                count={apiData.totalPages}
                handlePageChange={handlePageChange}
                page={apiData.currentPage}
              />
            </div>
          )}
          <div className="create-new-request">
            <Button className="cnr" onClick={handleCreateNewRequest}>
              {ReturntoDashboard.btn}
            </Button>
          </div>
          {canceldialogbox}
        </div>
      )}
    </>
  );
};
export default React.memo(ReturnToDashboard);
