import React, { useState } from "react";
import "@jpmuitk/style/css/jpmuitk.css";
import "./footer.scss";
import "../../containers/global.scss";
import { PARA } from "../../containers/global.constants";
import { Link } from "@jpmuitk/link";
import { getFileAPI } from "../../containers/commonactions/redux/actions/action";

export default function Footer(props) {
  const [date, setDate] = useState(new Date());
  return (
    <footer className={`${!props.isDynamicScreen ? "footer-content" : ""}`}>
      {!props.isDynamicScreen && <p className="para container">{PARA}</p>}
      <div className="footer container">
        <div className="footer-1">
          {!props.isDynamicScreen && (
            <p className="content">
              For more information call Chase Franchise Finance at 800.543.4622,
              Option 0.
            </p>
          )}
          <p className="content marginTop30">
            © {date.getFullYear()} JPMorgan Chase & Co.
          </p>
        </div>
        <div className="footer-2">
          {!props.isDynamicScreen && (
            <div className="list-1">
              <ul role="list">
                <li role="listitem" className="Chase">
                  <Link
                    href="https://www.jpmorgan.com/commercial-banking"
                    target="uitk"
                    className="col"
                    aria-label="Chase External link"
                  >
                    Chase
                  </Link>
                </li>
                <li
                  role="listitem"
                  aria-hidden="true"
                  className="Chase"
                  className="col"
                >
                  {" "}
                  |{" "}
                </li>
                <li role="listitem" className="JPMorgan">
                  <Link
                    href="https://www.jpmorgan.com/global"
                    target="uitk"
                    className="col"
                    aria-label="JPMorgan External link"
                  >
                    JPMorgan
                  </Link>
                </li>
                <li
                  role="listitem"
                  aria-hidden="true"
                  className="Chase"
                  className="col"
                >
                  {" "}
                  |{" "}
                </li>
                <li role="listitem" className="McDonalds">
                  <Link
                    href="https://www.mcdonalds.com/us/en-us.html"
                    target="uitk"
                    className="col"
                    aria-label="McDonald's External link"
                  >
                    McDonald's
                  </Link>
                </li>
              </ul>
            </div>
          )}
          <div className="list-2">
            <ul className={`${!props.isDynamicScreen ? "" : "marginTop30"}`}>
              <li className="Terms">
                <Link
                  href="https://www.chase.com/digital/resources/terms-of-use"
                  target="uitk"
                  className="col"
                  aria-label="Terms of Use External link"
                >
                  Terms of Use
                </Link>
              </li>{" "}
              <li className="Chase" aria-hidden="true" className="col">
                {" "}
                |{" "}
              </li>
              <li className="fair">
                <Link
                  href={""}
                  onClick={(e) =>
                    getFileAPI(e, "fairlending", "msword", "Fair Lending")
                  }
                  target="uitk"
                  className="col"
                  aria-label="Fair Lending External link"
                >
                  Fair Lending
                </Link>
              </li>
              <li className="Chase" aria-hidden="true" className="col">
                {" "}
                |{" "}
              </li>
              <li className="policy">
                <Link
                  href="https://www.chase.com/digital/resources/privacy-security"
                  target="uitk"
                  className="col"
                  aria-label="Privacy Policy External link"
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
