

const initialrptSummary = {
   
    AccountDetails: [],
    isLoading: false,
    error: ''
};

const ThirdPartyWiringReducer = (state = initialrptSummary, action={}) => {
    
    const { type, payload } = action;

    let newState = { ...state };

    switch (type) {
        case 'PARTY_RECEIVE':
        case "PARTY_REQUEST":

            newState.isLoading = true;

            return newState;


        case "SET_ACC_DETAILS_LIST":

            newState.AccountDetails = payload;
           
            newState.isLoading = false
        

            return newState;
        case  'SAVE_PARTY_FAILURE':
        case  "FETCH_PARTY_FAILURE":

             newState.isLoading = false

              newState.error = payload

              return newState;

        case "UPDATE_ACC_DETAILS_LIST":

            newState.AccountDetails = [...payload];
            
            newState.isLoading = false

            return  newState ;

        default:
            return newState;
    }
}

export default ThirdPartyWiringReducer;