import axios from "axios";
import centralStore from "../../../../store";
import { base_url, base_url_user } from "../../../global.constants";

const baseURL = base_url_user + "/api/v1/usersvc/ext/";

export const setData_request = (payload) => ({
  type: "SET_DEBT_REQUEST",
  payload,
});
export const setData = (payload) => ({
  type: "SET_DEBT_DETAILS_LIST",
  payload,
});
export const setData_failure = (error) => ({
  type: "SET_DEBT_FAILURE",
  payload: error,
});

export const getData = (url, loanId, userId) => {
  const payload = { loanId: loanId, userId: userId };
  url = baseURL + url;
  return async (dispatch) => {
    dispatch(setData_request(true));
    await axios
      .post(url, payload)
      .then((response) => {
        if (response && response?.status === 200) {
          dispatch(setData(response?.data?.debtVOs));
        } else {
          dispatch(setData_failure(response.status));
        }
      })

      .catch((e) => {
        dispatch(setData_failure(e.response.status));
      });
  };
};

export const postData = (url, data, userId, loanId) => {
  url = baseURL + url;
  const payload = { loanId: loanId, userId: userId, debtVOs: data };

  return async (dispatch) => {
    await axios
      .put(url, payload)
      .then((response) => {})
      .catch((error) => {
        console.error("Something went wrong!", error);
      });
  };
};

export const updateData = (payload) => ({
  type: "UPDATE_DEBT_DETAILS_LIST",
  payload,
});
